<template>
  <div>
    <v-simple-table class="text-left">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Form Name
            </th>
            <th class="text-left">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Visitas técnicas y Record de Aplicaciones</td>
            <td>
              <v-btn color="success" small dense :to="{name: 'TechnicalVisitView', query: { uuid: customerUuid, fieldUuid: field.uuid }}">
                {{$t('startFilling')}}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>

</template>

<script>
export default {
  props: ['customerUuid', 'field'],
  name: "AllAssessmentsTable"
}
</script>

<style scoped>

</style>


<i18n>
{
  "en": {
    "startFilling": "Assess"
  },
  "es": {
    "fieldName": "Evaluar"
  },
  "sw": {
    "fieldName": "Tathmini"
  }
}
</i18n>
