<template>
  <farmerEdit v-on:submit="submit" />
</template>


<script>

  import FarmerEdit from '@/views/farmers/components/Edit';

  export default {

    components: {
      FarmerEdit,
    },

    data: () => ({
      farmer: null,
    }),

    computed: {
    },

    methods: {
      submit(farmer) {
        this.$store.dispatch('upsertFarmer', farmer);
      }
    }

  }
</script>
