<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
      <tr
      >
        <td>{{ crops[field.primaryCropId] }} </td>
        <td>{{ crops[field.cropId] }} </td>
        <td>{{ field.fieldType }} </td>

        <td class="text-left">{{ fieldLandSize(field) }} <small>{{ fieldLandSizeUnit(field) }}</small></td>
        <td>

          <v-btn color="primary" text :to="{name: 'Field', query: { uuid: customer.uuid, fieldUuid: field.uuid } }" class="ml-1">
            <v-icon dense>mdi-flower-outline</v-icon>
          </v-btn>

        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import fields from "@/mixins/fields";
import Row from "@/components/Row";

export default {
  props: {
    customer: Object,
  },

  components: {
    Row,
  },

  computed: {
    crops() { return this.$store.getters.cropLookups; },
    customerAssessments() { return this.$store.getters.fieldSurveyAssessments(this.customer.uuid); },
  },

  mixins: [fields],
}
</script>

<style scoped>

</style>
