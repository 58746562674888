export const weedsData = [
    {
        "pest": "Hojas Anchas - Contacto",
        "activeIngredient": "Paraquat     SL 200",
        "Dose": "1.500 - 3.000 cc/ha"
    },
    {
        "pest": "Hojas Anchas - Contacto",
        "activeIngredient": "Extracto Citricos, Glicerina USP, Enzimas Proteoliticas y Metabolitos secundarios de origen vegetal     HO",
        "Dose": "30 cc/Litro"
    },
    {
        "pest": "Hojas Delgadas - Contacto",
        "activeIngredient": "Paraquat     SL 200",
        "Dose": "1.500 - 3.000 cc/ha"
    },
    {
        "pest": "Hojas Delgadas - Contacto",
        "activeIngredient": "Extracto Citricos, Glicerina USP, Enzimas Proteoliticas y Metabolitos secundarios de origen vegetal     HO",
        "Dose": "30 cc/Litro"
    },
    {
        "pest": "Hojas Anchas - Sistemico No Selectivo",
        "activeIngredient": "Glifosato     480 SL",
        "Dose": "3.000 cc/ha"
    },
    {
        "pest": "Amplio Espectro Sistemico No Selectivo",
        "activeIngredient": "Glifosato     480 SL",
        "Dose": "3.000 cc/ha"
    },
    {
        "pest": "Hojas Anchas  Semisistemico y Contacto",
        "activeIngredient": "Finale SL 150 - Burner 150 SL.        (Glufosinato de amonio)",
        "Dose": "1.500 - 2.000 cc/ha"
    },
    {
        "pest": "Graminicida Sistemico Selectivo",
        "activeIngredient": "Clethodim     120 EC",
        "Dose": "750 - 1.250 cc/ha"
    }
]
