export default {
  computed: {
    domain() {
      const hostname = window.location.hostname;
      let d = hostname.split('.')[0];
      if (hostname.includes('mazao')) { d = 'emazao'; }
      return d;
    },
  },
}
