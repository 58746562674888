<template>
  <v-select :items="items" :label="label" v-model="title" @change="change" :rules="required ? [requiredRule] : []" />
</template>

<script>
  export default {

    props: {
      value: String,
      required: Boolean,
      label: {
        type: String,
        default: 'Title',
      },
    },

    data: () => ({
      title: undefined,
      defaults: [
        { text: 'Mr',   value: 'mr'   },
        { text: 'Mrs',  value: 'mrs'  },
        { text: 'Ms',   value: 'ms'   },
        { text: 'Dr',   value: 'dr'   },
        { text: 'Prof', value: 'prof' },
      ],
    }),

    computed: {
      items() { return (this.titles || this.defaults); }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.title = to; }
      },
      title(to) { this.$emit('input', this.title ); },
    },

    methods: {
      change() {
        this.$emit('change', this.title)
      },
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },

  };
</script>