<template>
  <Urban         v-if="formChoice==='urban'" />
  <NileUg        v-else-if="formChoice==='nile_ug'" />
  <DefaultCreate v-else />
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import fields from '@/mixins/fields';

  import DefaultCreate from '@/views/purchases/partners/Default';
  import Urban from '@/views/purchases/partners/Urban';
  import NileUg from "@/views/purchases/partners/NileUgBase";

  export default {

    components: { DefaultCreate, Urban, NileUg },

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      formChoice() {
        let f = 'default';
        if (['urban_zm', 'urban_ke','urban_ug'].includes(this.partnerCode)) {
          f = 'urban';
        }
        if (this.partnerCode === 'nile_ug') {
          f = this.partnerCode
        }
        return f;
      },
    },

  }
</script>
