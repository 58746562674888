<template>

  <v-row>
    <v-col cols="12">
      <ServerSideResult :test="loanParameters" />
    </v-col>
  </v-row>

</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { ServerSideResult },

  props: ['customer', 'value'],

  data() {
    return {
      loanParameters: undefined,
    }
  },

  watch: {
    loanParameters(to) { this.$emit('input', to); }
  },

  mounted() {
    this.remoteLookup('ultimateTzCheckMeBackLimit', { uuid: this.customer.uuid }).then( limit => this.loanParameters = limit ).catch(e => this.loanParameters = null);
  },

  mixins: [lookups],

}
</script>
