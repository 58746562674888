<template>

  <v-container fluid class="pa-0">
    <v-row>

      <v-col :cols="cols">
        <v-combobox
          v-model="level0"
          :rules="(0 < (requiredLevels || 0) ? [requiredRule] : [])"
          :items="options0"
          v-if="options0.length>0"
          :label="$t(heirarchy[0])"
          :hint="heirarchy[0]"
          single-line
          persistent-hint
        ></v-combobox >
        <v-text-field
          v-else
          v-model="level0"
          :rules="(0 < (requiredLevels || 0) ? [requiredRule] : [])"
          :label="$t(heirarchy[0])"
          :hint="heirarchy[0]"
          single-line
          persistent-hint
        ></v-text-field >
      </v-col>

      <v-col :cols="cols" v-if="(showLevels || 10)>1">
        <v-combobox
          v-model="level1"
          :rules="(1 < (requiredLevels || 0) ? [requiredRule] : [])"
          :items="options1"
          v-if="options1.length>0"
          :label="$t(heirarchy[1])"
          :hint="heirarchy[1]"
          :disabled="!level0"
          single-line
          persistent-hint
        ></v-combobox >
        <v-text-field
          v-else
          v-model="level1"
          :rules="(1 < (requiredLevels || 0) ? [requiredRule] : [])"
          :label="$t(heirarchy[1])"
          :hint="heirarchy[1]"
          :disabled="!level0"
          single-line
          persistent-hint
        ></v-text-field >
      </v-col>

      <v-col :cols="cols" v-if="(showLevels || 10)>2">
        <v-combobox
          v-model="level2"
          :rules="(2 < (requiredLevels || 0) ? [requiredRule] : [])"
          :items="options2"
          v-if="options2.length>0"
          :label="$t(heirarchy[2])"
          :hint="heirarchy[2]"
          :disabled="!level1"
          single-line
          persistent-hint
        ></v-combobox >
        <v-text-field
          v-else
          v-model="level2"
          :rules="(2 < (requiredLevels || 0) ? [requiredRule] : [])"
          :label="$t(heirarchy[2])"
          :hint="heirarchy[2]"
          :disabled="!level1"
          single-line
          persistent-hint
        ></v-text-field >
      </v-col>

      <v-col :cols="cols" v-if="(showLevels || 10)>3">
        <v-combobox
          v-model="level3"
          :rules="(3 < (requiredLevels || 0) ? [requiredRule] : [])"
          :items="options3"
          v-if="options3.length>0"
          :label="$t(heirarchy[3])"
          :hint="heirarchy[3]"
          :disabled="!level2"
          single-line
          persistent-hint
        ></v-combobox >
        <v-text-field
          v-else
          v-model="level3"
          :rules="(3 < (requiredLevels || 0) ? [requiredRule] : [])"
          :label="$t(heirarchy[3])"
          :hint="heirarchy[3]"
          :disabled="!level2"
          single-line
          persistent-hint
        ></v-text-field >
      </v-col>

      <v-col :cols="cols" v-if="(showLevels || 10)>4">
        <v-combobox
          v-model="level4"
          :rules="(4 < (requiredLevels || 0) ? [requiredRule] : [])"
          :items="options4"
          v-if="options4.length>0"
          :label="$t(heirarchy[4])"
          :hint="heirarchy[4]"
          :disabled="!level3"
          single-line
          persistent-hint
        ></v-combobox >
        <v-text-field
          v-else
          v-model="level4"
          :rules="(4 < (requiredLevels || 0) ? [requiredRule] : [])"
          :label="$t(heirarchy[4])"
          :hint="heirarchy[4]"
          :disabled="!level3"
          single-line
          persistent-hint
        ></v-text-field >
      </v-col>

      <v-col :cols="cols" v-if="(showLevels || 10)>5">
        <v-combobox
          v-model="level5"
          :rules="(5 < (requiredLevels || 0) ? [requiredRule] : [])"
          :items="options5"
          v-if="options5.length>0"
          :label="$t(heirarchy[5])"
          :hint="heirarchy[5]"
          :disabled="!level4"
          single-line
          persistent-hint
        ></v-combobox >
        <v-text-field
          v-else
          v-model="level5"
          :rules="(5 < (requiredLevels || 0) ? [requiredRule] : [])"
          :label="$t(heirarchy[5])"
          :hint="heirarchy[5]"
          :disabled="!level4"
          single-line
          persistent-hint
        ></v-text-field >
      </v-col>

    </v-row>
  </v-container>

</template>

<script>

  export default {

    props: {
      value: Array,
      rules: Array,
      levels: Number,
      requiredLevels: Number,
    },

    data: () => ({
      level0: undefined,
      level1: undefined,
      level2: undefined,
      level3: undefined,
      level4: undefined,
      level5: undefined,
    }),

    computed: {

      showLevels() { return 10 + Math.min( (this.heirarchy || []).length, (this.levels || 10) ) },

      heirarchy() { return this.$store.getters.settings.geographicHeirarchy; },
      regions() { return this.$store.getters.settings.regions; },

      options0() {
        let r = [];
        if (this.regions) {
          r = Object.keys(this.regions).sort();
        }
        return r;
      },
      options1() {
        let r = [];
        if (this.level0 && this.regions[this.level0]) {
          r = Object.keys(this.regions[this.level0]).sort();
        }
        return r;
      },
      options2() {
        let r = [];
        if (this.level1 && this.regions[this.level0] && this.regions[this.level0][this.level1]) {
          r = Object.keys(this.regions[this.level0][this.level1]).sort();
        }
        return r;
      },
      options3() {
        let r = [];
        if (this.level2 && this.regions[this.level0] && this.regions[this.level0][this.level1] && this.regions[this.level0][this.level1][this.level2]) {
          r = Object.keys(this.regions[this.level0][this.level1][this.level2]).sort();
        }
        return r;
      },
      options4() {
        let r = [];
        if (this.level2 && this.regions[this.level0] && this.regions[this.level0][this.level1] && this.regions[this.level0][this.level1][this.level2] && this.regions[this.level0][this.level1][this.level2][this.level3]) {
          r = Object.keys(this.regions[this.level0][this.level1][this.level2][this.level3]).sort();
        }
        return r;
      },
      options5() {
        let r = [];
        if (this.level2 && this.regions[this.level0] && this.regions[this.level0][this.level1] && this.regions[this.level0][this.level1][this.level2] && this.regions[this.level0][this.level1][this.level2][this.level3] && this.regions[this.level0][this.level1][this.level2][this.level3][this.level4]) {
          r = Object.keys(this.regions[this.level0][this.level1][this.level2][this.level3][this.level4]).sort();
        }
        return r;
      },

      result() { return [this.level0, this.level1, this.level2, this.level3, this.level4, this.level5]; },

      cols() {
        let c = undefined;
        if (this.$vuetify.breakpoint.name=='xs') {
          c = 12;
        } else {
          c = 4;
        }
        return c;
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.level0 = to[0];
            this.level1 = to[1];
            this.level2 = to[2];
            this.level3 = to[3];
            this.level4 = to[4];
            this.level5 = to[5];
          } else {
            this.level0 = undefined;
            this.level1 = undefined;
            this.level2 = undefined;
            this.level3 = undefined;
            this.level4 = undefined;
            this.level5 = undefined;
          }
        }
      },
      result(to) { this.$emit('input', to); },
    },

    methods: {

      requiredRule(value) {
        let r = [
          v => !!v || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },


      // setLocality(e) { this.geographic3 = e; },

      // setGeographicOptions() {
      //   this.geographicOptions0 = this.getGeographicOptions0();
      //   this.geographicOptions1 = this.getGeographicOptions1();
      //   this.geographicOptions2 = this.getGeographicOptions2();
      // },

      // getGeographicOptions0() { return Object.keys(this.geographicStructure).sort(); },
      // getGeographicOptions1() {
      //   let places = {};
      //   if (this.geographic0) { places = this.geographicStructure[this.geographic0]; }
      //   places = places || {};
      //   return Object.keys(places).sort();
      // },
      // getGeographicOptions2() {
      //   let places = [];
      //   if (this.geographic0 && this.geographic1) { places = (this.geographicStructure[this.geographic0] || {})[this.geographic1]; }
      //   places = places || [];
      //   return places.sort();
      // },


      // changed0() {
      //   this.geographic1 = '';
      //   this.geographic2 = '';
      //   this.geographicOptions1 = this.getGeographicOptions1();
      //   this.geographicOptions2 = this.getGeographicOptions2();
      // },
      // changed1() {
      //   this.geographic2 = '';
      //   this.geographicOptions2 = this.getGeographicOptions2();
      // },
    },

  }
</script>


<i18n>
{
  "en": {
    "Area": "Area",
    "District": "District",
    "Province": "Province",
    "Region": "Region",
    "Town": "Town",
    "Village": "Village",
    "Ward": "Ward",
    "Locality": "Locality",
    "hint": "Select from menu, or enter new"
  },
  "es": {
    "Area": "Municipio",
    "District": "District",
    "Province": "Province",
    "Region": "Departamento",
    "Town": "Ciudad / Pueblo",
    "Village": "Village",
    "Ward": "Ward",
    "Locality": "Vereda",
    "hint": "Seleccionar del menú o escribir nuevo"
  },
  "sw": {
    "Area": "Eneo",
    "District": "Wilaya",
    "Province": "Mkoa",
    "Region": "Mkoa",
    "Town": "Mji",
    "Village": "Kijiji",
    "Ward": "Kata",
    "Locality": "Eneo",
    "hint": "Chagua kutoka kwa menyu, au ingiza mpya"
  }
}
</i18n>
