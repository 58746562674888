<template>
  <v-stepper-content step="2" class="ml-0">
    <v-form ref="locationForm" v-model="valid">
      <v-container fluid>
        <v-row v-if="!!customer">
          <v-col cols="12" md="4">
            <DepotInput v-model="customer.depot" label="Co-op" required />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox v-model="customer.atHome" label="Are we near where you live?" />
          </v-col>

          <v-col cols="12" md="4">
            <SelectInput v-model="customer.coffeeRegion" :items="coffeeRegions" label="Coffee Region" required />
          </v-col>
          <v-col cols="12">
            <RegionInput v-model="customer.demographic.geographicsArray" :requiredLevels="4" />
          </v-col>
        </v-row>

        <v-row class="mx-4 my-4">
          <v-col cols="12">
            <v-btn color="primary" style="width: 100%;" @click="validateAndContinue" :disabled="!valid"> Save & Continue </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import SelectInput from '@/components/SelectInput';
import RegionInput from '@/components/RegionInput';
import DepotInput from '@/components/DepotInput';

export default {

  components: { SelectInput, RegionInput, DepotInput },

  props: {
    value: Object,
  },

  data() {
    return {
      customer: undefined,
      valid: false,
      regions: ['Central', 'Northern', 'Eastern', 'Western'],
      coffeeRegions: ['Elgon', 'Busoga', 'Central', 'South West', 'Mid-North', 'Rwenzori', 'West Nile'],
    }
  },

  computed: {
    coffeeDistricts() { 
      let r = []
      if (this.customer.region) {
        r = this.districtOptions.western
        if (this.customer.region == 'Central') {
          r = this.districtOptions.central;
        } else if (this.customer.region == 'Northern') {
          r = this.districtOptions.northern;
        } else if (this.customer.region == 'Eastern') {
          r = this.districtOptions.eastern;
        } else if (this.customer.region == 'Western') {
          r = this.districtOptions.western;
        }
      }
      return r;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { 
        this.customer = to;
       }
    },
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
  },

  methods:{
    validate() {
      this.$refs.locationForm.validate();
    },
    validateAndContinue(){
      this.validate();
      this.$emit('nextStep');
    }
  },
}
</script>
