<template>
  <CheckMeBack :customer="customer"   :instrument="instrument" v-if="type=='InstrumentUltimateTzCheckMeBack'" />
  <SalaryAdvance :customer="customer" :instrument="instrument" v-else />
</template>

<script>
import SalaryAdvance from "@/views/customers/partners/ultimate_tz/components/salaryAdvance/Balances"
import CheckMeBack from "@/views/customers/partners/ultimate_tz/components/checkMeBack/Balances"

export default {

  components: { SalaryAdvance, CheckMeBack },

  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

}
</script>
