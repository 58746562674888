<template>

  <v-card>

    <v-card-title class="text-body-1">{{label}}</v-card-title>

    <l-map ref="map" :zoom="zoomFactor" :center="countryCentre" :style="styles" @click="addMarker">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <v-geosearch :options="geosearchOptions" ></v-geosearch>
      <l-marker v-for="marker, index in markers" :lat-lng="marker"></l-marker>
    </l-map>

    <v-card-text>
      <v-row align="center" class="mx-0" >

      </v-row>
    </v-card-text>

  </v-card>

</template>

<script>
// https://levelup.gitconnected.com/creating-a-custom-input-component-for-your-vuetify-form-2306ecfa8e78

import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';

export default {

  components: { LMap, LTileLayer, LMarker, LTooltip, VGeosearch },

  props: {
    value: Object,
    width: String,
    height: String,
    zoom: String,
    label: { type: String, default: 'Click On Location' },
    required: { type: Boolean, default: false },
  },

  data() {
    return {

      markers: [],
      
      url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        style: 'bar',
      },

      countryCentres: {
        CN: [34.4159664,86.0597456,4],
        CO: [4.472444,-73.608963,6],
        GH: [7.8984525,-3.279619,7],
        IN: [20.7496491,73.7126418,5],
        KE: [0.1544414,35.6591193,7],
        MW: [-13.2385279,32.046427,7],
        MZ: [-18.5834465,31.3018654,6],
        NG: [9.0270273,6.4282607,7],
        RW: [-1.9435634,29.3186274,9],
        SN: [14.4762603,-15.6407739,8],
        SZ: [-26.5165607,30.9009831,9],
        TG: [8.6087768,-0.2914759,8],
        TZ: [-6.3679558,32.74067,7],
        UG: [1.367892,31.1793222,8],
        US: [37.2568237,-104.672972,5],
        ZA: [-34.2961664,18.237231,6],
        ZM: [-13.1305789,25.6050687,7],
        ZW: [-19.0020193,26.9038308,7],
      }

    }
  },

  computed: {
    styles() {
      return {
        'width': this.width || '100%',
        'height': this.height || '300px',
      }
    },
    zoomFactor() { return this.zoom || 6 },

    countryCode() { return this.$store.getters.settings.countryCode; },
    countryCentre() {
      let c = this.countryCentres[this.countryCode];
      if (!c) { c = [-2.983, 30.856]; }
      return L.latLng(c[0], c[1]);
    },

    location() { return this.markers[0]; },
  },

  watch: {
    location(to) {
      this.$emit('input', to);
      // this.$refs.map.mapObject.panTo(L.latLng(to[0][0], to[0][1]));
    },
  },

  methods: {
    invalidate() {
      let _this = this;
      setTimeout(() => {
        _this.$refs.map.mapObject.invalidateSize(true);
      }, 200)
    },
    addMarker(event) {
      this.markers.pop();
      if (event) {
        console.log('push');
        console.log(event);
        this.markers.push(event.latlng);
        console.log('pushed');
      }
    },
  },

  mounted() {
    this.addMarker(this.value);
  }

};
</script>

