<template>
  <v-stepper-content step="4" class="ml-0">
    <v-form v-if="!!customer" ref="form7otp" v-model="valid" class="text-left" >

      <v-row class="mx-4 my-4">
        <v-col cols="12" md="5" class="py-5">
          <SignatureInput v-model="customer.sign_one" required type="signature" :typeId="customer.uuid" showImage />
        </v-col>  
      </v-row>
      <v-row class="mx-4 my-4"> 
        <v-col cols="12" md="5">
          <v-otp-input length="4" v-model="customer.msisdnOtp" label="OTP" type="password" />
        </v-col>
        <v-col cols="12" md="2">
          <v-btn>Resend</v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-4 my-4">
        <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid">Save</v-btn>
        <v-btn color="gray darken-3" class="mb-2" @click="$emit('previousStep');">Back</v-btn>
      </v-row>
      
    </v-form>
  </v-stepper-content>
</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";
import SignatureInput from "@/components/SignatureComponent/SignatureInput";

export default {

  components: { MsisdnInput, SignatureInput },

  props: {
    value: Object,
  },

  data() {
    return {
      customer: undefined,
      valid: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.form7otp.validate();
    },
    validateAndContinue(){
      this.validate();
      this.$emit('nextStep');
    },
    saveImage(imageBlob) {
      let uuid = ImageDb.add(imageBlob);
      this.$emit('saveImage', uuid);

    },
  },

}
</script>
