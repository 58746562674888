<template>
  <PageHeader title="Collect Inputs" back @back="toCustomer">

    <v-col cols="12">
      <SkuEditableTable v-model="purchases"/>
    </v-col>

    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="text-left">Inputs Cost</td>
              <td class="text-right"><b><Currency :value="cost" /></b></td>
            </tr>
            <tr>
              <td class="text-left">Credit available</td>
              <td class="text-right"><b><Currency :value="creditAvailable" /></b></td>
            </tr>
            <tr>
              <td class="text-left" :class="{ 'red--text': anyCashRequired }">Cash Payment Required</td>
              <td class="text-right"><b><Currency :class="{ 'red--text': anyCashRequired }" :value="cashRequired" /></b></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-col cols="12" class="text-right mt-2">
      <v-btn class="ml-auto" @click="submit" :disabled="!validPurchase" >submit</v-btn>
    </v-col>

  </PageHeader>

</template>

<script>
import {v4 as uuidv4} from "uuid";

import PageHeader from "@/components/PageHeader";
import SkuEditableTable from "@/views/purchases/components/SkuEditableTable";
import Currency from "@/components/Currency";

export default {

  components: { PageHeader, SkuEditableTable, Currency },

  props: {
    balances: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      purchases: {},
    }
  },
  computed: {
    customerUuid() { return this.$route.query.uuid; },
    fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    location() { return ((this.$store.getters.geolocation || {}).coords); },
    cost() { return (this.purchases.cost || 0); },
    creditAvailable() { return (this.balances.credit_available || 0); },
    cashRequired() { return Math.max( (this.cost-this.creditAvailable), 0 ); },
    anyCashRequired() { return (this.cashRequired>0); },
    validPurchase() { return this.purchases.valid; },
  },

  methods: {
    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    submit() {
      const delivery_uuid = uuidv4();
      this.purchases.tableResults.filter( e => (e.quantity || 0)>0 ).forEach(e => {
        const price = (parseFloat(e.price_per_unit) || 0);
        const quantity = (parseInt(e.quantity) || 0);
        const value = price*quantity;
        const item = {
          uuid: e.uuid,
          delivery_uuid: delivery_uuid,
          createdAt: new Date().toISOString(),
          sku: e.code,
          description: e.description,
          price_per_unit: price,
          value: value,
          quantity: quantity,
          status: 'placed',
          customerUuid: this.customerUuid,
          location: this.location,
          capturedById: this.fieldAgentId,
        }
        this.$store.dispatch('addPurchase', item);
      });

      this.toCustomer()
    },

  },
}
</script>

<style scoped>

</style>