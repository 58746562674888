<template>
  <v-card>
    <v-card-title>
      {{$t('title')}}
    </v-card-title>

    <v-card-text>
      <v-simple-table v-if="assessments.length>0">
        <tbody>
          <tr v-for="assessment in assessments">
            <td class="text-left"><Time :time="assessment.scoredAt" format="date" /></td>
            <td class="text-left">{{assessment.description}}</td>
            <td class="text-left">
              <v-rating
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                medium
                readonly
                :value="Number(assessment.score)"
              ></v-rating>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else>
        {{$t('noPurchases')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>
  import Time from '@/components/Time';
  import NumberFormatter from '@/components/NumberFormatter';

  export default {

    components: { Time, NumberFormatter },

    props: ['customer'],

    computed: {
      assessments() { return (this.customer.assessments || []).sort( (a,b) => (a.scoredAt < b.scoredAt ? 1 : -1) ).slice(0,7); }
    },

    methods: {
    },

  }
</script>


<i18n>
{
  "en": {
    "title": "Assessments",
    "noPurchases": "No purchases yet."
  },
  "es": {
    "title": "Evaluaciones",
    "noPurchases": "Sin compras todavía."
  },
  "sw": {
    "title": "Tathmini",
    "noPurchases": "Hakuna ununuzi bado."
  }
}
</i18n>
