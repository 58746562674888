<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.msisdn" />
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-btn-toggle small dense v-model="village" mandatory class="mt-4">
            <v-btn>Chekereni</v-btn>
            <v-btn>Moshi Town</v-btn>
          </v-btn-toggle>
        </v-col>

      </v-row>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <NameInput v-model="customer.firstName" :label="$t('First Name')" />
        </v-col>

        <v-col cols="12" sm="6">
          <NameInput v-model="customer.surname" :label="$t('Surname')" />
        </v-col>
      </v-row>

      <v-row class="red lighten-5">

        <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-model="customer.age"
              :label="$t('age')"
              min=0
              max=100
              type='number'
            />
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <GenderInput v-model="customer.demographic.gender" />
        </v-col>


      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn @click="$emit('clearInputs')" class="d-none d-sm-inline">{{$t('Clear')}}</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="save('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">{{$t('SaveAndViewDetail')}}</v-btn>
              <v-btn @click="save('submitAndNew')" color="primary" :disabled="!validForm">{{$t('SaveAndAddNew')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>
  import fields from '@/mixins/fields';

  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import NameInput from '@/components/NameInput';
  import GenderInput from '@/components/GenderInput';

  export default {

    components: {
      RegionPicker,
      MsisdnInput,
      NameInput,
      GenderInput,
    },

    props: ['customer'],

    data: () => ({
      validForm: false,
      villages: ['Chekereni', 'Moshi Town'],
      village: undefined,
    }),


    computed: {
      // crops() { return this.$store.getters.cropNames; },
      subdistricts() { return this.$store.getters.settings.subdistricts; },
      parishes() { return this.$store.getters.settings.parishes; },
    },

    watch: {
      village: function(id) { this.customer.regions = ['Kilimanjaro Region', this.villages[id || 0]]; },
    },

    methods: {
      save(action) {
        let field = this.newField('Maize');
        this.updateCustomerField(field);
        console.log(this.customer);
        this.$emit(action);
      },
      resetForm() {
        this.$refs.contactForm.reset()
      }
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "Primary Crop": "Primary Crop",
    "First Name": "First Name",
    "Surname": "Surname",
    "Notes": "Notes",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear",
    "subdistricts": "Subdistrict",
    "parish": "Parish",
    "source": "Source",
    "profession": "Prior Profession",
    "age": "Age",
    "transport": "Transport",
    "isAnAgent": "Is also an Agent",
    "isNotAnAgent": "Is NOT an Agent"
  },
  "es": {
    "Primary Crop": "Variedad",
    "First Name": "Primer nombre",
    "Surname": "Apellido",
    "Notes": "Notas",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar",
    "subdistricts": "Subdistrict",
    "parish": "Parish",
    "source": "Source",
    "profession": "Prior Profession",
    "age": "Age",
    "transport": "Transport",
    "isAnAgent": "Is an Agent",
    "isNotAnAgent": "Is NOT an Agent"
  },
  "sw": {
    "Primary Crop": "Mazao ya Msingi",
    "First Name": "Jina la kwanza",
    "Surname": "Jina la jina",
    "Notes": "Noti",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa",
    "subdistricts": "Subdistrict",
    "parish": "Parish",
    "source": "Source",
    "profession": "Prior Profession",
    "age": "Age",
    "transport": "Transport",
    "isAnAgent": "Is an Agent",
    "isNotAnAgent": "Is NOT an Agent"
  }
}
</i18n>
