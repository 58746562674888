import { openDB } from 'idb';

export default class Db {

  static async db() {

    const dbName = 'sasagri';
    const storeName = 'images';
    const version = 1;

    const db = await openDB(dbName, version,{
      upgrade(db, oldVersion, newVersion, transaction) {
        const store = db.createObjectStore(storeName, { keyPath: 'uuid' });
        store.createIndex('uploaded', 'uploaded');
      }
    });

    return db;

  }

  static async clear(storeName) {
    const db = await Db.db();
    const tx = db.transaction(storeName, 'readwrite');
    const store = await tx.objectStore(storeName);
    store.clear();
  }

  static async add(storeName, object) {
    const db = await Db.db();
    const tx = db.transaction(storeName, 'readwrite')
    const store = await tx.objectStore(storeName)
    const value = await store.add(object);
    await tx.done;
  }

  static async fromKey(storeName, key) {
    const db = await Db.db();
    let result = await db.transaction(storeName).objectStore(storeName).get(key);
    return result;
  }

}
