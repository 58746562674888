<template>
  <v-form v-model="valid" ref="deliveryForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
        <v-select v-model="crop.cropDetails" :items="crops" item-text="crop" return-object label="Crop" />
        </v-col>
        <v-col cols="12" md="4" v-if="depots && depots.length>0">
          <DepotInput v-model="crop.depot" />
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="crop.quantity" required label="Quantity" type="number" suffix="KG"/>
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="crop.processingCost" required label="Storage costs" type="number" prefix="BWP"/>
        </v-col>
        <v-col cols="12" md="4" class="d-flex">
          <p class="mr-3">Price/Kg: </p>
          <Currency :value="cropPricePerUnit" />
        </v-col>
                  <v-col cols="12" md="4" class="d-flex">
          <p class="mr-3">Value: </p>
          <Currency :value="cropValue" />
        </v-col>
        <v-col cols="12" md="4" class="d-flex">
          <p class="mr-3">Net Value: </p>
          <Currency :value="cropNetValue" />
        </v-col>
      </v-row>
      <v-row class="mx-3 my-4 d-flex flex-row-reverse">
        <v-btn color="primary" class="mb-2" @click="validateAndContinue" :disabled="!valid">Next</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import TextInput from "@/components/TextInput";
  import Currency from '@/components/Currency';
  import DepotInput from '@/components/DepotInput';

  export default {
  components: { TextInput, Currency, DepotInput },
  data: () => ({
    crop: {},
    formFields: {},
    valid: false
  }),
  mounted() {
    this.crop = {
      price_per_unit: 0,
      quantity: 0,
      processingCost: 0
    };
  },
  computed: {
    cropPricePerUnit() {
      return this.crop.cropDetails ? this.crop.cropDetails.price_per_unit : this.crop.price_per_unit;
    },
    uuid() { return this.$route.query.uuid; },
    cropValue() { return this.crop.quantity*this.cropPricePerUnit},
    customer() { return this.$store.getters.customer(this.uuid); },
    cropNetValue() { return this.cropValue-this.crop.processingCost},
    skus() { return this.$store.getters.settings.skus || []; },
    depots() {return this.$store.getters.settings.depots;},
    crops() { return this.skus.map(item => {
      return {
        cropId: Number(item.code),
        crop: item.description,
        price_per_unit: item.price_per_unit
      };
    }); }
  },
    watch: {
    crop: {
      immediate: true,
      handler(to) {
        this.formFields = to;
       }
    },
    formFields(to) { this.$emit('input', to); },
  },
  methods: {
    validate() {
      this.$refs.deliveryForm.validate();
    },
    toHome() {
      this.$router.push({ name: 'Home' });
    },
    validateAndContinue(){
      this.validate();
      this.crop.cropValue = this.cropValue
      this.crop.cropNetValue = this.cropNetValue
      this.crop.customerUuid = this.customer.uuid
      this.crop.cropId = this.crop.cropDetails.id
      this.$emit('nextStep');
    }
  }
  }
</script>
  