

export function findFieldCultivationContract(field, cultivationContractsAssessments) {
    return cultivationContractsAssessments.find(cultivationContract => cultivationContract.fieldUuid === field.uuid)
}

export function createFieldRows(fields, cultivationContractsAssessments) {
    // {field, fieldCode}
    return fields.map(field => {
        const foundCultivationContractsAssessment = findFieldCultivationContract(field, cultivationContractsAssessments)
        return {
            field: field,
            fieldCode: foundCultivationContractsAssessment ? foundCultivationContractsAssessment.formFields.fieldCode.answer : ''
        }
    })
}
