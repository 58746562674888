<template>
  <v-select
    v-model="item"
    v-bind="$attrs"
    v-on="$listeners"
    :rules="[requiredRule]"
    ref="customSelect"
    :items=items
    :menu-props="{ offsetY: true }"
  >
    <template v-slot:prepend-item>
      <v-checkbox
        v-if="isMultiple"
        @click="toggleSelectAll"
        :color="allSelected ? 'primary' : ''"
        label="Select All"
        hide-details
        class="ml-4 mb-2"
      ></v-checkbox>
    </template>
  </v-select>
</template>

<script>

export default {

  props: {
    value: undefined,
    items: {
      type: Array,
      required: true
    },
    required: Boolean,
  },

  data: () => ({
    item: undefined,
    allSelected: false
  }),

  watch: {
    value: {
      immediate: true,
      handler(to) { this.item = to; }
    },
    item(to) { this.$emit('input', this.item); },
  },

  computed: {
    isMultiple() {
      return this.$attrs.hasOwnProperty('multiple');
    }
  },

  methods: {
    closeMenu() {
      this.$refs.customSelect.isMenuActive = false;
    },
    requiredRule(value) {
      let r = [
        v => !!v || !this.required || v==0 || this.$t('required'),
      ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
      return ( typeof(r)==='string' ? r : true );
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.item = null;
        this.allSelected = false;
      } else {
        this.item = this.items.map(item => item.value ? item.value : item);
        this.allSelected = true;
      }
    }
  }
};

</script>
