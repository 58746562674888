<template>
  <v-card>
    <v-card-title>
      <v-icon>{{icon}}</v-icon>
      <v-toolbar-title>{{title}}</v-toolbar-title>
    </v-card-title>

    <v-row>
      <v-col>

        <v-card-text class="text-left">
          <v-list flat subheader three-line >
            <v-list-item-group v-model="selected" multiple>

              <v-list-item v-for="activity in activities" :key="activity" :value="activity">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="payload[activity]"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{$t(activity)}}</v-list-item-title>
                    <v-list-item-subtitle>{{$t(`c_${activity}`)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card-text>

      </v-col>

      <v-divider vertical v-if="selected.length>0"></v-divider>

      <v-col cols="12" md="6" v-if="selected.length>0">
        <div class="d-flex flex-column">
          <Activity v-for="activity in sorted" :key="activity" :type="type" :icon="icon" :activity="activity" class="mb-2" v-model="payload[activity]" />
        </div>
      </v-col>

    </v-row>

  </v-card>
</template>


<script>
  import Activity from '@/views/farmers/components/Activity';

  export default {

    components: { Activity },

    props: ['title', 'icon', 'type', 'activities',  'value'],

    data: () => ({
      selected: [],
      payload: {},
    }),

    computed: {
      sorted() { return this.selected.sort( (a,b) => { return ( this.$t(a) < this.$t(b) ? -1 : 1 ) } ) },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.payload = (to || {}); }
      },
      payload(to) { this.$emit('input', to); },
    },

  }
</script>


<i18n>
{
  "en": {
    "numberLabel": "Plants",
    "numberHint": "Number of plants",

    "poultry": "Poultry",
    "c_poultry": "Broilers, layers or indigenous chickens",
    "pigs": "Pigs",
    "c_pigs": " ",
    "dairy": "Dairy",
    "c_dairy": "Milk cows",
    "beef": "Beef",
    "c_beef": " ",
    "small stock": "Small Stock",
    "c_small stock": "Goats, sheep and other small ungulates.",
    "other_livestock": "Other Livestock",
    "c_other_livestock": "Other types of animals or birds",

    "wheat": "Wheat",
    "c_wheat": " ",
    "maize": "Maize",
    "c_maize": " ",
    "sunflowers": "Sunflowers",
    "c_sunflowers": " ",
    "beans": "Beans",
    "c_beans": " "
  },
  "es": {
    "numberLabel": "Plantas",
    "numberHint": "Numero de plantas",

    "poultry": "poultry",
    "c_poultry": "Pollos de engorde, ponedoras o pollos autóctonos",
    "pigs": "pigs",
    "c_pigs": "c_pigs",
    "dairy": "dairy",
    "c_dairy": "c_dairy",
    "beef": "beef",
    "c_beef": "c_beef",
    "small stock": "small stock",
    "c_small stock": "small c_stock",
    "other_livestock": "other",
    "c_other_livestock": "c_other",

    "wheat": "Trigo",
    "c_wheat": " ",
    "maize": "Maíz",
    "c_maize": " ",
    "sunflowers": "Girasoles",
    "c_sunflowers": " ",
    "beans": "Frijoles",
    "c_beans": " "
  },
  "sw": {
    "numberLabel": "Mimea",
    "numberHint": "Idadi ya mimea",

    "poultry": "Kuku",
    "c_poultry": "Kuku wa nyama, matabaka au kuku wa kienyeji",
    "pigs": "Nguruwe",
    "c_pigs": " ",
    "dairy": "Maziwa",
    "c_dairy": "Ng'ombe za maziwa",
    "beef": "Nyama ya ng'ombe",
    "c_beef": " ",
    "small stock": "Hisa ndogo",
    "c_small stock": "Mbuzi, kondoo na watu wengine wadogo wanaomulika.",
    "other_livestock": "Nyingine",
    "c_other_livestock": "Aina zingine za wanyama au ndege",

    "wheat": "Ngano",
    "c_wheat": " ",
    "maize": "Mahindi",
    "c_maize": " ",
    "sunflowers": "Alizeti",
    "c_sunflowers": " ",
    "beans": "Maharagwe",
    "c_beans": " "
  }
}
</i18n>
