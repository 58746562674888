<template>
  <v-rating
    v-model="rating"
    color="yellow darken-3"
    background-color="grey darken-1"
    empty-icon="$ratingFull"
    hover
    medium
    @input="handleInput($event)"
    v-bind:readonly="readonly"
  ></v-rating>
</template>

<script>
  export default {
    props: ['value', 'readonly'],
    data: () => ({
      rating: 0,
    }),
    watch: {
      value: {
        immediate: true,
        handler(to) { this.rating = to; }
      },
    },
    methods: {
      handleInput(stars) {
        this.$emit('input', stars);
      },
    },
  }
</script>