
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
export default {
  data: () => ({
    numberRules: [
      v => Number.isInteger(Number(v)) || "Numbers only",
      v => (Number(v) >= 0) || "A positive number please",
      v => !(/\D/.test(v)) || "Numbers please",
    ],
    numberWithDecimalRules: [
      v => (!/[^\d\,\.]/.test(v)) || "Numbers please",
      // v => (Number(v.replace(/[\,\.]/,'')) >= 0) || "A positive number please",
    ],
    formValidations: {
      required: [(v) => !!v || 'Required']
    },
    sharedValidations: new HugoValidations()
  }),
};
