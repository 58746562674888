<template>
  
      <v-container fluid class="red lighten-5">
         <v-row class="text-center">
          <v-card-title>{{$t('Sowing')}}</v-card-title>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <DateInput v-model="data.sowing.dateOfTransplanting.answer" :label="$t('1 ¿En que fecha sembró?')" hint="¿En que fecha sembró?" :rules="[rules.required]" />
          </v-col>
<!--          <v-col cols="12" sm="6">-->
<!--            <v-combobox-->
<!--              v-model="data.sowing.neccessaryToCarryOutPreviousDisinfectionBeforeTranspalnting.answer"-->
<!--              :label="$t('2 ¿Es necesario realizar desinfección previa antes del trasplante.')"-->
<!--              single-line-->
<!--              :rules="[rules.required]"-->
<!--            ></v-combobox>-->
<!--          </v-col>-->

          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="data.sowing.didEstablishWellAfterTransplanting.answer"
              row
              mandatory
              :label="$t('3 ¿Se establecieron bien las plantas después del transplante?')"
              :rules="[rules.required]"
            >
              <v-radio :label="$t('Yes')" value="Yes"></v-radio>
              <v-radio :label="$t('No')" value="No"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="data.sowing.mortalityRate.answer"
              type="number"
              :label="$t('4 NO, ¿cual es la tasa de mortalidad?')"
              :rules="[rules.required, ...rules.numberRules]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="data.sowing.causeOfIt.answer"
              type="text"
              :label="$t('5 ¿Cuál es la causa de la mortalidad?')"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <DateInput v-model="data.sowing.whenDidYouReplaceThePlants.answer" :label="$t('6 ¿Cuando reemplazó las plantas perdidas?')" hint="¿Cuando reemplazó las plantas perdidas?" :rules="[rules.required]" />
          </v-col>
          <v-col cols="12" sm="4">
           <TakePhoto :text="'Foto Siembra'">
              <v-btn color="primary" small dense class="ml-1"
                ><v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                <span class="hidden-sm-and-down">{{
                  $t("Foto Siembra")
                }}</span></v-btn
              >
            </TakePhoto>
          </v-col>
        </v-row>
        
      </v-container>
  
</template>

<script>
import DateInput from "@/components/DateInput";
import TakePhoto from "@/components/TakePhoto"
export default {
  components: {
    DateInput,
    TakePhoto
  },
  props :{
      data: Object,
  },
  data() {
    return {
      rules: {
      required: (value) => !!value || "Requerido",
      numberRules: [
        (v) => Number.isInteger(Number(v)) || "Numbers only",
        (v) => Number(v) >= 0 || "A positive number please",
        (v) => !/\D/.test(v) || "Numbers please",
      ],
      },
    }
  }
}
</script>


<style>

</style>
<i18n>
{
  "en": {
    "Photos":"Photos",
    "1 Learning Material":"Learning Material",
    "2 Date of transplanting?":"Date of transplanting?",
    "3 It is necessary to carry out previous disinfection before transplanting?":"It is necessary to carry out previous disinfection before transplanting?",
    "4 Did they establish well after transplanting?":"Did they establish well after transplanting?",
    "5 If NOT, what is the mortality rate? ":"If NOT, what is the mortality rate? ",
    "6 What is the cause of it?":"What is the cause of it?",
    "7 When did you replace the plants?":"When did you replace the plants?"
    

  },
  "es": {
    "Photos":"Foto Plántulas",
    "Sowing": "Siembra",
    "1 Learning Material":"Instructivos Transplante",
    "2 Date of transplanting?":"¿En que fecha sembró?",
    "3 It is necessary to carry out previous disinfection before transplanting?":"¿Es necesario realizar desinfección previa antes del trasplante.",
    "4 Did they establish well after transplanting?":"¿Se establecieron bien las plantas después del transplante?",
    "5 If NOT, what is the mortality rate? ":"NO, ¿cual es la tasa de mortalidad?",
    "6 What is the cause of it?":"¿Cuál es la causa de la mortalidad?",
    "7 When did you replace the plants?":"¿Cuando reemplazó las plantas perdidas?",
    "Yes": "Si",
    "No": "No",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
  },
  "sw": {
    "Photos":"Photos",
    "Learning Material":"Learning Material",
    "Date of transplanting?":"Date of transplanting?",
    "It is necessary to carry out previous disinfection before transplanting?":"It is necessary to carry out previous disinfection before transplanting?.",
    "Did they establish well after transplanting?":"Did they establish well after transplanting?",
    "If NOT, what is the mortality rate? ":"If NOT, what is the mortality rate? ",
    "What is the cause of it?":"What is the cause of it?",
    "When did you replace the plants?":"When did you replace the plants?"
  }
}
</i18n>