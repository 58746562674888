<template>

  <SalaryAdvance :customer="customer" v-if="loanType=='salary_advance'" />
  <CheckMeBack   :customer="customer" v-else-if="loanType=='check_me_back'" />
  <LoanRequestType v-model="loanType" v-else />

</template>

<script>
import SalaryAdvance from "@/views/partners/ultimate_tz/SalaryAdvance";
import CheckMeBack from "@/views/partners/ultimate_tz/CheckMeBack";
import LoanRequestType from "@/views/partners/ultimate_tz/LoanRequestType";

export default {

  components: { SalaryAdvance, CheckMeBack, LoanRequestType },

  props: ['value'],

  data() {
    return {
      valid: false,
      loanType: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

}
</script>
