<template>
  <span>
    {{customer.bank_name}}: {{customer.account_number}}
  </span>
</template>

<script>

export default {

  props: ['customer'],

};
</script>
