<template>
  <IndexCompact :farmers="farmers" v-if="breakpoint=='xs'" />
  <IndexNormalNile :farmers="farmers" v-else-if="partnerCode && breakpoint !== 'xs'" />
  <IndexNormal :farmers="farmers" v-else />
  
</template>


<script>
  import IndexCompact from '@/views/farmers/components/IndexCompact';
  import IndexNormal from '@/views/farmers/components/IndexNormal';
  import IndexNormalNile from '@/views/farmers/partners/nile_ug/IndexNormal'

  export default {
    components: { IndexCompact, IndexNormal, IndexNormalNile },
    computed: {
      breakpoint() { return this.$vuetify.breakpoint.name; },
      partnerCode() {
         const code = this.$store.getters.settings.partnerCode; 
         return code == 'nile_ug';
         },
    },
    props: ['farmers'],
  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
