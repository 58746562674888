<template>
<div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          small
          dense class="mr-1"
          v-bind="attrs"
          v-on="on"
        >
          New Assessment
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Hugo Forms
        </v-card-title>

        <v-card-text>
              <AllAssessmentsTable :customer-uuid="customerUuid" :field="field" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AllAssessmentsTable from "@/views/fields/AllAssessmentsTable";

export default {
  name: "ChooseAssessmentDialog",
  props: ['customerUuid', 'field'],
  components: {AllAssessmentsTable},
  data () {
    return {
      dialog: false,
    }
  },

}
</script>

<style scoped>

</style>


<i18n>
{
  "en": {
    "irrigation": "Irrigation"
  },
  "es": {
    "irrigation": "Irrigación"
  },
  "sw": {
    "irrigation": "Umwagiliaji"
  }
}
</i18n>
