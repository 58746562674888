<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="100%"
        fullscreen
    >
        <template v-slot:activator="{ on, attrs }">
          <slot name="dialogButton" :on="on" :attrs="attrs">
          </slot>
        </template>
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{$t('signature')}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="clearDrawing"
            >
              {{$t('clearDrawing')}}
            </v-btn>
            <v-btn
                dark
                text
                @click="saveImageAndClose"
            >

              {{$t('save')}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <SignatureSection
            @signed="saveImage"
            ref="signatureSection"  />
      </v-card>
    </v-dialog>
  </div>

</template>

<script>

import SignatureSection from "@/components/SignatureComponent/SignatureSection";
import ImageDb from "@/store/ImageDb";

export default {
  props: ['signed'],
  components: {SignatureSection},
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    clearDrawing(e) {
      this.$refs.signatureSection.clearDrawing()
    },
    saveImageAndClose(e) {
      const imageBlog = this.$refs.signatureSection.downloadImg()
      this.saveImage(imageBlog);
      this.dialog = false
    },
    saveImage(imageBlob) {
      let uuid = ImageDb.add(imageBlob);
      this.$emit('saveImage', uuid);

    },
  },
}
</script>

<i18n>
{
  "en": {
    "home": "Home",
    "skus": "Prices",
    "about": "About",
    "logout": "Sign Out"
  },
  "es": {
    "home": "Hogar",
    "skus": "Precios",
    "about": "Acerca de",
    "logout": "Desconectar"
  },
  "sw": {
    "home": "Nyumbani",
    "skus": "Bei",
    "about": "Kuhusu",
    "logout": "Ondoka"
  }
}
</i18n>
