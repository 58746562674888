<template>
  <v-input :rules="[requiredRule]" v-model="documentId">
    <div class="file-upload">
      <div class="drop-area" :class="{ 'pa-6': !dense, 'px-4': dense, 'py-2': dense }"  @drop.prevent="handleDrop" @dragover.prevent @click="click">
        <input :ref="refId" type="file" @change="handleInput" v-show="false" :accept="accept" :disabled="disabled">
        <slot>
          <span v-if="dense"><v-icon class="mr-1" :class="{ 'primary--text': (typeIdOkay), 'success--text': documentId }">{{icon}}</v-icon> {{label}}</span>
          <v-label v-if="!dense">{{label}}</v-label>
          <div class="content-wrapper text-center" v-if="!dense">
            <v-icon :size="50" class="mb-1" :class="{ 'primary--text': (typeIdOkay), 'success--text': documentId }">{{icon}}</v-icon>
            <div v-if="files.length === 0">{{localisedCaption}}</div>
            <div v-else>{{$t('fileAttached')}}</div>
          </div>
        </slot>
      </div>
    </div>
  </v-input>
</template>

<script>
  import VInput from 'vuetify/lib/components/VInput/VInput.js';
  import ImageDb from '@/store/ImageDb';

  export default {

    components: {  },

    props: {
      value: String,
      label: String,
      caption: String,
      accept: {
        type: String,
        default: ".*",
      },
      icon: {
        type: String,
        default: "mdi-cloud-upload-outline",
      },
      type: {
        type: String,
        required: true,
        validator(value) { return ['id_scan', 'field', 'assessment', 'bank_statement', 'direct_debits', 'id_card_front', 'id_card_back', 'work_id', 'credit_check', 'transactional', 'selfie', 'payslip', 'contract'].includes(value); },
      },
      typeId: String,
      required: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      documentId: undefined,
      validationProp: false,
      files: [],
      refId: "upload".concat(Math.round((Math.random()*1000000))),
    }),

    computed: {
      localisedCaption() {
        return this.caption || this.$t('caption');
      },
      typeIdOkay() { return ( (this.type==='transactional') ? true : this.typeId ); },
      disabled() { return !this.typeIdOkay; },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          this.documentId = to;
        }
      },
      documentId(to) { this.$emit('input', to); },
    },

    methods: {
      click() {
        this.$refs[this.refId].click();
      },
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
      handleDrop(e) {
        let files = e.dataTransfer.files;
        this.handleFiles(files);
      },
      handleInput(e) {
        let files = e.target.files;
        this.handleFiles(files);
      },
      handleFiles(files) {
        if (this.typeIdOkay) {
          this.files = files;
          for (const file of files) {
            this.save(file);
          }
        }
      },
      save(file) {
        let type = this.type
        let typeId = this.typeId
        console.log('===============', this.type, this.typeId)
        const _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let uuid = ImageDb.add(reader.result);
          _this.documentId = uuid;
          _this.imageId = uuid;
          _this.$store.dispatch('addImage', { type: type, type_id: typeId, uuid: uuid });
        };
      },
    }


  }
</script>



<i18n>
{
  "en": {
    "caption": "Drag a file into this box, or click on it to upload a file",
    "fileAttached": "File attached"
  },
  "es": {
    "caption": "Arrastre un archivo a este cuadro o haga clic en él para cargar un archivo",
    "fileAttached": "Archivo adjunto"
  },
  "sw": {
    "caption": "Buruta faili kwenye kisanduku hiki, au ubofye juu yake ili kupakia faili",
    "fileAttached": "Faili imeambatishwa"
  }
}
</i18n>




<style lang="scss">
  .file-upload {
    width: 100%;
    .drop-area {
        cursor: pointer;
        border: 2px lightgray dashed;
        width: 100%;
        display: block;
    }
    .v-input__slot { display: block; }
    .v-label { top: -7px; }
  }
</style>