<template>
  <v-container>
    <v-row v-if="showCrop">
      <v-col cols="12">
          <SelectInput
            label="Crop"
            multiple
            :items="crops"
            class="text-left"
            item-name="name"
            item-value="value"
            required
            v-model="field.cropIds"
          />
      </v-col>
    </v-row>

    <v-row v-if="showPlants">
      <v-col cols="12">
        <Row :title="$t('Number of plants(optional)')">
          <TextInput v-model="field.numberOfPlants" type="number" />
        </Row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
          <LandSizeInput label="Size" v-model="field.fieldSize" :required="true" />
        
      </v-col>
    </v-row>

    <v-row v-if="showFieldGps">
      <v-col cols="12">
        <Row :title="$t('Field Gps')">
          <LocationInput v-model="fieldGps" />
        </Row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" @click="saveFieldSection">Save & Continue</v-btn>
      </v-col>
    </v-row>
 </v-form>
  </v-container>
</template>

<script>
import LocationInput from "@/components/LocationInput";
import Row from "@/components/Row";
import SelectInput from "@/components/SelectInput";
import LandSizeInput from "@/components/LandSizeInput";
import fields from "@/mixins/fields";
import TextInput from "@/components/TextInput";

export default {
  components: {
    LocationInput,
    Row,
    SelectInput,
    LandSizeInput,
    TextInput
  },

  props: {
    value: Object,
    customer: Object
  },


  data() {
    return {
      valid: false,
      showFieldGps: false,
      field: {
        cropIds: [],
        numberOfPlants: 0
      },
      formFields: null,
      fieldGps: null,
      showPlants: false,
      showCrop: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!!to) {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },
  mounted() {

    this.customer.fields = this.customer.fields || []
    if (this.fieldUuid) {
      this.field = this.customer.fields.find( f => f.uuid===this.fieldUuid );

    } else {
      this.field = this.newField()
      this.customer.fields.push(this.field)
      this.$store.dispatch('upsertCustomer', this.customer);
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, field: this.field.uuid },
      });
    }
  },


  computed: {
    crops() { return this.$store.getters.crops; },
    fieldUuid() { return this.$route.query.field; },
  },
  methods: {
    saveFieldSection() {
      if (this.field && this.field.cropIds) {
        this.field.cropId = this.field.cropIds[0]
        this.field.customerId = this.customer.id
      }
      this.updateCustomerField(this.field);
      this.$store.dispatch('upsertField', this.field);
      this.$emit('input', this.field);
      this.$emit('next')
    },
  },

  mixins: [fields],
}
</script>
