<template>
  
  <PageHeader :customer="customer" :instrument="instrument" subtitle="Settlement">
    <template v-slot:buttons>
      <v-btn color="primary" small dense class="ma-1" :href="`${domain}/ultimate_tz/documents/settlement_quote/${uuid}?${qs}`" target="_blank" :disabled="!instrument" ><v-icon dense>mdi-printer</v-icon> <span class="hidden-sm-and-down">Settlement Quote</span></v-btn>
    </template>
    <SettlementSalaryAdvance :customer="customer" :instrument="instrument" :instrumentId="instrumentId" v-if="instrument && instrumentType=='InstrumentUltimateTzSalaryAdvance'" />
    <SettlementCheckMeBack   :customer="customer" :instrument="instrument" :instrumentId="instrumentId" v-if="instrument && instrumentType=='InstrumentUltimateTzCheckMeBack'" />
    <SettlementGovernment    :customer="customer" :instrument="instrument" :instrumentId="instrumentId" v-if="instrument && instrumentType=='InstrumentUltimateTzGovernment'" />
  </PageHeader>

</template>

<script>
import prodDebug from "@/mixins/prodDebug";
import lookups from '@/mixins/lookups';

import PageHeader from '@/views/partners/ultimate_tz/components/PageHeader';
import SettlementSalaryAdvance from "@/views/partners/ultimate_tz/SettlementSalaryAdvance"
import SettlementCheckMeBack from "@/views/partners/ultimate_tz/SettlementCheckMeBack"
import SettlementGovernment from "@/views/partners/ultimate_tz/SettlementGovernment"


export default {

  components: { PageHeader, SettlementSalaryAdvance, SettlementCheckMeBack, SettlementGovernment },

  data() {
    return {
      instrument: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    instrumentId() { return this.$route.query.instrumentId; },
    instrumentType() { return this.$route.query.instrumentType; },
    customer() { return this.$store.getters.customer(this.uuid); },
    title() {
      let r = 'Check Me Back';
      if (this.instrumentType=='InstrumentUltimateTzSalaryAdvance') { r = 'Salary Advance'; }
      if (this.instrumentType=='InstrumentUltimateTzGovernment') { r = 'Government'; }
      return r;
    },
    settlementAmount() { return (this.instrument ? this.instrument.settlement_amount : 0); },
    dueAt() { return (this.instrument ? this.instrument.due_at : ''); },
    userName() { return this.$store.getters.settings.name; },
    qs() { return new URLSearchParams({ agent_name: this.userName, settlement_amount: (this.settlementAmount || 0), due_at: this.dueAt }).toString(); },
    domain() { return (this.production ? 'https://connect.sasa.solutions' : 'http://connect.sasa.local:3000'); },
  },

  mounted() {
    this.remoteLookup(this.instrumentType, {
      uuid: this.customer.uuid,
      id: this.instrumentId,
    }).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
  },

  mixins: [prodDebug, lookups],

}
</script>
