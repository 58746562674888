<template>
  <v-sheet elevation="2">
    <v-form v-model="valid">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-textarea solo v-model="note" label="Notes" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn @click="submit" :disabled="note.length==0" color="primary" small dense class="ma-1"><v-icon dense>mdi-cloud-upload</v-icon> Save</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>


<script>
import upsertActions from '@/mixins/models/actions'

export default {

  components: { },

  props: ['customer'],

  data() {
    return {
      valid: false,
      note: '',
    }
  },

  methods: {
    submit(action) {
      this.upsertAction({ target: this.customer, action: 'note', note: this.note });
      this.$emit('close');
    }
  },

  mounted() {
    this.valid = false;
    this.note = '';
  },

  mixins: [upsertActions],

}
</script>