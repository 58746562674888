<template>
  <v-data-table
    :headers="headers"
    :items="inputOrders"
  ></v-data-table>
</template>

<script>
export default {
  data () {
    return {
      headers: [
        { text: 'Farmer', value: 'customerName' },
        { text: 'Item', value: 'description' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Cost', value: 'value' },
      ]
    }
  },
  computed: {
    inputOrders() { return this.$store.getters.orders }
  }

}
</script>

<style>

</style>
