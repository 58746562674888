export default {
  computed: {
    msisdnCountryCode() { return this.$store.getters.settings.countryCode; },
    msisdnTelephoneCode() { return this.$store.getters.settings.telephoneCode; },
    phoneNumberFromMsisdn() { return this.msisdnToTelephone(this.msisdn); },
  },
  methods: {
    telephoneToMsisdn(telephone) {
      return this.msisdnTelephoneCode + telephone.replace(/^0/, '');
    },
    msisdnToTelephone(msisdn) {
      const r = new RegExp(`^${this.msisdnTelephoneCode}`);
      let telephone = (msisdn || '');
      if ( telephone.match(r) ) {
        telephone = telephone.replace(r, '');
        if (!['BW', 'CO'].includes(this.msisdnCountryCode)) {
          if (telephone.length>0) {
            telephone = '0'.concat(telephone);
          }
        }
      }
      return telephone;
    },
    phoneNumberRules(v) {
      let r = null;
      if ((v || '').length>0) {
        if (this.msisdnCountryCode=='CO') {
          r = /^[2345678]\d{9}$/.test(v) || 'El número de teléfono debe tener 10 dígitos y comenzar con 2 a 8'
        } else if(this.msisdnCountryCode==='BW') {
          r = /^[123456789]\d{7}$/.test(v) || 'Telephone number must be 8 digits long and not start with a zero (0)'
        } else if(this.msisdnCountryCode==='AO') {
          r = /^9\d{9}$/.test(v) || 'Telephone number must be 10 digits long and start with a 9'
        } else {
          r = /^0\d{9}$/.test(v) || (this.$store.getters.settings.language=='sw' ? 'Nambari ya simu lazima iwe na tarakimu 10 na uanze na 0' : 'Telephone number must be 10 digits long and start with a 0')
        }
      } else {
        r = true;
      }
      return r;
    },
  }
};
