<template>
  <v-autocomplete
    v-model="employer"
    :search-input.sync="search"
    :items="businesses"
    item-text="name"
    return-object
    :label="label"
    :rules="required ? [requiredRule] : []"
    style="min-width: 100px; max-width: 100%;"
    prepend-icon="mdi-domain"
    hide-no-data
  />
</template>

<script>
import lookups from "@/mixins/lookups";

export default {

  props: {
    value: Object,
    required: Boolean,
    rules: Array,
    label: {
      type: String,
      default: 'Business',
    },
  },

  data() {
    return {
      valid: false,
      employer: undefined,
      search: undefined,
      businesses: [],
      requiredRule: v => !!v || 'Required',
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (to) {
          if (this.businesses.filter( e => e.id==to.id ).length==0) {
            this.businesses.push(to);
          }
        }
        this.employer = to;
      }
    },
    employer(to) { this.$emit('input', to); },
    search(to) { this.retrieveBusinesses(); },
  },

  methods: {
    async retrieveBusinesses () {
      this.remoteLookup('searchBusinesses', { search: this.search }).then( results => this.businesses = results );
    },
  },

  mixins: [lookups],

}
</script>
