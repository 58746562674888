<template>
  <v-card>

    <v-card-title>
      {{$t('location')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table v-if="anyFields" >
        <template v-slot:default>
          <tbody>
            
            <Row :title="$t(geographicHeirarchy[0])" :text="regions[0]" v-if="regions[0]" data-cy="geographic0"/>
            <Row :title="$t(geographicHeirarchy[1])" :text="regions[1]" v-if="regions[1]" data-cy="geographic1"/>
            <Row :title="$t(geographicHeirarchy[2])" :text="regions[2]" v-if="regions[2]" data-cy="geographic2"/>

            <Row title="Farm" :location="farmLocation" v-if="farmLocationDefined" />
            <Row title="Home" :location="homeLocation" v-if="homeLocationDefined" />

          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        {{$t('noData')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>

  import Row from '@/components/Row';

  export default {

    components: { Row },

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },

      geographicHeirarchy() { return this.$store.getters.settings.geographicHeirarchy; },
      demographic() { return (this.customer.demographic || {}); },
      regions() { return this.demographic.geographicsArray || []; },

      keyLocations() { return (this.demographic.keyLocations || {}); },
      farmLocation() { return this.keyLocations.work; },
      farmLocationDefined() {
        const location = (this.farmLocation || {});
        const detail = (location.lookupParameters || {});
        return (detail.address || detail.latitude);
      },
      homeLocation() { return this.keyLocations.home; },
      homeLocationDefined() {
        const location = (this.homeLocation || {});
        const detail = (location.lookupParameters || {});
        return (detail.address || detail.latitude);
      },
      // geographicStructure() { return this.$store.getters.settings.geographicStructure; },
      // geographicOptions0() { return Object.keys(this.geographicStructure).sort(); },
      // geographicOptions1() {
      //   const places = this.geographicStructure[this.geographic0] || {};
      //   return Object.keys(places).sort();
      // },
      // geographicOptions2() {
      //   const places = this.geographicStructure[this.geographic0] || {};
      //   return (places[this.geographic1] || []).sort();
      // },

      // regions() { return ( (!this.primaryLocation && this.customer.regions) ? this.customer.regions.join(", ") : null ); },

      anyFields() { return ( true || ((this.regions || []).filter(e => e).length>0) || this.farmLocationDefined || this.homeLocationDefined) ; },
    },

    methods: {
      edit() { this.$router.push({name: 'EditCustomerLocation', query: { uuid: this.customerUuid }}); },
    },

  }
</script>


<i18n>
{
  "en": {
    "noData": "No region or address data given.",
    "Region": "Region",
    "Town": "Town",
    "Locality": "Locality",
    "District": "District",
    "County": "County"
  },
  "es": {
    "noData": "No se proporcionaron datos de región o dirección.",
    "Region": "Región",
    "Town": "Pueblo",
    "Locality": "Localidad",
    "District": "Distrito",
    "County": "Condado"
  },
  "sw": {
    "noData": "Hakuna eneo au anwani iliyotolewa.",
    "Region": "mkoa",
    "Town": "Mji",
    "Locality": "Eneo",
    "District": "Wilaya",
    "County": "Jimbo"
  }
}
</i18n>
