<template>
  <v-card class="elevation-1 px-5 py-3">
    <v-data-table
      :headers="headers"
      :items="items.filter(e => {return e.quantity > 0})"
      hide-default-footer
    >
      <template v-slot:item.price_per_unit="{ item }">
        <Currency :value="item.price_per_unit" />
      </template>
      <template v-slot:item.quantity="{ item }">
        {{formattedQuantity(item.quantity)}}
      </template>
      <template v-slot:item.cost="{ item }">
        <Currency :value="item.cost" />
      </template>
    </v-data-table>
    <v-row class="py-3">
      <v-col class="font-weight-bold" >
        {{$t('Total')}}
        <Currency :value="total" />
      </v-col>
    </v-row>
    <v-row v-if="items.filter(e => {return e.quantity > 0}).length > 0" class="mt-4">
      <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="nextStep" >Submit Order</v-btn>
      <v-btn color="gray darken-3" class="mb-2" @click="previousStep">Back</v-btn>
    </v-row>
    
  </v-card>
</template>
<script>
  import Currency from '@/components/Currency';

  export default {
    components: {Currency},
    props: ["rules", "value", "items", "total"],
    data () {
      return {
        headers: [
          { text: 'Items', value: 'description' },
          { text: 'Price/ Unit', value: 'price_per_unit' },
          { text: 'Quantity(kg)', value: 'quantity' },
          { text: 'Cost', value: 'cost' },
        ]
      }
    },

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; }
    },

    methods:{
      nextStep(){
        this.$emit('nextStep');
      },
      previousStep() {
        this.$emit('previousStep');
      },
      formattedQuantity(quantity) {
        return this.partnerCode == 'eco_bw' ? `${quantity} Kg` : quantity
      }
    }
  }
</script>
