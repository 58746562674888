<template>
  <v-btn :disabled="buttonDisabled" @click="click" v-bind="$attrs" v-on="$listeners">
    <template v-slot:loader>
      <slot name="loader"></slot>
    </template>
    <slot></slot>
  </v-btn>
</template>

<script>
  export default {

    props: {
      disabled: Boolean,
      ms: {
        type: Number,
        default: 2000,
      },
    },

    data: () => ({
      off: false,
    }),

    computed: {
      buttonDisabled() { return (this.off || this.disabled); },
    },
    
    methods: {
      click(event) {
        this.disable();
        this.$emit('click', event);
      },
      disable() {
        this.off = true;
        let _this = this;
        setTimeout(() => {
          _this.off = false;
        }, this.ms);
      }
    },

  }
</script>
