<template>
  <v-sheet elevation="2">
    <v-form v-model="valid">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-btn color="primary" width="100%" :href="`${domain}/ultimate_tz/documents/demand_letter/${uuid}?${qs}`" target="_blank" :disabled="!instrument">
              <v-icon small dense>mdi-printer</v-icon> Letter of Demand
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn color="primary" width="100%" :href="`${domain}/ultimate_tz/documents/settlement_quote/${uuid}?${qs}`" target="_blank" :disabled="!instrument">
              <v-icon small dense>mdi-printer</v-icon> Settlement Quote
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn color="primary" width="100%" :href="`${domain}/ultimate_tz/documents/clearance/${uuid}?${qs}`" target="_blank" :disabled="openLoans">
              <v-icon small dense>mdi-printer</v-icon> Clearance Letter
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field solo v-model="note" label="Notes" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-btn @click="submit('demand_delivered')" color="primary" small dense class="ma-1" :disabled="!instrument"><v-icon dense>mdi-moped-outline</v-icon> Delivered Demand Letter</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn @click="submit('settlement_letter_provided')" color="primary" small dense class="ma-1" :disabled="!instrument"><v-icon dense>mdi-moped-outline</v-icon> Delivered Settlement Quote</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn @click="submit('clearance_letter_provided')" color="primary" small dense class="ma-1" :disabled="openLoans"><v-icon dense>mdi-moped-outline</v-icon> Clearance Letter</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>


<script>
import prodDebug from "@/mixins/prodDebug";
import upsertActions from '@/mixins/models/actions'

export default {

  components: { },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    instrument: {
      type: Object,
      required: false,
    },
    openLoans: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      valid: false,
      note: '',
    }
  },

  computed: {
    uuid() { return this.customer.uuid; },
    userName() { return this.$store.getters.settings.name; },
    qs() {
      return new URLSearchParams({ agent_name: this.userName, settlement_amount: ((this.instrument || {}).settlement_amount || 0), due_at: (this.instrument || {}).due_at }).toString();
    },
    domain() { return (this.production ? 'https://connect.sasa.solutions' : 'http://connect.sasa.local:3000'); },
  },

  methods: {
    submit(action) {
      this.upsertAction({ target: this.customer, action: action, note: this.note });
      this.$emit('close');
    }
  },

  mixins: [prodDebug, upsertActions],

}
</script>