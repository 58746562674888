<template>
  <v-form v-model="formValid">
    <PageHeader :title="$t('title')"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >

      <v-row no-gutters >
        <v-col cols="12">
            <v-text-field
              v-model="amount"
              :rules="[amountRules]"
              :label="$t('amount')"
              type='number'
              suffix='TSh'
              min="0"
              required
            ></v-text-field>
        </v-col>
      </v-row>


    </PageHeader>
  </v-form>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import referenceNumbers from '@/mixins/referenceNumbers';

  import PageHeader from '@/components/PageHeader';

  export default {

    components: { PageHeader },

    data: () => ({
      formValid: false,

      receiptNumber: undefined,
      amount: 0,

    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      location() { return ((this.$store.getters.geolocation || {}).coords); },
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    },

    methods: {
      amountRules(v) {
        let r = true;
        if (parseFloat(v) <= 0) { r = this.$t('minZero'); }
        if (parseFloat(v) > 20000000) { r = this.$t('maxExceeded'); }
        if (v.length==0) { r = this.$t('required') }
        return r;
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      save() {

        const receipt = {
          uuid: uuidv4(),
          createdAt: new Date().toISOString(),
          receiptNumber: this.receiptNumber,
          amount: parseFloat(this.amount),
          customerUuid: this.customerUuid,
          location: this.location,
          capturedById: this.fieldAgentId,
        }
        
        this.$store.dispatch('addReceipt', receipt);

        this.$router.push({ name: 'ShowReceipt', query: { uuid: this.customerUuid, receiptUuid: receipt.uuid } });
      },
    },

    mounted() {
      this.receiptNumber = this.generateReferenceNumber()
    },

    mixins: [referenceNumbers],

  }
</script>


<i18n>
{
  "en": {
    "title": "New Purchase",
    "amount": "Amount",
    "minZero": "Amount must be greater than zero",
    "maxExceeded": "Amount must be less than 20000000"
  },
  "es": {
    "title": "Nueva compra",
    "amount": "Monto",
    "minZero": "La cantidad debe ser mayor que cero",
    "maxExceeded": "La cantidad debe ser inferior a 20000000"
  },
  "sw": {
    "title": "Ununuzi Mpya",
    "amount": "Thamani",
    "minZero": "Kiasi lazima kiwe nambari chanya",
    "maxExceeded": "Kiasi lazima kiwe chini ya 20000000"
  }
}
</i18n>
