<template>
  <v-text-field
    v-model="telephone"
    :rules="allRules"
    :counter="counterNumber"
    :label="label || $t('telephone_number')"
    :hint="msisdn"
  ></v-text-field>
</template>

<script>

import msisdnToTelephone from '@/mixins/msisdnToTelephone'

export default {

    props: {
      value: String,
      required: Boolean,
      rules: Array,
      label: String,
    },

  data: () => ({
    telephone: undefined,
    counterNumber: 10
  }),

  computed: {
    // allRules() { return [this.requiredRule, this.phoneNumberRules, ...(this.rules || [])]; },
    allRules() { return [this.requiredRule, this.phoneNumberRules]; },
    msisdn() { return this.telephoneToMsisdn(this.telephone) },
    partnerCode() { return this.$store.getters.settings.partnerCode; },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.telephone = this.msisdnToTelephone(to);
      }
    },
    msisdn(to) { this.$emit('input', this.msisdn); },
  },

  methods: {
    requiredRule(text) {
      let r = [
        v => !this.required || !!((v || '').trim()) || this.$t('required'),
      ].map(e => e.call(this, text) ).find(e => typeof(e)==='string');
      return ( typeof(r)==='string' ? r : true );
    },
  },

  mounted() {
    this.telephone = this.msisdnToTelephone(this.value);
    this.counterNumber = this.partnerCode === 'eco_bw' ? 8 : 10
  },

  mixins: [msisdnToTelephone],

};
</script>


<i18n>
{
  "en": {
    "telephone_number": "Telephone Number"
  },
  "es": {
    "telephone_number": "Número de Teléfono"
  },
  "sw": {
    "telephone_number": "Namba ya Simu"
  }
}
</i18n>
