<template>

  <v-row>
    <v-col cols="12">
      <ServerSideResult :test="instrument" />
    </v-col>
  </v-row>

</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { ServerSideResult },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    loanRequest: {
      type: Object,
      required: true,
    },
    topup: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      instrument: undefined
    }
  },

  watch: {
    instrument(to) {
      if (to) {
        this.$emit('input', to);
      }
    }
  },

  mounted() {
    let params = {
      uuid: this.customer.uuid,
      principal: this.loanRequest.principal,
      interest_rate: this.loanRequest.interest_rate,
      installments: this.loanRequest.installments,
      installment: this.loanRequest.installment,
      repay_at: this.loanRequest.repay_at,
      cash: this.loanRequest.cash,
      eft: this.loanRequest.eft,
      topup: this.topup,
      mobileMoney: this.loanRequest.mobileMoney,
    }
    this.remoteLookup('ultimate_tz_check_me_back_create', params).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
  },

  mixins: [lookups],

}
</script>
