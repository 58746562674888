<template>
  <PageHeader :title="fieldName" icon="mdi-flower-outline" @back="toField" back>
      <v-btn color="error" block @click="deleteField">
        {{$t('delete')}}
      </v-btn>
  </PageHeader>
</template>


<script>
  import fields from '@/mixins/fields';

  import Row from '@/components/Row';
  import PageHeader from '@/components/PageHeader';
  
  export default {

    components: { Row, PageHeader },

    data: () => ({
      field: undefined,
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      fieldName() {
        let f = this.field.name || 'New Field';
        if (f.length>16) { f = f.replace(/Hectares/, 'ha'); }
        return f;
      },
    },

    watch: {
      fieldUuid: {
        immediate: true,
        handler(to) {
          if (to && this.customer) {
            this.field = this.customer.fields.filter( f => f.uuid==to )[0];
          } else {
            this.field = undefined;
          }
        },
      }
    },

    methods: {
      toField() {
        this.$router.push({ name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.fieldUuid } });
      },
      deleteField() {
        this.field.deleted = true
        this.updateCustomerField(this.field);
        this.$store.dispatch('upsertCustomer', this.customer);
        this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } });
      }
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "delete": "Delete?"
  },
  "es": {
    "delete": "Eliminar?"
  },
  "sw": {
    "delete": "Futa?"
  }
}
</i18n>
