<template>

  <v-container fluid class="text-left pa-0">

    <v-row v-if="recentCustomers.length>0">
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Recent profiles</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1" v-if="recentCustomers.length>0">
      <v-col cols="12" class="pa-0">
        <v-btn v-for="recent in recentCustomers" color="primary" @click="customer = recent" class="ma-1">
          {{recent.name}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled"  v-if="recentCustomers.length>0">Or, search</h3>
        <h3 class="text--disabled"  v-else>Search</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1">
      <v-col cols="12">
        <v-autocomplete
          v-model="customer"
          :items="customers"
          :search-input.sync="search"
          prepend-icon="mdi-database-search"
          item-text="name"
          item-value=""
          return-object
          style="min-width: 100px; max-width: 100%;"
        ></v-autocomplete>
      </v-col>
    </v-row>


    <v-row v-if="customer">
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Then, select a financial product {{(customer && customer.name ? 'for ' + customer.name : '' )}}</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1"  v-if="customer">
      <v-col cols="12" class="pa-0">
        <ServerSideResult :test="instruments">
          <v-btn v-for="instrument in instruments" color="primary" @click="redirectTo(instrument)" class="ma-1">
            {{instrumentTypeToName(instrument.type)}}
          </v-btn>
        </ServerSideResult>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Or, back</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pa-0">
        <v-btn text outlined @click="redirectTo(undefined)">
          Cancel
        </v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
  import lookups from '@/mixins/lookups';
  import instrumentNames from '@/mixins/instrumentNames';

  import ServerSideResult from "@/components/ServerSideResult";

  export default {

    components: {  ServerSideResult },

    props: ['value'],

    data() {
      return {
        target: undefined,
        customer: null,
        search: null,
        instruments: undefined,
      }
    },

    computed: {
      customers() { return this.$store.getters.customers.filter(customer => customer.fields.length >= 1).sort( (a,b) => (a.name > b.name ? 1 : -1) ); },
      recentCustomers() { return this.$store.getters.searchedCustomers.sort( (a,b) => (a.name > b.name ? 1 : -1) ); }
    },

    watch: {
      customer(to) {
        this.remoteLookup('searchInstrumentsForCustomer', {
          uuid: this.customer.uuid
        }).then( instruments => this.instruments = instruments.instruments ).catch(e => this.instruments = null);
      }
    },

    methods: {
      redirectTo(instrument) {
        let r = undefined;
        if (instrument) {
          let query = {
            uuid: this.customer.uuid,
            instrumentId: instrument.id,
            instrumentType: instrument.type,
          }
          r = { ...this.value };
          r.filter = undefined;
          r.query = query;
        }
        this.$emit('input', r);
      },
    },

    mixins: [lookups, instrumentNames],
  }
</script>