<template>
  <v-btn plain class="text-capitalize" v-bind="$attrs" v-on="$listeners" :to="{name: 'ShowCustomer', query: { uuid: theUuid } }" v-if="theUuid">
    <template v-slot:loader>
      <slot name="loader"></slot>
    </template>
    <slot><v-icon dense small>mdi-account</v-icon> {{theName}}</slot>
  </v-btn>
</template>

<script>
  export default {

    props: {
      customer: Object,
      name: String,
      uuid: String,
    },

    computed: {
      theUuid() { return (this.uuid || ( this.customer || {}).uuid); },
      theName() { return (this.name || ( this.customer || {}).name); },
    },
    
    methods: {
      click(event) {
        this.disable();
        this.$emit('click', event);
      },
    },

  }
</script>
