

export function parseCustomerUpdatedAt(customers) {
    return customers.reduce((total, customer) => {
        const farmUpdatedAt = !!customer.farm ? customer.farm.updatedAt : null
        const fieldsUpdatedAt = (customer.fields || []).map( field => field.updatedAt)
        const customerUpdatedAt = customer.updatedAt
        const allUpdatedAt = [farmUpdatedAt, ...fieldsUpdatedAt, customerUpdatedAt]
        const filteredUpdatedAt = allUpdatedAt
            .filter( updatedAt => !!updatedAt)
            .sort((a, b) => {
                const aDate = new Date(a)
                const bDate = new Date(b)

                return bDate.getTime() - aDate.getTime()
            })
        total[customer.uuid] = filteredUpdatedAt.length ? filteredUpdatedAt[0]: null;
        return total
    }, {})

}


export function findAllCustomersUpdatedMismatch(serverCustomersUpdated, customersUuidAndUpdatedAt) {
    const customersUpdatedMismatch = []
    Object.keys(serverCustomersUpdated).map(customerUuid => {
        const localCustomerUpdatedAt = new Date(customersUuidAndUpdatedAt[customerUuid])
        const serverCustomerUpdatedAt = new Date(serverCustomersUpdated[customerUuid])
        // const timeDifference = localCustomerUpdatedAt.getTime() - serverCustomerUpdatedAt.getTime()
        if (localCustomerUpdatedAt.getTime() !== serverCustomerUpdatedAt.getTime()) {
            customersUpdatedMismatch.push(customerUuid)
        }
    })
    return customersUpdatedMismatch
}
