<template>
  <v-form v-model="validForm">
    <v-container fluid>

      <v-row>
        <v-col cols="12" sm="4">
          <TextInput v-model="customer.partnerIdentifier" :label="$t('Farmer Registration Number')" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.first_name" :label="$t('First Name')" required />
        </v-col>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.surname" :label="$t('Surname')" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <MsisdnInput v-model="customer.msisdn" label="MPESA Phone Number" required />
        </v-col>
        <v-col cols="12" sm="4">
          <DateInput v-model="customer.demographic.birthdate" :max="new Date().toISOString().substr(0, 10)"  dropLists />
        </v-col>
        <v-col cols="12" sm="4">
          <GenderInput v-model="customer.demographic.gender" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <IdentityInput v-model="customer.demographic.identity" :selectable="false" defaultType="national_id" required />
        </v-col>
        <v-col cols="12" md="3">
          <PhotoInput v-model="customer.id_card_front" type="id_card_front" label=" Identity Card Front" :typeId="customer.uuid" required width="100%" />
        </v-col>
        <v-col cols="12" md="3">
          <PhotoInput v-model="customer.id_card_back" type="id_card_back" label=" Identity Card Back" :typeId="customer.uuid" required width="100%" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.workAddress" :label="$t('Farm Location')" required />
        </v-col>
        <v-col cols="4">
          <NameInput v-model="customer.village" :label="$t('Village')" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <RegionPicker v-model="customer.regions" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-left">
          <v-btn @click="save('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">
            <span class="hidden-sm-and-up">
              {{$t('Save')}}
            </span>
            <span class="hidden-xs-only">
              {{$t('SaveAndViewDetail')}}
            </span>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="save('submitAndNew')" color="primary" :disabled="!validForm">
            <span class="hidden-sm-and-up">
              {{$t('Save')}}+
            </span>
            <span class="hidden-xs-only">
              {{$t('Save')}}
            </span>
          </v-btn>
        </v-col>
      </v-row>

    </v-container>
    <v-alert
      type="success"
      max-width="500"
      v-if="succesAlert"
    >Successfully created farmer</v-alert>
  </v-form>
</template>


<script>
  import { v4 as uuidv4 } from "uuid";
  import PhotoInput from '@/components/PhotoInput';
  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import TextInput from '@/components/TextInput';
  import NameInput from '@/components/NameInput';
  import DateInput from '@/components/DateInput';
  import GenderInput from '@/components/GenderInput';
  import IdentityInput from '@/components/IdentityInput';
  import DepotInput from '@/components/DepotInput';

  export default {

    components: {
      PhotoInput,
      RegionPicker,
      MsisdnInput,
      TextInput,
      NameInput,
      DateInput,
      GenderInput,
      IdentityInput,
      DepotInput,
    },

    data: () => ({
      succesAlert: false,
      validForm: false,
      villages: ['Chekereni', 'Moshi Town'],
      village: undefined,
      customer: {
        demographic: { identity: {} }
      },
    }),

    methods: {
      save(action) {
        let regions = (this.customer.regions ? this.customer.regions : [])
        this.customer.demographic.geographicsArray = [
          ...regions,
          this.customer.workAddress,
          this.customer.village
        ]
        this.$store.dispatch('upsertCustomer', this.customer);
        this.displaySuccess()
      },

      displaySuccess(){
        this.succesAlert = true
        setTimeout(() => {
          this.succesAlert = false
        }, 5000)
      },

      initializeCustomer() {
        this.customer.uuid = this.customer.uuid || uuidv4();
        this.customer.isAFarmer = true;
      },
    },

    mounted() {
      this.initializeCustomer()
    },

  }
</script>

