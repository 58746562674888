<template>
  <div class="w-100 pa-1" style="border: 0.5px solid;">
    <div class="pr-4 pl-2 scroll">

      <v-simple-table dense>
        <tbody>
          <tr>
            <td>Stanbic Bank Uganda Limited</td>
            <td class="text-right"><b>REF NO:</b> Agricultural Loan</td>
          </tr>
          <tr>
            <td><b>Licensed and Supervised by Bank of Uganda</b></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-center" colspan="2">
              <h2><u>KEY FACTS DOCUMENT - LOANS</u></h2>
            </td>
          </tr>
          <tr>
            <td class="text-center" colspan="2">
              <div class="grey lighten-2 ma-2">
                THIS KEY FACTS DOCUMENT IS IMPORTANT TO YOU. IT SUMMARISES THE PRODUCT YOU ARE CONSIDERING.<br/>
                PLEASE ONLY SIGN AFTER YOU HAVE READ, UNDERSTOOD AND AGREED TO THE CONTENT OF THIS DOCUMENT.
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-simple-table dense>
        <tbody>
          <tr>
            <td><b>1.</b></td>
            <td class="w-25"><b><u>TYPE OF LOAN</u></b></td>
            <td>Agricultural Loan</td>
          </tr>
          <tr>
            <td><b>2.</b></td>
            <td><b><u>AIMS AND BENEFITS</u></b></td>
            <td>Access to advisory services, access to genuine inputs, mechanisation and insurance.</td>
          </tr>
          <tr>
            <td><b>3.</b></td>
            <td><b><u>COMMITMENT</u></b></td>
            <td>&nbsp;</td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colspan="2">
              <div class="d-flex justify-space-between">
                <div>a) <b>Loan Amount in UGX:</b> <Currency :value="value.exposure - value.interest - value.fees - value.insurance" /></div>
                <div>b) <b>Duration:</b> From <Time format="dd MMM yyyy" :time="Date.now()" /> to 31 Aug 2022</div>
              </div>
              <div>
                By taking this loan, you commit to providing security against the money the financial institution lends you,
                making timely repayments (including interest), and paying interest on your loan as well as any additional fees.
              </div>
              <div>
                c) <b>Security</b> you have to provide the following security: No security required
              </div>
              <div>
                d) <b>Loan installments:</b> Repayments of <Currency :value="value.exposure" />
                are to be made 1 time(s) per season and to be paid on 31 August.<br/>
                * For more details, please consult your repayment schedule
              </div>
              <div>
                e) <b>Grace period:</b> N/A
              </div>
              <div>
                f) <b>Fees:</b>
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">Description of fees</th>
                      <th class="text-left">Amount over the duration of the loan in UGX</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>i.</td>
                      <td>
                        <b>Interest</b>
                        <br/>
                        The interest rate is 12.5%, calculated on a "simple" basis, calculated daily.
                      </td>
                      <td>
                        Total interest paid<br/>
                        <b><Currency :value="value.interest" /></b>
                      </td>
                    </tr>
                    <tr>
                      <td>ii.</td>
                      <td>
                        <b>Administrative fees</b> of <Currency :value="value.fees" /> per season
                      </td>
                      <td>
                        <b><Currency :value="value.fees" /> </b>
                      </td>
                    </tr>
                    <tr>
                      <td>iii.</td>
                      <td>
                        <b>Insurance</b>
                      </td>
                      <td>
                        <b><Currency :value="value.insurance" /></b>
                      </td>
                    </tr>
                    <tr>
                      <td>iv.</td>
                      <td>
                        <b>Security-related charges</b>
                      </td>
                      <td>
                        <b><Currency :value="0" /></b>
                      </td>
                    </tr>
                    <tr>
                      <td>v.</td>
                      <td>
                        <b>Legal fees</b>
                      </td>
                      <td>
                        <b><Currency :value="0" /></b>
                      </td>
                    </tr>
                    <tr>
                      <td>vi.</td>
                      <td>
                        <b>New Application Credit inquiry</b>
                      </td>
                      <td>
                        <b><Currency :value="0" /></b>
                      </td>
                    </tr>
                    <tr>
                      <td>vii.</td>
                      <td>
                        <b>Annual loan Monitoring fee</b>
                      </td>
                      <td>
                        <b><Currency :value="0" /></b>
                      </td>
                    </tr>
                    <tr>
                      <td>viii.</td>
                      <td>
                        <b>STAMP DUTY</b>
                      </td>
                      <td>
                        <b><Currency :value="0" /></b>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-left" colspan="2">TOTAL COST OF CREDIT</th>
                      <th class="text-left"><Currency :value="value.insurance + value.fees + value.interest" /></th>
                    </tr>
                  </tfoot>
                </v-simple-table>
                The <b>TOTAL COST OF CREDIT</b> is the total amount you pay for your loan. It is calculated by adding together all costs
                you will need to pay over the period of the loan. Note that this is based on the terms above and may be subject to
                change. Also remember that you will also have to pay back the sum you borrowed (see 3.a. above).

              </div>
            </td>
          </tr>

          <tr>
            <td><b>4.</b></td>
            <td><b><u>RISKS</u></b></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colspan="2">
              a) Late repayments: if you delay repaying by more than 1 day, you will be charged 18% penalty.<br/>
              b) If you fail to repay the loan, you can lose your security.<br/>
              c) If you fail to repay the loan, you risk losing your reputation in your community and in front of your family.<br/>
              d) Failure to repay can harm your chances of accessing loans in the future.<br/>
              e) In case of default, you will have to meet the costs of loan recovery.<br/>
              f) The interest rate is variable so the amount of interest you have to pay may increase. We will immediately inform you of any changes.<br/>
            </td>
          </tr>

          <tr>
            <td><b>5.</b></td>
            <td><b><u>FURTHER POINTS TO CONSIDER</u></b></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colspan="2">
              <b>The right to cool off:</b> You have up to 10 working days from when you sign the contract to “cool off”, i.e. to cancel
              the loan by terminating the contract. To “cool off”, you have to provide written notice to the lender and return all the
              borrowed money. The lender may charge you a fee not exceeding 5% of the value of the loan. The right to “cool off”
              applies only to loans of UGX 3,000,000 or above with a duration of at least one year.<br/>

              <b>Early redemption:</b> This loan allows you to pay back the money you borrowed before the agreed date at a fee of 0%
              of amount outstanding.<br/>

              <b>How to complain:</b> If you are dissatisfied with our services, we welcome you to communicate this to us at any of our
              branches or our CCC on 0800150150 or 0800250250. We will acknowledge receipt of your complaint, investigate and
              give you an answer within two weeks.<br/>

              <b>Credit Reference Bureau:</b> Every borrower must be registered and requires a financial card.<br/>

              <b>Also remember to ask about:</b><br/>
              a) Insurance (risks insured, premiums, and benefits that accrue to the borrower).<br/>
              b) Security concepts (professional valuation, forced sale value, security margin and cash margin).<br/>
              c) Measures taken in case of failure to repay.<br/>
              d) Guarantor policy (if applicable) and the risks to the guarantor.<br/>
              e) Security liquidation policy/process.<br/>
              f) Security release process and associated costs.<br/>

              <b>Future communications</b><br/>
              It is important for us to be able to communicate with you. Below, please tick at least two preferred means of
              communication and provide details (and update us in case of any changes):

                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">Mobile Phone <v-icon dense small>mdi-checkbox-marked-outline</v-icon></th>
                      <th class="text-left" colspan="2">Email <v-icon dense small>mdi-checkbox-blank-outline</v-icon></th>
                      <th class="text-left" colspan="2">Post <v-icon dense small>mdi-checkbox-blank-outline</v-icon></th>
                      <th class="text-left" colspan="2">Over the counter <v-icon dense small>mdi-checkbox-blank-outline</v-icon></th>
                      <th class="text-left" colspan="2">Other <v-icon dense small>mdi-checkbox-marked-outline</v-icon></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td># 1</td>
                      <td colspan="4"><Telephone :msisdn="customer.msisdn" /></td>
                    </tr>
                    <tr>
                      <td># 2</td>
                      <td colspan="4">Agronomist: {{fieldAgent}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>


            </td>
          </tr>



        </tbody>
      </v-simple-table>

    </div>
  </div>
</template>

<script>
import Currency from "@/components/Currency";
import Telephone from "@/components/Telephone";
import Time from "@/components/Time";

export default {
  components: { Currency, Telephone, Time },
  props: {
    value: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fieldAgent() { return this.$store.getters.settings.name; },
  },
}
</script>


<style scoped>
.scroll {
  height: 50vh;
  overflow-y: auto;
}
.v-data-table {
  border: none;
  border-bottom: none;
}
</style>