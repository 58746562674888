export default {
  data: () => ({
    pestsAndRecommendations: {
  "Activador radicular": [
    {
      "intervention": "Eccobiox SL. (Bacillus megaterium 1X10⁷ Esporas/ml 20%)",
      "source": "1.000 centimetros cubicos/ha",
      "description": "1.000 centimetros",
      "unit": "???",
      "min": 0.21822633897691102,
      "max": 0.612399351370686
    }
  ],
  "Afidos - Pulgones": [
    {
      "intervention": "Biogrin 860 grs/litro. (Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.9144824441188883,
      "max": 1.429587263280166
    },
    {
      "intervention": "Oportune 25 SC (Buprofezin)",
      "source": "600 - 800 cc/ha",
      "description": "600 - 800",
      "unit": "cc/ha",
      "min": 0.5177214166798169,
      "max": 1.258268734399686
    },
    {
      "intervention": "Dantotsu 50 WG. (Clothianidin)",
      "source": "250 grs/ha",
      "description": "250",
      "unit": "g/ha",
      "min": 0.30378832435659586,
      "max": 1.8478823156289188
    },
    {
      "intervention": "Trival 200.000.000 UFC. (Paecilomyces fumosoroseus PF 1)",
      "source": "1 - 2 cc/litro",
      "description": "1 - 2",
      "unit": "cc/l",
      "min": 0.7354780154268793,
      "max": 1.3520976071831183
    },
    {
      "intervention": "Cosmo Oil EO 672 grs/litro. (Paraffinic oil)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.6543616118897765,
      "max": 0.22187675755967007
    },
    {
      "intervention": "Movento OD 150. (Spirotetramat)",
      "source": "600 cc/ha",
      "description": "600",
      "unit": "cc/ha",
      "min": 0.8954956924983893,
      "max": 0.9104233542167648
    },
    {
      "intervention": "Actara 25 WG - Furio 250 WG - Nutar 250 WG. (Thiametoxan)",
      "source": "400 grs/ha - 600 grs/ha",
      "description": "400 grs/ha - 600",
      "unit": "g/ha",
      "min": 0.18671841104630005,
      "max": 0.7116914366305067
    },
    {
      "intervention": "Depredador de Insectos. (Chrysoperla carnea - C. externa)",
      "source": "20.000 a 100.000 larvas/ha",
      "description": "20.000 a 100.000",
      "unit": "larvas/ha",
      "min": 0.15210283894856946,
      "max": 1.2056966809080525
    },
    {
      "intervention": "Raxter. (Metarhizium anisopliae)",
      "source": "1,0 - 2,0 grs/litro",
      "description": "1,0 - 2,0",
      "unit": "g/l",
      "min": 0.2453933824294191,
      "max": 0.6110691544625122
    },
    {
      "intervention": "Closer 240 SC. (Sulfoxaflor)",
      "source": "400 cc/ha",
      "description": "400",
      "unit": "cc/ha",
      "min": 0.8500101526068059,
      "max": 0.9504305705027807
    }
  ],
  "Altenaria spp.": [
    {
      "intervention": "Amistar 50 WP (Azoxystrobin)",
      "source": "100 a 125 gramos/ha",
      "description": "100 a 125",
      "unit": "g/ha",
      "min": 0.2872300178721833,
      "max": 1.459914364419142
    },
    {
      "intervention": "Cantus WG 50% (Boscalid)",
      "source": "300 gramos/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.6653407109231112,
      "max": 1.186004929057463
    },
    {
      "intervention": "Bellis WG 25%+12,8% (Boscalid + Piraclostrobin)",
      "source": "800 a 1.000 gramos/ha",
      "description": "800 a 1.000",
      "unit": "g/ha",
      "min": 0.5881308397299159,
      "max": 0.7705674293378559
    },
    {
      "intervention": "Cabo SC 500 (Fenhexamid)",
      "source": "1,0 a 1,5 kilos/ha",
      "description": "1,0 a 1,5",
      "unit": "kg/ha",
      "min": 0.2580143584197099,
      "max": 1.1306861537088848
    },
    {
      "intervention": "Domark 40 EW (Tetraconazole)",
      "source": "0,8 a 0,9 litros /ha",
      "description": "0,8 a 0,9 litros",
      "unit": "/ha",
      "min": 0.6485854448383617,
      "max": 1.6334383733975466
    },
    {
      "intervention": "Silvacur Combi EC 22,5% + 7,5%. (Tebuconazole + Triadimenol)",
      "source": "0,5 a 0,6 litros/ha",
      "description": "0,5 a 0,6",
      "unit": "l/ha",
      "min": 0.7925284112646322,
      "max": 1.257939043430163
    },
    {
      "intervention": "Folicur EW 25%. (Tebuconazole)",
      "source": "0,8 cc/ha",
      "description": "0,8",
      "unit": "cc/ha",
      "min": 0.7365174365050658,
      "max": 0.42368734291228183
    },
    {
      "intervention": "Azimut 320 SC 20% + 12%. (Tebuconale + Azoxystrobin)",
      "source": "0,8 litros/ha",
      "description": "0,8",
      "unit": "l/ha",
      "min": 0.31740195812767447,
      "max": 0.25483949737473255
    },
    {
      "intervention": "Nativo SC 10% + 20%. (Trifloxystrobin + Tebuconazole)",
      "source": "0,4 - 0,5 litros/ha",
      "description": "0,4 - 0,5",
      "unit": "l/ha",
      "min": 0.8266492991934947,
      "max": 0.39626655785251463
    },
    {
      "intervention": "Trifloxystrobin 500 SC. (Trifloxystrobin)",
      "source": "75 cc/h",
      "description": "75",
      "unit": "cc/ha",
      "min": 0.6587006204837872,
      "max": 1.6256211750481844
    },
    {
      "intervention": "Antracop 17,2% + 13,5 + 5,6% + 23,1%. (Cobre + Calcio + Magnesio + Potasio)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.23022473851219205,
      "max": 1.5929658358577428
    },
    {
      "intervention": "Bactox WP. (Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado(10%))",
      "source": "500 a 1.000 gramos/ha",
      "description": "500 a 1.000",
      "unit": "g/ha",
      "min": 0.052898135647632016,
      "max": 1.8233909180403405
    }
  ],
  "Amplio Espectro": [
    {
      "intervention": "Biocloro (Dioxido de Cloro Estabililzado)",
      "source": "1,0 a 2,0 litros/ha",
      "description": "1,0 a 2,0",
      "unit": "l/ha",
      "min": 0.6269924428225868,
      "max": 1.0712797567834889
    },
    {
      "intervention": "Lannate 40 SP. (Methomyl)",
      "source": "500 grs/ha",
      "description": "500",
      "unit": "g/ha",
      "min": 0.0669483307057348,
      "max": 0.9312568932105469
    }
  ],
  "Amplio Espectro Contacto No Selectivo": [
    {
      "intervention": "Gramoxone SL 200. (Paraquat)",
      "source": "1.500 - 3.000 cc/ha",
      "description": "1.500 - 3.000",
      "unit": "cc/ha",
      "min": 0.20451165458408238,
      "max": 0.09483314727459269
    },
    {
      "intervention": "Citrix H.O. (Extracto Citricos)",
      "source": "30 cc/Litro",
      "description": "30",
      "unit": "???",
      "min": 0.9528930830425567,
      "max": 1.5330918903378923
    }
  ],
  "Amplio Espectro Sistemico No Selectivo": [
    {
      "intervention": "Roundup 480 SL. (Glifosato)",
      "source": "3.000 cc/ha",
      "description": "3.000",
      "unit": "cc/ha",
      "min": 0.7349777342590148,
      "max": 0.73135217282848
    }
  ],
  "Basidiomicetos": [
    {
      "intervention": "Maxim XL 035 FS (Metalaxil + fludioxonil)",
      "source": "1 cc/litro Inmersion al cespedon",
      "description": "1 cc/litro Inmersion al",
      "unit": "???",
      "min": 0.6244759250842746,
      "max": 1.8199105657413217
    }
  ],
  "Bemicia tabaci - Trialeurodes vaporariorum": [
    {
      "intervention": "Biogrin 860 grs/litro. (Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.10295532938955765,
      "max": 0.49295343282442117
    },
    {
      "intervention": "Adral WP - Adral SC 200.000.000 esporas por gramo. (Beauveria bassiana)",
      "source": "1,0 - 2,0 grs/litro / 2,0 - 4,0 cc/litro",
      "description": "1,0 - 2,0 grs/litro / 2,0 - 4,0",
      "unit": "cc/l",
      "min": 0.7357154570258452,
      "max": 0.8980640854707447
    },
    {
      "intervention": "Safer Mix WP (100.000.000 esporas/gramo) - (400.000.000 esporas/gramo). (Bacillus thuringiensis - B. Bassiana) - (Lecanicillium lecanii - Metarhizium anisopliae)",
      "source": "1,0 -2,0 grs/litro / 300 - 500 cc/ha",
      "description": "1,0 -2,0 grs/litro / 300 - 500",
      "unit": "cc/ha",
      "min": 0.4936746525823089,
      "max": 1.4504778373735536
    },
    {
      "intervention": "Oportune 25 SC (Buprofezin)",
      "source": "600 - 800 cc/ha",
      "description": "600 - 800",
      "unit": "cc/ha",
      "min": 0.2951274886420996,
      "max": 0.9323584449143982
    },
    {
      "intervention": "Dantotsu 50 WG. (Clothianidin)",
      "source": "250 grs/ha",
      "description": "250",
      "unit": "g/ha",
      "min": 0.5325775841478254,
      "max": 0.726778815735903
    },
    {
      "intervention": "Confidor 350 SC. (Imidachloprid)",
      "source": "600 - 800 cc/ha",
      "description": "600 - 800",
      "unit": "cc/ha",
      "min": 0.01667065762394182,
      "max": 1.4045567749218753
    },
    {
      "intervention": "Trival 200.000.000 UFC. (Paecilomyces fumosoroseus PF 1)",
      "source": "1 - 2 cc/litro",
      "description": "1 - 2",
      "unit": "cc/l",
      "min": 0.8132284473322651,
      "max": 0.39122430915946604
    },
    {
      "intervention": "Epingle EW 100 grs/litro. (Pyriproxyfen)",
      "source": "500 cc/ha",
      "description": "500",
      "unit": "cc/ha",
      "min": 0.3968192679749213,
      "max": 1.8023298586302445
    },
    {
      "intervention": "Rimon DUO 100 CS 50 grs/litro + 50 grs/litro. (Novaluron + Bifentrin)",
      "source": "400 cc/ha",
      "description": "400",
      "unit": "cc/ha",
      "min": 0.4106660709644743,
      "max": 0.28037384533844834
    },
    {
      "intervention": "Cosmo Oil EO 672 grs/litro. (Paraffinic oil)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.3404953725049128,
      "max": 0.7507318828781191
    },
    {
      "intervention": "Oberon 240 SC. (Spiromesifen)",
      "source": "400 - 600 cc/ha",
      "description": "400 - 600",
      "unit": "cc/ha",
      "min": 0.46534230453461023,
      "max": 0.5721210697477275
    },
    {
      "intervention": "Oberon Speed SC 228 Spiromesifen + 1,14% Avamectina. (Spiromesifen + Abamectina)",
      "source": "600 cc/ha",
      "description": "600",
      "unit": "cc/ha",
      "min": 0.8508379399879836,
      "max": 1.623506387367713
    },
    {
      "intervention": "Movento OD 150. (Spirotetramat)",
      "source": "600 cc/ha",
      "description": "600",
      "unit": "cc/ha",
      "min": 0.7208902775448087,
      "max": 0.36151486150607304
    },
    {
      "intervention": "Closer 240 SC. (Sulfoxaflor)",
      "source": "400 cc/ha",
      "description": "400",
      "unit": "cc/ha",
      "min": 0.44641055499912363,
      "max": 0.47288627438590036
    },
    {
      "intervention": "Actara 25 WG - Furio 250 WG - Nutar 250 WG. (Thiametoxan)",
      "source": "400 grs/ha - 600 grs/ha",
      "description": "400 grs/ha - 600",
      "unit": "g/ha",
      "min": 0.5647968017501787,
      "max": 0.284319851020858
    },
    {
      "intervention": "Provado Combi SC 112,5 100 grs/litro + 12,5 grs/litro. (Imidacloprid + Beta Cyfluthrin)",
      "source": "1.000 cc/ha",
      "description": "1.000",
      "unit": "cc/ha",
      "min": 0.206086440166512,
      "max": 0.19483662080837427
    }
  ],
  "Botrytis cinerea": [
    {
      "intervention": "Cantus WG 50% (Boscalid)",
      "source": "300 gramos/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.4860025663810774,
      "max": 1.4700956226589603
    },
    {
      "intervention": "Bellis WG 25%+12,8%. (Boscalid + Piraclostrobin)",
      "source": "800 a 1.000 gramos/ha",
      "description": "800 a 1.000",
      "unit": "g/ha",
      "min": 0.8323852310457828,
      "max": 0.6475236151513069
    },
    {
      "intervention": "Cabo SC 500 (Fenhexamid)",
      "source": "1,0 a 1,5 kilos/ha",
      "description": "1,0 a 1,5",
      "unit": "kg/ha",
      "min": 0.2826937170186814,
      "max": 0.5739258153656548
    },
    {
      "intervention": "Stroby SC 50%. (Kresoxim methyl)",
      "source": "200 cc/ha",
      "description": "200",
      "unit": "cc/ha",
      "min": 0.6367409448174456,
      "max": 0.12669668641820753
    },
    {
      "intervention": "Frupica 40 SC. (Mepanipyrim)",
      "source": "900 - 1.200 cc/ha",
      "description": "900 - 1.200",
      "unit": "cc/ha",
      "min": 0.5010997591422969,
      "max": 1.804468690136388
    },
    {
      "intervention": "Bactox WP (Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado(10%)",
      "source": "500 a 1.000 gramos/ha",
      "description": "500 a 1.000",
      "unit": "g/ha",
      "min": 0.4903850253522737,
      "max": 0.15342064217214468
    }
  ],
  "Cercospora spp.": [
    {
      "intervention": "Amistar 50 WP. (Azoxystrobin)",
      "source": "100 a 125 gramos/ha",
      "description": "100 a 125",
      "unit": "g/ha",
      "min": 0.3633750416301129,
      "max": 1.5137980903439392
    },
    {
      "intervention": "Folicur EW 25%. (Tebuconazole)",
      "source": "0,8 cc/ha",
      "description": "0,8",
      "unit": "cc/ha",
      "min": 0.4102607511760966,
      "max": 1.8070563461114253
    },
    {
      "intervention": "Cabo SC 500. (Fenhexamid)",
      "source": "1,0 a 1,5 kilos/ha",
      "description": "1,0 a 1,5",
      "unit": "kg/ha",
      "min": 0.3180236781690562,
      "max": 1.0524232069941408
    },
    {
      "intervention": "Silvacur Combi EC 22,5% + 7,5%. (Tebuconazole + Triadimenol)",
      "source": "0,5 a 0,6 litros/ha",
      "description": "0,5 a 0,6",
      "unit": "l/ha",
      "min": 0.7565110025546509,
      "max": 1.0960853325627151
    },
    {
      "intervention": "Azimut 320 SC 20% + 12%. (Tebuconale + Azoxystrobin)",
      "source": "0,8 litros/ha",
      "description": "0,8",
      "unit": "l/ha",
      "min": 0.9118686762912316,
      "max": 0.7977793696351898
    },
    {
      "intervention": "Cantus WG 50%. (Boscalid)",
      "source": "300 gramos/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.8080513267139237,
      "max": 0.34093992601339096
    }
  ],
  "Chizas Control Escarabajos Plaga": [
    {
      "intervention": "Cronox WP 160.000.000 UFC. (Bacillus popilliae)",
      "source": "3 - 5 g/m2",
      "description": "3 - 5",
      "unit": null,
      "min": 0.08230960100680185,
      "max": 0.41142857980600844
    }
  ],
  "Chupadores": [
    {
      "intervention": "Trebon 30 EC. (Etofenprox)",
      "source": "500 cc/ha",
      "description": "500",
      "unit": "cc/ha",
      "min": 0.0848656471637419,
      "max": 0.6806619448861511
    }
  ],
  "Coleópteros": [
    {
      "intervention": "Adral WP - Adral SC 200.000.000 esporas por gramo. (Beauveria bassiana)",
      "source": "1,0 - 2,0 grs/litro / 2,0 - 4,0 cc/litro",
      "description": "1,0 - 2,0 grs/litro / 2,0 - 4,0",
      "unit": "cc/l",
      "min": 0.32050077407781663,
      "max": 1.5344856020355822
    },
    {
      "intervention": "Safer Mix WP (100.000.000 esporas/gramo) - (400.000.000 esporas/gramo). (Bacillus thuringiensis - B. Bassiana) - (Lecanicillium lecanii - Metarhizium anisopliae)",
      "source": "1,0 -2,0 grs/litro / 300 - 500 cc/ha",
      "description": "1,0 -2,0 grs/litro / 300 - 500",
      "unit": "cc/ha",
      "min": 0.454234924176786,
      "max": 1.902050234701886
    },
    {
      "intervention": "Raxter. (Metarhizium anisopliae)",
      "source": "1,0 - 2,0 grs/litro",
      "description": "1,0 - 2,0",
      "unit": "g/l",
      "min": 0.5608595056521938,
      "max": 0.8877202822232277
    }
  ],
  "Colletotorichum spp.": [
    {
      "intervention": "Silvacur Combi EC 22,5% + 7,5%. (Tebuconazole + Triadimenol)",
      "source": "0,5 a 0,6 litros/ha",
      "description": "0,5 a 0,6",
      "unit": "l/ha",
      "min": 0.38700951388173344,
      "max": 1.0010838728536644
    },
    {
      "intervention": "Domark 40 EW. (Tetraconazole)",
      "source": "0,8 a 0,9 litros /ha",
      "description": "0,8 a 0,9 litros",
      "unit": "/ha",
      "min": 0.19385591172763783,
      "max": 1.634477786356221
    },
    {
      "intervention": "Amistar 50 WP. (Azoxystrobin)",
      "source": "100 a 125 gramos/ha",
      "description": "100 a 125",
      "unit": "g/ha",
      "min": 0.9339893267476974,
      "max": 1.7229542607261559
    },
    {
      "intervention": "Cantus WG 50%. (Boscalid)",
      "source": "300 gramos/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.8793373761618976,
      "max": 1.8500375106069873
    },
    {
      "intervention": "Kruga SC 240. (Fenbuconazole)",
      "source": "0,5 litros/ha",
      "description": "0,5",
      "unit": "l/ha",
      "min": 0.5411887845745703,
      "max": 0.6899573799874352
    },
    {
      "intervention": "Cabo SC 500. (Fenhexamid)",
      "source": "1,0 a 1,5 kilos/ha",
      "description": "1,0 a 1,5",
      "unit": "kg/ha",
      "min": 0.23093114206515375,
      "max": 1.2328228309789828
    },
    {
      "intervention": "Rally 40 WP. (Myclobutanil)",
      "source": "80 gramos/ha",
      "description": "80",
      "unit": "g/ha",
      "min": 0.4623675975353021,
      "max": 1.4309609112168695
    },
    {
      "intervention": "Folicur EW 25%. (Tebuconazole)",
      "source": "0,8 cc/ha",
      "description": "0,8",
      "unit": "cc/ha",
      "min": 0.8874477090680833,
      "max": 1.4293864096988937
    },
    {
      "intervention": "Azimut 320 SC 20% + 12%. (Tebuconale + Azoxystrobin)",
      "source": "0,8 litros/ha",
      "description": "0,8",
      "unit": "l/ha",
      "min": 0.0969547726266401,
      "max": 1.9441565920995336
    },
    {
      "intervention": "Nativo SC 10% + 20%. (Trifloxystrobin + Tebuconazole)",
      "source": "0,4 - 0,5 litros/ha",
      "description": "0,4 - 0,5",
      "unit": "l/ha",
      "min": 0.5666940267790567,
      "max": 1.8906475673185072
    },
    {
      "intervention": "Trifloxystrobin 500 SC. (Trifloxystrobin)",
      "source": "75 cc/ha",
      "description": "75",
      "unit": "cc/ha",
      "min": 0.9989319879913883,
      "max": 1.0451167008062792
    },
    {
      "intervention": "Antracop 17,2% + 13,5 + 5,6% + 23,1% (Cobre + Calcio + Magnesio + Potasio)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.619826071929685,
      "max": 0.08766382214681645
    }
  ],
  "Crisomelidos - Cucarroncitos del Follaje": [
    {
      "intervention": "Trebon 30 EC. (Etofenprox)",
      "source": "500 cc/ha",
      "description": "500",
      "unit": "cc/ha",
      "min": 0.8836660957237389,
      "max": 0.8898927619225574
    },
    {
      "intervention": "Closer 240 SC. (Sulfoxaflor)",
      "source": "400 cc/ha",
      "description": "400",
      "unit": "cc/ha",
      "min": 0.5783240574994895,
      "max": 0.41420726978451317
    }
  ],
  "Dasineura spp.": [
    {
      "intervention": "Rescate 200 SP. (Acetamiprid)",
      "source": "0,75 grs/litro - 300 grs/ha",
      "description": "0,75 grs/litro - 300",
      "unit": "g/ha",
      "min": 0.7057642774888316,
      "max": 0.46516840757207567
    },
    {
      "intervention": "Movento OD 150. (Spirotetramat)",
      "source": "600 cc/ha",
      "description": "600",
      "unit": "cc/ha",
      "min": 0.8997838946121121,
      "max": 0.009150809818825323
    },
    {
      "intervention": "Actara 25 WG - Furio 250 WG - Nutar 250 WG. (Thiametoxan)",
      "source": "400 grs/ha - 600 grs/ha",
      "description": "400 grs/ha - 600",
      "unit": "g/ha",
      "min": 0.3724354655339306,
      "max": 0.4477267060192114
    },
    {
      "intervention": "Exalt 60 SC. (Spinetoram)",
      "source": "200 - 300 cc/ha",
      "description": "200 - 300",
      "unit": "cc/ha",
      "min": 0.4633395782542906,
      "max": 1.3285619562566424
    }
  ],
  "Deuteromicetos": [
    {
      "intervention": "Maxim XL 035 FS (Metalaxil + fludioxonil)",
      "source": "1 cc/litro Inmersion al cespedon",
      "description": "1 cc/litro Inmersion al",
      "unit": "???",
      "min": 0.6916302245664009,
      "max": 1.0192220116402975
    }
  ],
  "Dípteros": [
    {
      "intervention": "Trigard 750 WP - Ciromex 400 SC - Cohete 750 WP. (Ciromazina)",
      "source": "0,3 a 0,4 grs/litro",
      "description": "0,3 a 0,4",
      "unit": "g/l",
      "min": 0.9914431031793005,
      "max": 1.0362117326719364
    }
  ],
  "Empoasca spp.": [
    {
      "intervention": "Raxter. (Metarhizium anisopliae)",
      "source": "1,0 - 2,0 grs/litro",
      "description": "1,0 - 2,0",
      "unit": "g/l",
      "min": 0.3031070867636888,
      "max": 1.285242307153763
    }
  ],
  "Epitrix sp.": [
    {
      "intervention": "Trebon 30 EC. (Etofenprox)",
      "source": "500 cc/ha",
      "description": "500",
      "unit": "cc/ha",
      "min": 0.165667825783274,
      "max": 0.899390021605992
    }
  ],
  "Epitrix sp. Cucarroncitos del Follaje - Amplio Espectro": [
    {
      "intervention": "Fastac 10 EC - Dominex 10 EC. (Alfa Cipermetrina)",
      "source": "200 - 400 cc/ha",
      "description": "200 - 400",
      "unit": "cc/ha",
      "min": 0.2714002883455161,
      "max": 0.7087987291410385
    }
  ],
  "Escamas - Escamas Blandas y Cochinillas": [
    {
      "intervention": "Oportune 25 SC (Buprofezin)",
      "source": "600 - 800 cc/ha",
      "description": "600 - 800",
      "unit": "cc/ha",
      "min": 0.89563212903476,
      "max": 1.6570533931855909
    },
    {
      "intervention": "Depredador de Insectos. (Chrysoperla carnea - C. externa)",
      "source": "20.000 a 100.000 larvas/ha",
      "description": "20.000 a 100.000",
      "unit": "larvas/ha",
      "min": 0.965109426135292,
      "max": 0.5238089377090689
    }
  ],
  "Fungicida Protectante Amplio Espectro": [
    {
      "intervention": "Elosal 720 SC. (Azufre)",
      "source": "1,0 litro/ha",
      "description": "1,0",
      "unit": "l/ha",
      "min": 0.408268214569695,
      "max": 0.06179277841898023
    },
    {
      "intervention": "Mastercop EC 260 (Cobre Sulfato Pentahidratado)",
      "source": "1,5 lts/ha",
      "description": "1,5",
      "unit": "l/ha",
      "min": 0.8133828909880324,
      "max": 0.009813452138449641
    },
    {
      "intervention": "S-cooper SC 213. (Cobre Sulfato Pentahidratado)",
      "source": "0,3 lts/ha",
      "description": "0,3",
      "unit": "l/ha",
      "min": 0.10527300616949009,
      "max": 1.0787858847943785
    },
    {
      "intervention": "Ceraquint SP 10,2% + 51,9%. (Cobre Sulfato Pentahidratado + Fosfitos de Potasio)",
      "source": "2,0 kilos/ha",
      "description": "2,0",
      "unit": "kg/ha",
      "min": 0.5718267023144222,
      "max": 1.7203035432607796
    },
    {
      "intervention": "Stoller cooper 60. (Cobre soluble + Azufre)",
      "source": "1,5 lts/ha",
      "description": "1,5",
      "unit": "l/ha",
      "min": 0.5529055411756828,
      "max": 1.2162202911492825
    },
    {
      "intervention": "Oxicloruro de Cobre WP 58,8%. (Oxicloruro de Cobre)",
      "source": "1,5 a 3,0 kilos/ha",
      "description": "1,5 a 3,0",
      "unit": "kg/ha",
      "min": 0.9002035861753562,
      "max": 0.5856223122527542
    },
    {
      "intervention": "Polical 79%. (Polisulfuro de Calcio)",
      "source": "3,5 cc/litro - 1.200 cc/ha",
      "description": "3,5 cc/litro - 1.200",
      "unit": "cc/ha",
      "min": 0.48808233332948225,
      "max": 0.11319290348464639
    },
    {
      "intervention": "Antracol WP 70 (Propineb)",
      "source": "1,5 a 2,5 kilos/ha",
      "description": "1,5 a 2,5",
      "unit": "kg/ha",
      "min": 0.8255476377931505,
      "max": 0.3618400144931344
    },
    {
      "intervention": "Antrasin PC 21% + 18%. (Sulfato de Cobre + Sulfato de Calcio)",
      "source": "2,0 a 3,0 kilos/ha",
      "description": "2,0 a 3,0",
      "unit": "kg/ha",
      "min": 0.859616789870142,
      "max": 1.373683505136345
    },
    {
      "intervention": "Biocloro (Dioxido de Cloro Estabililzado)",
      "source": "1,0 a 2,0 litros/ha",
      "description": "1,0 a 2,0",
      "unit": "l/ha",
      "min": 0.3255698343897776,
      "max": 1.147481045771975
    },
    {
      "intervention": "Royal Cu SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%. (Potasio + Azufre + Cobre + Streptomicina + Silicio)",
      "source": "1,0 a 3,0 litros/ha",
      "description": "1,0 a 3,0",
      "unit": "l/ha",
      "min": 0.28699157515636664,
      "max": 0.8507100139673478
    }
  ],
  "Fusarium spp.": [
    {
      "intervention": "Vitavax 300 20% + 20%. (Carboxin + Captan)",
      "source": "2,0 a 4,0 kilos/ha",
      "description": "2,0 a 4,0",
      "unit": "kg/ha",
      "min": 0.5676965983605079,
      "max": 1.7572009696619377
    },
    {
      "intervention": "Bactox WP (Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado(10%)",
      "source": "500 a 1.000 gramos/ha",
      "description": "500 a 1.000",
      "unit": "g/ha",
      "min": 0.13918129318869432,
      "max": 1.7417439496496183
    },
    {
      "intervention": "Trichox WP. (Trichoderma harzianum 1.0 X 10⁸ UFC Esporas/Gramo de Producto Formulado)",
      "source": "500 gramos/ha",
      "description": "500",
      "unit": "g/ha",
      "min": 0.3358134416507662,
      "max": 1.2979237161464496
    }
  ],
  "Lepidópteros": [
    {
      "intervention": "Safer Mix WP (100.000.000 esporas/gramo) - (400.000.000 esporas/gramo). (Bacillus thuringiensis - B. Bassiana) - (Lecanicillium lecanii - Metarhizium anisopliae)",
      "source": "1,0 -2,0 grs/litro / 300 - 500 cc/ha",
      "description": "1,0 -2,0 grs/litro / 300 - 500",
      "unit": "cc/ha",
      "min": 0.5348980240933948,
      "max": 1.68712986630411
    },
    {
      "intervention": "Dipel WG - 32.000 UI - BaKtur 32.000 UI. (Bacillus thuringiensis pv kurstaki)",
      "source": "150 a 200 grs/Ha",
      "description": "150 a 200",
      "unit": "g/ha",
      "min": 0.9900067940934756,
      "max": 0.9839374255596971
    },
    {
      "intervention": "Proaxis 60 CS. (Cyalotrina gamma)",
      "source": "50 - 100 cc/ha",
      "description": "50 - 100",
      "unit": "cc/ha",
      "min": 0.7104776117253028,
      "max": 0.7011153766006029
    },
    {
      "intervention": "Dimilin 48 SC. (Diflubenzuron)",
      "source": "100 - 200 cc/ha",
      "description": "100 - 200",
      "unit": "cc/ha",
      "min": 0.5456548871911523,
      "max": 1.3863918173771905
    },
    {
      "intervention": "Avaunt 150 EC. (Indoxacarb)",
      "source": "200 - 300 cc/ha",
      "description": "200 - 300",
      "unit": "cc/ha",
      "min": 0.653680113314545,
      "max": 1.7165149051817907
    },
    {
      "intervention": "Intrepid SC. (Methoxyfenozide)",
      "source": "250 - 300 cc/ha",
      "description": "250 - 300",
      "unit": "cc/ha",
      "min": 0.004122919693780203,
      "max": 0.1997681524958761
    },
    {
      "intervention": "Trebon 30 EC. (Etofenprox)",
      "source": "500 cc/ha",
      "description": "500",
      "unit": "cc/ha",
      "min": 0.8224571599510345,
      "max": 1.2717446864599797
    },
    {
      "intervention": "Rimon DUO 100 CS 50 grs/litro + 50 grs/litro. (Novaluron + Bifentrin)",
      "source": "400 cc/ha",
      "description": "400",
      "unit": "cc/ha",
      "min": 0.4787618075759341,
      "max": 0.6709368955474577
    },
    {
      "intervention": "Provado Combi SC 112,5 100 grs/litro + 12,5 grs/litro. (Imidacloprid + Beta Cyfluthrin)",
      "source": "1.000 cc/ha",
      "description": "1.000",
      "unit": "cc/ha",
      "min": 0.48222482152660073,
      "max": 1.6574023254053958
    },
    {
      "intervention": "Exalt 60 SC. (Spinetoram)",
      "source": "200 - 300 cc/ha",
      "description": "200 - 300",
      "unit": "cc/ha",
      "min": 0.4261223060275722,
      "max": 0.48900782788435104
    },
    {
      "intervention": "Tracer 120 SC. (Spinosad)",
      "source": "300 grs/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.596461207611256,
      "max": 0.0077020455537175625
    }
  ],
  "Lepidópteros Estado de Huevo y Larvas": [
    {
      "intervention": "Controlador Biologico. (Trichogramma pretiosum - T. atopovirilia - T. exiguum)",
      "source": "50 - 200 pulgadas/ha",
      "description": "50 - 200",
      "unit": "???",
      "min": 0.02299669596877374,
      "max": 0.3815649669091914
    },
    {
      "intervention": "Dart 15 SC (Teflobenzuron)",
      "source": "300 cc/ha",
      "description": "300",
      "unit": "cc/ha",
      "min": 0.44640438892244283,
      "max": 0.028022618941325073
    },
    {
      "intervention": "Depredador de Insectos. (Chrysoperla carnea - C. externa)",
      "source": "20.000 a 100.000 larvas/ha",
      "description": "20.000 a 100.000",
      "unit": "larvas/ha",
      "min": 0.4744989364841834,
      "max": 1.351575522781408
    }
  ],
  "Lepidópteros Larvas": [
    {
      "intervention": "Match 50 EC. (Lufenuron)",
      "source": "200 - 500 cc/ha",
      "description": "200 - 500",
      "unit": "cc/ha",
      "min": 0.6856540874558997,
      "max": 1.2298547848467452
    },
    {
      "intervention": "Alsystin SC 480. (Triflumuron)",
      "source": "180 a 300 cc/ha",
      "description": "180 a 300",
      "unit": "cc/ha",
      "min": 0.9146651942506525,
      "max": 1.1558755471352915
    }
  ],
  "Lepidópteros Neoleucinodes elegantalis": [
    {
      "intervention": "Coragen. (Clorantaniliprole)",
      "source": "200 cc/ha",
      "description": "200",
      "unit": "cc/ha",
      "min": 0.3876125807193369,
      "max": 0.5798625603722187
    },
    {
      "intervention": "Proclaim 05 SG - Proclaim OPTI (Emamectin Benzoate)",
      "source": "150 - 250 cc/ha",
      "description": "150 - 250",
      "unit": "cc/ha",
      "min": 0.6382819227951174,
      "max": 0.127082160906151
    },
    {
      "intervention": "Belt 480 SC. (Flubendiamide)",
      "source": "100 - 150 cc/ha",
      "description": "100 - 150",
      "unit": "cc/ha",
      "min": 0.31727122701076627,
      "max": 0.8018461597576216
    }
  ],
  "Leveilulla taurica": [
    {
      "intervention": "Rally 40 WP. (Myclobutanil)",
      "source": "80 gramos/ha",
      "description": "80",
      "unit": "g/ha",
      "min": 0.48916786957683456,
      "max": 0.07765209228076086
    }
  ],
  "Liriomyza spp.": [
    {
      "intervention": "Biogrin 860 grs/litro. (Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.27582681217386695,
      "max": 1.1478623459734323
    },
    {
      "intervention": "Cosmo Oil EO 672 grs/litro. (Paraffinic oil)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.5012914763553963,
      "max": 1.94050727773062
    }
  ],
  "Meloidogiyne spp.; Globodera spp.; Heterodera spp.; Helicotylenchus spp.; Pratylenchus spp; Ditylenchus spp.; Radhopolus spp.": [
    {
      "intervention": "Rutinal (Extracto de Ruda)",
      "source": "3 - 5 cc/litro",
      "description": "3 - 5",
      "unit": "cc/l",
      "min": 0.7663086497764965,
      "max": 0.9017192660575182
    },
    {
      "intervention": "Safer Soil. (Paecilomyces lillacinus 5x10 8 conidias/g)",
      "source": "1,0 g/l de agua",
      "description": "1,0 g/l de",
      "unit": "agua",
      "min": 0.1986754198937798,
      "max": 0.3290772077095352
    },
    {
      "intervention": "Preparación Artesanal. (Extracto de Higuerilla)",
      "source": "2 litros/ 200 litros",
      "description": "2 litros/ 200",
      "unit": "l",
      "min": 0.5009759505530957,
      "max": 1.4727961190341348
    },
    {
      "intervention": "Sincocin SL. (Glyricidia - Extractos de plantas)",
      "source": "1.500 - 2.000 cc/ha",
      "description": "1.500 - 2.000",
      "unit": "cc/ha",
      "min": 0.7038648330830302,
      "max": 1.0421919904651022
    }
  ],
  "Mosca de la Fruta": [
    {
      "intervention": "Tracer 120 SC. (Spinosad)",
      "source": "300 grs/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.5122157367319744,
      "max": 0.6053060174509892
    }
  ],
  "Oomicetos + Ascomicetos": [
    {
      "intervention": "Maxim XL 035 FS (Metalaxil + fludioxonil)",
      "source": "1 cc/litro Inmersion al cespedon",
      "description": "1 cc/litro Inmersion al",
      "unit": "???",
      "min": 0.25806997822020694,
      "max": 1.7319720485300372
    }
  ],
  "Pentatomidos": [
    {
      "intervention": "Rescate 200 SP. (Acetamiprid)",
      "source": "0,75 grs/litro - 300 grs/ha",
      "description": "0,75 grs/litro - 300",
      "unit": "g/ha",
      "min": 0.06591907153240917,
      "max": 1.1098462018359254
    }
  ],
  "Peronospora spp.": [
    {
      "intervention": "Altair WP 15% + 35%. (Metalaxil + Oxicloruro de Cobre)",
      "source": "2,5 kilos/ha",
      "description": "2,5",
      "unit": "kg/ha",
      "min": 0.4741967447304748,
      "max": 1.9160100174897476
    }
  ],
  "Phytophthora spp.": [
    {
      "intervention": "Vitavax 300 20% + 20%. (Carboxin + Captan)",
      "source": "2,0 a 4,0 kilos/ha",
      "description": "2,0 a 4,0",
      "unit": "kg/ha",
      "min": 0.6319821278822008,
      "max": 0.8784566976565462
    },
    {
      "intervention": "Forum 500 SC - WP. (Dimetomorf)",
      "source": "0,6 a 0,8 lts/ha - 0,6 a 0,8 kls/ha",
      "description": "0,6 a 0,8 lts/ha - 0,6 a 0,8",
      "unit": "kg/ha",
      "min": 0.651603697568504,
      "max": 0.6715368450354382
    },
    {
      "intervention": "Acrobat MZ 9% + 60%. (Dimetomorf + Mancozeb)",
      "source": "1,8 a 2,4 kilos-lts/ha",
      "description": "1,8 a 2,4",
      "unit": "???",
      "min": 0.3902215473475168,
      "max": 1.9919406900491556
    },
    {
      "intervention": "Forum Team WG 12% + 6,7%. (Dimetomorf + Piraclostrobin)",
      "source": "1,5 kilos/ha",
      "description": "1,5",
      "unit": "kg/ha",
      "min": 0.12887505451396297,
      "max": 0.7205401398056768
    },
    {
      "intervention": "Etinfo 10 SC. (Etaboxam)",
      "source": "600 a 900 cc/ha",
      "description": "600 a 900",
      "unit": "cc/ha",
      "min": 0.8893349812384073,
      "max": 0.005242279921053639
    },
    {
      "intervention": "Consento SC 7,5% + 37,5%. (Fenamidone + Propamocarb)",
      "source": "1,5 a 2,0 litros/ha",
      "description": "1,5 a 2,0",
      "unit": "l/ha",
      "min": 0.060747622382761435,
      "max": 0.24115216486714064
    },
    {
      "intervention": "Infinito SC 6,25% + 62,5. (Fluopicolide + Propamocarb)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.42446021134525624,
      "max": 0.015796910117384932
    },
    {
      "intervention": "Bactox WP (Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado(10%)",
      "source": "500 a 1.000 gramos/ha",
      "description": "500 a 1.000",
      "unit": "g/ha",
      "min": 0.7954250446854868,
      "max": 0.8592928122079846
    },
    {
      "intervention": "Revus 250 SC. (Mandipropamid)",
      "source": "400 cc /ha",
      "description": "400 cc",
      "unit": "/ha",
      "min": 0.22033930883417474,
      "max": 0.5347338081798287
    },
    {
      "intervention": "Ridomil Gold WP 4% + 64%. (Metalaxil+Mancozeb)",
      "source": "2,0 a 2,5 kilos/ha",
      "description": "2,0 a 2,5",
      "unit": "kg/ha",
      "min": 0.9013330302714899,
      "max": 0.7909545617946707
    },
    {
      "intervention": "Altair WP 15% + 35%. (Metalaxil + Oxicloruro de Cobre)",
      "source": "2,5 kilos/ha",
      "description": "2,5",
      "unit": "kg/ha",
      "min": 0.23631292733973241,
      "max": 0.5528513962961923
    },
    {
      "intervention": "Promess 722 SL. (Propamocarb)",
      "source": "1,0 litros/ha",
      "description": "1,0",
      "unit": "l/ha",
      "min": 0.6887564225060522,
      "max": 1.1539186858925548
    },
    {
      "intervention": "Phostrol SL 51,8%. (Sodium, Potasium and Ammonium Phosphites)",
      "source": "2,0 a 2,4 litros/ha",
      "description": "2,0 a 2,4",
      "unit": "l/ha",
      "min": 0.22367940379300832,
      "max": 1.1278950985682838
    },
    {
      "intervention": "Antracop 17,2% + 13,5 + 5,6% + 23,1%. (Cobre + Calcio + Magnesio + Potasio)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.06254528231240275,
      "max": 1.055382688285055
    },
    {
      "intervention": "RHAPSODY® 1.34 S. (Bacillus subtilis1 X 10⁹ UFC/g)",
      "source": "2,5 a 10 centimetros cubicos/litro",
      "description": "2,5 a 10 centimetros",
      "unit": "???",
      "min": 0.37640677062642425,
      "max": 0.02084924200669125
    }
  ],
  "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.": [
    {
      "intervention": "Stroby SC 50%. (Kresoxim methyl)",
      "source": "200 cc/ha",
      "description": "200",
      "unit": "cc/ha",
      "min": 0.43496851051306473,
      "max": 0.048224518946276884
    }
  ],
  "Polyphagotarsonemus latus": [
    {
      "intervention": "Vertimec 1,8% EC. (Abamectina)",
      "source": "400 a 500 cc/ha",
      "description": "400 a 500",
      "unit": "cc/ha",
      "min": 0.006671000504474667,
      "max": 0.8914126435859591
    },
    {
      "intervention": "Floramite 50 WP. (Bifenazate)",
      "source": "150 grs/ha",
      "description": "150",
      "unit": "g/ha",
      "min": 0.9157610493376582,
      "max": 1.209022749538061
    },
    {
      "intervention": "Caldo Sulfocalcico HR. (Cal Hidratada + Azufre Pulverizado)",
      "source": "10 cc/litro",
      "description": "10",
      "unit": "cc/l",
      "min": 0.49169310226396035,
      "max": 1.4374914020840859
    },
    {
      "intervention": "Danisaraba 20 SC. (Cyflumetofen)",
      "source": "300 cc/litro",
      "description": "300",
      "unit": "cc/l",
      "min": 0.3695966651130338,
      "max": 1.1942731458373044
    },
    {
      "intervention": "Magister 200 SC. (Fenaxaquin)",
      "source": "1.000 cc/ha",
      "description": "1.000",
      "unit": "cc/ha",
      "min": 0.4697830216595653,
      "max": 1.782304167577803
    },
    {
      "intervention": "Lathix 54 EC. (Hexitiazox)",
      "source": "0,6 - 0,9 cc/litro",
      "description": "0,6 - 0,9",
      "unit": "cc/l",
      "min": 0.4499449929152257,
      "max": 1.3667010963997155
    },
    {
      "intervention": "Kendo. (Fenpyroximato)",
      "source": "1.000 grs/ha",
      "description": "1.000",
      "unit": "g/ha",
      "min": 0.05546756515337137,
      "max": 1.5937349957547395
    },
    {
      "intervention": "Cascade. (Flufenoxuron)",
      "source": "150 cc/ha",
      "description": "150",
      "unit": "cc/ha",
      "min": 0.8252288636366398,
      "max": 0.8265762926180205
    },
    {
      "intervention": "Milbeknock 1 EC. (Milbectin)",
      "source": "360 cc/ha",
      "description": "360",
      "unit": "cc/ha",
      "min": 0.2594149029862265,
      "max": 0.8276256160372859
    },
    {
      "intervention": "Acarex 200.000.000 UFC - Successor SC 500.000.000 UFC. (Paecilomyces fumosoroseus PF 2 - Paecilomyces fumosoroseus DSM15126)",
      "source": "1 - 2 cc/litro",
      "description": "1 - 2",
      "unit": "cc/l",
      "min": 0.7759969094960855,
      "max": 0.05709805595022632
    },
    {
      "intervention": "Santimec EC 90 grs/litro + 12 grs/litro. (Pyridaben + Abamectina)",
      "source": "400 cc/ha",
      "description": "400",
      "unit": "cc/ha",
      "min": 0.22461398515156206,
      "max": 1.2894017363551304
    },
    {
      "intervention": "Choque 240 SC. (Spirodiclofen)",
      "source": "500 - 600 cc/ha",
      "description": "500 - 600",
      "unit": "cc/ha",
      "min": 0.7952947074831886,
      "max": 1.621839280227488
    },
    {
      "intervention": "L Q AGRI 35 SL. (Saponinas)",
      "source": "1.500 cc/ha",
      "description": "1.500",
      "unit": "cc/ha",
      "min": 0.8263201382324735,
      "max": 1.5373785206127388
    },
    {
      "intervention": "Oberon 240 SC. (Spiromesifen)",
      "source": "400 - 600 cc/ha",
      "description": "400 - 600",
      "unit": "cc/ha",
      "min": 0.6438440479669536,
      "max": 0.49529073572845594
    },
    {
      "intervention": "Oberon Speed SC 228 + 1,14% (Spiromesifen + Abamectina)",
      "source": "600 cc/ha",
      "description": "600",
      "unit": "cc/ha",
      "min": 0.6177195996222589,
      "max": 1.2851077404312892
    }
  ],
  "Pseudomonas spp.": [
    {
      "intervention": "Royal Cu SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%. (Potasio + Azufre + Cobre + Streptomicina + Silicio)",
      "source": "1,0 a 3,0 litros/ha",
      "description": "1,0 a 3,0",
      "unit": "l/ha",
      "min": 0.8507417198114952,
      "max": 1.0314658353728707
    },
    {
      "intervention": "Cumbre WP 100UI + 300UI. (Sulfato Gentamicina + Clorhidrato Oxitetraciclina)",
      "source": "420 gramos/ha",
      "description": "420",
      "unit": "g/ha",
      "min": 0.6479698910789644,
      "max": 0.5285033259615681
    },
    {
      "intervention": "KillBacter WP 45% + 350 UI. (Oxicloruro Cobre + Clorhidrato Oxitetraciclina)",
      "source": "450 a 600 gramos/ha",
      "description": "450 a 600",
      "unit": "g/ha",
      "min": 0.7878902709172,
      "max": 0.26778894335070436
    },
    {
      "intervention": "Sulfato de Estreptomicina. (Sulfato de Streptomicina)",
      "source": "400 a 500 gramos/ha",
      "description": "400 a 500",
      "unit": "g/ha",
      "min": 0.7491590981331235,
      "max": 1.9824990147912305
    },
    {
      "intervention": "Yodigon 12% (Yodo complejo Povidona)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.6726017742711917,
      "max": 1.2950639520853136
    },
    {
      "intervention": "Agrodyne SL 132 - YodoSafer 120 (Yodo complejo y Acido Yodidrico / Yodo Polivinil Pirrolidona)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.6624206072554406,
      "max": 1.4976070678782005
    },
    {
      "intervention": "Validacin. (Validamycin)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.5543065901632195,
      "max": 1.3893731121931399
    },
    {
      "intervention": "Actiba T SC. (Streptomyces racemochromogene 1X10⁸ UFC/ml)",
      "source": "1.000 centimetros cubicos/ha",
      "description": "1.000 centimetros",
      "unit": "???",
      "min": 0.6305293827621433,
      "max": 1.9582345731682782
    },
    {
      "intervention": "Bactox WP (Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado(10%))",
      "source": "500 a 1.000 gramos/ha",
      "description": "500 a 1.000",
      "unit": "g/ha",
      "min": 0.8741817693804547,
      "max": 1.9360220448251007
    }
  ],
  "Puccinia pampeana": [
    {
      "intervention": "Impact 125 SC. (Flutriafol)",
      "source": "0,6 a 0,8 litros/ha",
      "description": "0,6 a 0,8",
      "unit": "l/ha",
      "min": 0.996696255880328,
      "max": 0.66078799961845
    },
    {
      "intervention": "Silvacur Combi EC 22,5% + 7,5%. (Tebuconazole + Triadimenol)",
      "source": "0,5 a 0,6 litros/ha",
      "description": "0,5 a 0,6",
      "unit": "l/ha",
      "min": 0.6118404421464759,
      "max": 0.3238736465828107
    },
    {
      "intervention": "Azimut 320 SC 20% + 12%. (Tebuconale + Azoxystrobin)",
      "source": "0,8 litros/ha",
      "description": "0,8",
      "unit": "l/ha",
      "min": 0.2801384365464973,
      "max": 1.4450417214015356
    }
  ],
  "Pythium spp.": [
    {
      "intervention": "Vitavax 300 20% + 20%. (Carboxin + Captan)",
      "source": "2,0 a 4,0 kilos/ha",
      "description": "2,0 a 4,0",
      "unit": "kg/ha",
      "min": 0.48550668951482123,
      "max": 0.27608861824418485
    }
  ],
  "Repelencia Insectos": [
    {
      "intervention": "Alisin 100 grs/litro - 100 grs/litro. (Extracto de Ajo + Extracto de Aji)",
      "source": "1,0 - 2,0 cc/litro",
      "description": "1,0 - 2,0",
      "unit": "cc/l",
      "min": 0.6008730555887897,
      "max": 0.08345274786663848
    }
  ],
  "Rhizoctonia solani": [
    {
      "intervention": "Vitavax 300 20% + 20%. (Carboxin + Captan)",
      "source": "2,0 a 4,0 kilos/ha",
      "description": "2,0 a 4,0",
      "unit": "kg/ha",
      "min": 0.7930439005880144,
      "max": 1.5197215363398857
    },
    {
      "intervention": "Validacin. (Validamycin)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.11438990951339756,
      "max": 1.1543644064297975
    },
    {
      "intervention": "Bactox WP (Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado(10%)",
      "source": "500 a 1.000 gramos/ha",
      "description": "500 a 1.000",
      "unit": "g/ha",
      "min": 0.15224894005430312,
      "max": 0.7375818628648863
    },
    {
      "intervention": "Trichox WP. (Trichoderma harzianum 1.0 X 10⁸ UFC Esporas/Gramo de Producto Formulado)",
      "source": "500 gramos/ha",
      "description": "500",
      "unit": "g/ha",
      "min": 0.44254628973932153,
      "max": 0.947188051926751
    },
    {
      "intervention": "Kruga SC 240. (Fenbuconazole)",
      "source": "0,5 litros/ha",
      "description": "0,5",
      "unit": "l/ha",
      "min": 0.9913399084694869,
      "max": 0.2301805387564222
    }
  ],
  "Semisistemico y Contacto para Hojas Anchas": [
    {
      "intervention": "Finale SL 150 - Burner 150 SL. (Glufosinato de amonio)",
      "source": "1.500 - 2.000 cc/ha",
      "description": "1.500 - 2.000",
      "unit": "cc/ha",
      "min": 0.8234355369362633,
      "max": 1.3883907267166293
    }
  ],
  "Sistemico Selectivo Graminicida": [
    {
      "intervention": "Arrow 120 EC - Select EC 120. (Clethodim)",
      "source": "750 - 1.250 cc/ha",
      "description": "750 - 1.250",
      "unit": "cc/ha",
      "min": 0.07244054227257746,
      "max": 0.5669498142158638
    }
  ],
  "Sphaerotheca spp.": [
    {
      "intervention": "Bellis WG 25%+12,8%. (Boscalid + Piraclostrobin)",
      "source": "800 a 1.000 gramos/ha",
      "description": "800 a 1.000",
      "unit": "g/ha",
      "min": 0.3129458506223878,
      "max": 1.4859384491333578
    }
  ],
  "Thrips tabaci - Thrips palmi - Frankiniella occidentalis": [
    {
      "intervention": "Rescate 200 SP. (Acetamiprid)",
      "source": "0,75 grs/litro - 300 grs/ha",
      "description": "0,75 grs/litro - 300",
      "unit": "g/ha",
      "min": 0.948882088072325,
      "max": 1.1566074766157828
    },
    {
      "intervention": "Biogrin 860 grs/litro. (Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.47173567384936466,
      "max": 1.284621361405068
    },
    {
      "intervention": "Adral WP - Adral SC 200.000.000 esporas por gramo. (Beauveria bassiana)",
      "source": "1,0 - 2,0 grs/litro / 2,0 - 4,0 cc/litro",
      "description": "1,0 - 2,0 grs/litro / 2,0 - 4,0",
      "unit": "cc/l",
      "min": 0.8532842819712625,
      "max": 1.3395634858117744
    },
    {
      "intervention": "Safer Mix WP (100.000.000 esporas/gramo) - (400.000.000 esporas/gramo). (Bacillus thuringiensis - B. Bassiana) - (Lecanicillium lecanii - Metarhizium anisopliae)",
      "source": "1,0 -2,0 grs/litro / 300 - 500 cc/ha",
      "description": "1,0 -2,0 grs/litro / 300 - 500",
      "unit": "cc/ha",
      "min": 0.9317608346624623,
      "max": 0.7341783861068982
    },
    {
      "intervention": "Depredador de Insectos. (Chrysoperla carnea - C. externa)",
      "source": "20.000 a 100.000 larvas/ha",
      "description": "20.000 a 100.000",
      "unit": "larvas/ha",
      "min": 0.3530618505604294,
      "max": 0.5302981712415225
    },
    {
      "intervention": "Dantotsu 50 WG. (Clothianidin)",
      "source": "250 grs/ha",
      "description": "250",
      "unit": "g/ha",
      "min": 0.900312656509961,
      "max": 0.4756090114803728
    },
    {
      "intervention": "Proaxis 60 CS. (Cyalotrina gamma)",
      "source": "50 - 100 cc/ha",
      "description": "50 - 100",
      "unit": "cc/ha",
      "min": 0.9587290053553182,
      "max": 1.310011046360444
    },
    {
      "intervention": "Provado Combi SC 112,5 100 grs/litro + 12,5 grs/litro. (Imidacloprid + Beta Cyfluthrin)",
      "source": "1.000 cc/ha",
      "description": "1.000",
      "unit": "cc/ha",
      "min": 0.8790303956816153,
      "max": 1.8970041728844536
    },
    {
      "intervention": "Cosmo Oil EO 672 grs/litro. (Paraffinic oil)",
      "source": "0,5 - 1 %",
      "description": "0,5 - 1",
      "unit": "%",
      "min": 0.45948315662415273,
      "max": 0.28146066119533564
    },
    {
      "intervention": "Exalt 60 SC. (Spinetoram)",
      "source": "200 - 300 cc/ha",
      "description": "200 - 300",
      "unit": "cc/ha",
      "min": 0.15729192554185067,
      "max": 1.822125107238804
    },
    {
      "intervention": "Tracer 120 SC. (Spinosad)",
      "source": "300 grs/ha",
      "description": "300",
      "unit": "g/ha",
      "min": 0.07289433658274447,
      "max": 0.9998410945692815
    },
    {
      "intervention": "Movento OD 150. (Spirotetramat)",
      "source": "600 cc/ha",
      "description": "600",
      "unit": "cc/ha",
      "min": 0.1881016624232028,
      "max": 0.2492309364078913
    }
  ],
  "Xanthomonas spp.": [
    {
      "intervention": "Royal Cu SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%. (Potasio + Azufre + Cobre + Streptomicina + Silicio)",
      "source": "1,0 a 3,0 litros/ha",
      "description": "1,0 a 3,0",
      "unit": "l/ha",
      "min": 0.35122850031972186,
      "max": 1.1363485360547352
    },
    {
      "intervention": "Cumbre WP 100UI + 300UI. (Sulfato Gentamicina + Clorhidrato Oxitetraciclina)",
      "source": "420 gramos/ha",
      "description": "420",
      "unit": "g/ha",
      "min": 0.7965747725144666,
      "max": 1.7536841248529564
    },
    {
      "intervention": "KillBacter WP 45% + 350 UI. (Oxicloruro Cobre + Clorhidrato Oxitetraciclina)",
      "source": "450 a 600 gramos/ha",
      "description": "450 a 600",
      "unit": "g/ha",
      "min": 0.2793933782598841,
      "max": 1.4900533587250553
    },
    {
      "intervention": "Sulfato de Estreptomicina. (Sulfato de Streptomicina)",
      "source": "400 a 500 gramos/ha",
      "description": "400 a 500",
      "unit": "g/ha",
      "min": 0.010385034971345353,
      "max": 0.3170822370472175
    },
    {
      "intervention": "Yodigon 12% (Yodo complejo Povidona)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.6313710578818967,
      "max": 1.1941430184117818
    },
    {
      "intervention": "Agrodyne SL 132 - YodoSafer 120 (Yodo complejo y Acido Yodidrico / Yodo Polivinil Pirrolidona)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.7094140167017591,
      "max": 0.3858853243855682
    },
    {
      "intervention": "Validacin. (Validamycin)",
      "source": "1,0 a 1,5 litros/ha",
      "description": "1,0 a 1,5",
      "unit": "l/ha",
      "min": 0.9313950190658553,
      "max": 1.4170790662442143
    }
  ],
  "Ácaros": [
    {
      "intervention": "Depredador de Insectos. (Chrysoperla carnea - C. externa)",
      "source": "20.000 a 100.000 larvas/ha",
      "description": "20.000 a 100.000",
      "unit": "larvas/ha",
      "min": 0.0525153816996875,
      "max": 1.5643261471905996
    }
  ]
}
  }),
};
