<template>
  <PrintLayout
      :title="$t('firstVisit')"
      ref="printLayoutRef"
      @back="toCustomer"
      v-model="isPrinting"
  >

    <v-main class="text-left pt-2">
      <h3> {{$t('register')}} </h3>
      <SingleImage :image="firstVisitImage" :maxHeight="150" />
      <v-simple-table class="text-left table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{$t('question')}}
              </th>
              <th class="text-left">
                {{$t('answer')}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, propertyName) in firstVisit.formFields">
              <template v-if="!!value.question">
                <td>
                  {{ value.question }}
                </td>
                <td>
                  {{ value.answer}}

                  <template v-if="!!value.extraObservationAnswer">
                    :observación adicional:
                    {{value.extraObservationAnswer}}
                  </template>
                </td>
              </template>
            </tr>
            <tr>
              <td>
                {{$t("registerForm.form.fieldGps") }}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                {{$t("registerForm.form.areaOfFarm") }}
              </td>
              <td>
                {{ firstVisit.formFields.landSize.answer.landSize }}
                {{$t(firstVisit.formFields.landSize.answer.unit.toLowerCase()) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <template v-if="!!firstVisit.formFields.demographic">
        <h3> {{$t('demographic')}} </h3>

        <v-simple-table class="text-left table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('question')}}
                </th>
                <th class="text-left">
                  {{$t('answer')}}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(value, propertyName) in firstVisit.formFields.demographic">
                <template v-if="!!value.question">
                  <td>
                    {{ value.question }}
                  </td>
                  <td>
                    {{ value.answer}}

                    <template v-if="!!value.extraObservationAnswer">
                      :observación adicional:
                      {{value.extraObservationAnswer}}
                    </template>
                  </td>
                </template>
              </tr>
              <tr>
                <td>
                  {{$t("registerForm.form.fieldGps") }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {{$t("registerForm.form.areaOfFarm") }}
                </td>
                <td>
                  {{ firstVisit.formFields.landSize.answer.landSize }}
                  {{$t(firstVisit.formFields.landSize.answer.unit.toLowerCase()) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>

      <h3>

        {{$t('firstVisitRatings')}}
      </h3>

      <v-simple-table class="text-left table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{$t('question')}}
              </th>
              <th class="text-left" v-if="!isPrinting">
                {{$t('description')}}
              </th>
              <th class="text-left">
                {{$t('rating')}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, propertyName) in firstVisit.ratingFormFields.ratings">
              <td>{{ value.label }}</td>
              <td v-if="!isPrinting">{{ value.description }}</td>
              <td v-if="!isPrinting">
                <StarRating
                    v-model="value.answer"
                    :readonly="true"
                />
                <template v-if="!!value.extraObservationAnswer">
                  :observación adicional:
                  {{value.extraObservationAnswer}}
                </template>
              </td>
              <td v-else>
                {{value.answer}}
                <template v-if="!!value.extraObservationAnswer">
                  :observación adicional:
                  {{value.extraObservationAnswer}}
                </template>
              </td>
            </tr>
            <tr>
              <th>
                {{$t('totalRating')}}
              </th>
              <td v-if="!isPrinting"></td>
              <td>
                {{totalWeightInRating.toFixed(1)}}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <FarmerAgentDisplay :customer="customer" />
    </v-main>
  </PrintLayout>
</template>

<script>
import SingleImage from "@/components/SingleImage";
import StarRating from "@/components/StarRating";
import PageHeader from "@/components/PageHeader";
import PrintLayout from "@/components/PrintLayout";
import FarmerAgentDisplay from "@/components/FarmerAgentDisplay";

export default {
  components: {FarmerAgentDisplay, SingleImage, StarRating, PageHeader, PrintLayout},
  data() {
    return {
      isPrinting: false
    }
  },
  computed: {
    firstVisit() {
      return this.$store.getters.assessments.find(assessment => assessment.assessmentUuid === this.firstVisitUuid)
    },
    firstVisitUuid() {
      return this.$route.query.firstVisitUuid;
    },
    totalWeightInRating() {
      return this.firstVisit.ratingFormFields.ratingScore.totalAnswersWeight/ this.firstVisit.ratingFormFields.ratingScore.totalWeight * 5;
    },
    customer() { return this.$store.getters.customer(this.firstVisit.customerUuid); },
    firstVisitImage() {
      const storedImage = this.firstVisit.farmImage
      if (storedImage) {
        return this.$store.getters.images.reverse().find(
            (e) => e.uuid === storedImage.uuid
        );

      } else {
        return this.$store.getters.images.reverse().find(
            (e) => e.type_id === this.customer.farm.uuid && e.type === this.imageUploadTypeEnums.firstVisit
        );

      }

    },
  },
  methods: {
    toCustomer() {
      this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customer.uuid } });
    },
  },
}
</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "registerForm":  {
      "title": "Register Section",
      "form": {
        "visitDate": "Visit Date",
        "fieldPhoto": "Terrain Photo",
        "fieldGps": "Field Gps",
        "areaOfFarm": "Area of Farm"
      }
    },
    "firstVisit": "First visit",
    "firstVisitRatings": "First visit ratings",
    "totalRating": "Total Rating"
  },
  "es": {
    "registerForm":  {
      "title": "Sección de registro",
      "form": {
        "visitDate": "Fecha de la visita",
        "fieldPhoto": "Foto Terreno",
        "fieldGps": "campo Gps",
        "areaOfFarm": "Area De Granja"
      }
    },
    "firstVisit": "Primera visita",
    "firstVisitRatings": "Calificaciones de la primera visita",
    "totalRating": "Calificación total"
  },
  "sw": {
    "registerForm":  {
      "title": "Sehemu ya Usajili",
      "form": {
        "visitDate": "Tarehe ya kutembelea",
        "fieldPhoto": "Picha ya Terrain",
        "fieldGps": "Gps ya Shamba",
        "areaOfFarm": "Eneo la Shamba"
      }
    },
    "firstVisit": "Ziara ya kwanza",
    "firstVisitRatings": "Ukadiriaji wa ziara ya kwanza",
    "totalRating": "Ukadiriaji Jumla"
  }
}
</i18n>
