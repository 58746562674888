<template>

  <v-container fluid>
    <v-row class="text-center">
      <v-col class="text-h4 red--text">
        Ultimate Finance may not lend to {{customer.name}}.
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import InstrumentUltimateTzSalaryAdvance from "@/views/instruments/InstrumentUltimateTzSalaryAdvance"

export default {

  components: { InstrumentUltimateTzSalaryAdvance },

  props: ['customer', 'instrumentId'],

}
</script>
