<template>
  <PageHeader title="Contract Detail" back @back="goBack" >
    <v-container fluid>

      <v-row class="text-left">
        <v-col cols="12">  
          <DisbursementContract v-model="value" :customer="customer" />
        </v-col>
      </v-row>

      <v-row class="text-left">
        <v-col cols="12">
          Complete terms and condition are available
          <a target="_blank" href="https://connect.sasa.solutions/OneFarmNileBreweriesFullContract.pdf">here</a>.
        </v-col>
      </v-row>

      <v-row class="text-left">
        <v-col cols="12" md="3"></v-col>

        <v-col cols="12" md="6" class="mt-10">
          <InsuranceBalanceTable v-model="value" />
          <p class="px-4 mt-4 mb-0">We have sent you an OTP. Enter this to confirm your acknowledge of this.</p>
          <div class="d-flex px-4">
            <v-text-field class="mr-4 p-0" label="OTP" type="number" required v-model="otp" />
            <v-btn elevation="2" class="align-self-center" @click="submit" :disabled="(otp || '').length<4">Submit</v-btn>
          </div>
        </v-col>

        <v-col cols="12" md="3"></v-col>
      </v-row>

    </v-container>
  </PageHeader>

</template>

<script>
import PageHeader from "@/components/PageHeader";
import DisbursementContract from "@/views/partners/nile_ug/components/DisbursementContract";
import InsuranceBalanceTable from "@/views/partners/nile_ug/components/InsuranceBalanceTable";

export default {

  components: { PageHeader, DisbursementContract, InsuranceBalanceTable },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      otp: undefined,
    }
  },

  computed: {
    customerUuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.customerUuid); },
  },

  methods: {
    goBack() { this.$router.push({ name: "ShowCustomer", query: { uuid: this.customerUuid } }); },
    submit() {
      this.customer.disbursement_otp_captured = this.otp;
      this.$store.dispatch('upsertCustomer', this.customer);
      this.$emit('termsAccepted');
    }
  },

}
</script>

<style scoped>

</style>