export const compostAndAmendmentsData = [
    {
        "pest": "HUMUS DE LOMBRIZ",
        "activeIngredient": "DESECHOSORGANICOS ESTIERCOL GANADO VACUNO Y LOMBRIZ CALIFORNIANA",
        "Dose": "1,0 Toneladas/Hectárea"
    },
    {
        "pest": "HUMUS DE  LOMBRIZ LIQUIDO",
        "activeIngredient": "ACIDOS HUMICOS-FULVICOS-HULMICOS",
        "Dose": "50 cc/Litro"
    },
    {
        "pest": "ABONAR COMPOST HUAO",
        "activeIngredient": "CELULOSA",
        "Dose": "3,0 a 5,0 Toneladas/Hectárea"
    },
    {
        "pest": "ABONISA",
        "activeIngredient": "COMPOST",
        "Dose": "3,0 a 5,0 Toneladas/Hectárea"
    },
    {
        "pest": "OLAFOS",
        "activeIngredient": "30% CaO - 20% P2O5 - 6% S -  SiO2 - F",
        "Dose": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido."
    },
    {
        "pest": "ROCA FOSFORICA",
        "activeIngredient": "40% CaO - 26% P2O5",
        "Dose": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido."
    },
    {
        "pest": "YESO AGRICOLA",
        "activeIngredient": "26% CaO - 13% S",
        "Dose": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido."
    },
    {
        "pest": "CAL DOLOMITA",
        "activeIngredient": "35% Cao - 15% Mgo",
        "Dose": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido."
    },
    {
        "pest": "FOFORITA HUILA",
        "activeIngredient": "40% CaO - 28% P2O5",
        "Dose": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido."
    },
    {
        "pest": "HARINAS DE ROCA",
        "activeIngredient": "Si - P - K + 60 ELEMENTOS",
        "Dose": "Mezcla 10% a 15 %  Con Fertilizantes Convencionales"
    }
]


