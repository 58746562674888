export default {
  data: () => ({
    species: [
      'Abarco - Cariniana pyriformis Miers',
      'Acacia amarilla hoja gigante',
      'Acacia amarilla - Caesalpinia peltophoroides',
      'Acacia amarilla gigante',
      'Aceite - Copaifera officialis',
      'Achiote - Bixa orellana',
      'Ackee - Blighia sápida *',
      'Aguacate - Persea americana',
      'Aguacatillo - Beilschmiedia o Persea caerulea',
      'Alcornoco - Bowdichia virgilioides Kunth',
      'Algarrobo - Hymenaea courbaril',
      'Almendrón - Terminalia catappa',
      'Amancayo amarillo - Plumeria rubra',
      'Amancayo blanco - Plumeria alba',
      'Amancayo curuba - Plumeria rubra',
      'Amancayo rosado - Plumeria rubra',
      'Anón amarillo - Anona glabra',
      'Ánón amazónico - Anona squamosa',
      'Anón pelón - Anona reticulata',
      'Anón silvestre - anona sp.',
      'Arazá - Eugenia stipitata',
      'Arenillo- Tetrorchidium rubrivenium',
      'Arrayán de fruto grande bosque de Yotoco',
      'Balso Ochroma pyramidale',
      'Bola de toro Guainía',
      'Palma assai - Euterpe oleracea',
      'Palma Bacris Guainía grande',
      'Palma Bactris corosilla',
      'Palma bactris Guanía peqeño',
      'Arrayán de Hato Viejo ',
      'Bala de cañón - Couroupita guianensis',
      'Bálsamo de Tolú - Myroxylon balsamum',
      'Bleo de chupa - Pereskia bleo',
      'Borojó - Borojoa patinoi',
      'Borrachero amarillo - Brugmanasia sp.',
      'Borrachero blanco - Brugmansia arbórea',
      'Borrachero rosado - Brugmansia insignis',
      'Bototo - Cochlospermum vitifolium',
      'Caballero de la noche - Cestrum nocturnum',
      'Cacao de monte - Pachira aquatica',
      'Cacao forastero - Theoroma cacao',
      'Cacao criollo - Theoroma cacao',
      'Cacho de cabra',
      'Caimito chocó',
      'Caimo amarillo - Pouteria caimito',
      'Caimo morado - Chrysophyllum cainito',
      'Camajón - Sterculia apetala',
      'Caoba - Swietenia macrophylla',
      'Caracolí - Anacardium excelsum',
      'Carbonero blanco - Calliandra haematocephala Alba',
      'Carbonero rojo - Calliandra trinervia',
      'Carbonero rosado - Calliandra pittieri',
      'Cedro caquetá',
      'Cedro macho - Guarea guidonia',
      'Cedro negro - Juglans neotropica',
      'Cedro rosado - cedrela odorata',
      'Ceiba tronadora - Hura crepitans',
      'Ceiba del pacífico - Ceiba pentandra',
      'Ceiba - Ceiba pentadra',
      'Ceiba rosada - Ceiba speciosa',
      'Chachafruto - Erythrina edulis',
      'Chagualo - Myrcine pellucida',
      'Chambimbe - Sapindus saponaria',
      'Chaparro - Curatella americana',
      'Chiminango - Pithecellobium dulce',
      'Chocho rojo - Erythrina rubrinervia',
      'Chocho Guainía - Ormosia monosperma',
      'Chocho rojo y negro grande - Ormosia coccinea',
      'Chocho del río Guabas - Ormosia colombiana',
      'Coca silvestre - Erythroxylum citrifolium',
      'Comino crespo - Aniba perutilis',
      'Corbón',
      'Cucharo - Myrsine coriacea',
      'Ébano - Caesalpinia ebano H. Karst',
      'Floramarillo - Senna spectabilis',
      'Frfuta milagrosa - Synsepalum dulcificum',
      'Jagua - genipa americana',
      'Gualanday caucano - Jacaranda caucana',
      'Gualanday del pacífico',
      'Gualanday llanero pequeño',
      'Guamo cola de mico - Inga ornata',
      'Guamo del caquetá',
      'Guamo machete',
      'Guamo mini guainía',
      'Guamo río guabas',
      'Guanábana',
      'Araño - Quararibaea asterolepis',
      'Guácimo - Guazuma ulmifolia',
      'Guayaba arazá',
      'Guayaba común',
      'Guayaba coronilla - Psidium guineense',
      'Guayacán amarillo - ',
      'Gustavia superba',
      'Hamelia patens',
      'Higuerón - Ficus aurea',
      'Hobo - Spondias mombin',
      'Cedroo de montaña - Cecrela montana',
      'Himatanthus bracteatus',
      'Huesito - Casearia sylvestris',
      'Indio desnudo - Bursera simaruba',
      'Jaboticaba',
      'Jagua Genipa americana',
      'Jigua laurel - Nectandra lineatifolia',
      'Jigua - Cinnamonum cinnamonifolium',
      'Jigua oreja de mula - Ocotea aurantiodora',
      'Laurel',
      'Limón mandarina',
      'Macrolobium acaciifolium',
      'Falaso olivo',
      'Madroño - Arbutus unedo',
      'Malagueto - Xylopia aromatica',
      'Mamey - Mammea americana',
      'Mamoncillo - Meliccoca bijuga',
      'Nacedero - Trichanthera gigantea ',
      'Manaca - Euterpe precatoria',
      'Mandarina oneco *',
      'Mataratón - Gliricidia sepium',
      'Ocobo amarillo - Handroanthus chrysanthus',
      'Floramarillo o vainillo - Senna spectabilis',
      'Fresita - Mutingia calabura',
      'Fruta de morú',
      'Naidí - manaca oleracea',
      'Naranja',
      'Níspero - Manilkara zapota',
      'Niguito',
      'Nogal cafetero - Cordia alliondra',
      'Orejero del Valle - Enterolobium cyclocarpum',
      'Palma Chamaedorea linearis',
      'Palma chontaduro - Bactris gassipes',
      'Palma corocito - Bactris minor',
      'Palma corozo - Aiphanes aculeata',
      'Palma de moriche amazónica - Mauritia flexuosa',
      'Palma de moriche llanera - Mauritia flexuosa',
      'Palma de puerco - Attalea butyracea',
      'Palma maracay',
      'Palma mil pesos',
      'Yaca - Artocarpus heterophyllus*',
      'Palma morú',
      'Palma patabá',
      'Palma pedrito',
      'Palma socratea',
      'Palo de la cruz -Brownea ariza',
      'Palma zancona del Valle - Syagrus sancona',
      'Papaya - Carica papaya',
      'Papayuelo - Vasconcellea pubescens',
      'Passiflora arborea ',
      'Pecueca - Cassia grandis',
      'Peluza del bosque del miedo El Amparo',
      'Pepa pan - artocarpus altilis',
      'Pepa trogón',
      'Pichindé ',
      'Písamo - Erythrina peppigiana',
      'Pitanga - Eugenia uniflora',
      'Poma amarilla* - Syzygium jambos',
      'Poma rosada* - Syzygium jambos',
      'Roble - Quercus humboldtii',
      'San joaquin - Cordia sebestana',
      'Caoba mahogani - Swietenia mahogani',
      'Tachuelo - Zanthoxylum monophylum',
      'Tamarindo - Tamarindus indica)*',
      'Totumo - Cresscentia cujeta',
      'Uva caimarona - Pourouma cecropiifolia',
      'Guáimaro - Brosimum utile',
      'Huina',
      'Guandúl - Cajanus cajan',
      'Mango - Magnifera indica*',
      'Cacao maraco -Theobroma bicolor',
      'Yarumo - cecropia peltalta',
      'Zapote - Quararibea cordata',
      'Níspero del chocó',
      'Samán - Samanea saman',
      'Guayacán rosado - Tabebuia rosea',
      'Choibá - Dipteryx oleifera',
      'Búcaro - Erythrina fusca',
      'Cañafístolo llanero - Cassia moschata',
      'Cabalonga - Thevetia peruviana',
      'Cacao copoazú - Theobroma grandiflorum',
      'Caimo bosque yotoco',
      'Guayaba del perú',
      'Guayacán de manizales - Lafoensia acuminata',
      'Mestizo - Cupaina americana',
      'Palma palmicha',
      'Peine de mono',
      'Cedro amartillo o iguamarillo - Pseudosamanea guachapele',
      'Guadua- Guadua angustifolia',
      'Indio desnudo - Bursera simaruba',
      'Oití - Licania tomentosa',
      'Orejero llanero - Enterolobium barinense',
      'Palma de cera - Ceroxylum quinduense',
      'Pavito - Jacaranda copaia',
      'Pino romerón - Retrophyllum rospigliosii',
      'Zapote costeño - Pouteria sapota',
    ],
  })
}