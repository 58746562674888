<template>
  <v-btn color="primary" :small="!text" :dense="!text" :text="text" :to="{name: routeName, query: { uuid: customer.uuid, type: 'customer' } }" >
    <v-icon dense>mdi-star-check-outline</v-icon>
    <span v-if="!icon">{{$t(title)}}</span>
  </v-btn>
</template>

<script>
  export default {
    props: {
      customer: { type: Object },
      title: { type: String, default: 'assess' },
      icon: { type: Boolean, default: false },
      text: { type: Boolean, default: false },
      routeName: { type: String, default: undefined },
    },
  }
</script>


<i18n>
{
  "en": {
    "assess": "Assess"
  },
  "es": {
    "assess": "Evaluar"
  },
  "sw": {
    "assess": "Tathmini"
  }
}
</i18n>



