<template>
  <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr v-for="farmer in list" :key="farmer.uuid" >
          <td class="text-start">
            {{ farmer.name }}
          </td>
          <td class="text-end">
            <v-btn small color="primary" :to="{name: 'ShowCustomer', query: { uuid: farmer.uuid } }" >
              <v-icon dense small>mdi-account</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>




<script>


  export default {

    props: ['farmers'],

    data: () => ({
    }),

    computed: {
      search() { return this.$store.getters.search; },
      list() {
        const regex = new RegExp(this.search, "i");
        return this.farmers.filter( e => regex.test(e.name) ).sort( (a,b) => { return (a.name < b.name ? -1 : 1); } );
      },
    },

  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
