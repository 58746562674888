<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid class="text-left">

      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-row class="text-left">
        <v-col cols="3">
          Required default
          <SignatureInput v-model="form.sign_one" required type="sdfsdg" :typeId="'xcfgdfgdfg'" showImage />
        </v-col>
        <v-col cols="3">
          Not Required
          <SignatureInput v-model="form.sign_two" type="sdfsdg" :typeId="'xcfgdfgdfg'" />
        </v-col>
        <v-col cols="3">
          Custom slot
          <SignatureInput v-model="form.sign_three" required type="sdfsdg" :typeId="'xcfgdfgdfg'" >
            <div>
              some custom divs and stuff
              {{form.sign_three}}
            </div>
          </SignatureInput>
        </v-col>
        <v-col cols="3">
          Custom slot with save
          <SignatureInput
              v-model="form.sign_four"
              required
              type="sdfsdg"
              :typeId="'xcfgdfgdfg'"
              @saveImage="(imageObject) => form.sign_four_obj = imageObject"
          >
            <SingleImage maxHeight="200px"  :image="form.sign_four_obj" />
          </SignatureInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <div style="min-height: 200px">
            <SingleImage v-if="!!form.sign_one_object" :image="form.sign_one_object" />
          </div>
        </v-col>
      </v-row>


      <v-row>
        <v-col class="text-right">
          <v-btn color="warning"
                 small
                 dense
                 class="mr-1"
                 @click="this.validate"
          >
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning"
                 small dense
                 :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import DocumentInput from "@/components/DocumentInput";
import PhotoInput from "@/components/PhotoInput";
import SignatureInput from "@/components/SignatureComponent/SignatureInput";
import SingleImage from "@/components/SingleImage";
import ImageDb from "@/store/ImageDb";

export default {
  components: {
    DocumentInput,
    PhotoInput,
    SignatureInput,
    SingleImage
  },
  data() {
    return {
      validForm: false,
      form: {
        sign_one: null,
        sign_two: null,
        sign_three: null,
        sign_one_object: null
      }
    }
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  }

}
</script>

<style scoped>

</style>