// import Pusher from 'pusher-js'

// const pusher = new Pusher('04f7229f2b648e89e6ee', { cluster: 'eu', encrypted: true });

export default {

  // computed: {
  //   pusherChannelId() { return (this.$store.getters.settings || {}).fieldAgentId; },
  // },

  //   watch: {
  //     targetFieldId: {
  //       immediate: true,
  //       handler(to) {
  //         if (to) {
  //           this.fieldId = parseInt(to);
  //         } else {
  //           this.fieldId = null;
  //         }
  //       },
  //     },
  //   },

  // watch: {
  //   pusherChannelId: {
  //     immediate: true,
  //     handler(channelId) {
  //       if (channelId) {
  //         let channel = pusher.subscribe('' + channelId);
  //         channel.bind('events', data => {
  //           const source = JSON.parse(data.payload)
  //           this.$store.dispatch('pusherEvent', { type: data.type, payload: source } );
  //         });
  //       }
  //     },
  //   },
  // },

}