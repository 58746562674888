<template>
  
  <v-container fluid class="pa-0" v-if="customer">

    <v-form>

      <FormContainer color="blue lighten-5">
        <v-row>
          <v-col cols="12" sm="6" class="text-h5 text-left">
            Meet {{ customer.name }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field full-width v-model="subject" label="Subject" />
          </v-col>
        </v-row>

        <v-row>

          <v-col cols="12" sm="6">
            <DateTimeInput
              v-model="startTime"
              dateLabel="Visit date"
              timeLabel="Visit time"
              :min="new Date().toISOString().substr(0, 10)"
              :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select class="mt-4"
              :items="durations"
              label="Meeting Duration"
              v-model="durationMinutes"
            ></v-select>
          </v-col>

        </v-row>
      </FormContainer>


      <div class="mt-2 d-flex">
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="4" class="pr-1">
              <v-btn color="secondary" style="width: 100%;" :to="{name: 'ShowCustomer', query: { partnerIdentifier: partnerIdentifier } }" >Back</v-btn>
            </v-col>
            <v-col cols="4" class="pr-1">
              <v-btn color="warning" style="width: 100%;" :to="{name: 'ShowCustomer', query: { partnerIdentifier: partnerIdentifier } }" >Cancel</v-btn>
            </v-col>
            <v-col cols="4" class="pl-1">
              <v-btn color="primary" :disabled="!validForm" @click="submit" style="width: 100%;" >Save</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </v-form>

  </v-container>    

  
</template>

<script>
  import parseDate from '@/mixins/parseDate'

  import DateTimeInput from '@/components/DateTimeInput';
  import FormContainer from '@/components/FormContainer';

  export default {

    components: { DateTimeInput, FormContainer },

    data: () => ({
      subject: '',
      startTime: null,
      durationMinutes: 60,
      durations: [ { value: 15, text: '15 Minutes' }, { value: 30, text: 'Half an hour' }, { value: 45, text: '45 Minutes' }, { value: 60, text: 'One hour' }, { value: 90, text: 'An hour and a half' }, { value: 120, text: 'Two hours' }, { value: 180, text: 'Three hours' }, { value: 240, text: 'Four hours' }, { value: 480, text: 'All day' } ],
    }),

    computed: {
      partnerIdentifier() { return this.$route.query.partnerIdentifier; },
      customer() { return this.$store.getters.customer(this.partnerIdentifier); },
      validForm() { return !(this.startTime==null); },
    },

    methods: {
      submit() {
        const appointment = {
          'startsAt': this.startTime,
          'endsAt': this.parseDateTime(this.startTime).plus({minutes: this.durationMinutes}).toISO(),
          'durationMinutes:': this.durationMinutes,
          'subject': this.subject,
          'id': -1*((this.customer.appointments || []).length + 1),
        };
        this.customer.appointments = this.customer.appointments || [];
        this.customer.appointments.push(appointment);
        this.$store.dispatch('upsertCustomer', this.customer);
        this.$router.push({name: 'ShowCustomer', query: { partnerIdentifier: this.partnerIdentifier }});
      }
    },

    mixins: [parseDate],
  }
</script>