<template>
  <v-form v-model="valid" ref="form7">
    <v-container fluid>
      <NucafeFarmerNeeds v-if="isNucafeFarmer" v-model="formFields" />
      <NileFarmerNeeds v-if="!isNucafeFarmer" v-model="formFields" />
      <v-row class="mt-4">
        <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid">Save & Continue</v-btn>
        <v-btn color="gray darken-3" class="mb-2" @click="previousStep">Back</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import NileFarmerNeeds from "@/views/inputOrders/NileUg";
import NucafeFarmerNeeds from "@/views/inputOrders/Nucafe";

export default {
  name: "FarmersNeeds",
  components: {
    NileFarmerNeeds,
    NucafeFarmerNeeds
  },
  props: ["rules", "value", "next", "previous"],
  data(){
    return {
      valid: true,
      formFields: {},
      productCategory: null,
    }
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
    productCategories() {
      const items = this.$store.getters.settings.skus.map(e => { return e.category ? e.category : 'Generic' })
      return [...new Set(items)]
    },
    isNucafeFarmer() { return this.$store.getters.settings.partnerCode == 'nucafe_ug' },
  },
   watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; }
    },
    formFields(to) { this.$emit('input', to); },
  },
  methods:{
    validate() {
      this.$refs.form7.validate();
    },
    validateAndContinue(){
      this.validate();
      this.$emit('nextStep');
    },
    previousStep() {
      this.$emit('previousStep');
    }
  }
};
</script>

<style>
</style>