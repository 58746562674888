<template>
  <div>
    <PageHeader
      :title="pageHeader"
      icon="mdi-flower-outline"
      @back="toHome"
      @save="validateAndContinue"
      @validate="validate"
    />
    <v-form v-model="valid" ref="deliveryForm">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
          <v-select v-model="coffee.coffeeType" :items="coffeTypes" item-text="text" item-value="value" label="Bean" />
          </v-col>
          <v-row class="d-flex justify-center mb-3">
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
              <v-btn :class="{ success: !toggle_exclusive }" class="mr-3">Hulled</v-btn>
              <v-btn :class="{ success: toggle_exclusive }">Not Hulled</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-col cols="12" md="4">
            <v-select v-model="coffee.grade" :items="robustaCoffeeOptions" label="Grade" />
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="coffee.moistureContent" required label="Moisture Content" type="number" suffix="%"/>
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="coffee.sample" required label="Sample out turn" type="number" suffix="%"/>
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="coffee.quantity" required label="Weight" type="number" suffix="KG"/>
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="coffee.processingCost" required label="Processing costs" type="number" suffix="UGX"/>
          </v-col>
          <v-col cols="12" md="4" class="d-flex">
            <p class="mr-3">Price/Kg: </p>
            <Currency :value="coffee.price_per_unit" />
          </v-col>
                    <v-col cols="12" md="4" class="d-flex">
            <p class="mr-3">Value: </p>
            <Currency :value="coffeeValue" />
          </v-col>
          <v-col cols="12" md="4" class="d-flex">
            <p class="mr-3">Net Value: </p>
            <Currency :value="coffeeNetValue" />
          </v-col>
        </v-row>
        <v-row class="mx-3 my-4 d-flex flex-row-reverse">
          <v-btn color="primary" class="mb-2" @click="validateAndContinue" :disabled="!valid">Next</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import TextInput from "@/components/TextInput";
import PageHeader from "@/components/PageHeader";
import Currency from '@/components/Currency';

export default {
  components: { TextInput, PageHeader, Currency },
  data: () => ({
    coffee: {},
    toggle_exclusive: undefined,
    robustaCoffeeOptions: [18,17,15,12,'Undergrade/triage'],
    arabicaCoffeeOptions: ['AA','A','A+','AB','CPB','Undergade'],
    valid: false,
    coffeTypes : [
      {
        text: 'Kiboko (Dry Cherry Coffee)',
        value: 'Kiboko'
      },{
        text: 'Fair Average Quality',
        value: 'Fair Average Quality'
      },{
        text: 'Red Cherries',
        value: 'Red Cherries'
      }
    ]
  }),
  mounted() {
    this.coffee = {
      price_per_unit: Math.floor(Math.random() * 1000) + 2000,
      quantity: 0,
      processingCost: 0
    }
  },
  computed: {
    uuid() { return this.$route.query.uuid; },
    coffeeValue() { return this.coffee.quantity*this.coffee.price_per_unit},
    customer() { return this.$store.getters.customer(this.uuid); },
    coffeeNetValue() { return this.coffeeValue-this.coffee.processingCost},
    pageHeader() { return `New Delivery for ${this.customer.name}`}
  },
  methods: {
    validate() {
      this.$refs.deliveryForm.validate();
    },
    toHome() {
      this.$router.push({ name: 'Home' });
    },
    validateAndContinue(){
      this.validate();
      this.coffee.coffeeValue = this.coffeeValue
      this.coffee.coffeeNetValue = this.coffeeNetValue
      this.coffee.customerUuid = this.customer.uuid
      this.coffee.hulled = this.toggle_exclusive
      this.$store.dispatch('addDelivery', this.coffee);
      this.toHome()
    }
  }
}
</script>
