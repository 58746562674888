<template>
    <v-form ref="testForm" v-model="validForm">
      <v-container fluid>
  
        <v-row>
  
          <v-col cols="3">
            <FingerprintIdentification v-model="form.authorisation" :customer="customer" :msisdn="msisdn" :user="strategy=='user'" live />
          </v-col>
  
          <v-col cols="3" class="text-left">
            <div class="text-h5">Result</div>
            <div>
              <ServerSideResult :test="form.authorisation">
                {{form.authorisation}}
              </ServerSideResult>
            </div>
          </v-col>
  
        </v-row>
  
  
        <v-row>
          <v-col class="text-right">
            <v-btn color="warning" small dense class="mr-1" @click="this.validate">
              {{$t('validate')}}
            </v-btn>
            <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
              {{$t('save')}}
            </v-btn>
          </v-col>
        </v-row>
  
      </v-container>
  
    </v-form>
  </template>
  
  <script>
  import FingerprintIdentification from "@/components/FingerprintIdentification";
  import MsisdnInput from '@/components/MsisdnInput';
  import ServerSideResult from '@/components/ServerSideResult';
  import CustomerSearchInput from "@/components/CustomerSearchInput";
  
  export default {
  
    components: {
      FingerprintIdentification,
      MsisdnInput,
      ServerSideResult,
      CustomerSearchInput,
    },
  
    data() {
      return {
        validForm: false,
        form: {},
        msisdn: '27733651998',
        strategy: 'user',
        customer_selected: undefined,
      }
    },
  
    computed: {
      userName() { return this.$store.getters.settings.name; },
      uuid() { return this.$store.getters.settings.fieldAgentUuid; },
      partnerCode() { this.$store.getters.settings.partnerCode; },
      telephoneCode() { return this.$store.getters.settings.telephoneCode; },
      customer() { return (( this.strategy==='searched customer' ? { uuid: (this.customer_selected || {}).uuid } : undefined) || (this.strategy==='customer' ? { uuid: this.uuid } : undefined )); },
    },
  
    methods: {
      validate(event) {
        this.validating = true;
        this.$refs.testForm.validate();
      },
      saveForm(event) {
        console.log('saveForm', event)
      }
    },
  
    mounted() {
      this.msisdn = `${this.telephoneCode}833939595`;
    }
  
  }
  </script>