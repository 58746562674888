<template>
  <PageHeader :title="customer.name"  v-if="customer" back save @back="toCustomer" @save="save" :valid="true" >


    <FormContainer>
      <v-row>
        <v-col cols="12" class="text-left">
          <h4>{{$t('Location Overview')}}</h4>
          <RegionPicker v-model="regions" @change="change" />
        </v-col>
      </v-row>

      <v-row>
        
        <v-col cols="12" sm="6">
          <div class="text-left">
            <h4>{{$t('Farm Location')}}</h4>
            <v-radio-group v-model="farmLocationType" row>
              <v-radio :label="$t('Address')" value="address" />
              <v-radio :label="$t('GPS Coordinates')" value="gps" />
            </v-radio-group>
            <v-text-field v-if="farmLocationType=='address'" v-model="farmAddress" :label="$t('Address / Farm Name')" :hint="$t('Address / Farm Name')" single-line ></v-text-field>
            <LocationInput v-else v-model="farmGPS" />
          </div>
        </v-col>


        <v-col cols="12" sm="6" v-if="!show('hide_home_location')">
          <div class="text-left">
            <h4>{{$t('Home Location')}}</h4>
            <v-radio-group v-model="homeLocationType" row>
              <v-radio :label="$t('Address')" value="address" />
              <v-radio :label="$t('GPS Coordinates')" value="gps" />
            </v-radio-group>
            <v-text-field v-if="homeLocationType=='address'" v-model="homeAddress" :label="$t('Home Address')" :hint="$t('Home Address')" single-line ></v-text-field>
            <LocationInput v-else v-model="homeGPS" />
          </div>
        </v-col>

      </v-row>

    </FormContainer>


  </PageHeader>
</template>


<script>
  import displaySwitches from '@/mixins/displaySwitches';

  import PageHeader from '@/components/PageHeader';
  import Row from '@/components/Row';
  import FormContainer from '@/components/FormContainer';
  import RegionPicker from '@/components/RegionPicker';
  import LocationInput from '@/components/LocationInput';

  export default {

    components: { PageHeader, Row, FormContainer, RegionPicker, LocationInput },

    data: () => ({
      regions: undefined,
      updatedRegions: undefined,

      farmLocationType: 'address',
      farmAddress: undefined,
      farmGPS: undefined,

      homeLocationType: 'address',
      homeAddress: undefined,
      homeGPS: undefined,
    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          this.revertToDefaults();
          if (to) {

            const demographic = (to.demographic || {});
            this.regions = demographic.geographicsArray;

            const keyLocations = demographic.keyLocations || {};
            const homeLocation = (keyLocations.home || {});
            const farmLocation = (keyLocations.work || {});

            const homeParams = homeLocation.lookupParameters || {}
            this.homeLocationType = (homeParams.latitude ? 'gps' : 'address');
            this.homeAddress = homeParams.address;
            this.homeGPS = homeParams;

            const farmParams = farmLocation.lookupParameters || {}
            this.farmLocationType = (farmParams.latitude ? 'gps' : 'address');
            this.farmAddress = farmParams.address;
            this.farmGPS = farmParams;

          }
        }
      },
    },

    methods: {
      change(newRegions) {
        this.updatedRegions = newRegions;
      },
      revertToDefaults() {
        this.regions = undefined;
        this.farmLocationType = 'address';
        this.farmAddress = undefined;
        this.farmGPS = undefined;
        this.homeLocationType = 'address';
        this.homeAddress = undefined;
        this.homeGPS = undefined;
      },
      save() {

        this.customer.demographic = this.customer.demographic || {};
        this.customer.demographic.keyLocations = this.customer.demographic.keyLocations || {};

        this.customer.demographic.geographicsArray = this.regions;

        this.customer.demographic.keyLocations.home = this.customer.demographic.keyLocations.home || {};
        if (this.homeLocationType==='address') { this.customer.demographic.keyLocations.home.lookupParameters = { address: this.homeAddress  }; };
        if (this.homeLocationType==='gps') { this.customer.demographic.keyLocations.home.lookupParameters = this.homeGPS; };

        this.customer.demographic.keyLocations.work = this.customer.demographic.keyLocations.work || {};
        if (this.farmLocationType==='address') { this.customer.demographic.keyLocations.work.lookupParameters = { address: this.farmAddress  }; };
        if (this.farmLocationType==='gps') { this.customer.demographic.keyLocations.work.lookupParameters = this.farmGPS; };
        
        this.$store.dispatch('upsertCustomer', this.customer);

        this.toCustomer();

      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
    },

    mixins: [displaySwitches],

  }
</script>


<i18n>
{
  "en": {
    "Location Overview": "Location Overview",
    "Farm Location": "Farm Location",
    "Home Location": "Home Location",
    "Address": "Address",
    "GPS Coordinates": "GPS Coordinates",
    "Address / Farm Name": "Address / Farm Name",
    "Home Address": "Home Address"
  },
  "es": {
    "Location Overview": "Ubicaciones",
    "Farm Location": "Ubicación Finca",
    "Home Location": "Ubicación Residencia",
    "Address": "Dirección",
    "GPS Coordinates": "GPS Coordinadas",
    "Address / Farm Name": "Dirección / Nombre de la Finca",
    "Home Address": "Dirección Residencia"
  },
  "sw": {
    "Location Overview": "Muhtasari wa Mahali",
    "Farm Location": "Mahali pa Shamba",
    "Home Location": "Mahali pa Nyumbani",
    "Address": "Anwani",
    "GPS Coordinates": "Uratibu wa GPS",
    "Address / Farm Name": "Anwani / Jina la Shamba",
    "Home Address": "Anwani ya nyumbani"
  }
}
</i18n>
