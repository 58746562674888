<template>
  <PageHeader :title="$t('title')"  v-if="customer" back save @back="toCustomer" @save="save" :valid='validSubmission' >

    <v-row no-gutters >
      <v-form ref="theForm">

        <v-col cols="12" class="d-flex justify-space-between">
          <div style="width:80%;">
            <v-select
              :items="categories"
              :label="$t('Category')"
              v-model="category"
              solo
              v-if="categories.length>2"
            ></v-select>
          </div>
          <div style="width:20%; text-align: right;" class="font-weight-bold" >
            {{$t('total')}}
            <Currency :value="totalCost" />
          </div>
        </v-col>

        <v-col cols="12">

          <v-data-table dense :headers="headers" :items="table" item-key="item.uuid" :items-per-page="-1" class="w-100" hide-default-footer :search="searchTrigger" :custom-filter="tableFilter" >

            <template v-slot:header.description>{{$t('Description')}}</template>
            <template v-slot:header.price_per_unit>{{$t('Price')}} <small>/ {{$t('unit')}}</small></template>
            <template v-slot:header.quantity>{{$t('Quantity')}}</template>
            <template v-slot:header.cost>{{$t('Cost')}}</template>

            <template v-slot:item.price_per_unit="{ item }">
              <Currency :value="item.price_per_unit" />
              <span class="hidden-xs-only pl-0"><small>/ {{ item.unit }}</small></span>
            </template>

            <template v-slot:item.quantity="{ item }">
              <v-text-field type='number' :rules="quantityRules.check(item.minimum_order_quantity, item.maximum_order_quantity)" style="text-align: right;" v-model="item.quantity" @input="updateCost(item.uuid)" />
            </template>

            <template v-slot:item.cost="{ item }" class="hidden-xs-only">
              <Currency :value="(parseInt(item.quantity) || 0)*item.price_per_unit" />
            </template>

          </v-data-table>
        </v-col>

        <v-col cols="12" v-if="showUzimaUg" class="d-flex justify-space-between">
          <div></div>
          <div>
            <v-switch v-model="uzimaUgDocSaleType">
              <template v-slot:label>
                <span v-if="uzimaUgDocSaleType">Majority of day old chicks to be <b>sold at one month</b></span>
                <span v-else>Majority of day old chicks to be <b>reared to maturity</b></span>
              </template>
            </v-switch>
          </div>
        </v-col>

        <v-col cols="12" class="d-flex justify-space-between">
          <div></div>
          <div  class="font-weight-bold">
            {{$t('total')}}
            <Currency :value="totalCost" />
          </div>
        </v-col>

      </v-form>
    </v-row>

  </PageHeader>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import PageHeader from '@/components/PageHeader';
  import InputOrderEdit from '@/views/inputOrders/components/InputOrderEdit';
  import Currency from '@/components/Currency';

  export default {

    components: { PageHeader, InputOrderEdit, Currency },

    data: () => ({
      table: [],
      category: undefined,
      quantityRules: {
        check(min, max) {
          return [
            v => Number.isInteger(Number(v)) || "Numbers only",
            v => (Number(v) >= 0) || "A positive number please",
            v => !(/\D/.test(v)) || "Numbers please",
            v => (Number(v) >= (min || 0) ) || `Minimum order: ${min}`,
            v => (Number(v) <= (max || 10000000) ) || `Maximum order: ${max}`,
          ]
        }
      },
      uzimaUgDocSaleType: true,
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      showUzimaUg() { return this.partnerCode==='uzima_ug' && (((this.table.filter(e => (e.code==='cash_doc')))[0] || {}).quantity || 0)>0 },
      skus() { return this.$store.getters.settings.skus; },
      currency() { return this.$store.getters.currency; },
      totalCost() { return this.table.map( e => (parseFloat(e.quantity) || 0)*parseFloat(e.price_per_unit) ).reduce((a, b) => a + b, 0); },
      fieldsValid() { return this.table.map( e => (parseFloat(e.quantity)===0 || (parseFloat(e.quantity)>=parseFloat(e.minimum_order_quantity || 0))) && (parseFloat(e.quantity)<=parseFloat(e.miaxmum_order_quantity || 10000000)) ); },
      validSubmission() { return ((this.fieldsValid.every(e => e)) && (this.totalCost>0)); },
      location() { return ((this.$store.getters.geolocation || {}).coords); },
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
      categories() { return ['All Categories'].concat([...new Set(this.skus.map(e => e.category))].filter(e => e).sort()); },

      headers() { return [
        {
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          align: 'center',
          sortable: true,
          value: 'price_per_unit',
        },
        {
          align: 'start',
          sortable: false,
          value: 'quantity',
        },
        {
          align: 'end',
          sortable: false,
          value: 'cost',
        },
      ]; },

      search() { return this.$store.getters.search; },
      searchTrigger() { return (this.search || '') + (this.category || '') },
    },

    watch: {
      skus: {
        immediate: true,
        handler(to) {
          if (to) {
            this.table = to.map( e => {e.quantity = 0; e.cost = 0; e.uuid = uuidv4(); return e; } );
          }
        },
      }
    },

    methods: {
      updateCost(uuid) {
        let index = this.table.findIndex( e => e.uuid===uuid );
        let item = this.table[index];
        item.quantity = parseInt(item.quantity);
        item.cost = item.quantity*item.price_per_unit;
        this.$set(this.table, index, item);
      },
      tableFilter(value, searchTrigger, item) {
        let search = this.search || '';
        let category = (this.category || 'All Categories');
        let r = true;
        if (searchTrigger.length>0) {
          if (search.length>0) { r = r && item.description.match(new RegExp(this.search, 'i')); }
          if (category!=='All Categories') { r = r && item.category===category; }
        }
        return r;
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      save() {
        this.table.filter( e => (e.quantity || 0)>0 ).forEach( e => {

          const order = {
            uuid: e.uuid,
            createdAt: new Date().toISOString(),
            sku: e.code,
            description: e.description,
            price_per_unit: (parseFloat(e.price_per_unit) || 0),
            value: (parseFloat(e.price_per_unit) || 0) * (parseInt(e.quantity) || 0),
            quantity: (parseInt(e.quantity) || 0),
            status: 'placed',

            uzimaUgDocSaleType: this.uzimaUgDocSaleType,
            
            customerUuid: this.customerUuid,
            location: this.location,
            capturedById: this.fieldAgentId,
          }

          this.$store.dispatch('addOrder', order);

        });

        this.toCustomer();
      },
    },
  }
</script>


<i18n>
{
  "en": {
    "title": "New Order",
    "Description": "Description",
    "Price": "Price",
    "unit": "Unit",
    "Quantity": "Quantity",
    "Qost": "Cost",
    "total": "Total",
    "Category": "Category"
  },
  "es": {
    "title": "Nuevo orden",
    "Description": "Descripción",
    "Price": "Precio",
    "unit": "Unidad",
    "Quantity": "Cantidad",
    "Cost": "Costo",
    "total": "Total",
    "Category": "Category"
  },
  "sw": {
    "title": "Mahitaji",
    "Description": "Maelezo",
    "Price": "Bei",
    "unit": "Kitengo",
    "Quantity": "Wingi",
    "Cost": "Gharama",
    "total": "Jumla",
    "Category": "Jamii"
  }
}
</i18n>
