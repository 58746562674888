<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="12">
        <v-radio-group v-model="target" row >
          <v-radio label="To Bank"       value="toBank"   v-if="isSafe" />
          <v-radio label="From Bank"     value="fromBank" v-if="isSafe" />
          <v-radio label="To Safe"       value="toSafe"   v-if="!isSafe" />
          <v-radio label="Transfer to Consultant" value="consultant" v-if="isSafe" />
          <v-radio label="Transfer to Consultant" value="consultantToConsultant" v-if="!isSafe" />
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="text-left">
        <span v-if="target=='toBank'" class="text-h5">Transfer to bank from safebox</span>
        <span v-if="target=='fromBank'" class="text-h5">Transfer to safe from bank</span>
        <span v-else-if="target=='toSafe'" class="text-h5">Transfer to safebox</span>
        <FieldAgentInput v-else-if="target=='consultant' || target=='consultantToConsultant'" v-model="recipient" label="Loan Consultant" online depot />
      </v-col>

      <v-col cols="12" v-if="target=='fromBank'">
        <SelectInput v-model="fromBank" :items="banks" label="From bank" />
      </v-col>

      <v-col cols="12">
        <CurrencyInput v-model="cash" label="Cash Receipted" />
      </v-col>

      <v-col cols="12" v-if="target=='toBank'">
        <SelectInput v-model="toBank" :items="banks" label="To bank" />
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="success" style="width: 100%;" small dense @click="$emit('submit')" :disabled="!valid">Send</v-btn>
      </v-col>
    </v-row>


  </v-container>

</template>

<script>
import FieldAgentInput from "@/components/FieldAgentInput";
import CurrencyInput from "@/components/CurrencyInput";
import SelectInput from "@/components/SelectInput";

export default {

  components: { FieldAgentInput, CurrencyInput, SelectInput },

  props: {
    value: {
      type: Object,
    },
    safe: {
      type: Boolean,
      default: false,
    },
    balance: Number,
  },

  data() {
    return {
      recipient: undefined,
      target: undefined,
      cash: 0,
      toBank: undefined,
      fromBank: undefined,
      banks: [
        'CBA Expense Account',
        'CRDB',
        'NMB',
        'NMB float',
        'Tigo Pesa',
        'UBA',
      ],
    }
  },

  computed: {
    result() {
      let r = {
        recipient: this.recipient,
        recipient_id: (this.recipient || {}).id,
        target: this.target,
        cash: this.cash,
        toBank: this.toBank,
        fromBank: this.fromBank,
      }
      return r;
    },
    isSafe() { return (this.safe); },
    valid() {
      let r = false;
      if (this.target) {
        if (this.target!='fromBank') {
          r = false;
          let cash = (this.cash || 0);
          let balance = (this.balance || 0) / 100;
          if ((cash>0) && (cash<=balance)) {
            r = true;
          }
        }
        if (this.target==='consultant' || this.target==='consultantToConsultant') {
          r = r && !!this.recipient;
        }
        if (this.target==='fromBank') {
          r = false;
          let cash = (this.cash || 0);
          if (cash>0) { r = true; }
        }
        if (r && this.target=='fromBank') {
          r = !!this.fromBank;
        }
        if (r && this.target=='toBank') {
          r = !!this.toBank;
        }
      }
      return r;
    }
  },

  watch: {
    result: {
      deep: true,
      handler(to) {
        this.$emit('input', to);
      }
    },
  },

}
</script>
