<template>
  <v-text-field
    v-if="display"
    hide-details
    :label="$t('search')"
    :placeholder="$t('search')"
    filled
    rounded
    light
    dense
    single-line
    :value="search"
    v-on:input="onSearch($event)"
    append-icon="mdi-magnify"
    style="max-width: 300px;"
    >
  </v-text-field>
</template>

<script>
export default {
  props: ['displaySearchOnRoutes'],
  methods: {
    onSearch(event) {
      this.$store.dispatch('updateSearch', event)
    }
  },
  computed: {
    display() {
      let showFor = (this.displaySearchOnRoutes || '').split(',').map(r => r.trim());
      return showFor.includes(this.$route.name);
    },
    search() {
      return this.$store.getters.search;
    },
  },
};
</script>


<i18n>
{
  "en": {
    "search": "Search"
  },
  "es": {
    "search": "Búsqueda"
  },
  "sw": {
    "search": "Tafuta"
  }
}
</i18n>
