<template>
  <IndexCompact :customers="customers" v-if="breakpoint=='xs'" />
  <IndexNormal :customers="customers" v-else />
</template>


<script>
  import IndexCompact from '@/views/customers/components/IndexCompact';
  import IndexNormal from '@/views/customers/components/IndexNormal';

  export default {
    components: { IndexCompact, IndexNormal },
    computed: {
      breakpoint() { return this.$vuetify.breakpoint.name; },
    },
    props: ['customers'],
  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
