<template>
  <div>
    <PageHeader
      :title="customer ? customer.name : phoneNumberFromMsisdn"
      icon="mdi-flower-outline"
      @back="toCustomers"
      @save="save"
      back
      :valid="true"
      validate
      @validate="validate"
    />

    <Stepper :tabs="tabs" :captions="captions" :complete="complete" @position="stepChange($event)" ref="stepper" vertical>
      <template v-slot:PersonalDetails>
        <PersonalDetails v-model="formFields.personalDetails" ref="form1" @valid="validity['PersonalDetails']=$event" @nextStep="nextStep" @previousStep="previousStep" :rules="rules" />
      </template>

      <template v-slot:IncomeDetails>
        <IncomeDetails v-model="formFields.incomeDetails" :next="next" :previous="previous" ref="form2" @valid="validity['IncomeDetails']=$event" @nextStep="nextStep" @previousStep="previousStep" :rules="rules" />
      </template>

      <template v-slot:NinaCardFront>
        <NinaCardFront v-model="formFields.ninCardFront" :next="next" :previous="previous" ref="form3" @valid="validity['NinaCardFront']=$event" @nextStep="nextStep" @previousStep="previousStep" :rules="rules" :customerUuid="customerUuid" @ninFrontImage="handleImage"/>
      </template>

      <template v-slot:NinaCardBack>
        <NinaCardBack v-model="formFields.ninCardBack" :next="next" :previous="previous" ref="form4" @valid="validity['NinaCardBack']=$event" @nextStep="nextStep" @previousStep="previousStep" :rules="rules" :customerUuid="customerUuid" @ninaBackImage="handleImage"/>
      </template>

      <template v-slot:SeasonHarvestTable>
        <SeasonHarvestTable v-model="formFields.seasonsAndHarvests" @nextStep="nextStep" @previousStep="previousStep" ref="form5" @valid="validity['SeasonHarvestTable']=$event" :rules="rules" />
      </template>

      <template v-slot:Map>
        <Map v-model="formFields.farmLocation" @nextStep="nextStep" @previousStep="previousStep" ref="form6" @valid="validity['Map']=$event"  :rules="rules" />
      </template>

      <template v-slot:OtpAcknowledgement>
        <OtpAcknowledgement v-model="formFields.otpAcknowledgement" ref="form8" :submit="submitApplication" :capital="formFields.farmersNeeds.total" @valid="validity['OtpAcknowledgement']=$event" @nextStep="nextStep" :rules="rules" />
      </template>

      <template v-slot:ThankYou>
        <ThankYou v-model="formFields.personalDetails.name"/>
      </template>
    </Stepper>
    <!-- end stepper -->
  </div>
</template>

<script>
import msisdnToTelephone from '@/mixins/msisdnToTelephone';
import Stepper from "@/components/Stepper";
import PersonalDetails from "@/views/partners/nile_ug/components/PersonalDetails.vue";
import NinaCardFront from "@/views/partners/nile_ug/components/NinaCardFront.vue";
import NinaCardBack from "@/views/partners/nile_ug/components/NinaCardBack.vue";
import SeasonAndHarvest from "@/views/partners/nile_ug/components/SeasonAndHarvest.vue";
import IncomeDetails from "@/views/partners/nile_ug/components/IncomeDetails.vue";
import PageHeader from "@/components/PageHeader";
import Map from "@/views/partners/nile_ug/components/Map.vue";
// import Map2 from "@/views/partners/nile_ug/components/Map2";
import LocationInput from "@/components/LocationInput";
import FarmersNeeds from "@/views/partners/nile_ug/components/FarmersNeeds";
import OtpAcknowledgement from "@/views/partners/nile_ug/components/OtpAcknowledgement.vue";
import ThankYou from "@/views/partners/nile_ug/components/ThankYou.vue";
import SeasonHarvestTable from "@/views/partners/nile_ug/components/SeasonHarvestTable";

export default {

  name: "NileStepperRegistrationForm",

  components: {
    PageHeader,
    PersonalDetails,
    IncomeDetails,
    NinaCardFront,
    NinaCardBack,
    SeasonAndHarvest,
    Map,
    LocationInput,
    FarmersNeeds,
    OtpAcknowledgement,
    ThankYou,
    SeasonHarvestTable,
    Stepper
  },

  data: function () {
    return {
      customer: undefined,
      formFields: undefined,
      step: 1,
      tabs: ['PersonalDetails', 'IncomeDetails', 'NinaCardFront', 'NinaCardBack', 'SeasonHarvestTable', 'Map', 'OtpAcknowledgement', 'ThankYou'],
      captions: {
        'PersonalDetails': 'Personal Details',
        'IncomeDetails': 'Income Details',
        'NinaCardFront':'NIN Card Details Front',
        'NinaCardBack': 'NIN Card Details Back',
        'SeasonHarvestTable': 'Season And Harvest',
        'Map': 'Primary Garden',
        'OtpAcknowledgement': 'OTP Acknowledgement',
        'ThankYou': 'Thank You'
      },
      validity: {
        'PersonalDetails': false,
        'IncomeDetails': false,
        'NinaCardFront': false,
        'NinaCardBack': false,
        'SeasonHarvestTable': false,
        'Map': false,
        'OtpAcknowledgement': false,
        'ThankYou': false 
      },
      firstStep: true,
      lastStep: false,
      rules: {
        required: (value) => (value==0 || !!value) || "Required",
        maxLength: (value) =>
          (value && value.length == 14) ||
          "Nin number must be 14 characters long",
        fcsLength: (value) =>
          ( ((value || '').length == 0) || ((value || '').length == 10) ) ||
          "FCS number must be 10 digits long",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
        matchingDependents: (value) => {
          if (
            this.formFields.personalDetails.schoolGoingDependent <=
            this.formFields.personalDetails.dependents
          ) {
            return true;
          } else {
            return "School going dependents should be less than or equal to number of dependents";
          }
        },
        maxDependents: (value) =>
          ( (!(value==null)) && value <= 20) || "Cannot exceed 20 dependents",
      },
    };
  },
  computed: {
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    invalid() { return this.tabs.filter(e => (this.validity[e]===false) ); },
    valid() { return this.invalid.length==0; },
    customerUuid() { return this.$route.query.uuid; },
    isStart() { return this.step == 1; },
    isLastStep() { return this.step == 9; },
    msisdn() { return this.customer ? this.customer.msisdn : null; },
  },
  watch: {
    step() { this.save(); },
  },
  methods: {
    next() { this.step += 1; },
    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },
    previous() { this.step -= 1; },
    concludeLater() {
      this.save();
      this.toCustomers();
    },
    nextStep() {
      this.save();
      this.$refs.stepper.nextStep();
    },
    previousStep() {
      this.save();
      this.$refs.stepper.previousStep();
    },
    toCustomers() {
      this.$router.push({ name: 'Customers' });
    },
    validate() {
      // validate the different forms here
      this.$refs.form1.validate();
      this.$refs.form2.validate();
      this.$refs.form3.validate();
      this.$refs.form4.validate();
      this.$refs.form5.validate();
      this.$refs.form6.validate();
      this.$refs.form7.validate();
      this.$refs.form8.validate();
    },
    handleImage(imageObj, side) {
      if (side === "front") {
        this.formFields.ninCardFront.ninCardFrontImage = imageObj;
      } else {
        this.formFields.ninCardBack.ninCardBackImage = imageObj;
      }
    },

    submitApplication() {
      this.customer.nileUgApplication = true;
      this.save();
      this.next();
    },

    save() {

      this.customer.name = this.formFields.personalDetails.name;

      this.customer.demographic = {
        gender: this.formFields.personalDetails.gender,
        birthdate: this.formFields.personalDetails.birthdate,
        dependents: this.formFields.personalDetails.dependents,
        schooling: this.formFields.personalDetails.schooling,

      //   identity: {
      //     type: 'NIN Number',
      //     number: this.formFields.ninNumber,
      //   },

      };

      this.customer.personalDetails = this.formFields.personalDetails;
      this.customer.incomeDetails = this.formFields.incomeDetails;
      this.customer.ninCardFront = this.formFields.ninCardFront;
      this.customer.ninCardBack = this.formFields.ninCardBack;
      this.customer.seasonsAndHarvests = this.formFields.seasonsAndHarvests;
      this.customer.farmersNeeds = this.formFields.farmersNeeds;
      this.customer.otpAcknowledgement = this.formFields.otpAcknowledgement;
      this.customer.farmLocation = this.formFields.farmLocation;

      this.customer.lastNileSubmissionPage = Math.max(...[ parseInt(this.customer.lastNileSubmissionPage || 0), this.step ]);
      this.customer.nileSubmissionAt = (new Date()).toISOString();

      this.$store.dispatch("upsertCustomer", this.customer);

    },
  },
  mounted() {

    this.step = 1;
    this.customer = this.$store.getters.customer(this.$route.query.uuid);

    this.formFields = {};

    // read it out of previously saved data
    this.formFields.personalDetails = this.customer.personalDetails || {};
    this.formFields.incomeDetails = this.customer.incomeDetails || {};
    this.formFields.ninCardFront = this.customer.ninCardFront || {};
    this.formFields.ninCardBack = this.customer.ninCardBack || {};
    this.formFields.seasonsAndHarvests = this.customer.seasonsAndHarvests || [];
    this.formFields.farmersNeeds = this.customer.farmersNeeds || {};
    this.formFields.otpAcknowledgement = this.customer.otpAcknowledgement || {};
    this.formFields.farmLocation = this.customer.farmLocation || {};


    // now, if we are working with details loaded from the server
    this.formFields.personalDetails.name = this.customer.name || '';
    this.formFields.personalDetails.gender = this.customer.demographic.gender || this.formFields.personalDetails.gender;
    this.formFields.personalDetails.birthdate = this.customer.demographic.birthdate || this.formFields.personalDetails.birthdate;
    this.formFields.personalDetails.dependents = this.customer.demographic.dependents || this.formFields.personalDetails.dependents;
    this.formFields.personalDetails.schooling = this.customer.demographic.schooling || this.formFields.personalDetails.schooling;
    this.formFields.incomeDetails.fcsCardNumber = this.formFields.incomeDetails.fcsCardNumber || "";
    this.formFields.incomeDetails.assetsHeld = this.formFields.incomeDetails.assetsHeld || [];
    this.formFields.incomeDetails.alternativeIncomeAmount = parseInt(this.formFields.incomeDetails.alternativeIncomeAmount);
    this.formFields.ninCardFront.ninCardFrontImage = this.formFields.ninCardFront.ninCardFrontImage || {};
    this.formFields.ninCardBack.ninCardBackImage = this.formFields.ninCardBack.ninCardBackImage || {};
    this.formFields.seasonsAndHarvests = this.formFields.seasonsAndHarvests || [];
    this.formFields.farmersNeeds.total = this.formFields.farmersNeeds.total || 0;
    this.formFields.farmersNeeds.tableResults = this.formFields.farmersNeeds.tableResults || [];
  },

  mixins: [msisdnToTelephone],
};
</script>


<style>
</style>