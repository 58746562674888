<template>

  <v-container fluid>
    <v-row v-if="!submitted">
      <v-col cols="12">
        <v-form v-model="valid" >
          <v-container fluid>
            <v-row>
              <v-col cols="8">
                <CurrencyInput v-model="balance" style="width: 100%;" />
              </v-col>
              <v-col cols="4">
                <v-btn :disabled="!validBalance" color="primary" small dense block class="mt-2" @click="submit">Submit</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <ServerSideResult :test="done" >
          Thank you. Your submission has been saved.
        </ServerSideResult>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from "@/components/ServerSideResult";
import CurrencyInput from "@/components/CurrencyInput";

export default {

  components: { ServerSideResult, CurrencyInput },

  data() {
    return {
      valid: false,
      balance: 0,
      submitted: false,
      done: undefined,
    }
  },

  computed: {
    validBalance() { return this.valid && (parseInt(this.balance) >= 0); },
  },

  methods: {
    submit() {
      this.submitted = true;
      this.remoteLookup('ultimate_tz_set_safe_eod_balance', { balance: this.balance }).then( done => this.done = true ).catch(e => console.log(e));
    }
  },

  mixins: [lookups],

}
</script>
