export const fungicidesData = [
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Azoxystrobin 50 WP",
        "Dose": "100 a 125 gramos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Boscalid WG 50%",
        "Dose": "300 gramos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Boscalid + Piraclostrobin WG 25%+12,8%",
        "Dose": "800 a 1.000 gramos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Fenhexamid SC 500",
        "Dose": "1,0 a 1,5 kilos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Tebuconazole + Triadimenol EC 22,5% + 7,5%",
        "Dose": "0,5 a 0,6 litros/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Tebuconale + Azoxystrobin 320 SC 20% + 12%",
        "Dose": "0,8 litros/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Trifloxystrobin + Tebuconazole SC 10% + 20%",
        "Dose": "0,4 - 0,5 litros/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Trifloxystrobin 500 SC",
        "Dose": "75 cc/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Cobre + Calcio + Magnesio + Potasio 17,2% + 13,5 + 5,6% + 23,1%.",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Altenaria spp.",
        "activeIngredient": "Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado 10% WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Tebuconazole + Triadimenol EC 22,5% + 7,5%",
        "Dose": "0,5 a 0,6 litros/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Tetraconazole 40 EW",
        "Dose": "0,8 a 0,9 litros /ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Azoxystrobin 50 WP",
        "Dose": "100 a 125 gramos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Boscalid WG 50%",
        "Dose": "300 gramos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Fenhexamid SC 500",
        "Dose": "1,0 a 1,5 kilos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Myclobutanil 40 WP",
        "Dose": "80 gramos/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Tebuconazole EW 25%",
        "Dose": "0,8 cc/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Tebuconale + Azoxystrobin 320 SC 20% + 12%",
        "Dose": "0,8 litros/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Trifloxystrobin + Tebuconazole SC 10% + 20%",
        "Dose": "0,4 - 0,5 litros/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Trifloxystrobin 500 SC",
        "Dose": "75 cc/ha"
    },
    {
        "pest": "Colletotorichum spp.",
        "activeIngredient": "Cobre + Calcio + Magnesio + Potasio 17,2% + 13,5 + 5,6% + 23,1%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Azoxystrobin 50 WP",
        "Dose": "100 a 125 gramos/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Tebuconazole EW 25%",
        "Dose": "0,8 cc/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Tebuconazole + Triadimenol EC 22,5% + 7,5%",
        "Dose": "0,5 a 0,6 litros/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Tebuconale + Azoxystrobin 320 SC 20% + 12%",
        "Dose": "0,8 litros/ha"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "Boscalid WG 50%",
        "Dose": "300 gramos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Boscalid WG 50%",
        "Dose": "300 gramos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Boscalid + Piraclostrobin WG 25%+12,8%",
        "Dose": "800 a 1.000 gramos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Kresoxim methyl SC 50%",
        "Dose": "200 cc/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Mepanipyrim 40 SC",
        "Dose": "900 - 1.200 cc/ha"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado 10% WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Sphaerotheca spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Fusarium spp.",
        "activeIngredient": "Carboxin + Captan 20% + 20%",
        "Dose": "2,0 a 4,0 kilos/ha"
    },
    {
        "pest": "Fusarium spp.",
        "activeIngredient": "Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado 10% WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Fusarium spp.",
        "activeIngredient": "Trichoderma harzianum 1.0 X 10⁸ UFC Esporas/Gramo de Producto Formulado WP",
        "Dose": "500 gramos/ha"
    },
    {
        "pest": "Pythium spp.",
        "activeIngredient": "Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado 10% WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Pythium spp.",
        "activeIngredient": "Trichoderma harzianum 1.0 X 10⁸ UFC Esporas/Gramo de Producto Formulado WP",
        "Dose": "500 gramos/ha"
    },
    {
        "pest": "Pythium spp.",
        "activeIngredient": "Carboxin + Captan 20% + 20%",
        "Dose": "2,0 a 4,0 kilos/ha"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Carboxin + Captan 20% + 20%",
        "Dose": "2,0 a 4,0 kilos/ha"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Validamycin 5%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado 10% WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Trichoderma harzianum 1.0 X 10⁸ UFC Esporas/Gramo de Producto Formulado WP",
        "Dose": "500 gramos/ha"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Fenbuconazole SC 240",
        "Dose": "0,5 litros/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Carboxin + Captan 20% + 20%",
        "Dose": "2,0 a 4,0 kilos/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Dimetomorf 500 SC - WP",
        "Dose": "0,6 a 0,8 lts/ha - 0,6 a 0,8 kls/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Dimetomorf + Mancozeb MZ 9% + 60%",
        "Dose": "1,8 a 2,4 kilos-lts/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Dimetomorf + Piraclostrobin WG 12% + 6,7%",
        "Dose": "1,5 kilos/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Etaboxam 10 SC",
        "Dose": "600 a 900 cc/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Fenamidone + Propamocarb 7,5% + 37,5% SC",
        "Dose": "1,5 a 2,0 litros/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Fluopicolide + Propamocarb SC 6,25% + 62,5",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Bacillus subtilis 2X10⁹ Esporas/Gramo de Producto Formulado 10% WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Mandipropamid 250 SC",
        "Dose": "400 cc /ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Metalaxil+Mancozeb 4% + 64% WP",
        "Dose": "2,0 a 2,5 kilos/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Metalaxil + Oxicloruro de Cobre WP 15% + 35%",
        "Dose": "2,5 kilos/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Propamocarb 722 SL",
        "Dose": "1,0 litros/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Sodio, Potasio y Fosfitos de Amonios SL 51,8%",
        "Dose": "2,0 a 2,4 litros/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Cobre + Calcio + Magnesio + Potasio 17,2% + 13,5 + 5,6% + 23,1%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Phytophthora spp.",
        "activeIngredient": "Bacillus subtilis1 X 10⁹ UFC/g 1.34 S",
        "Dose": "2,5 a 10 centimetros cubicos/litro"
    },
    {
        "pest": "Meloidogiyne spp.; Globodera spp.; Heterodera spp.; Helicotylenchus spp.; Pratylenchus spp; Ditylenchus spp.; Radhopolus spp.",
        "activeIngredient": "Extracto de Ruda",
        "Dose": "3 - 5 cc/litro"
    },
    {
        "pest": "Meloidogiyne spp.; Globodera spp.; Heterodera spp.; Helicotylenchus spp.; Pratylenchus spp; Ditylenchus spp.; Radhopolus spp.",
        "activeIngredient": "Paecilomyces lillacinus 5x10 8 conidias/g / Trichoderma harzianum, T. Asperellum, T. atroviride 5x10 8 conidias/g WP",
        "Dose": "1,0 gramo por litro de agua"
    },
    {
        "pest": "Meloidogiyne spp.; Globodera spp.; Heterodera spp.; Helicotylenchus spp.; Pratylenchus spp; Ditylenchus spp.; Radhopolus spp.",
        "activeIngredient": "Extracto de Higuerilla",
        "Dose": "2 litros/ 200 litros"
    },
    {
        "pest": "Meloidogiyne spp.; Globodera spp.; Heterodera spp.; Helicotylenchus spp.; Pratylenchus spp; Ditylenchus spp.; Radhopolus spp.",
        "activeIngredient": "Glyricidia Extractos de plantas 56% - Acidos grasos 4% - Extractos Minerales 39% SL - SC",
        "Dose": "1.500 - 2.000 cc/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Flutriafol 125 SC",
        "Dose": "0,6 a 0,8 litros/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Tebuconazole + Triadimenol EC 22,5% + 7,5%",
        "Dose": "0,5 a 0,6 litros/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Puccinia pampeana",
        "activeIngredient": "Tebuconale + Azoxystrobin 320 SC 20% + 12%",
        "Dose": "0,8 litros/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Podosphaera spp.; Sphaerotheca spp.; Plasmopara spp.; Uncinula spp.; Erysiphe spp.",
        "activeIngredient": "Kresoxim methyl SC 50%",
        "Dose": "200 cc/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Metalaxil + Oxicloruro de Cobre WP 15% + 35%",
        "Dose": "2,5 kilos/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Peronospora spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Deuteromicetos",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Oomicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Ascomicetos",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Basidiomicetos",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio SC 6,4% + 1,25% + 23,2% + 4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Myclobutanil 40 WP",
        "Dose": "80 gramos/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Azufre 720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Cobre Sulfato Pentahidratado EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Cobre Sulfato Pentahidratado SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio SP 10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Cobre soluble + Azufre cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Oxicloruro de Cobre 58,8% WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Polisulfuro de Calcio 79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Metalaxil + fludioxonil XL 035 FS",
        "Dose": "1 cc/litro Inmersion al cespedon"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Propineb WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio PC 21% + 18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Dioxido de Cloro Estabililzado 2.500 ppm 0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    }
]