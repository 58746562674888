<template>
  <PrintLayout @back="toCustomer" >
    <v-simple-table>
      <tbody>

          <Row :title="$t('name')" :text="customer.name" bold="true" v-if="customer.name" />
          <Row :title="$t('telephone')" :msisdn="customer.msisdn" />
          <Row :title="$t('partnerIdentifier')" :code="customer.partnerIdentifier" v-if="customer.partnerIdentifier" />
          <Row :title="(customer.demographic.primaryIdentityType || customer.demographic.identityType) | id_number_title" :identityNumber="customer.demographic" v-if="identityNumber" />
          <Row :title="$t('tags')" v-if="segmentList.length>0" >
            <v-chip v-for="segment in segmentList" :key="segment" small class="mr-1" >{{ segment }}</v-chip>
          </Row>

          <Row :title="$t('Age')" :number="age" unit="years" v-if="age" />
          <Row :title="$t('Birthdate')" :date="birthdate" v-if="birthdate" />
          <Row :title="$t('Gender')" :gender="gender" v-if="gender" />
          <Row :title="$t('Dependents')" :number="dependents" v-if="!(isNaN(dependents))" />
          <Row :title="$t('Schooling')" :number="schooling" unit="years" v-if="schooling" />
          <Row :title="$t('Preferred Language')" :text="language" v-if="showLanguage && language" />

          <Row :title="geographicHeirarchy[0]" :text="regions[0]" v-if="regions[0]" />
          <Row :title="geographicHeirarchy[1]" :text="regions[1]" v-if="regions[1]" />
          <Row :title="geographicHeirarchy[2]" :text="regions[2]" v-if="regions[2]" />

          <Row title="Farm" :location="farmLocation" v-if="farmLocation" />
          <Row title="Home" :location="homeLocation" v-if="homeLocation" />


      </tbody>
    </v-simple-table>
  </PrintLayout>
</template>


<script>

  import PrintLayout from '@/components/PrintLayout';
  import Row from '@/components/Row';

  export default {

    components: { PrintLayout, Row },

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },

      identityNumber() { return (this.customer.demographic ? this.customer.demographic.identityNumber : null); },
      primaryLocation() { return (this.customer.demographic && this.customer.demographic.keyLocations ? this.customer.demographic.keyLocations['region'] || this.customer.demographic.keyLocations['work'] || this.customer.demographic.keyLocations['home'] : null); },
      segmentList() { return this.customer.segmentList || []; },
      fieldAgent() { return (this.customer.fieldAgent ? this.customer.fieldAgent.name : null) },

      demographic() { return this.customer.demographic || {} },

      age() { return this.demographic.age; },
      birthdate() { return this.demographic.birthdate; },
      dependents() { return this.demographic.dependents; },
      schooling() { return this.demographic.schooling; },
      language() { return this.demographic.language; },
      gender() { return this.demographic.gender; },

      geographicHeirarchy() { return this.$store.getters.settings.geographicHeirarchy; },
      demographic() { return (this.customer.demographic || {}); },
      regions() { return this.demographic.geographicsArray; },

      keyLocations() { return (this.demographic.keyLocations | {}); },
      farmLocation() { return this.keyLocations.work; },
      homeLocation() { return this.keyLocations.home; },

      partnerCode() { return this.$store.getters.settings.partnerCode; },
      showLanguage() { return !['apex_tz', 'hr_co', 'winwin_zw'].includes(this.partnerCode); },

    },

    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    },

  }
</script>


<i18n>
{
  "en": {
    "name": "Name",
    "telephone": "Telephone Number",
    "partnerIdentifier": "Your Identifier",
    "tags": "Tags / Segments",

    "Age": "Age",
    "Birthdate": "Birthdate",
    "Gender": "Gender",
    "Dependents": "Dependents",
    "Schooling": "Schooling",
    "Preferred Language": "Preferred Language",
    "Farm": "Farm Location",
    "Home": "Home Location"
  },
  "es": {
    "name": "Nombre",
    "telephone": "Número de Teléfono",
    "partnerIdentifier": "Tu identificador",
    "tags": "Etiquetas / Segmentos",

    "Age": "Años",
    "Birthdate": "Fecha de Nacimiento",
    "Gender": "Género",
    "Dependents": "Personas a cargo (económicamente)",
    "Schooling": "Escolaridad",
    "Preferred Language": "",
    "Farm": "Ubicación Finca",
    "Home": "Ubicación Residencia"
  },
  "sw": {
    "name": "Jina",
    "telephone": "Namba ya Simu",
    "partnerIdentifier": "Kitambulisho",
    "tags": "Lebo / Sehemu",

    "Age": "Umri",
    "Birthdate": "Siku ya kuzaliwa",
    "Gender": "Jinsia",
    "Dependents": "Dependents",
    "Schooling": "Kusoma",
    "Preferred Language": "Preferred Language",
    "Farm": "Mahali pa Shamba",
    "Home": "Mahali pa Nyumbani"
  }
}
</i18n>
