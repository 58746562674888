<template>
  
  <v-sheet :elevation="theElevation" :color="sheetColor" :rounded="rounded" class="pa-2" @click="click" @mouseleave="mouseleave" @mouseenter="mouseenter" v-bind:style="{cursor: (hasClickListener ? 'pointer' : undefined )}" >
    <div class="d-flex flex-column">
      <div class="text-h5"><slot></slot></div>
      <div class="text-caption">{{label}}</div>
    </div>
  </v-sheet>

</template>

<script>

export default {

  props: {
    label: String,
    highlight: [String, Boolean],
    cents: {
      type: Boolean,
      default: true,
    },
    width: String,
    color: String,
    elevation: {
      type: Number,
      default: 2,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      theElevation: undefined,
    }
  },

  computed: {
    sheetColor() {
      let r = this.color;
      let s = ((typeof(this.highlight)=='string') && (this.highlight==this.label));
      let b = ((typeof(this.highlight)=='boolean') && this.highlight);
      if (s || b) { r = 'success'; }
      return r;
    },
    hasClickListener(){
      return (!!(this.$listeners && this.$listeners.click));
    }
  },

  methods: {
    mouseleave() {
      this.theElevation = this.elevation;
    },
    mouseenter() {
      this.theElevation = this.elevation * (this.hasClickListener ? 2 : 1);
    },
    click() {
      if (this.hasClickListener) {
        this.$listeners.click();
      }
    },
  },

  mounted() {
    this.theElevation = this.elevation;
  },

}
</script>
