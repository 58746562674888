<template>
  <v-input :rules="[requiredRule]" v-model="fingerprint" class="pa-2" style="width: 100%;">
    <v-card style="width: 100%;" :loading="loading" loader-height="4" elevation="4">
      <template slot="progress">
        <v-progress-linear color="red" indeterminate></v-progress-linear>
      </template>
      <v-card-title>{{$t(finger)}}</v-card-title>
      <v-img height="100" :src="image" @click="captured" />
      <v-card-actions>
        <v-checkbox v-model="unavailable" label="Unavailable" @change="captured" :disabled="!loading" />
      </v-card-actions>
    </v-card>
  </v-input>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import { Base64 } from '@digitalpersona/core';
  import ImageDb from '@/store/ImageDb';
  import lookups from "@/mixins/lookups";
  import VInput from 'vuetify/lib/components/VInput/VInput.js';

  export default {

    props: {
      customer: {
        value: Object,
        required: true,
      },
      hand: {
        value: String,
        required: Boolean,
        validator(value) { return ['left', 'right'].includes(value); },
      },
      finger: {
        value: String,
        required: Boolean,
        validator(value) { return ['index', 'middle', 'ring', 'little', 'thumb'].includes(value); },
      },
      fingerNumber: Number,
      loading: Boolean,
    },

    data: () => ({
      fingerprint: false,
      uuid: uuidv4(),
      sample: undefined,
      unavailable:false,
    }),

    computed: {
      image() { return (this.sample ? "data:image/png;base64," + Base64.fromBase64Url(this.sample) : undefined); }
    },

    methods: {

      fingerprintCaptured(sample) {
        this.sample = sample;
        this.captured();
        this.saveFingerprint();
      },

      captured() {
        if (this.unavailable || this.image) {
          this.$emit('captured', this.fingerNumber);
        }
      },

      saveFingerprint() {
        let uuid = ImageDb.add(Base64.fromBase64Url(this.sample));  
        this.$store.dispatch('addImage', { type: 'fingerprint', type_id: this.customer.uuid, uuid: uuid, hand: this.hand, finger: this.finger });
        this.$emit('click', this.fingerNumber, uuid);
      },

      requiredRule(value) {
        let r = [
          v => !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },

    mounted() {
      this.remoteLookup('fingerprint_image_get', { uuid: this.customer.uuid, hand: this.hand, finger: this.finger }).then( detail => console.log(detail) );
    },

    mixins: [lookups],

  }
</script>


<i18n>
{
  "en": {
    "little": "Little Finger",
    "ring": "Ring Finger",
    "middle": "Middle Finger",
    "index": "Index Finger",
    "thumb": "Thumb"
  },
  "es": {
    "index": "Hombre",
    "feindex": "Mujer"
  },
  "sw": {
    "index": "Mwanaume",
    "feindex": "Mwanamke"
  }
}
</i18n>
