

export const phase1registerFormFields = {
  landPreparation: {
    question: '¿Tipo de preparación terreno?',
    answer: undefined,
  },
  drainageTrenches: {
    question: '¿Zanjas para Drenaje?',
    answer: undefined
  },
  distanceBetweenRows: {
    question: '¿Distancia entre surcos (Mts)?',
    answer: undefined
  },
  plantingBedsHeight: {
    question: '¿Altura Camas?',
    answer: undefined
  },
  distanceBetweenPlants: {
    question: '¿Distancia entre plantas (Mts) ?',
    answer: undefined
  },
  rows: {
    question: '¿Hileras?',
    answer: undefined
  },
  totalPlants: {
    question: '¿Total Plantas por hectárea?',
    answer: undefined
  },
  // sticks: {
  //   question: '¿Tutorado?',
  //   answer: undefined
  // },
  totalContractPlants: {
    question: 'Total Plantas del Contrato',
    answer: undefined
  },
  fiberCaliber: {
    question: '¿Calibre de Fibra?',
    answer: undefined
  },
  fiberMooring: {
    question: '¿Amarre de Fibra (cm)?',
    answer: undefined
  },
  totalFiberHectare: {
    question: 'Total Fibra por Hectárea',
    answer: undefined
  },
  totalContractFiber: {
    question: 'Total Fibra del Contrato',
    answer: undefined
  },
  typeOfRoll: {
    question: '¿Tipo de Rollo?',
    answer: undefined
  },
  totalFiberRolls: {
    question: 'Total Rollos de Fibra',
    answer: undefined
  },
  totalSticks: {
    question: '¿Total tutores?',
    answer: undefined
  },
  linesOfFibers: { // todo remove
    question: '¿Líneas de fibra?',
    answer: undefined
  },
  dateOfTransplant: {
    question: '¿Fecha Estimada Transplante?',
    answer: undefined
  },
  stickDistanceForPlanting: {
    question: '¿Distancia Tutorado (Mts)?',
    answer: undefined
  },
  attachedImages: []
}

export const phase1Irrigation = {

          typeOfMulch: {
            question: '¿Sistema de riego por goteo?',
            answer: undefined,
          },
          drainageTrenches: {
            question: '¿Distancia entre goteros (Mts) ?',
            answer: undefined,
          },
          dripperFlow: {
            question: '¿Flujo por gotero?',
            answer: undefined,
          },
          typeOfFilterInTheSystem: {
            question: '¿Qué tipo de Filtro se usará?',
            answer: undefined,
          },
          dateForInstallingIrrigationSystem: {
            question: '¿Fecha definida para instalación sistema riego?',
            answer: undefined,
          },
          irrigationSystemWasTested: {
            question: '¿Hizo prueba del sistema de riego?',
            answer: undefined,
          },
          dateWhen: {
            question: '¿Cuando?',
            answer: undefined,
          },
    totalTapePerHectare: {
        question: 'Total Cinta por Hectárea.(Mts)',
        answer: undefined,
    },
    totalTapePerContract: {
        question: 'Total Cinta del Contrato.(Mts)',
        answer: undefined,
    },
    totalDroppers: {
        question: 'Total Goteros.',
        answer: undefined,
    },
    totalLandFlows: {
        question: 'Flujo Total del Terreno/Hora.',
        answer: undefined,
    },
    typeOfFertilizerInjection: {
        question: '¿Que tipo de Inyección Fertilizante?',
        answer: undefined,
    },

    attachedImages: []
}



export const phase1PlasticMulch = {
          // typeOfMulch: {
          //   question: '¿Sistema de riego por goteo?',
          //   answer: undefined,
          // },
          plasticCaliber: {
            question: '¿Calibre del Plástico?',
            answer: undefined,
          },
          plasticColor: {
            question: '¿Color del Plástico?',
            answer: undefined,
          },
          measuresOfTheMulch: {
            question: '¿Ancho del Mulch (Mts) ?',
            answer: undefined,
          },
          necessaryChemical: {
            question: '¿Es necesario realizar control de arvenses antes de realizar la labor de cobertura?',
            answer: undefined,
          },
          distanceBetweenHoles: {
            question: '¿Diseño - Distancias perforaciones?',
            answer: undefined,
          },
    attachedImages: []
}
