<template>
  <PageHeader
      title="Portion Survey"
      back
      @back="toCustomer"
  >
    <ConservationAgriculture
        ref="conservationAgricultureStep"
        v-model="conservationAgricultureFormFields"
        :customer="customer" >
      <v-row class="mx-4 my-4">
        <v-col>
          <v-btn color="primary" @click="saveVisibleMitigationAndGoToMapField" :disabled="!isConservationAgricultureFormFieldsValid"> Save and map field</v-btn>
        </v-col>
      </v-row>
    </ConservationAgriculture>   
  </PageHeader>
</template>

<script>
import FieldSurvey from "@/views/partners/eco_bw/views/fieldSurvey/FieldSurvey";
import ConservationAgriculture from "@/views/partners/eco_bw/views/fieldSurvey/ConservationAgriculture";
import fields from "@/mixins/fields";
import PageHeader from "@/components/PageHeader";
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    FieldSurvey,
    ConservationAgriculture,
    PageHeader
  },
  data() {
    return {
      showGoToFieldMap: false,
      fieldSurveyAssessment: {
        uuid: uuidv4(),
        conservationAgricultureFormFields: null,
        fieldUuid: null,
        assessmentName: "Portion Survey"
      },
      
      
      fieldSurveyFormField: {
        location: null,
        cropId: null,
        fieldSize: null,
        fieldType: null,
      },
      conservationAgricultureFormFields: null,
    }
  },
  mounted() {
    // if field exist update form data, if no field dont do anything
    if (!this.customer.fields) {
      this.customer.fields = []
    }

    this.fieldSurveyAssessment.fieldUuid   = this.fieldUuid

    // if (this.fieldAssessment) {
    //   this.conservationAgricultureFormFields = this.fieldAssessment.conservationAgricultureFormFields;
    // }

    // if (this.fieldSurveyAssessment && this.fieldSurveyAssessment.conservationAgricultureFormFields) {
    //   this.conservationAgricultureFormFields = this.fieldSurveyAssessment.conservationAgricultureFormFields;
    // }
  },

  computed: {
    customerUuid() { return this.$route.query.uuid; },
    farmsCount() { return this.$store.getters.farmsForCustomers(this.customerUuid).length; },
    customer() { return this.$store.getters.customer(this.$route.query.uuid); },
    complete() { return [] },
    crops() { return this.$store.getters.crops; },
    fieldUuid() { return this.$route.query.fieldUuid; },
    field() { return this.customer.fields ?this.customer.fields.find( f => f.uuid===this.fieldUuid ) : null; },
    isConservationAgricultureFormFieldsValid() {
      if (this.conservationAgricultureFormFields) {
        // Check for the presence of required fields
        const requiredFields = ['originOfSeed', 'originOfFertilizer', 'fertlizerBatchCode', 'seedBatchCode', 'mainCrop'];
        const missingRequiredFields = requiredFields.filter(field => !this.conservationAgricultureFormFields[field]);

        if (missingRequiredFields.length > 0) {
          return false;
        }

        // Check if coverCrop.answer is true and coverCrop.legumesSelected is not empty
        if (
          this.conservationAgricultureFormFields.conservationTechnique.lowTill.answer === false &&
          this.conservationAgricultureFormFields.conservationTechnique.coverCrop.answer === false &&
          this.conservationAgricultureFormFields.conservationTechnique.basinMethod.answer === false &&
          this.conservationAgricultureFormFields.conservationTechnique.mulching.answer === false
        ) {
          return false;
        }

        if (
          this.conservationAgricultureFormFields.coverCrop &&
          this.conservationAgricultureFormFields.coverCrop.answer === true &&
          this.conservationAgricultureFormFields.secondaryCrop &&
          this.conservationAgricultureFormFields.secondaryCrop.length === 0
        ) {
          return false;
        }

        // If all conditions are met, return true
        return true;
      }
      return false;
    },
    fieldAssessment(){
      const assessments = this.$store.getters.assessments.filter((obj) => obj.fieldUuid === this.fieldUuid && obj.customerUuid === this.customerUuid);
      return assessments.length > 0 ? assessments[0] : null;
    }
  },
  methods: {
    saveVisibleMitigationAndGoToMapField() {
      
      this.$refs.conservationAgricultureStep.validate()
      // this.fieldSurveyAssessment = this.fieldAssessment ? this.fieldAssessment : this.fieldSurveyAssessment

      if (this.conservationAgricultureFormFields) {
        let cropIds = [this.conservationAgricultureFormFields.mainCrop]
        if (this.conservationAgricultureFormFields.secondaryCrop) {
          cropIds.push(this.conservationAgricultureFormFields.secondaryCrop)
        }
        this.fieldSurveyAssessment.conservationAgricultureFormFields = this.conservationAgricultureFormFields
        const field_name = `Portion ${(this.farmsCount+1)}`
        const fieldDetails = {
          "fieldType":    this.conservationAgricultureFormFields.fieldType,
          "customerId":   this.customer.id,
          "customerUuid": this.customerUuid,
          "uuid":         this.fieldUuid,
          "payload":      this.conservationAgricultureFormFields,
          "name":         field_name,
          "cropIds":      cropIds,
          "cropId":       this.conservationAgricultureFormFields.mainCrop
        }
        this.$store.dispatch('upsertFarm', fieldDetails);
      }   
      this.fieldSurveyAssessment.customerUuid = this.customerUuid
      this.fieldSurveyAssessment.assessmentType = 'PortionSurvey'
      this.$store.commit('upsertAssessment', this.fieldSurveyAssessment)
      
      this.toFieldMapping();
    },

    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    toFieldMapping() {
      this.$router.push({ name: 'FieldMap', query: { uuid: this.customerUuid, fieldUuid: this.fieldUuid, isFarm: true } });
    },
  },
  mixins: [fields],
}
</script>

<style scoped>
</style>


