<template>
  <v-card class="elevation-16 mx-auto" >

    <v-card-title class="pb-0">
      {{title}}
      <v-spacer />
      <v-icon dense @click="showDescription=!showDescription">mdi-information-outline</v-icon>
    </v-card-title>
    <v-card-text>
      <span v-if="showDescription">{{description}}</span>

      <div class="text-center mt-0 pt-0">
        <StarRating v-model="rating" @input="handleInput" />
      </div>
    </v-card-text>

  </v-card>
</template>


<script>
  import StarRating from '@/components/StarRating';

  export default {
    components: { StarRating },
    props: ['value', 'title', 'description'],
    data: () => ({
      rating: 0,
      showDescription: false,
    }),
    watch: {
      value: {
        immediate: true,
        handler(to) { this.rating = to; }
      },
    },
    methods: {
      handleInput() {
        this.$emit('input', this.rating);
      },
    },
  }
</script>