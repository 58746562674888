<template>
  <v-card>
    <l-map :bounds="allLatLngs" :style="styles">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-polygon  v-for="polygon in filteredCoords" :lat-lngs="latlngs(polygon.coordinates)" color="green" @click="redirectTo(polygon)">
        <l-tooltip :content="polygon.caption" />
      </l-polygon>
    </l-map>
  </v-card>
</template>

<script>

  import L from 'leaflet';
  import { LMap, LTileLayer, LMarker, LTooltip, LPolygon } from 'vue2-leaflet';

  export default {

    components: { LMap, LTileLayer, LMarker, LTooltip, LPolygon },

    props: ['coords', 'height'],

    data() {
      return {
        url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
        attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }
    },

    computed: {
      styles() {
        return {
          'width': this.width || '100%',
          'height': this.height || '300px',
        }
      },
      filteredCoords() { return this.coords.filter( e => e.coordinates && e.coordinates.length>0 ) },
      allLatLngs() {
        let ll = [];
        this.filteredCoords.forEach( e => ll.push(this.latlngs(e.coordinates)) );
        return ll.filter(Boolean).flat(1);
      },

    },

    methods: {
      latlngs(coordinates) {
        if (coordinates) {
          return coordinates.map( e => [ e.latitude, e.longitude ] )
        } else {
          return null;
        }
      },
      redirectTo(polygon) { this.$router.push({ name: 'Field', query: { partnerIdentifier: polygon.partnerIdentifier, id: polygon.fieldId } }); },
    }
  };

</script>
