<template>
  <v-card v-if="customer">
    <v-card-title>
      <span class="hidden-xs-only">Actions and Recommendations</span>
      <span class="hidden-sm-and-up">Actions</span>
      <v-spacer />
      <v-btn color="primary" small dense :to="{name: 'CreateAction', query: { partnerIdentifier: customer.partnerIdentifier } }" class="ml-1">New</v-btn>
    </v-card-title>

    <v-card-text>
      <span v-if="farmerActions.length===0">None.</span>
      <v-simple-table v-else>
        <template v-slot:default>
          <tbody>
            <tr v-for="action in farmerActions" >
              <td class="text-left"><Time :time=action.createdAt format="date" /></td>
              <td class="text-left">{{ action.subject }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>


<script>

  import Time from '@/components/Time';

  export default {

    components: { Time },

    props: ['customer'],

    computed: {
      farmerActions() { return this.$store.getters.farmerActions(this.customer.partnerIdentifier); },
    },

  }
</script>
