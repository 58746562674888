<template>
  <PageHeader title="Scan" :subtitle="customer.name" icon="mdi-camera">
    
    <v-container fluid>

      <v-row dense>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.payslip_one" type="payslip" :typeId="customer.uuid" label="Current Payslip" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.payslip_two" type="payslip" :typeId="customer.uuid" label="Previous Payslip" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.bank_statement" type="bank_statement" :typeId="customer.uuid" label="Bank Statement" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.debit_orders"   type="direct_debits"  :typeId="customer.uuid" label="Direct Debit" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.bank_other_1"   type="bank_statement" :typeId="customer.uuid" label="Other" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.bank_other_2"   type="bank_statement" :typeId="customer.uuid" label="Other" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.id_card_front" type="id_card_front" :typeId="customer.uuid" label="NIDA Front" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.id_card_back" type="id_card_back" :typeId="customer.uuid" label="NIDA Back" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.work_id" type="work_id" :typeId="customer.uuid" label="Work ID Card" width="100%" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <PhotoInput v-model="customer.selfie" type="selfie" :typeId="customer.uuid" label="Photo" width="100%" class="mb-2" />
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';

import PageHeader from '@/components/PageHeader';

import PhotoInput from "@/components/PhotoInput";


export default {

  components: { PageHeader, PhotoInput },

  data() {
    return {
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

  watch: {
  },

  methods: {
  },

  mounted() {
  },

  mixins: [lookups],

}
</script>
