<template>
  <v-form ref="basicForm" v-model="valid" class="text-left" >

    <v-row>
      <v-col cols="12" md="1">
        <TitleInput v-model="customer.title" required @change="changeTitle" />
      </v-col>
      <v-col cols="12" sm="4">
        <NameInput v-model="customer.first_name" :label="$t('First Name')" required />
      </v-col>
      <v-col cols="12" sm="3">
        <NameInput v-model="customer.middle_name" :label="$t('Middle Name')" />
      </v-col>
      <v-col cols="12" sm="4">
        <NameInput v-model="customer.surname" :label="$t('Surname')" required />
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="3">
        <IdentityInput v-model="customer.demographic.identity" defaultType="national_id" :selectable="false" required @input="idChanged" />
      </v-col>
      <v-col cols="12" md="3">
        <MsisdnInput v-model="customer.msisdn" required />
      </v-col>
      <v-col cols="12" md="3">
        <MsisdnInput v-model="customer.alternative_msisdn" label="Alternate Telephone" />
      </v-col>
      <v-col cols="12" md="3">
        <EmailInput v-model="customer.email" />
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="6">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-checkbox v-model="customer.demographic.marital_status" label="Customer is Married" @change="married" false-value="single" true-value="married" />
            </v-col>
            <v-col cols="12">
              <v-select :items="sources" label="How did you hear about Ultimate Finance?" v-model="customer.lead_source" :rules="[(v) => !!v || 'Required']" />
            </v-col>
            <v-col cols="12" class="d-flex align-center" v-if="customer.lead_source=='Referred'">
              <CustomerSearchInput label="Referred By" v-model="customer.referred_by" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6">
        <MapInput ref="map" v-model="customer.home_location" label="Home Location" required style="width: 100%;" />
      </v-col>
    </v-row>

  </v-form>
</template>

<script>
import TitleInput from "@/components/TitleInput";
import MsisdnInput from "@/components/MsisdnInput";
import IdentityInput from "@/components/IdentityInput";
import NameInput from "@/components/NameInput";
import EmailInput from "@/components/EmailInput";
import GenderInput from "@/components/GenderInput";
import DateInput from "@/components/DateInput";
import CustomerSearchInput from "@/components/CustomerSearchInput";
import MapInput from "@/components/MapInput";


export default {

  components: { TitleInput, NameInput, MsisdnInput, IdentityInput, EmailInput, GenderInput, DateInput, CustomerSearchInput, MapInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
      sources: [ 'Direct Marketing', 'Referred', 'Radio', 'Social Media', 'Website', 'Word of Mouth', 'Other' ],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
  },


  methods: {
    validate() {
      this.$refs.basicForm.validate();
    },
    changeTitle(to) {
      if (to=='mr') { this.customer.gender = 'male'; }
      if (['mrs', 'ms'].includes(to)) { this.customer.gender = 'female'; }
    },
    married() {
      if (this.customer.married) {
        this.customer.nextOfKin.relationship = 'spouse';
        this.customer.nextOfKin.title = ( this.customer.title==='mr' ? 'mrs' : 'mr' );
      } else {
        this.customer.nextOfKin.relationship = undefined;
      }
    },
    idChanged(to) {
      if (to) {
        console.log(to);
        if (to.gender) { this.customer.demographic.gender = to.gender; }
        console.log(to.gender);
        if (to.birthdate) { this.customer.demographic.birthdate = to.birthdate; }
        console.log(to.birthdate);
      }
    },
    invalidateMap() {
      this.$refs.map.invalidate();
    },
  },

}
</script>