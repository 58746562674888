<template>

  <v-container fluid class="text-left pa-0">

    <v-row class="mb-1" v-if="anyRecent">
      <v-col cols="12" class="pa-0">
        <v-btn v-for="recent in recentCustomers" color="primary" @click="redirectTo(recent)" class="ma-1">
          {{recent.name}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Search</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1">
      <v-col cols="10">
        <v-autocomplete
          v-model="customer"
          :items="customers"
          :search-input.sync="search"
          prepend-icon="mdi-database-search"
          item-text="name"
          item-value=""
          return-object
          style="min-width: 100px; max-width: 100%;"
          v-if="anyCustomers"
          @change="redirectTo(customer)"
        ></v-autocomplete>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Or, back</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pa-0">
        <v-btn text outlined @click="redirectTo(undefined)">
          Cancel
        </v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>
<script>
  export default {
    props: ['value'],
    data() {
      return {
        target: undefined,
        customer: null,
        search: null,
      }
    },
    computed: {
      customers() { return (this.$store.getters.customers || []).sort( (a,b) => ((a.name || '') > (b.name || '') ? 1 : -1) ); },
      recentCustomers() { return (this.$store.getters.searchedCustomers || []).sort( (a,b) => ((a.name || '') > (b.name || '') ? 1 : -1) ); },
      anyCustomers() { return (this.customers || []).length>0 },
      anyRecent() { return (this.recentCustomers || []).length>0 },
    },
    methods: {
      redirectTo(customer) {
        let r = undefined;
        if (customer) {
          let query = { 'uuid': customer.uuid };
          r = { ...this.value };
          r.filter = undefined;
          r.query = query;
        }
        this.$emit('input', r);
        this.$store.dispatch('searchedCustomers', customer)
      },
    }
  }
</script>