<template>
  <div id="canvasWrapper">
    <canvas
      @mousedown="startPainting"
      @mouseup="finishedPainting"
      @touchstart="touchStart"
      @touchend="touchEnd"
      @touchmove="touchMove"
      @mousemove="draw"
      height="100vh"
      width="100vw"
      id="canvas"
    ></canvas>
  </div>

</template>

<script>

export default {
  props: ['signed'],
  data () {
    return {
      field: undefined,
      vueCanvas:null,
      painting:false,
      canvas:null,
      ctx:null,
      url: null
    }
  },

  mounted() {
    this.canvas = document.getElementById("canvas");
    this.ctx = this.canvas.getContext("2d");
    this.canvas.height = window.innerHeight;
    this.canvas.width = window.innerWidth;
  },

  methods: {
    downloadImg() {
      this.url = this.canvas.toDataURL()
      return this.url
    },
    updateFieldSize(size) {
      this.field.fieldSize.landSize = size.landSize;
      this.field.fieldSize.unit = size.unit;
    },
    clearDrawing(e) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    startPainting(e) {
      this.painting = true;
      this.draw(e)
    },
    touchEnd(e) {
      this.painting = false;
    },
    touchStart(e) {
      this.painting = true;
    },
    touchMove(e) {
      const touch = e.touches[0]
      this.drawCoord(touch.clientX,touch.clientY)
    },
    finishedPainting() {
      this.painting = false;
      this.ctx.beginPath()
    },
    drawCoord(coordX, coordY) {
      if(!this.painting){
        return
      }
      this.ctx.lineWidth = 10;
      this.ctx.lineCap ="round"

      this.ctx.lineTo(coordX, coordY)
      this.ctx.stroke()
      this.ctx.beginPath()
      this.ctx.moveTo(coordX, coordY)
    },
    draw(e) {
      if(!this.painting){
        return
      }
      this.ctx.lineWidth = 10;
      this.ctx.lineCap ="round"
      this.ctx.lineTo(e.clientX,e.clientY)
      this.ctx.stroke()
      this.ctx.beginPath()
      this.ctx.moveTo(e.clientX,e.clientY)
    }
  },
}
</script>

<style scoped>
  #canvas {
    border: 3px solid black;
    height: 90vh;
    width: 100vw;
    display: block;
  }
</style>