<template>
  <v-form ref="basicForm" v-model="valid" v-if="!!formFields">
    <DateInput
        v-model="formFields.fieldRegistrationDate"
        label="Field registration date"
        required
    />
        <v-row>
      <v-col cols="12" md="6">
        <SelectInput
            :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,20, '20+']"
            v-model="formFields.peopleSupportedByField.answer"
            :label="formFields.peopleSupportedByField.label"
            required />
      </v-col>
      <v-col cols="12" md="6">
        <SelectInput
            v-model="formFields.ownership.answer"
            :items="[
                'Not yet registered',
                'Family Plot (shared with Family)',
                'Self owned',
                ]"
            required
            :label="formFields.ownership.label"
        />

      </v-col>

    </v-row>
    <v-col cols="12" md="6" class="d-flex">
      <div>
        <v-radio-group
            v-model="formFields.evidenceFieldExpansionOntoElephantCorridor"
            label="Evidence of field expansion onto elephant corridor"
        >
          <v-radio
              label="Yes"
              :value="true"
          ></v-radio>
          <v-radio
              label="No"
              :value="false"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-col>

    <slot>
      <div class="text-left pb-2">
        <v-btn color="primary" @click="saveFieldCorridorAwareness" :disabled="!isFieldCorridorAwarenessFormFieldsValid" > Save</v-btn>
      </div>
    </slot>
  </v-form>
</template>

<script>
import TextInput from "@/components/TextInput";
import DateInput from "@/components/DateInput";
import StarRating from "@/components/StarRating";
import SelectInput from "@/components/SelectInput";

export default {
  components: {
    TextInput,
    DateInput,
    StarRating,
    SelectInput
  },
  props: {
    value: Object,
    fieldSurveyAssessment: Object
  },
  data() {
    return {
      valid: false,
      formFields: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!!to) {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },
  computed: {
    customerUuid() { return this.$route.query.uuid; },
    fieldsCount() { return this.$store.getters.fieldsForCustomers(this.customerUuid).length; },
    fieldUuid() { return this.$route.query.farmUuid; },
    customer() { return this.$store.getters.customer(this.$route.query.uuid); },
    isFieldCorridorAwarenessFormFieldsValid() {
      return this.formFields
          && this.formFields.evidenceFieldExpansionOntoElephantCorridor !== null
          && !!this.formFields.fieldRegistrationDate
    }
  },

  methods: {
    validate() {
      this.$refs.basicForm.validate();

    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it's zero-based
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    saveFieldCorridorAwareness() {
      this.validate();
      this.fieldSurveyAssessment.fieldCorridorAwarenessFormFields = this.formFields
      const field_name = `Field ${(this.fieldsCount+1)}`
      const isCaAware = !this.formFields.evidenceFieldExpansionOntoElephantCorridor
      this.$store.dispatch('upsertField', {
        customerUuid: this.customerUuid,
        customerId: this.customer.id,
        name: field_name,
        fieldType: "Field",
        caAware: isCaAware,
        uuid: this.fieldUuid,
        ownership: this.formFields.ownership.answer,
        registrationDate: this.formFields.fieldRegistrationDate,
        peopleSupported: this.formFields.peopleSupportedByField.answer
      });
      this.$emit('next')
    },
  },
}
</script>

<style scoped>

</style>
