<template>
  <span v-if="number">
    <IdentityNumberDefault v-if="country==='default'" :demographic="demographic" :number="number" :type="type" />
    <IdentityNumberZa v-if="country==='ZA'" :demographic="demographic" :number="number" :type="type" />
  </span>
</template>

<script>

import IdentityNumberDefault from '@/components/IdentityNumberDefault';
import IdentityNumberZa from '@/components/IdentityNumberZa';

export default {

  components: { IdentityNumberDefault, IdentityNumberZa },

  props: ['demographic'],

  computed: {
    identityNumber() { return ((this.demographic || {}).identity || {}) },
    country() {
      // CO the number is un formatted
      let c = (this.demographic || {}).country;
      if ( !['ZA'].includes(c) ) { c="default"; };
      return c;
    },
    number() { return this.identityNumber.number; },
    type() { return this.identityNumber.type; },
  },

};
</script>
