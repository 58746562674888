<template>
  <div>
    <PageHeader
      :title="customer.name"
      icon="mdi-flower-outline"
      @back="toCustomers"
      @save="save"
      @validate="validate"
    />

    <Stepper :tabs="tabs" :captions="captions" :complete="complete" @position="stepChange($event)" ref="stepper" vertical>
      <template v-slot:FarmersNeeds>
        <FarmersNeeds
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form7"
        v-model="formFields.farmersNeeds"
        @nextStep="nextStep"
        @previousStep="previousStep"
        @valid="validity['FarmersNeeds']=$event"
      />
      </template>

      <template v-slot:OrderConfirmation>
        <OrderConfirmation :total="formFields.farmersNeeds.total" :items="formFields.farmersNeeds.tableResults || []" :submit="submitApplication" :capital="formFields.farmersNeeds.total" @nextStep="submitDetails" @previousStep="previousStep" />
      </template>

      <!-- <template v-slot:ThankYou>
        <ThankYou />
      </template> -->
    </Stepper>
    <!-- end stepper -->
  </div>
</template>

<script>
import Stepper from "@/components/Stepper";
import PageHeader from "@/components/PageHeader";
import FarmersNeeds from "@/views/partners/nile_ug/components/FarmersNeeds";
import ThankYou from "@/views/partners/nucafe_ug/landing/ThankYou.vue";
import OrderConfirmation from "@/views/partners/nucafe_ug/landing/OrderConfirmation.vue";

export default {

  name: "NucafeOrderInputs",

  components: {
    PageHeader,
    FarmersNeeds,
    ThankYou,
    Stepper,
    OrderConfirmation
  },

  data: function () {
    return {
      formFields: {
        farmersNeeds: {
          total: 0
        }
      },
      step: 1,
      tabs: ['FarmersNeeds', 'OrderConfirmation'],
      captions: {
        'FarmersNeeds': 'Order Inputs',
        'OrderConfirmation': 'Order Confirmation'
      },
      validity: {
        'FarmersNeeds': false,
        'OrderConfirmation': false
      },
      firstStep: true,
      lastStep: false,
      rules: {
        required: (value) => (value==0 || !!value) || "Required",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
      },
    };
  },
  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    invalid() { return this.tabs.filter(e => (this.validity[e]===false) ); },
    valid() { return this.invalid.length==0; },
    customerUuid() { return this.$route.query.uuid; },
    isStart() { return this.step == 1; },
    isLastStep() { return this.step == 3; },
  },
  watch: {
    step() { this.save(); },
  },
  methods: {
    next() { this.step += 1; },
    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },
    previous() { this.step -= 1; },
    concludeLater() {
      this.save();
      this.toCustomers();
    },
    nextStep() {
      this.$refs.stepper.nextStep();
    },
    submitDetails() {
      this.save();
      this.$router.push({name: 'OrderInputs'});
      // this.$refs.stepper.nextStep();
    },
    previousStep() {
      this.save();
      this.$refs.stepper.previousStep();
    },
    toCustomers() {
      this.$router.push({ name: 'Customers' });
    },
    validate() {
      // validate the different forms here
      this.$refs.form7.validate();
    },

    submitApplication() {
      this.customer.nileUgApplication = true;
      this.nextStep();
    },

    save() {
      this.customer.farmersNeeds = this.formFields.farmersNeeds;
      this.formFields.farmersNeeds.tableResults.forEach( e => {
        const order = {
          uuid: e.uuid,
          createdAt: new Date().toISOString(),
          sku: e.code,
          description: e.description,
          price_per_unit: (parseFloat(e.price_per_unit) || 0),
          value: (parseFloat(e.cost) || 0),
          quantity: (parseInt(e.quantity) || 0),
          status: 'placed',
          customerName: this.customer.name,
          customerUuid: this.customer.uuid,
          location: this.location,
          capturedById: this.fieldAgentId,
        }
        if (order.quantity > 0) { this.$store.dispatch('addOrder', order) }
      })
    },
  },

};
</script>


<style>
</style>
