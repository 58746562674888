<template>
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :rules="allRules"
        v-on="on"
        v-bind:disabled="disabled"
        v-bind:hint="hint"
        persistent-hint
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :active-picker.sync="activePicker"
      :min="min"
      :max="max"
      @change="saveVisitDate"
    ></v-date-picker>
  </v-menu>

</template>

<script>
  import parseDate from '@/mixins/parseDate';

  export default {

    props: {
      value: [String, Date],
      required: Boolean,
      rules: Array,
      label: String,
      min: String,
      max: String,
      year: Boolean,
      disabled: Boolean,
      hint: String,
    },

    data: () => ({
      activePicker: null,
      date: null,
      dateMenu: false,
    }),

    computed: {
      allRules() {
        return (!!this.rules ? [this.requiredRule, ...this.rules] : [this.requiredRule]);
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            if ((typeof to === 'string') && ( to.length==10 )) {
              this.date = to;
            } else {
              const t = this.parseDateTime(to);
              this.date = t.toFormat('yyyy-MM-dd');
            }
          } else {
            this.date = null;
          }
        },
      },
      dateMenu(val) {
        val && this.year && setTimeout(() => (this.activePicker = 'YEAR'));
      },
    },

    methods: {
      saveVisitDate(date) {
        this.$refs.dateMenu.save(date)
        this.$emit('input', date);
      },
      requiredRule(value) {
        let r = [
          v => !this.required || !!v || 'Required',
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },

    mixins: [parseDate],
  };
</script>
