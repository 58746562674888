<template>
  <v-app-bar dense app :color="colorCode">
    <template v-if="partnerCode === 'eco_bw'">
      <v-app-bar-nav-icon @click="toHome">
        <Logo />
      </v-app-bar-nav-icon>
    </template>
    <template v-else>
      <v-app-bar-nav-icon class="hidden-sm-and-up white--text" @click="toHome" />
      <v-app-bar-nav-icon class="hidden-xs-only" @click="toHome">
        <Logo />
      </v-app-bar-nav-icon>
    </template>

    <v-spacer></v-spacer>

    <template v-if="live">
      <SearchBox :displaySearchOnRoutes="displaySearchOnRoutes" class="mr-2" />
      <HeaderBarRoutes />
    </template>

  </v-app-bar>

</template>

<script>
import SearchBox from '@/components/SearchBox';
import HeaderBarRoutes from '@/components/HeaderBarRoutes';
import Logo from '@/components/HeaderBarLogo';


export default {
  components: { SearchBox, HeaderBarRoutes, Logo },
  props: ['displaySearchOnRoutes'],
  computed: {
    live() { return this.$store.getters.authenticated; },
    partnerCode() { return this.$store.getters.settings.partnerCode; },
    colorCode() {
      let color = "info";
      const code = this.partnerCode;
        if(code == "nile_ug"){
          color = "primary";
        } else if (code=='ultimate_tz' || code=='two_tz') {
          color = 'rgba(0,79,160,0.1)';
        } else if (code==='eco_bw') {
          color = 'rgb(66,82,43)';
        }
        return color;
      }
  },
  methods: {
    logout() { this.$store.dispatch('logout'); },
    toHome() {
      if (this.$route.name==='Home') {
        this.$router.replace({ name: "Home", query: { f: 'h'} })
      } else {
        this.$router.push({ name: 'Home' });
      }
    },
  }
};
</script>

