<template>
  <PageHeader title="Milk Collection" >
    <div id="printTarget">

      <div class="d-flex flex-column" style="min-height: 78vh;">
        <div>
          <div class="d-flex justify-space-between mb-4 print-hide"  v-if="isBluetoothAvailable">
            <template v-if="bluetoothDevice">
              <div class="d-flex justify-space-between" style="width: 100%;">
                <div>Connected:</div>
                <div>{{connectedBluetoothDevice.device.name}}</div>
                <div>
                  <v-btn
                      color="primary"
                      class="mr-4 print-hide"
                      small
                      @click="bluetoothDisconnectServer"
                  >
                    <!--              todo make disconnect work-->
                    disconnect
                  </v-btn>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="d-flex justify-space-between print-hide" style="width: 100%;">
                <div>Bluetooth is available</div>
                <div>
                  <v-btn
                      color="primary"
                      class="mr-4 print-hide"
                      @click="bluetoothScan"
                      small
                  >
                    connect
                  </v-btn>
                </div>
                <div>
                  <v-btn plain @click="toggleDemoReadings" x-small>{{(btDemoReadings ? 'Stop' : 'Demo')}}</v-btn>
                </div>
              </div>
            </template>

          </div>

          <template v-else>
            <div class="d-flex justify-space-between print-hide">
              <div class="print-hide">Bluetooth is <strong>not</strong> available.</div>
              <div class="print-hide"><v-btn plain @click="toggleDemoReadings" x-small>{{(btDemoReadings ? 'Stop' : 'Demo')}}</v-btn></div>
            </div>
          </template>

          <div>
            <div class="text-left">Can Number</div>
            <div>
              <div class="mb-4">
                <v-text-field
                    class="print-hide"
                    hide-details="auto"
                    outlined
                    v-model="canNumber"
                ></v-text-field>
              </div>
            </div>

            <div class="d-flex " style="min-height: 72px">
              <div>
                <div class="d-flex justify-content-center text--secondary">
                  Scale (kg)
                </div>
                <div class="d-flex justify-content-center text--secondary text-h2">
                  {{btWeighScaleRawValue.toFixed(2)}}
                </div>
              </div>

              <div class="ml-auto">
                <div class="d-flex justify-space-between">
                  <div>Farmer (kg)</div>
                  <div><v-btn plain @click="resetLastReading" x-small>Clear</v-btn></div>
                </div>
                <h1 class="d-flex text-right justify-content-center text-h2">
                  {{farmerScaleValue.toFixed(2)}}
                </h1>
              </div>
            </div>

            <div class="mb-4">
              <v-combobox
                v-model="farmer"
                :search-input.sync="search"
                :items="farmers"
                item-text="search"
                return-object
                label="Farmer"
                style="min-width: 100px; max-width: 100%;"
              />
            </div>

          </div>

          <div>
            <v-btn
                color="success"
                class="mr-4 print-hide"
                @click="submitAndPrint"
                :disabled="!valid"
                block
            >
              Submit and print
            </v-btn>
          </div>

        </div>

        <div class="mt-2 pa-2 align-self-end" style="width: 100%;">
          <hr />

          <div class="d-flex justify-space-between justify-content-between">
            <p class="dairy_ke-p" style="margin: 0">Session</p>
            <p class="dairy_ke-p" style="margin: 0;text-align: right;">{{amPm}}</p>
          </div>

          <div class="d-flex justify-space-between justify-content-between">
            <p class="dairy_ke-p" style="margin: 0">Cumulative</p>
            <p class="dairy_ke-p" style="margin: 0;text-align: right;">
              <NumberFormatter v-model="cumulative" unit="kg" />
            </p>
          </div>

          <div class="d-flex justify-space-between justify-content-between">
            <p class="dairy_ke-p" style="margin: 0">Route</p>
            <p class="dairy_ke-p" style="margin: 0;text-align: right;">{{route}}</p>
          </div>

          <div class="d-flex justify-space-between justify-content-between">
            <p class="dairy_ke-p" style="margin: 0">Time</p>
            <p class="dairy_ke-p" style="margin: 0;text-align: right;">
              <Time :time="sessionTime" format="HH:mm" />
            </p>
          </div>
        </div>
        <v-divider></v-divider>

      </div>

    </div>

  </PageHeader>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PrintLayoutMixin from "@/mixins/PrintLayoutMixin";
import lookups from "@/mixins/lookups";
import scale from "@/mixins/scale";

import PageHeader from "@/components/PageHeader";
import Time from "@/components/Time";
import NumberFormatter from "@/components/NumberFormatter";

export default {

  components: { PageHeader, Time, NumberFormatter },

  data() {
    return {
      search: null,
      farmers: [],
      farmer: null,

      cumulative: null,
      collectorSettings: undefined,

      canNumber: null,

      sessionTime: new Date(),

      timer: null,      
      lastReading: null,
    }
  },

  computed: {
    valid() { return ( (!!this.canNumber) && ( (this.search || '').length>2  ) ) },
    amPm() { return (this.sessionTime.getHours() >= 12) ? "PM" : "AM"; },
    farmerScaleValue() { return (this.btWeighScaleRawValue - this.lastReading); },
    fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    route() { return ( this.collectorSettings ? this.collectorSettings.route : undefined ) },
    location() { return ((this.$store.getters.geolocation || {}).coords); },
  },


  watch: {
    
    search(to) {
      this.retrieveFarmers();
    },

    farmer(to) {
      this.cumulative = undefined;
      if (to && typeof(to)==='object') {
        this.remoteLookup('dairyFarmerCumulativeMilk', { uuid: to.uuid }).then( milk => this.cumulative = milk );
      }
    },

  },

  methods: {

    toggleDemoReadings() {
      this.btDemoReadings = !this.btDemoReadings;
    },

    resetLastReading() {
      this.lastReading = this.btWeighScaleRawValue;
    },

    async retrieveFarmers () {
      this.remoteLookup('searchDairyFarmers', { search: this.search }).then( results => this.farmers = results );
    },

    submitAndPrint() {
      const submission = {
        uuid: uuidv4(),
        customerUuid: (this.farmer ? this.farmer.uuid : null),
        farmer_reference: this.search,
        canNumber: this.canNumber,
        weight: this.farmerScaleValue,

        amPm: this.amPm,
        route: this.route,

        location: this.location,
        createdAt: this.sessionTime,
      }

      this.$store.dispatch('milkCollectionUpsert', submission);

      this.farmer = undefined;
      this.cumulative = undefined;
      this.resetLastReading();
      
    },

    // async printReceipt () {
    //   await this.printMixin()
    // },

  },


  mounted() {
    this.remoteLookup('dairyMilkCollectorSettings', { id: this.fieldAgentId }).then( r => this.collectorSettings = r );
    this.timer = setInterval(() => {
      this.sessionTime = new Date();
    }, 1000);
  },

  beforeDestroy() { clearInterval(this.timer) },

  mixins: [lookups, PrintLayoutMixin, scale],
}
</script>

<style scoped>
#weigh-scale-value {
  font-size: 48px;
}
.weigh-scale-value {
  font-size: 48px;
}
#printTarget {
  width: 100%;
}
</style>
