import { DateTime } from 'luxon';

export default {
  methods: {
    parseDate(source) {
      let t = this.parseDateTime(source);
      return ( t ? t.toJSDate() : null );
    },

    parseIsoDate(source, options) {
      let t = this.parseDateTime(source);
      return ( t ? t.toISO(options) : null );
    },

    // takes a source (Date, LuxonDate, number or string) and returns a Luxon DateTime
    parseDateTime(source) {
      let t = null;
      if (source) {
        if (typeof source === 'object') {
          if (source.isLuxonDateTime) {
            t = source;
          } else {
            t = DateTime.fromJSDate(source);
          }
        } else if (isNaN(source)) {
          t = DateTime.fromISO(source);
          if (!t.isValid) {
            t = DateTime.fromFormat(source, "yyyy-MM-dd HH:mm:ss z");
          }
        } else {
          let n = parseInt(source);
          if ((n>20200000) && (n<20400000)) {
            t = DateTime.fromISO(source);
          } else {
            if (source > Date.now()/10) {
              t = DateTime.fromMillis(source);
            } else {
              t = DateTime.fromSeconds(source);
            }
          }
        }
        return t;
      }

    },
  }
};