<template>
  <span class="text-no-wrap">
    <small class="hidden-xs-only">{{currency}}</small>
    <NumberFormatter :value="amount" />
  </span>
</template>



<script>
  import NumberFormatter from '@/components/NumberFormatter';

  export default {

    components: { NumberFormatter },

    props: {
      value: {
        type: Number,
      },
      cents: Boolean,
    },

    computed: {
      currency() { 
        let currency = this.$store.getters.currency;
        const code =  this.$store.getters.settings.partnerCode; 
        currency = code == "eco_bw" ? 'BWP': currency;
        return code == "nile_ug" ? 'UGX': currency;
      },
      amount() { return (this.value || 0)/(this.cents ? 100.0 : 1); },
    },

  }
</script>
