<template>
  <v-container fluid class="text-left pb-3">
    <h4 class="mb-3">Successfully ordered</h4>
    <p class="body2 text-left">
      Your delivery order has successfully been submitted
    </p>
    <v-row class="mt-4">
      <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="goHome">Home</v-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ThankYou",
  methods: {
    goHome() {
      this.$router.push({
        path: '/'
      })
    },
  },
};
</script>
