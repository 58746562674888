<template>

  <Value :label="label" :elevation="elevation" :color="color" :rounded="rounded" :highlight="highlight" :width="width" v-bind="$attrs" v-on="$listeners">
    <Time :time="value" format="d MMM yyyy" />
  </Value>

</template>

<script>
import Value from "@/views/instruments/components/Value"
import Time from "@/components/Time"

export default {

  components: { Value, Time },

  props: {
    value: [Date, String],
    label: String,
    highlight: [String, Boolean],
    cents: {
      type: Boolean,
      default: true,
    },
    width: String,
    color: String,
    elevation: {
      type: Number,
      default: 2,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },

}
</script>
