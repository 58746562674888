<template>
  <Livestock v-if="renderer==='livestock'" :activity='activity' :icon="icon" v-model="payload" />
  <Crop v-else-if="renderer==='crop'" :activity='activity' :icon="icon" v-model="payload" />
  <Poultry v-else-if="renderer==='poultry'" :activity='activity' :icon="icon" v-model="payload" />
</template>


<script>
  import Livestock from '@/views/farmers/components/ActivityLivestock';
  import Crop from '@/views/farmers/components/ActivityCrop';
  import Poultry from '@/views/farmers/components/ActivityPoultry';


  export default {
    components: { Livestock, Crop, Poultry },
    props: ['activity', 'type', 'icon', 'value'],

    data: () => ({
      payload: undefined,
    }),

    computed: {
      renderer() {
        let c = this.type;
        if (this.activity==='poultry') { c = 'poultry'; }
        return c;
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.payload = to; }
      },
      payload(to) { this.$emit('input', to); },
    },
  }
</script>
