<template>
  <v-stepper-content step="5">
    <v-form v-model="valid" ref="form5">
      <v-container fluid>
        <SeasonHarvestTable
            v-model="formFields.seasonTable"
        />
        <v-row class="mt-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid">Save & Continue</v-btn>
          <v-btn color="gray darken-3" @click="previous">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import SeasonHarvestTable from "@/views/partners/nile_ug/components/SeasonHarvestTable";
import {summarizeSeasonHarvestTableResults} from "@/views/partners/nile_ug/components/utils";

export default {
  name: "SeasonAndHarvest",
   emits:['input'],
  props: ["rules", "value", "next", "previous"],
  components: {
    SeasonHarvestTable
  },
  data() {
    return {
      valid: false,
      formFields: {},
      currentYear: new Date().getFullYear(),
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to || {};
      },
    },
    formFields(to) {
      this.$emit("input", to);
    },
  },

  computed: {
    isOnlySeasonLeft() {
      return this.formFields.seasonsAndHarvests.length == 1;
    },
    tableResults () {
      return summarizeSeasonHarvestTableResults(this.formFields)
    }
  },
  methods: {
    validate() {
      this.$refs.form5.validate();
    },
    validateAndContinue() {
      this.validate();
      this.next();
    },
    addSeason() {
      // get current year and reduce
      let prevYear = this.currentYear - 1;
      this.currentYear = prevYear;
      this.formFields.seasonsAndHarvests.push({
        year:prevYear,
        seasons:[]
      });
    },

    deleteSeason(yr) {
      const index = this.formFields.seasonsAndHarvests.findIndex((year) => year.year == yr);
      this.formFields.seasonsAndHarvests.splice(index, 1);
    },
    initSeason() {
      const year = new Date().getFullYear();
      this.formFields.seasonsAndHarvests.push({
        year,
        seasons:[]
      });
      this.currentYear = year;
    },
    reset() {
      const year = new Date().getFullYear();
      this.formFields.seasonsAndHarvests = [{
        year,
        seasons:[]
      }];
      this.currentYear = year;
    },
  },
};
</script>

<style>
</style>