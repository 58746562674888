<template>
  <v-container fluid>

    <v-row>
      <v-col cols="6" v-if="customer">
        <Widget title="Customer">
          <v-simple-table>
            <tbody>
              <Row title="Name"      :text="customer.name" bold="true" />
              <Row title="Telephone" :msisdn="customer.msisdn" />
              <Row title="Identity"  :text="customer.demographic.identity.number"  />
            </tbody>
          </v-simple-table>
        </Widget>
      </v-col>

      <v-col cols="6">
        <Widget title="Approval" v-if="approval">
          <v-simple-table>
            <tbody>
              <Row title="Created"   :time="approval.created_at" />
              <Row title="Request"   :text="approval.description" />
              <Row title="Status"    :status="approval.status"  />
            </tbody>
          </v-simple-table>
        </Widget>
      </v-col>    

      <v-col cols="6" v-if="customer">
        <Widget title="Product History">
          <History :customer="customer" />
        </Widget>
      </v-col>

    </v-row>


    <v-row v-if="images">
      <v-col cols="12">
        <div no-gutters v-if="images && images.length>0">
          <Images :images="images" />
        </div>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
  import lookups from '@/mixins/lookups';

  import ServerSideResult from '@/components/ServerSideResult';
  import Widget from '@/components/Widget';
  import Row from "@/components/Row";
  import Images from "@/components/Images";
  // import Time from "@/components/Time";
  // import Currency from "@/components/Currency";
  // import Instrument from "@/views/instruments/Instrument"
  // import CustomerFacingTransactions from "@/views/instruments/CustomerFacingTransactions"
  // import CustomerActions from "@/views/actions/CustomerActions"
  import History from "@/views/instruments/History"



  export default {

    components: { ServerSideResult, Widget, Row, Images, History },

    props: ['approval'],

    data: () => ({
    }),

    computed: {
      customer() { return this.$store.getters.customer(this.approval.customer_uuid || this.approval.customerUuid); },
      images() {
        let i = undefined;
        if (this.customer) {
          i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
          if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
          if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
          if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
        }
        return i;
      },
    },

    methods: {
    },
    
    mounted() {
    },

    mixins: [lookups],

  }
</script>
