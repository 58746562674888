<template>
  <PageHeader :title="title" icon="mdi-check-all">
    <template v-slot:buttons>
      <v-btn v-if="!working" @click="update('approved')" :disabled="disabled" color="primary" small dense class="ma-1"><v-icon dense>mdi-checkbox-marked-outline</v-icon> Approve</v-btn>
      <v-btn v-if="!working" @click="update('reject')"  :disabled="disabled" color="primary" small dense class="ma-1"><v-icon dense>mdi-close-outline</v-icon> Reject</v-btn>
      <v-progress-circular v-if="working" :value="15" indeterminate color="primary" ></v-progress-circular>
    </template>

    <ServerSideResult :test="approval" style="width: 100%;">
      <LoanRequest :approval="approval" v-if="form=='loanRequest'" />
      <CheckMeBackFirstLoanRequest :approval="approval" v-if="form=='checkMeBackFirstLoanRequest'" />
      <ClientOnboarding :approval="approval" v-else-if="form=='clientOnboarding'" />
      <NewEmployer :approval="approval" v-else-if="form=='newEmployer'" />
      <SalaryAdvanceRequest :approval="approval" v-else-if="form=='salaryAdvanceRequest'" />
      <Default     :approval="approval" v-else  />
    </ServerSideResult>
  </PageHeader>
</template>


<script>
  import lookups from '@/mixins/lookups';

  import PageHeader from "@/components/PageHeader";
  import ServerSideResult from '@/components/ServerSideResult';

  import Default from '@/views/approvals/components/Default'
  import LoanRequest from '@/views/approvals/components/LoanRequest'
  import CheckMeBackFirstLoanRequest from '@/views/approvals/components/CheckMeBackFirstLoanRequest'
  import ClientOnboarding from '@/views/approvals/components/ClientOnboarding'
  import NewEmployer from '@/views/approvals/components/NewEmployer'
  import SalaryAdvanceRequest from '@/views/approvals/components/SalaryAdvanceRequest'

  export default {

    components: {
      PageHeader,
      ServerSideResult,
      Default,
      LoanRequest,
      CheckMeBackFirstLoanRequest,
      ClientOnboarding,
      NewEmployer,
      SalaryAdvanceRequest
    },

    data: () => ({
      approval: undefined,
      working: false,
      updated: undefined,
    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      title() { return (this.approval ? this.approval.assessment_name : 'Approval'); },
      disabled() { return (this.approval ? (this.form==='default') : true ); },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      form() {
        let r = 'default';
        if ( this.title=='Salary Advance Request' || this.title=='Check Me Back Request' ) {
          r = 'loanRequest' 
        } else if (this.title=='Client Onboarding') {
          r = 'clientOnboarding'
        } else if (this.title=='Salary Advance First Loan Request') {
          r = 'salaryAdvanceRequest'
        } else if (this.title=='Check Me Back First Loan Request') {
          r = 'checkMeBackFirstLoanRequest'
        }else if (this.title=='New Employer') {
          r = 'newEmployer'
        } 
        return r;
      }
    },

    watch: {
      updated() {
        this.$router.push({ name: 'ApprovalsIndex' });
      }
    },

    methods: {
      update(status) {
        this.working = true;
        this.remoteLookup('approve_or_reject', {uuid: this.uuid, status: status}).then( done => this.updated = true ).catch(e => this.working = true);
      },
    },
    
    mounted() {
      this.remoteLookup('approval', {uuid: this.uuid}).then( approval => this.approval = approval.approval ).catch(e => this.approval = null);
    },

    mixins: [lookups],

  }
</script>
