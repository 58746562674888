<template>
  
  <v-container fluid>

    <v-row class="ma-4 d-none d-sm-block" v-if="choices.includes('salary_advance')">
      <v-col cols="12">
        <v-btn class="text-h3 pa-8" color="primary" style="width: 100%;" small dense @click="loanType='salary_advance'">Salary Advance</v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-4 d-none d-sm-block" v-if="choices.includes('check_me_back')">
      <v-col cols="12">
        <v-btn class="text-h3 pa-8" color="primary" style="width: 100%;" small dense @click="loanType='check_me_back'">Check Me Back</v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-4 d-sm-none" v-if="choices.includes('salary_advance')">
      <v-col cols="12">
        <v-btn color="primary" style="width: 100%;" small dense @click="loanType='salary_advance'">Salary Advance</v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-4 d-sm-none" v-if="choices.includes('check_me_back')">
      <v-col cols="12">
        <v-btn color="primary" style="width: 100%;" small dense @click="loanType='check_me_back'">Check Me Back</v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>

export default {

  data() {
    return {
      loanType: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
    choices() {
      let r = [];
      if ( this.customer.employer.government ) {
        r.push('salary_advance');
      } else if ( this.customer.employer.category==='bank' ) {
        r.push('check_me_back');
      } else {
        r.push('salary_advance');
      }
      if (r.length==1) { this.loanType=r[0]; }
      return r;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.loanType = to; }
    },
    loanType(to) {
      this.$emit('input', to);
    },
  },

}
</script>
