<template>
  
    <v-container fluid v-if="instrument">

      <v-row>
        <v-col class="text-h5">
          <InstrumentUltimateTzCheckMeBack :instrument="instrument" highlight="Available Balance" />
        </v-col>
      </v-row>

      <v-row>
        <CheckMeBackInput :customer="customer" :topup="instrument.available_balance/100.0" />
      </v-row>

    </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import CheckMeBackInput from "@/views/partners/ultimate_tz/CheckMeBackInput";
import ServerSideResult from "@/components/ServerSideResult";
import InstrumentUltimateTzCheckMeBack from "@/views/instruments/InstrumentUltimateTzCheckMeBack"


export default {

  components: {  CheckMeBackInput, ServerSideResult, InstrumentUltimateTzCheckMeBack },

  props: ['customer', 'instrumentId', 'instrument'],

  data() {
    return {
      valid: false,
      cash: 0,
      cashReceiptNumber: undefined,
      topupProcess: undefined,
      minPrincipal: 10000,
    }
  },

  computed: {
    // topupAmount() { return ((this.instrument.available_balance || 0)/100.0); },
    // mayTopup() { return this.valid && (this.cash>=this.minPrincipal) && ( (this.instrument) && (this.cash<=this.topupAmount ) ) },
  },

  watch: {
    // instrument(to) {
    //   if (to && this.cash==0) {
    //     this.cash = this.topupAmount;
    //   }
    // }
  },

  methods: {
    // validate() { this.$refs.topupForm.validate(); },
    topup() {
      this.disbursing = false;
      this.remoteLookup('instrumentUltimateTzCheckMeBackTopup', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
        cash: this.cash,
        receiptNumber: this.cashReceiptNumber,
      }).then( e => this.topupProcess = 'done' ).catch(e => this.topupProcess = null);
    },
  },

  mixins: [lookups],

}
</script>
