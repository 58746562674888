<template>
  <code>
    {{number}}
  </code>
</template>

<script>

export default {

  props: ['demographic', 'number', 'type'],

};
</script>
