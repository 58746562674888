<template>
  <div>
    <v-progress-circular indeterminate color="primary" :size="20" :width="2" v-if="status==='loading'" />
    <i v-else-if="status==='offline'">Not available in offline mode.</i>
    <slot v-else-if="status==='loaded'"></slot>
  </div>
</template>

<script>

export default {

  props: ['test'],

  computed: {
    status() {
      let s = 'loaded';
      if (this.test===null) { s = 'offline'; }
      if (this.test===undefined) { s = 'loading'; }
      return s;
    }
  }

};
</script>
