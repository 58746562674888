<template>
  <span>

    <v-icon small v-if="gender=='female'">mdi-gender-female</v-icon>
    <span v-if="gender=='female'">{{$t('female')}}</span>

    <v-icon small v-if="gender=='male'">mdi-gender-male</v-icon>
    <span v-if="gender=='male'">{{$t('male')}}</span>

  </span>
</template>

<script>

export default {

  props: ['gender'],

};
</script>


<i18n>
{
  "en": {
    "male": "Male",
    "female": "Female"
  },
  "es": {
    "male": "Hombre",
    "female": "Mujer"
  },
  "sw": {
    "male": "Mwanaume",
    "female": "Mwanamke"
  }
}
</i18n>
