<template>
  <v-container fluid>
    <v-textarea
      filled
      label="JSON"
      auto-grow
      v-model="message"
    ></v-textarea>

    <v-btn depressed color="primary" @click="submit" >Submit</v-btn>

    <v-textarea label="Customer" value='{"type":"FieldAgent","payload":{"id":22789,"partnerId":64,"customerDetailId":2947185,"msisdn":"570728599392","partnerIdentifier":"7609095087086","language":"en","data":{"firstName":"Andre","surname":"Taute","test":false},"customerAt":null,"createdAt":"2021-03-15T18:02:59.324Z","updatedAt":"2021-06-15T12:12:45.886Z","internal":false,"passwordDigest":null,"instrumentsCount":0,"sortSequence":"taute andre","suspended":null,"suspendedToggledAt":null,"suspendedToggledBy":null,"locations":null,"deletedAt":null,"deletedBy":null,"goodStanding":true,"demographicId":90873,"email":null,"eventedSmsAllowed":true,"sourceProcessType":null,"sourceProcessId":null,"isAFarmer":false,"defaultLocationCacheId":159117,"defaultLocationDescriptionCache":"Cali, Torres Comfandi, Valle Del Cauca","nameCache":"Andre Taute","nameSearchCache":"andretaute","uuid":"dc61eeaf-0453-42e8-8e80-437c6f52e5f6","segmentList":["Ops"]}}' />

  </v-container>
</template>



<script>

  export default {

    data: () => ({
      message: null,
    }),

    computed: {
    },

    methods: {
      submit(farmer) {
        const json = JSON.parse(this.message);
        this.$store.dispatch('pusherEvent', json);
      }
    }

  }
</script>
