<template>
  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <Row :title="$t('Field Type')" :text="field.fieldType"/>
        <Row :title="$t('Field Name')" :text="field.name"/>
        <Row v-if="showField" :title="$t('Field Survey')" :text="field.name">
          <v-btn
              :to="{name: 'EcoExistBwFieldSurvey', query: { uuid: customerUuid, field: fieldUuid } }"
              color="secondary"
              small
              dense>
            go to field survey
          </v-btn>
        </Row>

        <Row :title="$t('Main crop')" :text="field.payload.mainCrop" v-if="field.payload.mainCrop"/>
        <Row :title="$t('Origin of seed')" :text="field.payload.originOfSeed" v-if="field.payload.originOfSeed"/>
        <Row :title="$t('Origin of fertilizer')" :text="field.payload.originOfFertilizer" v-if="field.payload.originOfFertilizer"/>
        <Row :title="$t('Fertilizer batch code')" :text="field.payload.fertlizerBatchCode" v-if="field.payload.fertlizerBatchCode"/>
        <Row :title="$t('Seed batch code')" :text="field.payload.seedBatchCode" v-if="field.payload.seedBatchCode"/>

        <Row :title="$t('Secondary crop')" :text="field.payload.secondaryCrop" v-if="field.payload.secondaryCrop"/>
        <Row :title="$t('Origin of secondary seed')" :text="field.payload.originOfSecondarySeed" v-if="field.payload.secondaryCrop"/>
        <Row :title="$t('Origin of secondary fertilizer')" :text="field.payload.originOfSecondaryFertilizer" v-if="field.payload.secondaryCrop"/>
        <Row :title="$t('Secondary fertilizer batch code')" :text="field.payload.secondaryFertlizerBatchCode" v-if="field.payload.secondaryCrop"/>
        <Row :title="$t('Secondary seed batch code')" :text="field.payload.secondarySeedBatchCode" v-if="field.payload.secondaryCrop"/>
        
        <Row :title="$t('crop')" :text="cropLookups[field.cropId]" v-else-if="field.cropId"/>
        <template v-else>

          <Row :title="$t('crop')" :text="cropLookups[field.primaryCropId]" v-if="field.primaryCropId" />
        </template>

        <Row :title="$t('size')" v-if="parseFloat(field.fieldSize.landSize) != 0" >
          <LandSize :landSize="field.fieldSize" v-if="field.fieldSize && field.fieldSize.landSize && (parseFloat(field.fieldSize.landSize) !== 0)" />
          <template  v-else>
            <span>{{$t('notSet')}}</span>:
            <strong class="red--text text--lighten-1">{{$t('Invalid field size, cannot create contract and assessments')}}</strong>
          </template>
        </Row>

        <Row v-if="field.numberOfPlants" :title="$t('Number of plants')" :text="field.numberOfPlants" />

        <Row :title="$t('location')" :location="field.location" />

        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import Row from "@/components/Row";
import LandSize from "@/components/LandSize";

export default {

  components: {
    Row,
    LandSize
  },
  data() {
    return { 
      showField: false
    }
  },
  props: ['field'],

  computed: {
    fieldUuid() { return this.$route.query.fieldUuid; },
    customerUuid() { return this.$route.query.uuid; },
    cropLookups() { return this.$store.getters.cropLookups; },
    multipleCrops() {
      if (this.field.cropIds) {
        return this.$store.getters.crops.filter(item => this.field.cropIds.includes(item.value)).map(item => item.text).join(', ');
      }  
    }
  }
}
</script>

<style scoped>

</style>
