<template>

  <PageHeader :customer="customer" :instrument="instrument" subtitle="Rollover">
    <RolloverSalaryAdvance :customer="customer" :instrument="instrument" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzSalaryAdvance'" />
    <RolloverCheckMeBack   :customer="customer" :instrument="instrument" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzCheckMeBack'" />
  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';

import PageHeader from '@/views/partners/ultimate_tz/components/PageHeader';
import RolloverSalaryAdvance from "@/views/partners/ultimate_tz/RolloverSalaryAdvance"
import RolloverCheckMeBack from "@/views/partners/ultimate_tz/RolloverCheckMeBack"

export default {

  components: { PageHeader, RolloverSalaryAdvance, RolloverCheckMeBack },

  data() {
    return {
      instrument: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    instrumentId() { return this.$route.query.instrumentId; },
    instrumentType() { return this.$route.query.instrumentType; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

  mounted() {
    this.remoteLookup(this.instrumentType, {
      uuid: this.customer.uuid,
      id: this.instrumentId,
    }).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
  },

  mixins: [lookups],

}
</script>
