<template>
  <PageHeader :title="customer.name" icon="mdi-form-select" @back="toCustomer" back v-if="customer" >
    <v-container fluid>
      <v-row no-gutters>

        <v-col  cols="12" sm="6" lg="3" class="pa-2">
          <v-card outlined>
            <v-card-title>Basics</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="Name" :text="customer.personalDetails.name" />
                  <Row title="Telephone Number" :msisdn="customer.msisdn" />
                  <Row title="Cultivated Land" :number="customer.farmersNeeds.acerage" unit="acres" v-if="customer.farmersNeeds" />
                  <Row title="Inputs Requested" :amount="customer.farmersNeeds.total" v-if="customer.farmersNeeds" :bold="true" />
                  <Row title="Captured By" :text="customer.fieldAgent.name" v-if="customer.fieldAgent" />
                  <Row title="Submitted" :date="customer.nileSubmissionAt" v-if="customer.nileSubmissionAt" />
                  <Row title="Submitted" text="Not yet submitted." v-else />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="customer.personalDetails">
          <v-card outlined>
            <v-card-title>Personal Details</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="Gender" :gender="customer.personalDetails.gender" />
                  <Row title="Birthdate" :date="customer.personalDetails.birthdate" />
                  <Row title="Dependents" :number="customer.personalDetails.dependents" />
                  <Row title="School Going Dependents" :number="customer.personalDetails.schoolGoingDependent" />
                  <Row title="Schooling" :text="customer.personalDetails.schooling" />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="customer.incomeDetails">
          <v-card outlined>
            <v-card-title>Income</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="FCS Card" :gender="customer.incomeDetails.fcsCardNumber" v-if="customer.incomeDetails.fcsCardNumber" />
                  <Row title="FCS Card" text="Not given" v-else />
                  <Row title="Agriculture Experience" :text="customer.incomeDetails.agricultureExperience" unit="years" />
                  <Row title="Years As A Supplier To Nile" :number="customer.incomeDetails.yearsAsSupplier" unit="years" />
                  <Row title="Alternative Income" :amount="customer.incomeDetails.alternativeIncomeAmount" />
                  <Row title="Alternative Income Sources" >
                    {{ (customer.incomeDetails.alternativeIncomeSource || []).join(', ') }}
                  </Row>
                  <Row title="Owns Land?" :text="customer.incomeDetails.doYouOwnLand" />
                  <Row title="Assets">
                    {{ (customer.incomeDetails.assetsHeld || []).join(', ') }}
                  </Row>
                  <Row title="Other Loans?" :text="customer.incomeDetails.otherLoans" />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="customer.otpAcknowledgement">
          <v-card outlined>
            <v-card-title>Costs and Charges</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="Inputs Requested" :amount="customer.farmersNeeds.total" v-if="customer.farmersNeeds" :bold="true" />
                  <Row title="Wearther Insurance?" :text="(customer.otpAcknowledgement.weatherInsurance ? 'Requested' : 'No')" :bold="true" />
                  <Row title="Weather Insurance Price" :number="customer.otpAcknowledgement.pricing.pricing.weatherInsurance*100.0" unit="%" />
                  <Row title="Weather Insurance Cost" :amount="Math.round(customer.otpAcknowledgement.pricing.charges.weatherInsurance)" />
                  <Row title="Interest Rate (6 Months)" :number="customer.otpAcknowledgement.pricing.pricing.sixMonthInterestRate*100.0" unit="%" />
                  <Row title="Interest Cost" :amount="Math.round(customer.otpAcknowledgement.pricing.charges.interest)" />
                  <Row title="Initiation Fees" :number="customer.otpAcknowledgement.pricing.pricing.initiationFees*100.0" unit="%" />
                  <Row title="Initiation Cost" :amount="Math.round(customer.otpAcknowledgement.pricing.charges.initiationFees)" />
                  <Row title="Total Exposure" :amount="Math.round(customer.otpAcknowledgement.pricing.charges.exposure)" bold="true" />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="customer.ninCardFront">
          <v-card outlined>
            <v-card-title>NIN Card</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="NIN Number" :code="customer.ninCardFront.ninNumber" />
                  <Row title="Card Number" :code="customer.ninCardFront.cardNumber" />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="(customer.seasonsAndHarvests || []).length>0">
          <v-card outlined>
            <v-card-title>Harvest History</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr class="text-left" v-for="history in customer.seasonsAndHarvests">
                    <td>{{history.year}} {{history.season}}</td>
                    <td>{{history.harvest}} <span class="caption">kg</span></td>
                    <td>{{history.land}} <span class="caption">acres</span></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="customer.farmersNeeds">
          <v-card outlined>
            <v-card-title>Agriculatural Inputs</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="Cultivated Land" :number="customer.farmersNeeds.acerage" unit="acres" />
                  <Row v-for="need in (customer.farmersNeeds.tableResults || [])" :title="need.description" :number="need.quantity" :unit="need.unit" />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


        <v-col  cols="12" sm="6" lg="3" class="pa-2" v-if="customer.farmLocation">
          <v-card outlined>
            <v-card-title>Farm Location</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="District" :text="customer.farmLocation.district" v-if="customer.farmLocation.district" />
                  <Row title="Subcounty" :text="customer.farmLocation.subCounty" v-if="customer.farmLocation.subCounty" />
                  <Row title="Parish" :text="customer.farmLocation.parish" v-if="customer.farmLocation.parish" />
                  <Row title="Village" :text="customer.farmLocation.village" v-if="customer.farmLocation.village" />
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>


      </v-row>
    </v-container>

  </PageHeader>
</template>


<script>
  import PageHeader from '@/components/PageHeader'
  import Row from '@/components/Row';

  export default {
    components: { PageHeader, Row },
    data: () => ({
    }),
    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
      // partnerCode() { return this.$store.getters.settings.partnerCode; },
    },
    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
    }
  }
</script>