<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2" class="text-right">
        <Time :time="startAt" format="d MMM" v-if="startAt" />
      </v-col>
      <v-col cols="8">
        <v-range-slider
          max="30"
          min="-30"
          v-model="range"
        ></v-range-slider>
      </v-col>
      <v-col cols="2" class="text-left">
        <Time :time="endAt" format="d MMM" v-if="endAt" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Customers :data="filteredData" />
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  import Customers from '@/views/customers/partners/ultimate_tz/Customers';
  import Time from '@/components/Time';

  export default {

    components: {
      Customers,
      Time,
    },

    props: ['data'],

    data: () => ({
      range: undefined,
    }),

    computed: {
      filteredData() { return (this.data || []).filter( e => ( e.due_at > this.startAt && e.due_at < this.endAt) ) },
      startAt() { return ( this.range ? new Date( (new Date()).getTime() + this.range[0]*24*60*60*1000 ) : undefined ); },
      endAt()   { return ( this.range ? new Date( (new Date()).getTime() + this.range[1]*24*60*60*1000 ) : undefined ); },
    },

    mounted() {
      const d = new Date();
      const day = d.getDate();
      const month = d.getMonth();
      const year = d.getFullYear();
      const daysInCurrentMonth = new Date(year, (month + 1), 0).getDate();
      // const daysInPriorMonth = new Date(year, month, 0).getDate();
      let startAt = -3;
      let endAt = 3;
      if ( day<11 ) {
        startAt = 0;
        endAt = 11-day;
      } else {
        startAt = 0;
        endAt = (daysInCurrentMonth-day+10);
      }
      this.range = [startAt,endAt];
    },

  }
</script>
