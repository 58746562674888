<template>
  <PageHeader
      title="Field detail"
      back
      @back="toCustomer"
  >

    <main>
      <v-form ref="basicForm" v-model="valid" v-if="formFields">

        <v-row>
          <v-col cols="12" md="12" class="text-left">
            <h3>Are the corridors helping?</h3>

            <div>
              <v-radio-group
                  :label="formFields.areCorridorsHelping.questionOne.label"
                  v-model="formFields.areCorridorsHelping.questionOne.answer"
              >
                <v-radio
                    label="Yes"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
              </v-radio-group>

            </div>
            <div>
              <v-radio-group
                  :label="formFields.areCorridorsHelping.questionTwo.label"
                  v-model="formFields.areCorridorsHelping.questionTwo.answer"
              >
                <v-radio
                    label="Yes"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
              </v-radio-group>

            </div>
            <div>
              <v-radio-group
                  :label="formFields.areCorridorsHelping.questionThree.label"
                  v-model="formFields.areCorridorsHelping.questionThree.answer"
              >
                <v-radio
                    label="Yes"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
              </v-radio-group>

            </div>
            <div>
              <v-radio-group
                  :label="formFields.areCorridorsHelping.questionFour.label"
                  v-model="formFields.areCorridorsHelping.questionFour.answer"
              >
                <v-radio
                    label="Yes"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
              </v-radio-group>

            </div>
            <div>
              <v-radio-group
                  :label="formFields.areCorridorsHelping.questionFive.label"
                  v-model="formFields.areCorridorsHelping.questionFive.answer"
              >
                <v-radio
                    label="Yes"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
              </v-radio-group>

            </div>
          </v-col>

          <v-col cols="12" md="12" class="text-left">
            <hr/>
            <h4 class="mt-5">{{formFields.understandingOfCorridorAwareness.label}}</h4>
            <v-textarea
                filled
                :value="formFields.understandingOfCorridorAwareness.answer"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="12" class="text-left">
            <h4 class="text-left">Fill at least three</h4>
          </v-col>

          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.elephantCorridorsArePathways.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.elephantCorridorsArePathways.explained"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.corridorLocationForLeastDisturbance.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.corridorLocationForLeastDisturbance.explained"
              ></v-checkbox>
            </div>

          </v-col>
        </v-row>

        <!--    ======-->
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.allocatingFieldsOnElephantCorridors.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.allocatingFieldsOnElephantCorridors.explained"
              ></v-checkbox>
            </div>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.avoidBlockingElephantCorridors.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.avoidBlockingElephantCorridors.explained"
              ></v-checkbox>
            </div>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.tawanaLandBoardHaveAgreedToAvoidFieldAllocationOnTheseCorridors.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.tawanaLandBoardHaveAgreedToAvoidFieldAllocationOnTheseCorridors.explained"
              ></v-checkbox>
            </div>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.elephantCorridorsAreNotProtectedAreasButSharedWithWildlife.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.elephantCorridorsAreNotProtectedAreasButSharedWithWildlife.explained"
              ></v-checkbox>
            </div>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.corridorsInTheEasternPanhandle.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.corridorsInTheEasternPanhandle.explained"
              ></v-checkbox>
            </div>

          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
                style="font-weight:bold"
                disabled
                filled
                :value="formFields.awarenessFields.elephantCorridorsOfferOpportunitiesForEcotourism.label"
            ></v-textarea>
            <div class="d-flex">
              <v-checkbox
                  label="Explained"
                  v-model="formFields.awarenessFields.elephantCorridorsOfferOpportunitiesForEcotourism.explained"
              ></v-checkbox>
            </div>

          </v-col>

        </v-row>
        <slot>

        </slot>
      </v-form>
    </main>
  </PageHeader>
</template>

<script>
import DateInput from "@/components/DateInput";
import StarRating from "@/components/StarRating";
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    DateInput,
    StarRating,
    PageHeader
  },
  props: ["value", "next"],
  data() {
    return {
      valid: false,
      formFields: {
        understandingOfCorridorAwareness: {
          label : 'What do you understand by elephant corridors?',
          answer: null
        },
        areCorridorsHelping: {
          questionOne: {
            label : 'Do elephants use the corridors as an important movement route?',
            answer: null
          },
          questionTwo: {
            label : 'Are there less elephants moving into your field because of corridors?',
            answer: null
          },
          questionThree: {
            label : 'Do you think the corridors are helping to reduce elephant conflict at your field?',
            answer: null
          },
          questionFour: {
            label : 'Are the corridors benefiting you in any way?',
            answer: null
          },
          questionFive: {
            label : 'Are the corridors benefiting your village or community in any way?',
            answer: null
          },
        },
        awarenessFields: {
          elephantCorridorsArePathways: {
            label: 'Elephant corridors are pathways or routes that most elephants use most of the time to move from the woodlands to the permanent waters of the Okavango.',
            explained: false
          },
          corridorLocationForLeastDisturbance: {
            label: 'Corridors are located between villages and fields where there is least disturbance from people, where they can avoid contract with people.',
            explained: false
          },
          avoidBlockingElephantCorridors: {
            label: 'Blocking Elephant corridors should be avoided to allow elephants to move and prevent further conflict.',
            explained: false
          },
          allocatingFieldsOnElephantCorridors: {
            label: 'Allocating fields on elephant corridors places farmers in the direct path of many elephants, increasing the likelihood of conflict and making it very difficult to harvest any crops.',
            explained: false
          },
          tawanaLandBoardHaveAgreedToAvoidFieldAllocationOnTheseCorridors: {
            label: 'The Tawana land board have agreed to avoid field allocation on these corridors, but rather to focus on more compatible land uses like livestock grazing and tourism activities to reduce conflict.',
            explained: false
          },
          elephantCorridorsAreNotProtectedAreasButSharedWithWildlife: {
            label: 'Elephant corridors are not protected areas, but zones on tribal land that are shared with elephants and other wildlife.',
            explained: false
          },
          corridorsInTheEasternPanhandle: {
            label: 'There are 13 corridors in the eastern panhandle. Field allocations are easier to protect outside of them using effective elephant deterrents.',
            explained: false
          },
          elephantCorridorsOfferOpportunitiesForEcotourism: {
            label: 'Elephant corridors offer opportunities for ecotourism to help increase benefits coming back to communities. ',
            explained: false
          },
        },
      }
    };
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!!to) {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },
  computed: {
    customerUuid() { return this.$route.query.uuid; },
    isFormValid() {
      return this.$store.getters.customer(this.customerUuid);
    },
    customer() { return this.$store.getters.customer(this.customerUuid); },
  },

  methods: {
    validate() {
      this.$refs.basicForm.validate();
    },

    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
  },
}
</script>

<style scoped>

</style>
