<template>
  <div>
    <PageHeader
      :title="customer.name"
      icon="mdi-flower-outline"
      @back="toCustomers"
      @save="save"
      @validate="validate"
    />

    <Stepper :tabs="tabs" :captions="captions" :complete="complete" @position="stepChange($event)" ref="stepper" vertical>
      <template v-slot:FarmersNeeds>
        <FarmersNeeds
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form7"
        v-model="formFields.farmersNeeds"
        @nextStep="nextStep"
        @previousStep="previousStep"
        @valid="validity['FarmersNeeds']=$event"
      />
      </template>

      <template v-slot:OtpAcknowledgement>
        <OtpAcknowledgement v-model="formFields.otpAcknowledgement" ref="form8" :submit="submitApplication" :capital="formFields.farmersNeeds.total" @valid="validity['OtpAcknowledgement']=$event" @nextStep="nextStep" @previousStep="previousStep" :rules="rules" />
      </template>

      <template v-slot:ThankYou>
        <ThankYou />
      </template>
    </Stepper>
    <!-- end stepper -->
  </div>
</template>

<script>
import Stepper from "@/components/Stepper";
import PageHeader from "@/components/PageHeader";
import FarmersNeeds from "@/views/partners/nile_ug/components/FarmersNeeds";
import OtpAcknowledgement from "@/views/partners/nile_ug/components/OtpAcknowledgement.vue";
import ThankYou from "@/views/partners/nile_ug/components/ThankYou.vue";

export default {

  name: "NileStepperLoanRequest",

  components: {
    PageHeader,
    FarmersNeeds,
    OtpAcknowledgement,
    ThankYou,
    Stepper
  },

  data: function () {
    return {
      formFields: {
        otpAcknowledgement: {},
        farmersNeeds: {
          total: 0
        }
      },
      step: 1,
      tabs: ['FarmersNeeds', 'OtpAcknowledgement', 'ThankYou'],
      captions: {
        'FarmersNeeds': 'Loan Details',
        'OtpAcknowledgement': 'OTP Acknowledgement',
        'ThankYou': 'Thank You'
      },
      validity: {
        'FarmersNeeds': false,
        'OtpAcknowledgement': false,
        'ThankYou': false 
      },
      firstStep: true,
      lastStep: false,
      rules: {
        required: (value) => (value==0 || !!value) || "Required",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
      },
    };
  },
  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    invalid() { return this.tabs.filter(e => (this.validity[e]===false) ); },
    valid() { return this.invalid.length==0; },
    customerUuid() { return this.$route.query.uuid; },
    isStart() { return this.step == 1; },
    isLastStep() { return this.step == 3; },
  },
  watch: {
    step() { this.save(); },
  },
  methods: {
    next() { this.step += 1; },
    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },
    previous() { this.step -= 1; },
    concludeLater() {
      this.save();
      this.toCustomers();
    },
    nextStep() {
      this.save();
      this.$refs.stepper.nextStep();
    },
    previousStep() {
      this.save();
      this.$refs.stepper.previousStep();
    },
    toCustomers() {
      this.$router.push({ name: 'Customers' });
    },
    validate() {
      // validate the different forms here
      this.$refs.form7.validate();
      this.$refs.form8.validate();
    },

    submitApplication() {
      this.customer.nileUgApplication = true;
      this.nextStep();
    },

    save() {
      this.customer.farmersNeeds = this.formFields.farmersNeeds;
      this.customer.otpAcknowledgement = this.formFields.otpAcknowledgement;
      this.customer.lastNileSubmissionPage = Math.max(...[ parseInt(this.customer.lastNileSubmissionPage || 0), this.step ]);
      this.customer.nileSubmissionAt = (new Date()).toISOString();
      this.$store.dispatch("upsertCustomer", this.customer);
    },
  },

};
</script>


<style>
</style>