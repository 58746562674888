<template>
  <v-form ref="basicForm" v-model="valid">
    <v-card outlined>
      FieldSurvey
      <v-list dense>
        <v-list-item
            v-for="(item, i) in [1,2,4]"
            :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="'item.icon'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'item.text'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
    };
  },

  methods: {
    validate() {
      this.$refs.basicForm.validate();
    },
  },
}
</script>

<style scoped>

</style>
