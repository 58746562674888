<template>
  <div>
    <PageHeader
      :title="pageHeader"
      icon="mdi-flower-outline"
    />
    <Stepper :tabs="tabs" :captions="captions" :complete="complete" @position="stepChange($event)" ref="stepper" vertical>
      <template v-slot:DeliveryItem>
        <CropDeliveryInputs
          ref="deliveryForm"
          @nextStep="nextStep"
          v-model="formFields"
        />
      </template>

      <template v-slot:OrderConfirmation>
        <OrderConfirmation :total="formFields.cropNetValue" :items="items || []" @nextStep="submitApplication" :capital="formFields.total" @previousStep="previousStep"/>
      </template>
      <template v-slot:ThankYou>
        <ThankYou />
      </template>
    </Stepper>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader";
import Stepper from "@/components/Stepper";
import CropDeliveryInputs from "@/views/partners/eco_bw/landing/CropDeliveryInputs.vue";
import OrderConfirmation from "@/views/partners/nucafe_ug/landing/OrderConfirmation.vue";
import ThankYou from "@/views/partners/eco_bw/landing/ThankYou.vue";

export default {
  components: { PageHeader, Stepper, CropDeliveryInputs, OrderConfirmation, ThankYou },

  data: function() {
    return {
      items: [],
      formFields: {
          total: 0     
      },
      step: 1,
      tabs: ['DeliveryItem', 'OrderConfirmation', 'ThankYou'],
      captions: {
        'DeliveryItem': 'Delivery Item',
        'OrderConfirmation': 'Order Confirmation',
        'ThankYou': 'Thank you'
      },
      validity: {
        'DeliveryItem': false,
        'OrderConfirmation': false,
        'ThankYou': false,
      },
      firstStep: true,
      lastStep: false,
    }
  },
  computed: {
    uuid() { return this.$route.query.uuid; },
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    customer() { return this.$store.getters.customer(this.uuid); },
    pageHeader() { return `New Delivery for ${this.customer.name}`}
  },
  
  methods: {
    nextStep() { 
      this.step += 1;
      this.$refs.stepper.nextStep();
      this.formFields.total=this.formFields.cropNetValue
      this.items = [
        {
          description: this.formFields.cropDetails.crop,
          price_per_unit: this.formFields.cropDetails.price_per_unit, 
          quantity: this.formFields.quantity, 
          cost: this.formFields.cropNetValue, 
        }
      ]
     },
    previousStep() {
      this.$refs.stepper.previousStep();
    },
    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },
    previous() { this.step -= 1; },
    submitApplication() {
      this.formFields.price_per_unit = this.formFields.cropDetails.price_per_unit
      this.formFields.cropId = this.formFields.cropDetails.cropId
      this.$store.dispatch('addDelivery', this.formFields);
      this.$refs.stepper.nextStep();
    }
  }
}
</script>
