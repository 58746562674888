<template>
  <div style="position: fixed; bottom: 0;" class="mb-5 ml-2">
    <AlertMessage
        v-for="(alert, index) in allAlerts"
        :alert="alert"
        :index="index"
    />
  </div>
</template>

<script>
import AlertMessage from "@/components/Alert/AlertMessage";

export default {
  name: "AlertWrapper",
  components: {AlertMessage},
  computed: {
    allAlerts() {
      return this.$store.getters.allAlerts
    }
  }
}
</script>

<style scoped>

</style>