<template>
  <DateInputDropLists v-model="date" v-bind="$attrs" v-on="$listeners" v-if="dropLists" />
  <DateInputSelector v-model="date" v-bind="$attrs" v-on="$listeners" v-else />
</template>

<script>
  import DateInputDropLists from '@/components/components/DateInputDropLists';
  import DateInputSelector from '@/components/components/DateInputSelector';

  export default {

    components: { DateInputDropLists, DateInputSelector },

    props: {
      value: [String, Date],
      dropLists: Boolean,
    },

    data: () => ({
      date: undefined,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.date = to; },
      },
      date(to) { this.$emit('input', to); }
    },

  };
</script>
