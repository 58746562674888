<template>
  <PageHeader title="Actions" icon="mdi-account-voice">

    <template v-slot:buttons>
      <v-btn @click="refresh" :disabled="!refreshable" color="primary" small dense class="ma-1"><v-icon dense>mdi-refresh</v-icon> Refresh</v-btn>
    </template>

    <ServerSideResult :test="actions" style="width: 100%;">
      <Actions :actions="actions" />
    </ServerSideResult>

  </PageHeader>
</template>


<script>
  import lookups from '@/mixins/lookups';

  import PageHeader from "@/components/PageHeader";
  import ServerSideResult from '@/components/ServerSideResult';
  import Actions from '@/views/actions/components/Actions';

  export default {

    components: { PageHeader, ServerSideResult, Actions },

    data: () => ({
      actions: undefined,
      refreshable: false,
    }),
    
    methods: {
      refresh() {
        this.refreshable = false;
        this.loadActions();
        let _this = this;
        setTimeout(() => {
          _this.refreshable = true;
        }, 2000);
      },
      loadActions() {
        this.remoteLookup('get_actions_for_agent', {}).then( actions => this.actions = actions.actions ).catch(e => this.actions = null);
      }
    },

    mounted() { this.refresh(); },
    
    mixins: [lookups],

  }
</script>
