<template>
  <v-card class="text-left mt-2">
    <v-card-title style="white-space: nowrap;">
      Corridor Awareness Assessment
      <v-spacer />
      <v-btn
          color="primary"
          v-if="corridorAwarenessAssessment"
          small
          dense
          :to="{name: 'EditCorridorAwareness', query: { uuid: customer.uuid } }"
      >
        {{$t('edit')}}
      </v-btn>

      <v-btn
          color="primary"
          v-else
          small
          dense
          :to="{name: 'EditCorridorAwareness', query: { uuid: customer.uuid } }"
      >
        {{$t('new')}}
      </v-btn>

    </v-card-title>

    <div
        v-if="corridorAwarenessAssessment && corridorAwarenessAssessment.awarenessQuestions.areCorridorsHelping"
        class="pa-4">
      <h5>Are the corridors helping?</h5>

      <StarRating
          v-model="areTheCorridorsHelpingStarRating"
          :readonly="true"
      />
    </div>

    <template v-if="corridorAwarenessAssessment && corridorAwarenessAssessment.awarenessQuestions.areCorridorsHelping">

      <v-simple-table >
        <template v-slot:default>
          <thead>
          <tr>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="awarenessField in corridorAwarenessAssessment.awarenessQuestions.areCorridorsHelping"
          >
            <td >{{ awarenessField.label }}</td>
            <td>{{ awarenessField.answer ? 'Yes' : 'No'}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>



    <template v-if="corridorAwarenessAssessment && corridorAwarenessAssessment.awarenessQuestions.awarenessFields">

      <div class="pa-4">


        <hr/>
      </div>

      <v-simple-table >
        <template v-slot:default>
          <thead>
          <tr>
            <th>
            </th>
            <th class="text-left">
              Explained
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="awarenessField in corridorAwarenessAssessment.awarenessQuestions.awarenessFields"
          >
            <td v-if="awarenessField.explained === true">{{ awarenessField.label }}</td>
            <td v-if="awarenessField.explained === true">Yes</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>



  </v-card>
</template>

<script>
import StarRating from "@/components/StarRating";
import Time from "@/components/Time";

export default {
  props: {

    customer: Object
  },
  components: {
    StarRating,
    Time
  },
  computed: {
    corridorAwarenessAssessment() {
      return this.customer ? this.$store.getters.customerAssessment(this.customer.uuid, 'CorridorAwarenessAssessment') : null;
    },
    areTheCorridorsHelpingStarRating() {
      return (this.corridorAwarenessAssessment && this.corridorAwarenessAssessment.awarenessQuestions.areCorridorsHelping) ? Object.values(this.corridorAwarenessAssessment.awarenessQuestions.areCorridorsHelping).filter(question => question.answer).length : null
    }
  }
}
</script>

<style scoped>

</style>
