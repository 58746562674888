<template>
  <v-select :items="localised" :label="$t('Marital Status')" v-model="status" />
</template>


<script>
  export default {

    props: ['value'],

    data: () => ({
      status: undefined,
      statuses: [
        "single",
        "married",
        "widowed",
        "separated",
        "co-habiting",
      ]
    }),

    computed: {
      localised() { return this.statuses.map( e => { return { "value": e, "text": this.$t(e) } } ) },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.status = to; }
      },
      status(to) { this.$emit('input', to); },
    },


  }
</script>


<i18n>
{
  "en": {
    "Marital Status": "Marital Status",
    "single": "Single",
    "married": "Married",
    "widowed": "Widowed",
    "separated": "Seperated",
    "co-habiting": "Co-habiting"
  },
  "es": {
    "Marital Status": "Estado Civil",
    "single": "Único",
    "married": "Casado",
    "widowed": "Viudo",
    "separated": "Separado",
    "co-habiting": "Viviendo juntos"
  },
  "sw": {
    "Marital Status": "Hali ya ndoa",
    "single": "Sijaoa",
    "married": "Aliyeoa",
    "widowed": "Mjane",
    "separated": "Talaka",
    "co-habiting": "Kuishi pamoja"
  }
}
</i18n>
