<template>
  <v-form ref="fingerprintForm" v-model="valid" class="text-left" >

    <v-row v-if="!connected">
      <v-col class="text-h4 red--text">
        Device is disconnected
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-h4">
        Left Hand
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" v-for="(finger, index) in ['little', 'ring', 'middle', 'index', 'thumb']">
        <EnrollFingerprint :customer="customer" hand="left" :finger="finger" :fingerNumber="index" :loading="fingerNumber===index" :ref="`finger-${index}`" @captured="captured" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-h4">
        Right Hand
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" v-for="(finger, index) in ['thumb','index','middle','ring','little']">
        <EnrollFingerprint :customer="customer" hand="right" :finger="finger" :fingerNumber="index + 5" :loading="fingerNumber===(index + 5)" :ref="`finger-${(index + 5)}`" @captured="captured" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" v-if="!this.production">
        <v-btn id="start" @click="startCapture">start</v-btn>
        <v-btn id="start" @click="onSamplesAcquired">test</v-btn>
      </v-col>
    </v-row>


  </v-form>

</template>

<script>
import prodDebug from "@/mixins/prodDebug";
import EnrollFingerprint from "@/components/components/EnrollFingerprint";
import { FingerprintReader, SampleFormat } from '@digitalpersona/devices';

export default {

  components: { EnrollFingerprint },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: true,
      connected: false,
      reader: undefined,
      acquisitionStarted: false,
      captured_data: undefined,

      fingerNumber: 0,

      left: {},
      right: {},
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
      }
    },
    customer(to) { this.$emit('input', to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {

    captured(fingerNumber) {
      console.log(fingerNumber);
      this.fingerNumber = (fingerNumber || 0) + 1;
      if (this.fingerNumber==10) { this.fingerNumber = 0; }
    },

    validate() {
      this.$refs.fingerprintForm.validate();
    },

    startCapture() {
      console.log('start capture');
      if (this.acquisitionStarted) return;
      // this.reader = new FingerprintReader();
      var currentFormat = SampleFormat.PngImage;
      console.log("reader: ", this.reader)
      console.log("acquisition: ", this.acquisitionStarted)
      this.reader.startAcquisition(currentFormat).then(() => {
        this.acquisitionStarted = true
        console.log("starting the capture process: ")
        //Disabling start once started
        // this.disableEnableStartStop();
      });
    },

    onSamplesAcquired(s) {
      console.log("this is s: ", s.samples);
      console.log("this is also s: ", s);
      let currentFinger = `finger-${this.fingerNumber}`;
      console.log(currentFinger);
      this.$refs[currentFinger][0].fingerprintCaptured(s.samples[0]);
      console.log('tta');
    },

  },

  mounted () {
    this.reader = new FingerprintReader();

    this.reader.onDeviceConnected = (e) => {
          // Detects if the deveice is connected for which acquisition started
      this.connected = true
      console.log("Scan your finger");
      console.log("nothing is beeing loged")
    }
    
    this.reader.onDeviceDisconnected = (e) => {
        // Detects if device gets disconnected - provides deviceUid of disconnected device
      this.connected = false
      console.log("Device disconnected");
      console.log("its not active any more")
      console.log("nothing is beeing logged")
    }

    this.reader.onSamplesAcquired = (s) => {
      this.onSamplesAcquired(s);
    }
  
    this.reader.onQualityReported = (quality) => {
      console.log("quality : ", quality.quality);
    }

    if (this.production) {
      console.log('start scanning');
      this.startCapture();
    }
    
  },

  mixins: [prodDebug],

}
</script>
