<template>
  <PageHeader :title="$t('trees')" icon="mdi-pine-tree" >
    WIP
  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader';

  export default {

    components: { PageHeader },

    data: () => ({

    }),

    computed: {
    },

  }
</script>
