<template>
  <v-card>
    <v-card-title>
      {{$t('title')}}
    </v-card-title>

    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>{{$t('warning')}}</div>
        <div>
          <v-btn color="red lighten-4" small dense @click="deleteCustomer">{{$t('title')}}</v-btn>
        </div>
      </div>
    </v-card-text>

  </v-card>
</template>


<script>

  export default {

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
    },

    methods: {
      deleteCustomer() {
        this.$store.dispatch('deleteCustomer', this.customerUuid);
        this.toCustomers();
      },
      toCustomers() { this.$router.push({ name: 'Customers' }); },
    },

  }
</script>



<i18n>
{
  "en": {
    "title": "Delete",
    "warning": "Delete this customer (here, and from the server)"
  },
  "es": {
    "title": "Borrar",
    "warning": "Eliminar este cliente (aquí y del servidor)"
  },
  "sw": {
    "title": "Futa",
    "warning": "Futa mteja huyu (hapa, na kutoka kwa seva)"
  }
}
</i18n>
