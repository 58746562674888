<template>
  <EnrollFingerprints v-model="customer" v-if="customer" />
</template>

<script>
import EnrollFingerprints from "@/components/EnrollFingerprints";

export default {

  components: { EnrollFingerprints },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: true,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
      }
    },
    customer(to) { this.$emit('input', to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.fingerprintForm.validate();
    },
  },

}
</script>

