<template>
  <PageHeader :title="customer.name" icon="mdi-star-check-outline" @subtitle="toCustomer" @back="toCustomer" @save="submit" back save :valid='formValid' >

    <v-form>

      <FormContainer color="blue lighten-5">

        <v-container fluid class="py-0 px-2">
          <v-row>

            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['biosecurity']"               title="Biosecurity" description="Uses a footbath, regularly cleaned litter, dedicated poultry house clothes, disinfected materials" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['poultry_house_size']"        title="Size of poultry house" description="Should be 15 meters squared per 100 birds up to 5 weeks" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['poultry_house_ventilation']" title="Ventilation" description="Well aerated poultry house with many windows and good airflow " /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['feeders_and_drinkers']"      title="Feeders and drinkers" description="1 feeder per 50 chicks for first 2 weeks; 1 drinker per 100 chicks for first 2 weeks" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['feed_storage']"              title="Feed storage locations" description="Dry, well-aerated, away from sunlight and rain, lifted off the floor on a pallet" /></v-col>

            
            <v-col cols="12">
              <v-textarea label="Comments" v-model="note" auto-grow ></v-textarea>
            </v-col>
            
          </v-row>
        </v-container>

      </FormContainer>
    </v-form>

  </PageHeader>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';

  import PageHeader from '@/components/PageHeader';
  import FormContainer from '@/components/FormContainer';
  import StarRatingCard from '@/components/StarRatingCard';

  export default {
    components: { PageHeader, FormContainer, StarRatingCard },
    data: () => ({
      assessment: {},
      note: '',
    }),
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      formValid() { return Object.keys(this.assessment).length==5 },
    },

    methods: {

      submit() {

        let score = 0;
        const results = this.assessment;

        score = score + results['biosecurity'] * 0.2;
        score = score + results['poultry_house_size'] * 0.2;
        score = score + results['poultry_house_ventilation'] * 0.2;
        score = score + results['feeders_and_drinkers'] * 0.2;
        score = score + results['feed_storage'] * 0.2;

        score = Math.round(score);
        this.customer.assessments = this.customer.assessments || [];
        this.customer.assessments.push({ assessment_name: 'uzima_agent', customer_uuid: this.customerUuid, description: 'Agent', uuid: uuidv4(), assessment: this.assessment, 'score': score, note: this.note, scoredAt: (new Date().toISOString()) });

        this.$store.dispatch('upsertCustomer', this.customer);
        
        this.toCustomer();

      },

      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      
    }
  }
  
</script>