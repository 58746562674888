<template>
  <PageHeader :title="$t('title')"  v-if="customer" back @back="toCustomer" print @print="print" >

    <v-container fluid class="pa-0 mt-2" id="printTarget" >

      <v-row no-gutters >
        <v-col cols="12" class="text-left">
          <span class="text-h4 mr-2">{{$t('receiptedFrom')}}:</span>
          <span class="text-h4 font-weight-bold">{{customer.name}}</span>
        </v-col>
      </v-row>

      <v-row no-gutters >

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              {{ receipt.receiptNumber }}
            </v-card-title>
            <v-card-subtitle>
              {{$t('receiptNumber')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <Time :time="receipt.createdAt" format="date" />
            </v-card-title>
            <v-card-subtitle>
              {{$t('receipted')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <Currency :value="receipt.amount" />
            </v-card-title>
            <v-card-subtitle>
              {{$t('amount')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

      </v-row>
    </v-container>


  </PageHeader>
</template>

<script>
  import PageHeader from '@/components/PageHeader';
  import Time from '@/components/Time';
  import Currency from '@/components/Currency';
  import AppMenuItem from '@/components/AppMenuItem';
  
  export default {

    components: { PageHeader, Time, Currency, AppMenuItem },

    data: () => ({
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      receiptUuid() { return this.$route.query.receiptUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      receipt() { return this.$store.getters.receipt(this.receiptUuid); },
    },

    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      async print() { await this.$htmlToPaper('printTarget'); },
    },


  }
</script>


<i18n>
{
  "en": {
    "title": "Cash Receipt",
    "amount": "Amount",
    "receiptNumber": "Receipt Number",
    "receipted": "Receipted",
    "receiptedFrom": "Receipted From"
  },
  "es": {
    "title": "Recibo de caja",
    "amount": "Monto",
    "receiptNumber": "Número de recibo",
    "receipted": "Recibido en",
    "receiptedFrom": "Recibido desde"
  },
  "sw": {
    "title": "Stakabadhi ya Fedha",
    "amount": "Thamani",
    "receiptNumber": "Namba ya risiti",
    "receipted": "Kupokelewa Saa",
    "receiptedFrom": "Imepokewa Kutoka"
  }
}
</i18n>
