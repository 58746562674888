<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field label="Employer Name" v-model="business.name" :rules="[v => !!v || 'Required']" />
        </v-col>
        <v-col cols="12" md="4">
          <NameInput v-model="business.branchName" :label="$t('Branch Name')" />
        </v-col>
        <v-col cols="12" md="4">
          <MsisdnInput v-model="business.msisdn" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field label="Branch Address" type="text" v-model="business.branchAddress"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="business.url" placeholder="Web Site" :rules="rules"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-radio-group v-model="businessType" mandatory row >
                  <v-radio label="Private" value="private" />
                  <v-radio label="Government" value="government" />
                  <v-radio label="Bank" value="bank" />
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4">
                <v-select :items="days" label="Payroll Day" v-model="business.payload.payrollDay"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn color="warning" small dense @click="validate">Validate</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="save" color="primary" :disabled="!valid">Save</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import MsisdnInput from '@/components/MsisdnInput';
import NameInput from '@/components/NameInput';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    NameInput,
    MsisdnInput,
  },

  data() {
    return {
      business: { uuid: uuidv4(), government: false, bank: false, payload: { payrollDay: 25 } },
      valid: true,
      businessType: null,
      rules: [
        (value) => ((value || '')=='') || this.isURL(value) || "URL is not valid",
      ],
      days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,'LD'],
    }
     
  },

  watch: {
    businessType(to) {
      this.business.category = undefined;
      this.business.government=false;
      if (to=='bank') { this.business.category = 'bank'; }
      if (to=='government') { this.business.government=true; }
    },
  },

  methods: {
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },

    save() {
      this.$store.dispatch('upsertBusiness', this.business);
      this.$router.push({ name: 'Home' });
    },

    validate() {
      this.$refs.form.validate();
    },
  }
}
</script>