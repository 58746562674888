<template>

  <v-container fluid>

    <ServerSideResult :test="theInstrument" >

      <v-row class="text-center" v-if="theInstrument">

        <v-col cols="12" md="2" sm="3">
          <Balance label="Balance" :balance="parseInt(theInstrument.balance)" :highlight="highlight" @click="highlight='Balance'" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Balance label="Settlement Amount" :balance="parseInt(theInstrument.settlement_amount)" :highlight="highlight" @click="highlight='Settlement Amount'" />
        </v-col>
        <v-col cols="12" md="2" sm="3" v-if="mayRollover">
          <Balance label="Min Payment to Rollover" :balance="parseInt(theInstrument.rollover_amount)" :highlight="highlight" @click="highlight='Min Payment to Rollover'" />
        </v-col>
        <v-col cols="12" md="2" sm="3" v-if="mayTopup">
          <Balance label="Available Balance" :balance="parseInt(theInstrument.available_balance)" :highlight="highlight" @click="highlight='Available Balance'" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Percentage label="Rate" :value="parseFloat(theInstrument.interest_rate)" :highlight="highlight" />
        </v-col>
        <v-col cols="12" md="2" sm="3" v-if="!theInstrument.disbursed_at">
          <Action label="Not Disbursed" >
            <v-btn dense small color="primary" :to="{name: 'UltimateTzLoanRequest', query: { uuid: this.uuid, instrumentId: theInstrument.id } }">
              <v-icon dense small>mdi-play</v-icon> Disburse
            </v-btn>
          </Action>
        </v-col>

      </v-row>

    </ServerSideResult>
  
  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import Balance from "@/views/instruments/components/Balance"
import Percentage from "@/views/instruments/components/Percentage"
import Action from "@/views/instruments/components/Action"
import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { Balance, Percentage, Action, ServerSideResult },

  // note, if you pass an instrumentId, you must also pass a customer.
  props: {
    instrument: Object,
    instrumentId: Number,
    customer: Object,
    value: String,
  },

  data() {
    return {
      theInstrument: undefined,
      highlight: undefined,
    }
  },

  computed: {
    uuid() { return (this.customer ? this.customer.uuid : this.$route.query.uuid); },
    mayRollover() { return ( (this.theInstrument) && ( (this.theInstrument.rollover_amount || 0)>=0 ) && typeof(this.theInstrument.rollover_amount)=='number' ); },
    mayTopup() { return ( (this.theInstrument) && ( (this.theInstrument.available_balance || 0)>=0 ) && typeof(this.theInstrument.available_balance)=='number' ); },
  },

  watch: {
    theInstrument(to) { this.$emit('instrument', to) },
    value: {
      immediate: true,
      handler(to) { this.highlight = to; },
    },
    highlight(to) { this.$emit('input', to); },
  },

  mounted() {
    if (this.instrument) {
      this.theInstrument = this.instrument;
    } else {
      this.remoteLookup('instrumentUltimateTzSalaryAdvance', {
        uuid: this.uuid,
        id: this.instrumentId,
      }).then( instrument => this.theInstrument = instrument ).catch(e => this.theInstrument = null);
    }
  },

  mixins: [lookups],


}
</script>
