<template>
  <v-list-item style="cursor: pointer" :to="to" :disabled="disabled" @click="$emit('click')" >
    <v-icon color="primary" class="mr-4">{{icon}}</v-icon>
    <v-list-item-content>
      <v-list-item-title>{{title}}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['icon', 'to', 'title', 'disabled'],
};
</script>
