<template>
  <v-container fluid>
    <v-row>

      <v-col v-for="test in tests">
        <v-btn elevation="2" :to="test.to">{{test.caption}}</v-btn>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {

  data() {
    return {
      tests: [
        { to: "/tests/filesAndPhotos",   caption: 'Files And Photos'  },
        { to: "/tests/identityNumbers",  caption: 'Identity Numbers'  },
        { to: "/tests/dates",            caption: 'Dates'             },
        { to: "/tests/Fingerprints",     caption: 'Fingerprints'      },
        { to: "/tests/FingerprintEnroll",caption: "FingerprintEnroll"},
        { to: "tests/FingerprintIdentification", caption: "FingerprintIdentification"},
        { to: "/tests/validation",       caption: 'Validation'        },
        { to: "/tests/ultimate",         caption: 'Ultimate'          },
        { to: "/tests/pusher",           caption: 'Pusher'            },
        { to: "/tests/signature",        caption: 'TestSignature'     },
        { to: "/tests/select",        caption: 'TestSelect'     },
      ]
    }
  },

};
</script>

