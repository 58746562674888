<template>
  <v-text-field
    v-model.number="amount"
    :prefix="prefix"
    :label="label"
    :single-line="hideDetails"
    type="number"
    :hint="formatted(amount)"
    :persistent-hint="amount!==0"
    :rules="required ? allRules: []"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
  import formatter from "@/mixins/numberFormatter";

  export default {
    props: {
      value: [Number,String],
      required: Boolean,
      rules: Array,
      label: String,
    },
    data: () => ({
      amount: 0,
    }),
    computed: {
      allRules() { return !!this.rules ? [this.numberRules, ...this.rules] : [this.numberRules] },
      prefix() { return this.$store.getters.currency; },
      currencyAmount() { return (Number.parseFloat(this.amount) || 0) },
      hideDetails() { return (this.label ? false : true); },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          this.amount = (Number.parseFloat(to) || 0);
        }
      },
      currencyAmount(to) {
        this.$emit('input', this.currencyAmount);
      }
    },
    methods: {
      numberRules(value) {
        let r = [
          v => !this.required || !!v || 'Required',
          v => (/\d/.test(v)) || 'Numbers only',
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },
    mixins: [formatter],
  };
</script>

