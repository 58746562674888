<template>
  <v-container fluid class="pa-0" >
    <v-row no-gutters>

      <!-- Wide -->
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <Heading :icon="icon" :title="title" :subtitle="subtitle" @subtitle="$emit('subtitle')" @title="$emit('title')" />

          <div class="text-right hidden-xs-only">
            <slot name="buttons"></slot>
            <v-btn color="primary" small dense class="ma-1" @click="$emit('print')" v-if="print"><v-icon dense>mdi-printer</v-icon> <span class="hidden-sm-and-down">{{$t('print')}}</span></v-btn>
            <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="$emit('validate')" v-if="validate">
              {{$t('validate')}}
            </v-btn>
            <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="$emit('save')" v-if="save" :disabled="!valid" >
              {{saveButtonTitle}}
            </v-btn>
            <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="$emit('refresh')" v-if="refresh" >
              Refresh
            </v-btn>
            <v-btn color="info" small dense class="ma-1" @click="$emit('back')" v-if="back" >
              <v-icon>mdi-backburger</v-icon>
            </v-btn>
          </div>

          <div class="text-left hidden-sm-and-up">
            <v-menu bottom left offset-y style="z-index: 1000;">

              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" v-bind="attrs" v-on="on" >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense class="">
                <slot name="menu"></slot>
                <v-list-item-group color="primary" >
                  <AppMenuItem icon="mdi-printer" @click="$emit('print')" :title="$t('print')" v-if="print" />
                  <AppMenuItem icon="mdi-backburger" @click="$emit('back')" :title="$t('back')" v-if="back" />
                  <AppMenuItem icon="mdi-content-save-edit-outline" @click="$emit('save')" :title="saveButtonTitle" v-if="save" :disabled="!valid" />
                </v-list-item-group>
              </v-list>

            </v-menu>
          </div>

        </div>
      </v-col>

      <v-col cols="12" class="hidden-xs-only">
        <v-divider class="mt-1 mb-2" />
      </v-col>

    </v-row>

    <v-row no-gutters style="width:100%;">
      <slot></slot>
    </v-row>

    <v-row no-gutters class="hidden-sm-and-up">
      <v-col cols="12" class="text-center mt-2">
        <slot name="buttons"></slot>
        <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="$emit('validate')" v-if="validate">
          {{$t('validate')}}
        </v-btn>
        <v-btn color="warning" small dense class="ma-1" @click="$emit('save')" v-if="save" :disabled="!valid" >
          {{saveButtonTitle}}
        </v-btn>
        <v-btn color="info" small dense class="ma-1" @click="$emit('print')" v-if="print" >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn color="info" small dense class="ma-1" @click="$emit('back')" v-if="back" >
          <v-icon>mdi-backburger</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
  import Heading from '@/components/Heading';
  import AppMenuItem from '@/components/AppMenuItem'

  export default {
    components: { Heading, AppMenuItem },
    props: {
      icon: {},
      title: {},
      subtitle: {},
      saveCaption: { default: undefined, type: String },
      back: { default: false, type: Boolean },
      save: { default: false, type: Boolean },
      validate: { default: false, type: Boolean },
      print: { default: false, type: Boolean },
      valid: { default: false, type: Boolean },
      refresh: { default: false, type: Boolean },
    },
    computed: {
      saveButtonTitle() { return this.saveCaption || this.$t('save'); },
    }
  }
</script>


<i18n>
{
  "en": {
    "validate": "Validate"
  },
  "es": {
    "validate": "Validar"
  },
  "sw": {
    "validate": "Thibitisha"
  }
}
</i18n>
