<template>

  <v-container fluid class="pa-0 text-left">
    <v-row>
      <v-col cols="12">
        <Heading large>
          <span @click="updateLocation">
            <v-icon color="primary" :disabled="!location" dense >mdi-crosshairs-gps</v-icon>
          </span>
          GPS Details
        </Heading>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr class="font-weight-bold">
                <td>GPS Location Status</td>
                <td>{{ status }}</td>
              </tr>
              <tr v-if="location">
                <td>Latitude</td>
                <td>{{ coords.latitude }}</td>
              </tr>
              <tr v-if="location">
                <td>Longitude</td>
                <td>{{ coords.longitude }}</td>
              </tr>
              <tr v-if="location">
                <td>Accuracy</td>
                <td>{{ coords.accuracy }} m</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="" @click="updateLocation">
          <v-icon left dark >mdi-crosshairs-gps</v-icon>
          Update Location
        </v-btn>
      </v-col>
    </v-row>




  </v-container>

</template>

<script>
import Heading from '@/components/Heading';

export default {
  components: { Heading },

  computed: {
    status() { return this.$store.getters.geolocationStatus; },
    location() { return this.$store.getters.geolocation; },
    coords() {
      let r = null;
      if ((location) && (this.location)) { r = this.location.coords; }
      return r;
    },
  },
  methods: {
    updateLocation() {
      this.$store.dispatch('updateGeolocation');
    }
  }
};
</script>
