<template>
  <v-card>
    <v-card-title>
      {{$t('Fields')}}
      <v-spacer />
      <v-btn
          v-if="!fieldsForCustomers.length >= 1"
          color="warning"
          small
          dense
          :to="{name: 'EcoExistBwFieldSurvey', query: { uuid: customer.uuid, farmUuid: this.newFieldUuid } }"
          class="ml-1">
        {{$t('new')}}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
          <tr v-for="fieldRow in fieldsForCustomers" :key="fieldRow.id" :title="fieldRow.name" >
            <td class="text-left">{{ fieldRow.name }}</td>
            <td class="text-right">
              <v-btn color="primary" text :to="{name: 'Field', query: { uuid: customer.uuid, fieldUuid: fieldRow.uuid } }" class="ml-1">
                <v-icon dense>mdi-flower-outline</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>


<script>
  import fields from '@/mixins/fields';
  import { v4 as uuidv4 } from 'uuid';
  import Row from '@/components/Row';
  import Time from '@/components/Time';
  import AssessButton from '@/views/fields/components/AssessButton';
  import {createFieldRows} from "@/views/customers/components/utils";
  import ShowEcoBwFieldsSummary from "@/views/customers/partners/eco_bw/ShowEcoBwFieldsSummary";

  export default {

    components: {
      Row,
      Time,
      AssessButton,
      ShowEcoBwFieldsSummary,
    },
    data: () => ({
      newFieldUuid: uuidv4(),
    }),

    props: ['customer'],
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldsForCustomers() { return this.$store.getters.fieldsForCustomers(this.customerUuid) },
      fields() { return (this.customer.fields || []).filter(field => ( field.deleted===undefined && field.fieldType === 'outer field') ) ; },
      customerFarms() {return this.customer.customerFarms; },
      partnerCode() { return this.$store.getters.settings.partnerCode },
      crops() { return this.$store.getters.cropLookups; },
      fieldSurveyAssessment() {
        return !!this.customer.economic.payload ? this.customer.economic.payload.fieldSurveyFormField : null;
      },
      fieldRows() {
        const customerCultivationContracts = this.$store.getters.assessments.filter(assessment =>
            assessment.assessmentName === 'cultivationContract' &&
            assessment.customerUuid === this.customer.uuid
        )
        return createFieldRows(this.fields, customerCultivationContracts)
      }
    },

    methods: {
      toNewField() { this.$router.push({name: 'FieldEdit', query: { uuid: this.$route.query.uuid, fieldUuid: 'new' }}); },
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "fields": "Fields"
  },
  "es": {
    "fields": "Cultivos"
  },
  "sw": {
    "fields": "Mashamba"
  }
}
</i18n>
