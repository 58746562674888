<template>
  <v-btn
      color="primary"
      small
      dense class="mr-1"
      :disabled="disableLink"
      :to="{name: 'TechnicalVisitView', query: { uuid: customer.uuid, fieldUuid: field.uuid }}"
  >
    {{$t('newAssessment')}}
  </v-btn>
</template>

<script>
export default {
  props: ['field', 'cultivationContractAssessment', 'customer'],
  computed: {
    disableLink() {
      return !this.cultivationContractAssessment || !this.field.fieldSize.landSize || parseFloat(this.field.fieldSize.landSize) === 0
    }
  }
}
</script>

<style scoped>

</style>



<i18n>
{
  "en": {
    "newAssessment": "New Assessment"
  },
  "es": {
    "newAssessment": "VISITA TÉCNICA"
  },
  "sw": {
    "newAssessment": "Tathmini Mpya"
  }
}
</i18n>
