<template>

  <v-form v-model="formValid">
    <FormContainer color="green lighten-5">

      <v-row>
        <v-col cols="12" sm="6">
          <v-select :items="crops" label="Crop" class="text-left" v-model="field.cropId"></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <LandSizeInput label="Field size" v-model="field.fieldSize" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="text-center">
          <v-switch v-model="present" label="I am currently standing in this field" color="success" value="in" hide-details ></v-switch>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="field.numberOfPlants" label="Number of Plants" min=0 type='number' :rules="numberRules"  />
        </v-col>
      </v-row>

    </FormContainer>

  </v-form>

</template>

<script>

  // :area, :area_unit, :crop_id, :area_under_cultivation

  import FormContainer from '@/components/FormContainer'
  import LandSizeInput from '@/components/LandSizeInput';

  export default {
    components: { FormContainer, LandSizeInput },
    props: ['field'],
    data: () => ({
      formValid: true,
      present: false,
      numberRules: [
        v => Number.isInteger(Number(v)) || 'Numbers only',
        v => (Number(v) >= 0) || 'A positive number please',
        v => !(/\D/.test(v)) || 'Numbers please',
      ],
    }),
    computed: {
      partnerIdentifier() { return this.$route.query.partnerIdentifier; },
      customer() { return this.$store.getters.customer(this.partnerIdentifier); },
      crops() { return this.$store.getters.crops; },
    },
    watch: {
      formValid: function(to) { this.$emit('valid', to) },
      field: function(to) { this.$emit('input', to); },
      present: function(to) {
        this.field.location = null;
        if (to) {
          this.field.location = {};
          if (this.$store.getters.geolocation && this.$store.getters.geolocation.coords) {
            this.field.location.latitude = this.$store.getters.geolocation.coords.latitude;
            this.field.location.longitude = this.$store.getters.geolocation.coords.longitude;
          }
        }
      },
    },
    // methods: {
    //   handleChange() {  },
    // },
  }
  
</script>