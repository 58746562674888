<template>
  <v-container class="pa-3 d-flex flex-column align-center justify-center">
    <v-card class="text-left pa-3 mt-n1 mb-3" :width="width" outlined>
      <v-card-title class="text-h6 mb-4"> Enter Farmer Phone Number </v-card-title>
      <v-form class="pa-3" ref="form" v-model="valid">
        <MsisdnInput v-model="customer.msisdn" />
        <!-- <v-select :items="buyingCentres" label="Buying Centre" solo v-model="depotId" class="mt-1" ></v-select> -->
        <v-select :items="fieldAgents" label="Agronomist" solo v-model="fieldAgentId"></v-select>
        <v-btn :disabled="!valid" color="primary" class="mr-4 mt-3" @click="submit">
          Go
        </v-btn>
      </v-form>
    </v-card>
    <v-card class="text-left mb-5 mx-auto" :width="width" outlined>
      <v-card-text>
        <div class="text--primary body-1">
          By proceeding you acknowledge that SBG may use your data for
          credit decision making processes, including sharing your data with
          credit bureaus.
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "NileUg",
  props:['customer'],
  components: {
    MsisdnInput,
  },
  data: function () {
    return {
      valid: false,
      // depotId: undefined,
      // buyingCentres: [
      //   { text: 'Amanang', value: 320 },
      //   { text: 'Barawa', value: 308 },
      //   { text: 'Cheminy', value: 312 },
      //   { text: 'Kaprenkurt', value: 309 },
      //   { text: 'Kapyoyon', value: 311 },
      //   { text: 'Kortek', value: 321 },
      // ],
      fieldAgentId: undefined,
      fieldAgents: [
        { text: 'Caroline Yesho', value: 3020398 },
        { text: 'Claire Cherop', value: 3020399 },
        { text: 'Collins Musau', value: 3020400 },
        { text: 'Ivan Ndiwa', value: 3020401 },
        { text: 'Kennedy Mwesigwa', value: 3025316 },
        { text: 'Nelson Musisi', value: 3025315 },
        { text: 'Omara Henry', value: 3025317 },
      ],
    };
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "80%";
        case "lg":
          return "80%";
        case "xl":
          return "80%";
      }
    },
  },

  watch: {
    // depotId(to) {
    //   this.customer.depot.id = to;
    //   this.fieldAgentId = {
    //     320: 3025316,
    //     308: 3020399,
    //     312: 3020398,
    //     311: 3029302,
    //     321: 3029303,
    //   }[to];
    // },
    fieldAgentId(to) {
      const name = (this.fieldAgents.find(e => e.value===to) || {}).text;
      this.customer.fieldAgent = {
        id: to,
        name: name,
      };
      this.customer.depot.id = {
        3025316: 320,
        3020399: 308,
        3020400: 309,
        3020401: 309,
        3020398: 312,
        3025315: 311,
        3025317: 321,
      }[to];
    },
  },

  methods: {
    submit() {
      this.customer.send_takeon_otp = true;
      this.$store.dispatch('upsertCustomer', this.customer);
      this.$router.push({name: "NileRegisterContact", query: { uuid: this.customer.uuid }})
    },
  },

  mounted() {
    this.customer.uuid = this.customer.uuid || uuidv4();
    this.customer.msisdn = this.customer.msisdn || undefined;
    this.customer.fieldAgent = {
      id: this.$store.getters.settings.fieldAgentId,
      name: this.$store.getters.settings.name,
    };
    this.customer.isAFarmer = true;
    this.customer.depot = this.customer.depot || { id: undefined }
  },

};
</script>

<style>
.height100 {
  height: 100vh;
}
</style>