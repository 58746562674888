<template>
  <div>
    <CorridorAwareness v-model="corridorAwarenessFields" ref="corridorAwareness">
      <div class="text-left pb-2">
        <v-btn
            color="primary"
            @click="saveCorridorAwarenessAssessment"
            :disabled="!isCorridorAwarenessFormFieldsValid"
        >
          Submit and view details
        </v-btn>
      </div>
    </CorridorAwareness>
  </div>
</template>

<script>
import CorridorAwareness from "@/views/customers/components/CorridorAwareness";
import {createNewCorridorAwarenessAssessment} from "@/views/partners/eco_bw/utils";

export default {
  components: {
    CorridorAwareness
  },
  data() {
    return {
      corridorAwarenessFields: null,
    }
  },

  mounted() {
    if (this.corridorAwarenessAssessment) {
      this.corridorAwarenessFields = this.corridorAwarenessAssessment.awarenessQuestions
    }
  },

  computed: {
    customerUuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.customerUuid); },
    corridorAwarenessAssessment() {
      return this.customerUuid ? this.$store.getters.customerAssessment(this.customerUuid, 'CorridorAwarenessAssessment') : null;
    },
    isCorridorAwarenessFormFieldsValid() {
      return this.corridorAwarenessFields
          && Object.values(this.corridorAwarenessFields.awarenessFields).filter(formField => !!formField.explained).length > 2
          && Object.values(this.corridorAwarenessFields.areCorridorsHelping).filter(question => question.answer === null).length === 0
    },
  },
  methods: {
    saveCorridorAwarenessAssessment() {
      const newAssessment = createNewCorridorAwarenessAssessment(this.customer, 'CorridorAwarenessAssessment',this.corridorAwarenessFields)
      this.$store.commit('upsertAssessment', newAssessment)
      this.toCustomer()
    },
    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
  }
}
</script>

<style scoped>

</style>
