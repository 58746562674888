export default {
  data() {
    return {
      debugNavigator: navigator,
      mediaDevices: [],
      isBluetoothAvailable: null,
      connectedBluetoothDevice: null,
      bluetoothDevice: null,
      btWeighScaleRawValue: 0,
      btDemoReadings: false,
      btScaleTimer: null,
    }
  },

  methods: {

    bluetoothDisconnectServer() {
      if (!!this.bluetoothDevice) {
        this.bluetoothDevice.gatt.disconnect();
        this.bluetoothDevice = null
        this.connectedBluetoothDevice = null
      }
    },

    bluetoothScan() {
      const optionalDevice = BluetoothUUID.getService('49535343-fe7d-4ae5-8fa9-9fafd205e455')
      navigator.bluetooth.requestDevice({
        // filters: [...] <- Prefer filters to save energy & show relevant devices.
        acceptAllDevices: true,
        // filters: [{namePrefix: "CX"}],
        optionalServices: [optionalDevice]
      })
          .then(device => {
            console.log('Connecting to GATT Server...', device);

            this.bluetoothDevice = device
            return device.gatt.connect();
          })
          .then(server => {
            // Note that we could also get all services that match a specific UUID by
            // passing it to getPrimaryServices().
            this.connectedBluetoothDevice = server;
            console.log('Getting Services...', server);
            // const ress = await server.getPrimaryServices()
            // this.readServer(server)
            // debugger
            return server.getPrimaryServices();
          })
          .then(services => {
            // check number of services
            console.log('services', services);
            if (services.length > 0) {
              return services[0].getCharacteristics();
            } else {
              // todo
              throw error
            }

          })
          .then(characteristics => {
            console.log('characteristics.length', characteristics.length);
            const myCharacteristic = characteristics[0]
            console.log('selected', myCharacteristic);

            return myCharacteristic.startNotifications().then(_ => {
              myCharacteristic.addEventListener('characteristicvaluechanged',
                  this.handleNotifications);
            });
          })
          .catch(error => {
            console.log('Argh! ' + error);

          });
    },

    handleNotifications(event) {
      let value = event.target.value;
      let a = [];
      let parsedValue = ''
      for (let i = 0; i < value.byteLength; i++) {
        a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
        parsedValue += String.fromCharCode(value.getUint8(i))

      }
      if (parsedValue && parsedValue.split('=').length === 2) {
        this.btWeighScaleRawValue = parseFloat(parsedValue.split('=')[1])
      }
    },

  },

  mounted() {
    if (navigator.bluetooth) {
      navigator.bluetooth.getAvailability()
          .then(isBluetoothAvailable => {
            this.isBluetoothAvailable = isBluetoothAvailable
          }).catch(error => {
        console.log(`> Bluetooth availability error`);
      });
    } else {
      this.isBluetoothAvailable = false
    }
    this.btScaleTimer = setInterval(() => {
      if (this.btDemoReadings) {
        this.btWeighScaleRawValue = parseFloat( ( this.btWeighScaleRawValue + Math.random() ).toFixed(2) );
      }
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.btScaleTimer)
  },

  destroyed() {
    this.bluetoothDisconnectServer();
    this.btWeighScaleRawValue=null
  },

}
