export const insecticidesData = [
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Abamectina      1,8%",
        "Dose": "400 a 500 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Bifenazate     50 WP",
        "Dose": "150 grs/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Cal Hidratada + Azufre Pulverizado",
        "Dose": "10 cc/litro"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Fenaxaquin    200   SC",
        "Dose": "1.000 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Cyflumetofen    20 SC",
        "Dose": "300 cc/litro"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Fenpyroximato     5% SC",
        "Dose": "1.000 grs/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Flufenoxuron      100 CD",
        "Dose": "150 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Hexitiazox    54 EC",
        "Dose": "0,6 - 0,9 cc/litro"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Milbectin   1  EC",
        "Dose": "360 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 2 - Paecilomyces fumosoroseus  DSM15126     200.000.000 UFC - 500.000.000 UFC",
        "Dose": "1 - 2 cc/litro"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Pyridaben    WP  20%",
        "Dose": "500 grs/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Pyridaben + Abamectina    WP   10,2% (90+ 12)",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Spirodiclofen     240 SC",
        "Dose": "500 - 600 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Spiromesifen   240 SC",
        "Dose": "400 - 600 cc/ha"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Spiromesifen + Abamectina   240  SC  (228+11,4)",
        "Dose": "600 cc/ha"
    },
    {
        "pest": "Acaros Polyphagotarsonemus latus",
        "activeIngredient": "Saponinas    35   SL",
        "Dose": "1.500 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados     860 grs/litro",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Beauveria bassiana      WP-SC 200.000.000 Esporas Por Gramo",
        "Dose": "1,0 - 2,0 grs/litro --- 2,0 - 4,0 cc/litro"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Buprofezin     25 SC",
        "Dose": "600 - 800 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Clothianidin     50  WG",
        "Dose": "250 grs/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Imidachloprid    350 SC",
        "Dose": "600 - 800 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Novaluron + Bifentrin    100 CS (50+50)",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 1     200.000.000   UFC",
        "Dose": "1 - 2 cc/litro"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Paraffinic oil     EO  67,2%",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Spiromesifen   240 SC",
        "Dose": "400 - 600 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Spiromesifen + Abamectina   240  SC  (228+11,4)",
        "Dose": "600 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Spirotetramat    OD   151",
        "Dose": "600 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Pyriproxyfen     EW  10%",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Thiametoxan     25  WG",
        "Dose": "400 grs/ha - 600 grs/ha (Sistema Riego)"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "Sulfoxaflor    240  SC",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Minadores   -   Lirriomyza spp.",
        "activeIngredient": "Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados     860 grs/litro",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Minadores   -   Lirriomyza spp.",
        "activeIngredient": "Acetamiprid     200 SP",
        "Dose": "0,75 grs/litro - 300 grs/ha"
    },
    {
        "pest": "Minadores   -   Lirriomyza spp.",
        "activeIngredient": "Paraffinic oil     EO  67,2%",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Minadores   -   Lirriomyza spp.",
        "activeIngredient": "Pyriproxyfen     EW  10%",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Minadores   -   Lirriomyza spp.",
        "activeIngredient": "Spinetoram     60 SC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Minadores   -   Lirriomyza spp.",
        "activeIngredient": "Spinosad    4,8%   SC",
        "Dose": "100 a 200 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados     860 grs/litro",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Acetamiprid     200 SP",
        "Dose": "0,75 grs/litro - 300 grs/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Alfa Cipermetrina     10 EC",
        "Dose": "300 - 500 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Clothianidin     50  WG",
        "Dose": "250 grs/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Cyalotrina gamma     60 SC",
        "Dose": "50 - 100 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Imidacloprid + Beta Cyfluthrin     SC  11,25%  (100+12,5)",
        "Dose": "1.000 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Methomyl    21,6 %  SL  -   40%  SP",
        "Dose": "1.000  cc/ha --- 500 grs/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Paraffinic oil     EO  67,2%",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Pyriproxyfen     EW  10%",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Spinetoram     60 SC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Spinosad    4,8%   SC",
        "Dose": "100 a 200 cc/ha"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Spirotetramat    OD   150",
        "Dose": "600 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Aceite Vegetal: Saponinas + Acidos Carboxilicos Insaturados+ A. Gliceridos Saturados     860 grs/litro",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Beauveria bassiana      WP-SC 200.000.000 Esporas Por Gramo",
        "Dose": "1,0 - 2,0 grs/litro --- 2,0 - 4,0 cc/litro"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Buprofezin     25 SC",
        "Dose": "600 - 800 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Clothianidin     50  WG",
        "Dose": "250 grs/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Imidacloprid + Beta Cyfluthrin     SC  11,25%  (100+12,5)",
        "Dose": "1.000 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Metarhizium anisopliae",
        "Dose": "1,0 - 2,0 grs/litro"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 1     200.000.000   UFC",
        "Dose": "1 - 2 cc/litro"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Paraffinic oil     EO  67,2%",
        "Dose": "0,5 - 1 %"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Spiromesifen   240 SC",
        "Dose": "400 - 600 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Spirotetramat    OD   153",
        "Dose": "600 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Sulfoxaflor    240  SC",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Thiametoxan     25  WG",
        "Dose": "400 grs/ha - 600 grs/ha (Sistema Riego)"
    },
    {
        "pest": "Dasineura  -  Dasineura spp.",
        "activeIngredient": "Acetamiprid     200 SP",
        "Dose": "0,75 grs/litro - 300 grs/ha"
    },
    {
        "pest": "Dasineura  -  Dasineura spp.",
        "activeIngredient": "Ciromazina    750  WP",
        "Dose": "0,3 a 0,4 grs/litro"
    },
    {
        "pest": "Dasineura  -  Dasineura spp.",
        "activeIngredient": "Spinetoram     60 SC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Dasineura  -  Dasineura spp.",
        "activeIngredient": "Spinosad    4,8%   SC",
        "Dose": "100 a 200 cc/ha"
    },
    {
        "pest": "Dasineura  -  Dasineura spp.",
        "activeIngredient": "Thiametoxan     25  WG",
        "Dose": "400 grs/ha - 600 grs/ha (Sistema Riego)"
    },
    {
        "pest": "Dasineura  -  Dasineura spp.",
        "activeIngredient": "Imidacloprid + Beta Cyfluthrin     SC  11,25%  (100+12,5)",
        "Dose": "1.000 cc/ha"
    },
    {
        "pest": "Chinches  -  Nezara viridula",
        "activeIngredient": "Acetamiprid     200 SP",
        "Dose": "0,75 grs/litro - 300 grs/ha"
    },
    {
        "pest": "Lepidopteros -  Neoleucinodes elegantalis",
        "activeIngredient": "Alfa Cipermetrina     10 EC",
        "Dose": "300 - 500 cc/ha"
    },
    {
        "pest": "Lepidopteros -  Neoleucinodes elegantalis",
        "activeIngredient": "Clorantaniliprole    200  SC",
        "Dose": "200 cc/ha"
    },
    {
        "pest": "Lepidopteros  -  Neoleucinodes elegantalis",
        "activeIngredient": "Emamectin Benzoate   05 SG  -  OPTI",
        "Dose": "150 - 250 cc/ha"
    },
    {
        "pest": "Lepidopteros -  Neoleucinodes elegantalis",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Lepidopteros -  Neoleucinodes elegantalis",
        "activeIngredient": "Flubendiamide     480 SC",
        "Dose": "100 - 150 cc/ha"
    },
    {
        "pest": "Lepidopteros  -  Neoleucinodes elegantalis",
        "activeIngredient": "Methoxyfenozide    SC",
        "Dose": "250 - 300 cc/ha"
    },
    {
        "pest": "Lepidopteros  -  Neoleucinodes elegantalis",
        "activeIngredient": "Indoxacarb      150 EC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Lepidopteros  -  Neoleucinodes elegantalis",
        "activeIngredient": "Methomyl    21,6 %  SL  -   40%  SP",
        "Dose": "1.000  cc/ha --- 500 grs/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Alfa Cipermetrina     10 EC",
        "Dose": "300 - 500 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Bacillus thuringiensis pv kurstaki      62.000 UI",
        "Dose": "150 a 200 grs/Ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Cyalotrina gamma     60 SC",
        "Dose": "50 - 100 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Diflubenzuron   48 SC",
        "Dose": "100 - 200 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Indoxacarb      150 EC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Lufenuron     50 EC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Methoxyfenozide    SC",
        "Dose": "250 - 300 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "Methomyl    21,6 %  SL  -   40%  SP",
        "Dose": "1.000  cc/ha --- 500 grs/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Alfa Cipermetrina     10 EC",
        "Dose": "300 - 500 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Bacillus thuringiensis pv kurstaki      62.000 UI",
        "Dose": "200 a 250 grs/Ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Cyalotrina gamma     60 SC",
        "Dose": "50 - 100 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Diflubenzuron   48 SC",
        "Dose": "250 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Indoxacarb      150 EC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Indoxacarb      150 EC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Methomyl    21,6 %  SL  -   40%  SP",
        "Dose": "1.000  cc/ha --- 500 grs/ha"
    },
    {
        "pest": "Lepidópteros  - Heliothis spp.",
        "activeIngredient": "Methoxyfenozide    SC",
        "Dose": "250 - 300 cc/ha"
    },
    {
        "pest": "Crisomelidos  -  Diabrotica spp.",
        "activeIngredient": "Alfa Cipermetrina     10 EC",
        "Dose": "300 - 500 cc/ha"
    },
    {
        "pest": "Crisomelidos  -  Diabrotica spp.",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Crisomelidos  -  Diabrotica spp.",
        "activeIngredient": "Methomyl    21,6 %  SL  -   40%  SP",
        "Dose": "1.000  cc/ha --- 500 grs/ha"
    },
    {
        "pest": "Crisomelidos  -  Diabrotica spp.",
        "activeIngredient": "Sulfoxaflor    240  SC",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Crisomelidos  -  Epitrix spp.",
        "activeIngredient": "Alfa Cipermetrina     10 EC",
        "Dose": "300 - 500 cc/ha"
    },
    {
        "pest": "Crisomelidos  -  Epitrix spp.",
        "activeIngredient": "Etofenprox     30 EC",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Crisomelidos  -  Epitrix spp.",
        "activeIngredient": "Methomyl    21,6 %  SL  -   40%  SP",
        "Dose": "1.000  cc/ha --- 500 grs/ha"
    },
    {
        "pest": "Crisomelidos  -  Epitrix spp.",
        "activeIngredient": "Sulfoxaflor    240  SC",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Chizas  -  Phyllophaga obsoleta",
        "activeIngredient": "Bacillus popilliae      WP 160.000.000 UFC",
        "Dose": "3 - 5 gramos por metro cuadrado"
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "Beauveria bassiana      WP-SC 200.000.000 Esporas Por Gramo",
        "Dose": "1,0 - 2,0 grs/litro --- 2,0 - 4,0 cc/litro"
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "Metarhizium anisopliae",
        "Dose": "1,0 - 2,0 grs/litro"
    },
    {
        "pest": "Escamas Blandas y Cochinillas",
        "activeIngredient": "Buprofezin     25 SC",
        "Dose": "600 - 800 cc/ha"
    },
    {
        "pest": "Escamas Blandas y Cochinillas",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Escamas Blandas y Cochinillas",
        "activeIngredient": "Pyriproxyfen     EW  10%",
        "Dose": "500 cc/ha"
    },
    {
        "pest": "Arañita Roja  -  Tetranychus urticae",
        "activeIngredient": "Cal Hidratada + Azufre Pulverizado",
        "Dose": "10 cc/litro"
    },
    {
        "pest": "Arañita Roja  -  Tetranychus urticae",
        "activeIngredient": "Fenaxaquin    200   SC",
        "Dose": "1.000 cc/ha"
    },
    {
        "pest": "Arañita Roja  -  Tetranychus urticae",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 2 - Paecilomyces fumosoroseus  DSM15126     200.000.000 UFC - 500.000.000 UFC",
        "Dose": "1 - 2 cc/litro"
    },
    {
        "pest": "Arañita Roja  -  Tetranychus urticae",
        "activeIngredient": "Pyridaben    WP  20%",
        "Dose": "500 grs/ha"
    },
    {
        "pest": "Arañita Roja  -  Tetranychus urticae",
        "activeIngredient": "Pyridaben + Abamectina    WP   10,2% (90+ 12)",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Huevos  de Lepidópteros",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Lepidóteros  -   Estado de Huevo",
        "activeIngredient": "Trichogramma pretiosum - T. atopovirilia - T. exiguum",
        "Dose": "50 - 200 pulgadas/ha"
    },
    {
        "pest": "Lepidópteros  -  Estado de Huevo",
        "activeIngredient": "Teflobenzuron     15  SC",
        "Dose": "300 cc/ha"
    },
    {
        "pest": "Larvas de Lepidópteros",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Lepidópteros  -  Estado Larvas",
        "activeIngredient": "Teflobenzuron     15  SC",
        "Dose": "300 cc/ha"
    },
    {
        "pest": "Lepidópteros Larvas",
        "activeIngredient": "Triflumuron     SC  480",
        "Dose": "180 a 300 cc/ha"
    },
    {
        "pest": "Repelencia Insectos",
        "activeIngredient": "Extracto de Ajo + Extracto de Aji    10% + 10%",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Cigarritas  -  Empoasca spp.",
        "activeIngredient": "Metarhizium anisopliae",
        "Dose": "1,0 - 2,0 grs/litro"
    },
    {
        "pest": "Pasadores de Fruto  -  Neoleucinodes elegantalis",
        "activeIngredient": "Novaluron + Bifentrin    100 CS (50+50)",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Neoleucinodes elegantalis",
        "activeIngredient": "Spinetoram     60 SC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Neoleucinodes elegantalis",
        "activeIngredient": "Spinosad    4,8%   SC",
        "Dose": "100 a 200 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Spodoptera spp.",
        "activeIngredient": "Novaluron + Bifentrin    100 CS (50+50)",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Spodoptera spp.",
        "activeIngredient": "Spinetoram     60 SC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Spodoptera spp.",
        "activeIngredient": "Spinosad    4,8%   SC",
        "Dose": "100 a 200 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Heliothis spp.",
        "activeIngredient": "Novaluron + Bifentrin    100 CS (50+50)",
        "Dose": "400 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Heliothis spp.",
        "activeIngredient": "Spinetoram     60 SC",
        "Dose": "200 - 300 cc/ha"
    },
    {
        "pest": "Pasadores de Fruto  -  Heliothis spp.",
        "activeIngredient": "Spinosad    4,8%   SC",
        "Dose": "100 a 200 cc/ha"
    }
]
