<template>
  <div fluid class="text-left black--text">

    <h4 class="mt-4">Visible Mitigation</h4>

    <div>

      <v-card outlined v-for="deterrent in visibleMitigation.effectivenessFormFields" class="mt-4">
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div>
              <p>  <strong> {{deterrent.visibleMitigationTitle}}</strong></p>
            </div>
            <div>
              <SingleImage maxHeight="200px" :image="{uuid: deterrent.visibleMitigationImage}" />

            </div>
          </div>

          <div class="d-flex justify-space-between">

            <div>
              Maintenance
            </div>
            <div>
              <StarRating
                  v-model="deterrent.starRating"
                  :readonly="true"
              />

              <!--        v-model="value.answer"-->
            </div>
          </div>


        </v-card-text>

      </v-card>

    </div>

  </div>

</template>

<script>
import StarRating from "@/components/StarRating";
import SingleImage from "@/components/SingleImage";

export default {
  props: {
    visibleMitigation: Object
  },
  components: {
    StarRating,
    SingleImage
  }
}
</script>

<style scoped>

</style>
