<template>
  <v-btn color="primary" :small="!text" :dense="!text" :text="text" :to="{name: assessmentForm, query: { uuid: customer.uuid, fieldUuid: field.uuid } }" >
    <v-icon dense>mdi-star-check-outline</v-icon>
    <span v-if="!icon">{{$t('assess')}}</span>
  </v-btn>
</template>

<script>
  export default {
    props: {
      customer: { type: Object },
      field: { type: Object },
      icon: { type: Boolean, default: false },
      text: { type: Boolean, default: false },
    },
    computed: {
      partnerCode() { return this.$store.getters.partnerCode; },
      assessmentForm() {
        let f = 'FieldScore';
        if ( ['hr_co', 'winwin_zw'].includes(this.partnerCode) ) { f = 'HRFieldScore'; }
        return f;
      },
    },
  }
</script>


<i18n>
{
  "en": {
    "assess": "Assess"
  },
  "es": {
    "assess": "Evaluar"
  },
  "sw": {
    "assess": "Tathmini"
  }
}
</i18n>



