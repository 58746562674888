<template>

  <v-row>

    <v-col cols="12" sm="6">
      <v-card class="text-left" style="width: 100%;">
        <v-card-text>
          <v-container fluid class="text-h5">
            <v-row :class="{ 'red--text': !sufficientCashOnHand }">
              <v-col>Cash</v-col>
              <v-col><CurrencyInput v-model="cash" :disabled="arrears" /></v-col>
            </v-row>
            <v-row>
              <v-col>EFT</v-col>
              <v-col><CurrencyInput v-model="eft" :disabled="arrears || !bankAccount || cashMaxed" /></v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>Mobile Money</v-col>
              <v-col><CurrencyInput v-model="mobileMoney" :disabled="arrears || !mpesaAccount || cashMaxed" /></v-col>
            </v-row>
            <v-row v-if="topup">
              <v-col>Transfer</v-col>
              <v-col><Currency v-model="transfer" /></v-col>
            </v-row>
            <v-row>
              <v-col>Installments</v-col>
              <v-col class="d-flex justify-space-between">
                <SelectInput :items="installment_items" single-line v-model="installments" />
              </v-col>
            </v-row>
            <v-row v-if="(mobileMoney>0 || eft>0)" class="text-caption">
              <v-col cols="6" v-if="eft>0"><BankDetails :customer="customer" /></v-col>
              <v-col cols="6" v-if="mobileMoney>0">Mobile Money Wallet: <Telephone :msisdn="customer.msisdn"/></v-col>
            </v-row>

            <v-row v-if="showAccountStatus" class="text-caption">
              <v-col cols="6" v-if="eft>0"><BankDetails :customer="customer" /></v-col>
              <v-col cols="6" v-if="!bankAccount" class="red--text">No bank details available.</v-col>
              <v-col cols="6" v-if="mobileMoney>0">Mobile Money Wallet: <Telephone :msisdn="customer.msisdn"/></v-col>
              <v-col cols="6" v-if="!mpesaAccount" class="red--text">No telephone number.</v-col>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6">
      <v-card class="text-left" style="width: 100%;">
        <v-card-text>
          <v-container fluid class="text-h5">
            <v-row :class="{'red--text': (principal>maxPrincipal)}">
              <v-col>Principal</v-col>
              <v-col><Currency v-model="principal" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Nett Disbursement</v-col>
              <v-col><Currency v-model="cashDisbursal" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Interest</v-col>
              <v-col><Currency v-model="pricing.interest" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Total Repayable</v-col>
              <v-col><Currency v-model="pricing.total_repayable" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Installment</v-col>
              <v-col><Currency v-model="pricing.installment" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>First Repayment</v-col>
              <v-col><Time :time="pricing.repay_at" format="d MMM yyyy" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Monthly Interest</v-col>
              <v-col>{{(pricing.interest_rate*100.0).toFixed(2)}} %</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-btn color="success" style="width: 100%;" :disabled="!valid" @click="$emit('agreed')">
        Submit
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-container fluid>
        <v-row>
          <v-col cols="4" :class="{ 'red--text': !sufficientCashOnHand }">
            Cash on hand: <Currency v-model="cashBoxBalance" />
          </v-col>
          <v-col cols="4" v-if="maxPrincipal">
            Limits:
            <Currency v-model="minPrincipal" />
            to
            <Currency v-model="maxPrincipal" />
            over
            {{maxTerm}}
            months
          </v-col>
          <v-col cols="4" v-if="maxForSelectedTerm">
            Maximum for this term
            <Currency v-model="maxForSelectedTerm" />
            over
            {{installments}}
            months
          </v-col>
        </v-row>
      </v-container>
    </v-col>

  </v-row>



</template>

<script>
import lookups from '@/mixins/lookups';

import Time from '@/components/Time';
import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import SelectInput from "@/components/SelectInput";
import Telephone from "@/components/Telephone";
import BankDetails from '@/views/partners/ultimate_tz/components/BankDetails'

export default {

  components: { Time, CurrencyInput, TextInput, Currency, ServerSideResult, SelectInput, Telephone, BankDetails },

  props: {
    value: {},
    customer: {
      type: Object,
      required: true,
    },
    loanParameters: {
      type: Object,
      required: true,
    },
    topup: {
      type: Number,
      required: false,
    },
    transfer: {
      type: Number,
      required: false,
    },
    arrears: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      mobileMoney: 0,
      cash: 0,
      eft: 0,
      minPrincipal: 100000,
      installments: undefined,

      pricing: undefined,
      serverPricing: undefined,

      cashBoxBalance: 0,

      installment_items: [
        { text: 'One Installment',    value: 1  },
        { text: 'Two Installments',   value: 2  },
        { text: 'Three Installments', value: 3  },
        { text: 'Four Installments',  value: 4  },
        { text: 'Five Installments',  value: 5  },
        { text: 'Six Installments',  value: 6  },
        { text: 'Seven Installments',  value: 7  },
      ],
    }
  },

  computed: {
    bankAccount() { return (this.customer.account_number || '').length>6; },
    mpesaAccount() { return (this.customer.msisdn || '').length>9; },
    showAccountStatus() {
      let r = false;
      if (!this.bankAccount) { r = true; }
      if (!this.mpesaAccount) { r = true; }
      if (this.mobileMoney>0) { r = true; }
      if (this.eft>0) {  r= true; }
      return r;
    },
    sufficientCashOnHand() { return this.cashBoxBalance>=parseInt(this.cash || 0); },

    maxPrincipal() { return ((this.loanParameters || {}).loan_amount || 0); },
    maxTerm() { return ((this.loanParameters || {}).duration_months || 0); },
    maxForSelectedTerm() { return (this.pricing ? this.pricing.limit : undefined) },

    principal() { return parseInt(this.mobileMoney || 0) + parseInt(this.cash || 0) + parseInt(this.eft || 0) + parseInt(this.transfer || 0) },
    cashDisbursal() { return parseInt(this.mobileMoney || 0) + parseInt(this.cash || 0) + parseInt(this.eft || 0); },
    cashMaxed() { return this.cash==this.maxForSelectedTerm; },
    // netDisbursement() { return this.principal*(1-this.interest_rate) },
    interest() { return this.principal*this.interest_rate; },
    repayable() { return this.principal; },
    installmentDay() { return this.customer.employer.payload.payrollDay; },

    valid() {
      let r = true;
      if (r) { r = (this.principal >= this.minPrincipal); }
      if (r) { r = !!this.maxPrincipal; }
      if (r) { r = (this.principal <= (this.maxForSelectedTerm || this.maxPrincipal) ); }
      return r;
    },

    results() {
      let r = undefined;
      if (this.valid && this.pricing) {
        r = {
          uuid: this.customer.uuid,
          principal: this.pricing.principal,
          interest_rate: this.pricing.interest_rate,
          installments: this.installments,
          installment: this.pricing.installment,
          repay_at: this.pricing.repay_at,
          cash: this.cash,
          eft: this.eft,
          mobileMoney: this.mobileMoney,
          transfer: this.transfer,
          payroll_day: this.pricing.payroll_day,
        }
      }
      return r;
    },
  },

  watch: {
    principal(to) {
      this.setPricing();
    },
    installments(to) {
      this.setPricing();
    },
    maxForSelectedTerm(to) {
      if (to && (to<this.principal)) {
        this.mobileMoney = 0;
        this.eft = 0;
        this.cash = to;
      }
    },
    results: {
      deep: true,
      handler(to) {
        this.$emit('input', to);
      }
    },
    serverPricing(to) {
      let e = (to || {});
      this.pricing = {
        principal: e.principal,
        limit: e.limit,
        allowed: e.allowed,
        interest_rate: e.interest_rate,
        netDisbursement: e.netDisbursement,
        installment: e.installment,
        interest: e.interest,
        total_repayable: e.total_repayable,
        repay_at: e.repay_at,
        payroll_day: e.payroll_day,
      }
    },
  },

  methods: {
    setPricing() {
      if (this.valid) {
        this.remoteLookup('ultimateTzCheckMeBackPricing', { uuid: this.customer.uuid, principal: this.principal, installments: this.installments }).then( pricing => this.pricing = pricing ).catch(e => this.pricing = null);
      } else {
        this.pricing = undefined;
      }   
    },
  },

  mounted() {
    this.cash = this.maxPrincipal - this.transfer;
    this.cash = (Math.floor(this.cash / 1000) * 1000);
    this.installments = this.loanParameters.duration_months;
    this.setPricing();

    this.remoteLookup('ultimate_tz_cash_box_balance', {}).then( balance => this.cashBoxBalance = (balance.balance/100.0) ).catch(e => this.cashBoxBalance = 0);
  },

  mixins: [lookups],

}
</script>
