<template>
  <PageHeader title="New Expense" icon="mdi-cash-register">
    <template v-slot:buttons>
      <v-btn color="primary" small dense class="ma-1" :to="{ name: 'UltimateTzCashbox' }"><v-icon dense>mdi-piggy-bank-outline</v-icon> Cashbox</v-btn>
    </template>

    <Capture v-model="expense" v-if="!expense" />
    <Save v-model="expense" v-else />

  </PageHeader>

</template>

<script>
import PageHeader from "@/components/PageHeader";
import Capture from '@/views/partners/ultimate_tz/expenses/Capture'
import Save from '@/views/partners/ultimate_tz/expenses/Save'

export default {

  components: { PageHeader, Capture, Save },

  data() {
    return {
      expense: undefined,
    }
  },

}
</script>
