<template>
  <PageHeader :title="pageTitle" icon="mdi-account" :subtitle="subtitle" >
    <template v-slot:buttons>
      <slot name="buttons"></slot>
    </template>
    <slot></slot>
  </PageHeader>
</template>

<script>
import instrumentNames from '@/mixins/instrumentNames';
import PageHeader from '@/components/PageHeader';

export default {

  components: { PageHeader },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    instrument: {
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    }
  },

  computed: {
    instrumentType() { return (this.instrument ? this.instrument.type : undefined); },
    product() { return this.instrumentTypeToName(this.instrumentType); },
    pageTitle() { return ( this.product ? `${this.customer.name}: ${this.product}` : this.customer.name ); },

  },

  mixins: [instrumentNames],

}

</script>
