<template>
  <PageHeader :title="customer.name" icon="mdi-account" >

    <template v-slot:buttons>
      <v-btn v-if="showPrint" color="primary" small dense :to="{name: 'PrintCustomer', query: { uuid: customer.uuid } }" class="ml-1"><v-icon dense>mdi-printer</v-icon> <span class="hidden-sm-and-down">{{$t('print')}}</span></v-btn>
      <AssessButton v-if="showAssessment==='uzima_ug_agent'" :customer="customer" class="ml-1" title="Agent" routeName="UzimaUgAgentScore" />
    </template>


    <template v-slot:menu>

      <AppMenuItem v-if="showPrint" icon="mdi-printer" :to="{name: 'PrintCustomer', query: { uuid: customer.uuid } }" :title="$t('print')" />

      <TakePhoto  :text="$t('imageInfo')" @click="saveImage">
        <AppMenuItem icon="mdi-camera-outline" :title="$t('Take Photos')" />
      </TakePhoto>

      <TakePhoto :text="$t('idImageInfo')" @click="idImage">
        <AppMenuItem icon="mdi-camera-enhance-outline" :title="$t('Take ID Photos')" />
      </TakePhoto>

    </template>

    <slot></slot>

  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader'
  import TakePhoto from '@/components/TakePhoto';
  import AppMenuItem from '@/components/AppMenuItem';
  import AssessButton from '@/views/customers/components/AssessButton';

  export default {

    components: { PageHeader, TakePhoto, AppMenuItem, AssessButton },

    props: ['customer'],

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      showPrint() { return (this.partnerCode==='apex_tz'); },
      showAssessment() {
        let r = undefined;
        if (this.partnerCode==='uzima_ug') {
          if ( this.customer.isAnUzimaAgent ) { r = 'uzima_ug_agent'; }
        }
        return r;
      },
    },

    methods: {
      saveImage(uuid) {
        this.$store.dispatch('addImage', { type: 'customer',    type_id: this.customer.uuid, uuid: uuid });
        },
      idImage(uuid)   { this.$store.dispatch('addImage', { type: 'demographic', type_id: this.customer.uuid, uuid: uuid }); },
      async print() { await this.$htmlToPaper('printTarget'); },
    },
  }

</script>


<i18n>
{
  "en": {
    "Take Photos": "Take Photos",
    "Take ID Photos": "Take ID Photos",
    "id": "ID",
    "idImageInfo": "Use your camera to photograph identity and other documents",
    "imageInfo": "Use your camera to take photos of the customer"
  },
  "es": {
    "Take Photos": "Tomar fotos",
    "Take ID Photos": "Tomar Foto de Cédula",
    "id": "C.C",
    "idImageInfo": "Utilice su cámara para fotografiar identidad y otros documentos",
    "imageInfo": "Usa tu cámara para tomar fotos del cliente"
  },
  "sw": {
    "Take Photos": "Piga Picha",
    "Take ID Photos": "Piga Picha za Vitambulisho",
    "id": "ID",
    "idImageInfo": "Tumia kamera yako kupiga picha za utambulisho na hati zingine",
    "imageInfo": "Tumia kamera yako kupiga picha za mteja"
  }
}
</i18n>
