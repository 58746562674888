<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="12" class="text-h3 text--primary text-left">
        <span v-if="status=='eligible'">               Check Me Back of up to <Currency v-model="maxPrincipal" />          </span>
        <span v-else-if="status=='pricing_agreed'">    Sign and Upload Loan Contract                                       </span>
        <span v-else-if="status=='loan_created'">      Waiting for Approvals                                               </span>
        <span v-else-if="status=='approved'">          Cash Received                                                       </span>
        <span v-else-if="status=='concluded'">                                                                             </span>
      </v-col>
    </v-row>

    <div v-if="status=='eligible'">        <Pricing      :customer="customer" v-model="loanRequest" :loanParameters="loanParameters" :topup="topup" @agreed="status='pricing_agreed'" :transfer="transfer" :arrears="arrears" />   </div>
    <div v-if="status=='pricing_agreed'">  <Documents    :customer="customer"                       :loanRequest="loanRequest"        @uploaded="status='docs_submitted'" /> </div>
    <div v-if="status=='docs_submitted'">  <LoanCreation :customer="customer" v-model="instrument"  :loanRequest="loanRequest"  :topup="topup"       @input="status='loan_created'" />      </div>
    <div v-if="status=='loan_created'">    <Approvals    :customer="customer" :instrument="instrument"                                @done="status='approved'" />           </div>
    <div v-if="status=='approved'">        <Authorise    :customer="customer"                                                         @input="status='authorised'" />        </div>
    <div v-if="status=='authorised'">      <Conclude     :customer="customer" :instrument="instrument"  :topup="topup"                @done="status='concluded'" />          </div>
    <div v-if="status=='concluded'">       <ThankYou     :customer="customer"  />                                                                                             </div>

  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import Eligibility from '@/views/partners/ultimate_tz/check_me_back/Eligibility'
import Pricing from '@/views/partners/ultimate_tz/check_me_back/Pricing'
import Documents from '@/views/partners/ultimate_tz/check_me_back/Documents'
import LoanCreation from '@/views/partners/ultimate_tz/check_me_back/LoanCreation'
import Approvals from '@/views/partners/ultimate_tz/check_me_back/Approvals'
import Authorise from '@/views/partners/ultimate_tz/check_me_back/Authorise'
import Conclude from '@/views/partners/ultimate_tz/check_me_back/Conclude'
import ThankYou from '@/views/partners/ultimate_tz/check_me_back/ThankYou'


import Currency from "@/components/Currency";

export default {

  components: { Eligibility, Pricing, Documents, LoanCreation, Approvals, Authorise, Conclude, ThankYou, Currency },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    topup: {
      type: Number,
      required: false,
    },
    transfer: {
      type: Number,
      required: false,
    },
    arrears: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      status: undefined,
      loanParameters: undefined,
      loanRequest: undefined,
      instrument: undefined,
      status: 'initialised',
      noNeedToload: true,
    }
  },

  computed: {
    maxPrincipal() { return ((this.loanParameters || {}).loan_amount || 0); },
  },

  watch: {
    loanParameters(to) {
      if (to) { this.status = 'eligible'; }
    },
  },

  mounted() {
    this.remoteLookup('ultimateTzCheckMeBackLimit', { uuid: this.customer.uuid }).then( limit => this.loanParameters = limit ).catch(e => this.loanParameters = null);
  },

  mixins: [lookups],

}
</script>
