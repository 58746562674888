
export default {
    computed: {
        economicBasicInfo() {
            return (this.customer.economic && this.customer.economic.payload && this.customer.economic.payload.basicInfo)
        },
        familySize() {
            return this.economicBasicInfo ? this.economicBasicInfo.familySize : 0
        },
        yearsFarming() {
            return this.economicBasicInfo ? this.economicBasicInfo.yearsFarming : null
        },
        transport() {
            return this.economicBasicInfo ? this.economicBasicInfo.transport : null
        },
        livelihood() {
            return (this.economicBasicInfo && this.economicBasicInfo.livelihood) ? this.economicBasicInfo.livelihood.join(', ') : null
        },
        regionLocation() {
            return this.economicBasicInfo ? this.economicBasicInfo.region : null
        },
    },
}
