<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <DocumentInput v-model="document" dense type="assessment" :typeId="uuid" label="Drag a file into this box, or click on it to upload a file" />
      </v-col>
      <v-col cols="6">
        <WebCam v-model="webcam" type="assessment" :typeId="uuid" label="Webcam" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div no-gutters v-if="images.length==0  && documents.length==0">
          <i>No documents or images attached.</i>
        </div>
        <div no-gutters v-else>
          <Images :images="images" :documents="documents" />
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Images from "@/components/Images";
import DocumentInput from "@/components/DocumentInput";
import WebCam from "@/components/WebCam";

export default {

  components: { Images, DocumentInput, WebCam },

  props: {
    customer: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      document: undefined,
      webcam: undefined,
    }
  },

  computed: {
    uuid() { return this.customer.uuid; },
    documents() { 
      return this.$store.getters.documents.filter( e => ( e.type_id===this.customer.uuid ));
     },
    images() {
      let i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
      if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
      if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
      if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
      return i;
    },
  },

}
</script>
