<template>
  <v-card max-width="344">
    <v-card-text class="d-flex justify-space-between px-4" >
      <p  v-for="char in identifierArray" class="display-1 text--primary mb-0">
        {{char}}
      </p>
    </v-card-text>
    <v-card-subtitle>{{$t('caption')}}</v-card-subtitle>
  </v-card>

</template>

<script>
import referenceNumbers from '@/mixins/referenceNumbers';

export default {
  props: ['value'],
  data: () => ({
    identifier: null,
  }),
  computed: {
    identifierArray() { return Array.from(this.identifier); },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.identifier = (to || this.generateReferenceNumber());
        this.$emit('input', this.identifier);
      }
    },
    // identifier(to) { this.$emit('input', to); },
  },
  mixins: [referenceNumbers],
};
</script>


<i18n>
{
  "en": {
    "caption": "Farmer Identifier"
  },
  "es": {
    "caption": "Identificará al granjero"
  },
  "sw": {
    "caption": "Kitambulisha Mkulima"
  }
}
</i18n>
