<template>
  <v-container fluid>

    <v-row class="text-left">
      <v-col cols="6" class="text-h4">
        {{customer.name}}
      </v-col>
    </v-row>

    <v-row>

      <v-col cols="6" class="text-left">
        <TestCard class="mx-auto" title="Raise Monthly Interest" :results="interestRaised" @test="raiseInterest">
          <template v-slot:Inputs>
            <v-row>
              Raise Monthly Interest
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              Done
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="6" class="text-left">
        <TestCard class="mx-auto" title="Raise Arrears Interest" :results="arrearsInterestRaised" @test="raiseArrearsInterest">
          <template v-slot:Inputs>
            <v-row>
              Raise Arrears Interest
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              Done
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="6" class="text-left">
        <TestCard class="mx-auto" title="Write Off Loan" :results="writtenOff" @test="writeOff">
          <template v-slot:Inputs>
            <v-row>
              Write Off
            </v-row>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="6" class="text-left">
        <TestCard class="mx-auto" title="Age Loan" :results="loanAged" @test="ageLoan">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="4"><TextInput v-model="ageDays" label="Age By" suffix="days" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              Done
            </div>
          </template>
        </TestCard>
      </v-col>

    </v-row>


  </v-container>
</template>

<script>

import lookups from '@/mixins/lookups';

import TestCard from '@/views/tests/TestCard';
import TextInput from '@/components/TextInput';

export default {

  components: { TestCard, TextInput },

  data() {
    return {
      interestRaised: undefined,
      arrearsInterestRaised: undefined,
      loanAged: undefined,
      ageDays: 10,
      writtenOff: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

  methods: {
    raiseInterest() {
      this.remoteLookup('testUltimateTzRaiseInterest', {
        uuid: this.customer.uuid
      }).then( installment => this.interestRaised = true ).catch(e => this.interestRaised = null);
    },
    ageLoan() {
      this.remoteLookup('testUltimateTzAgeLoan', {
        uuid: this.customer.uuid,
        days: this.ageDays,
      }).then( e => this.loanAged = true ).catch(e => this.loanAged = null);
    },
    raiseArrearsInterest() {
      this.remoteLookup('test_ultimate_tz_raise_arrears_interest', {
        uuid: this.customer.uuid
      }).then( e => this.arrearsInterestRaised = true ).catch(e => this.arrearsInterestRaised = null);
    },
    writeOff() {
      this.remoteLookup('test_ultimate_tz_write_off', {
        uuid: this.customer.uuid
      }).then( e => this.writtenOff = true ).catch(e => this.writtenOff = null);
    },
  },

  mixins: [lookups],

}
</script>
