<template>
  <div>
  <PageHeader :title="farmName" icon="mdi-flower-outline" @subtitle="toCustomer" @back="toCustomer" back>
  </PageHeader>
  <v-card>
    <v-simple-table dense>
      <template v-slot:default>
          <t-body>
            <tr>
              <td>Farm Name:</td>
              <td>{{customerFarm.name}}</td>
            </tr>
        </t-body>
      </template>
    </v-simple-table>
    <v-col cols="12" class="pa-1" v-if="showPlot">
      <MapPolygons :coords="coords" height='300px' />
    </v-col>
  </v-card>
</div>
</template>
<script>
  import PageHeader from '@/components/PageHeader';
  import MapPolygons from '@/components/MapPolygons';

  export default {
    components: { PageHeader, MapPolygons },
    data: () => ({
      farm: undefined
    }),

    computed: {
      farmUuid() { return this.$route.query.farmUuid; },
      farmsForCustomers() { return this.$store.getters.farmsForCustomers(this.customerUuid) },
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      customerFarm() {return this.farmsForCustomers.filter(e => e.uuid == this.farmUuid)[0] },
      showPlot() { return this.customerFarm.map && this.customerFarm.map.length>2; },
      farmName() { return this.customerFarm.name},
      coords() {
        return [{
          customerUuid: this.customerUuid,
          fieldUuid: this.farmUuid,
          caption: this.farmName,
          coordinates: this.customerFarm.map,
        }];
      }
    },

    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    }
  }
</script>