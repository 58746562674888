<template>
  <v-card flat class="text-xs-center item pa-1" @click="$emit('input', target)" elevation="2">
    <v-card-text>
      <div class="subheading">{{caption}}</div>
      <div class></div>
    </v-card-text>
    <v-icon color="primary" class="mr-4 item-icon">{{icon}}</v-icon>
  </v-card>
</template>

<script>
export default{
  props: ['caption', 'icon', 'target', 'value'],
}
</script>

<style scoped>
 v-card {
  color: blue
 }
 .item-icon {
  font-size: 3.0em;
  left: 40%;
 }
</style>
