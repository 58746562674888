<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid>


      <v-row class="text-left">
        <v-col cols="2">
          <v-switch v-model="required" :label="(required ? 'Required' : 'Not Required')" />
        </v-col>
        <v-col cols="2">
          <v-select :items="countries" label="Country" v-model="country" clearable />
        </v-col>
        <v-col cols="2">
          <v-select :items="typeOptions" label="Default ID Type" v-model="defaultType" clearable />
        </v-col>
        <v-col cols="2">
          <v-select :items="typeOptions" label="ID Types" v-model="types" multiple clearable />
        </v-col>
        <v-col cols="2">
          <v-switch v-model="none" :label="(none ? 'Include \'none provided\' as an option' : 'Allow \`none provided\`')" />
        </v-col>
        <v-col cols="2">
          <v-switch v-model="selectable" :label="(selectable ? 'User selectable ID type' : 'Do not show the ID type')" />
        </v-col>
      </v-row>


      <v-row class="text-left text-caption">
        <v-col cols="2"></v-col>
        <v-col cols="2">
          For testing different countries. Usually the component will use the entry set on the partner.
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2">
          Show or hide the ID Type selector. You should probably set the default ID type if you set this.
        </v-col>
      </v-row>

      <v-row>
        <v-col><v-divider /></v-col>
      </v-row>



      <v-row>
        <v-col>
          <IdentityInput v-model="form.identity" :required="required" :country="country" :defaultType="defaultType" :selectable="selectable" :none="none" :idTypes="types" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pre class="text-left">{{form.identity}}</pre>
        </v-col>
      </v-row>



      <v-row>
        <v-col class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import IdentityInput from "@/components/IdentityInput";

export default {

  components: {
    IdentityInput,
  },

  data() {
    return {
      validForm: false,
      form: {},
      required: false,
      none: true,
      selectable: true,
      defaultType: undefined,
      type: undefined,
      types: undefined,
      typeOptions:[
        { value: "voters_card",      text: "Voters Card"       },
        { value: "national_id",      text: "National ID"       },
        { value: "drivers_license",  text: "Drivers License"   },
        { value: "passport",         text: "Passport"          },
        { value: "foreigner_id",     text: "Foreigner ID"      }
      ],
      country: undefined,
      countries: [
        { text: 'Botswana', value: 'BW' },
        { text: 'Colombia', value: 'CO' },
        { text: 'Kenya', value: 'KE' },
        { text: 'South Africa', value: 'ZA' },
        { text: 'Tanzania', value: 'TZ' },
        { text: 'Uganda', value: 'UG' },
      ]
    }
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  }

}
</script>
