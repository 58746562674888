
export default {
    methods: {
        async printMixin() {
            console.log('printMixin')
            await this.$htmlToPaper('printTarget', null,() => {
            });
        },
    },
};
