<template>
  <v-card>
    <v-card-title>
      <v-icon>{{icon}}</v-icon>
      <v-toolbar-title>{{$t(activity)}}</v-toolbar-title>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-text-field v-model="number" type="number" :rules="numberRules" min=0 :hint="$t('numberHint')" :label="$t('numberLabel')" ></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>


<script>
  import validationRules from '@/mixins/validationRules';

  export default {
    props: ['activity', 'type', 'icon', 'value'],

    data: () => ({
      number: undefined,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.number = to; }
      },
      number(to) {
        this.$emit('input', to);
      },
    },

    mixins: [validationRules],
  }
</script>


<i18n>
{
  "en": {
    "numberLabel": "Animals",
    "numberHint": "Number of animals farmed",
    "pigs": "Pigs",
    "c_pigs": " ",
    "dairy": "Dairy",
    "c_dairy": "Milk cows",
    "beef": "Beef",
    "c_beef": " ",
    "small stock": "Small Stock",
    "c_small stock": "Goats, sheep and other small ungulates.",
    "other_livestock": "Other Livestock",
    "c_other_livestock": "Other types of animals or birds"
  },
  "es": {
    "numberLabel": "Animales",
    "numberHint": "Número de animales criados",
    "pigs": "pigs",
    "c_pigs": "c_pigs",
    "dairy": "dairy",
    "c_dairy": "c_dairy",
    "beef": "beef",
    "c_beef": "c_beef",
    "small stock": "small stock",
    "c_small stock": "small c_stock",
    "other_livestock": "other",
    "c_other_livestock": "c_other"
  },
  "sw": {
    "numberLabel": "Wanyama",
    "numberHint": "Idadi ya wanyama wanaofugwa",
    "pigs": "Nguruwe",
    "c_pigs": " ",
    "dairy": "Maziwa",
    "c_dairy": "Ng'ombe za maziwa",
    "beef": "Nyama ya ng'ombe",
    "c_beef": " ",
    "small stock": "Hisa ndogo",
    "c_small stock": "Mbuzi, kondoo na watu wengine wadogo wanaomulika.",
    "other_livestock": "Nyingine",
    "c_other_livestock": "Aina zingine za wanyama au ndege"
  }
}
</i18n>

