<template>
  <InstrumentUltimateTzCheckMeBack   :instrument="instrument" :instrumentId="instrumentId" :customer="customer" v-model="highlight" @instrument="loaded" v-if="theInstrumentType=='InstrumentUltimateTzCheckMeBack'"   />
  <InstrumentUltimateTzSalaryAdvance :instrument="instrument" :instrumentId="instrumentId" :customer="customer" v-model="highlight" @instrument="loaded" v-else-if="theInstrumentType=='InstrumentUltimateTzSalaryAdvance'" />
  <InstrumentUltimateTzGovernment :instrument="instrument" :instrumentId="instrumentId" :customer="customer" v-model="highlight" @instrument="loaded" v-else-if="theInstrumentType=='InstrumentUltimateTzGovernment'" />
</template>

<script>
import InstrumentUltimateTzCheckMeBack from "@/views/instruments/InstrumentUltimateTzCheckMeBack"
import InstrumentUltimateTzSalaryAdvance from "@/views/instruments/InstrumentUltimateTzSalaryAdvance"
import InstrumentUltimateTzGovernment from "@/views/instruments/InstrumentUltimateTzGovernment"

export default {

  components: { InstrumentUltimateTzCheckMeBack, InstrumentUltimateTzSalaryAdvance, InstrumentUltimateTzGovernment },

  // note, if you pass an instrumentId, you must also pass a customer.
  props: {
    value: String,
    instrument: Object,
    instrumentId: Number,
    customer: Object,
    instrumentType: String
  },

  data: () => ({
    highlight: undefined,
  }),

  computed: {
    theInstrumentType() { return (this.instrumentType ? this.instrumentType : (this.instrument ? this.instrument.type : undefined) ); },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.highlight = to; },
    },
    highlight(to) { this.$emit('input', to); },
  },

  methods: {
    loaded(to) { this.$emit('instrument', to); },
  },

}
</script>
