export default {
    methods: {
      generateReferenceNumber() {
        // we want a six digit, base32 encoded result
        // 32 encoded, rather than 36, as we want to swap our i, 1, zero and o.

        let start_of_time = 1596964306;
        let seconds_in_year = 365*24*60*60;
        let t = Math.round(Date.now()/1000) - start_of_time;

        // first digit (some randomness, then the year)
        let random = Math.floor(Math.random() * (16 - 9 + 1)) + 9; // a random component to keep the high digits interesting
        let year = Math.floor(t / seconds_in_year);
        let first = (Math.floor(t / seconds_in_year) + random).toString(32);

        // now the rest of it
        t = t - year*seconds_in_year;
        let lower = t.toString(32);
        let id = first + lower.padStart(5,'0');

        // put it all together
        return id.replace(/0/g,'x').replace(/o/g, 'w').replace(/1/g, 'y').replace(/l/g, 'z');
      },
    }
};

