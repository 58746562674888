<template>
  <span v-if="validSettings">{{internalLatitude}}; {{internalLongitude}}</span>
  <span v-else>Not set</span>
</template>

<script>

export default {

  props: ['location', 'latitude', 'longitude', 'lat', 'lon', 'long'],

  computed: {
    internalLatitude() { return parseFloat(this.latitude || this.lat || (this.location || {}).latitude).toFixed(3); },
    internalLongitude() { return parseFloat(this.longitude || this.lon || this.long || (this.location || {}).longitude).toFixed(3); },
    validSettings() { return !isNaN(this.internalLatitude); },
  }

};
</script>
