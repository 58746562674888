<template>

  <v-container fluid>
    <v-row>

      <v-card v-for="index in indexes" class="mx-auto my-12" max-width="274" >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-img
          height="200"
          :src="srcs[index]"
        ></v-img>

        <v-card-title>{{( images[index]['uploaded'] ? 'Uploaded' : 'Not uploaded' )}}</v-card-title>

      </v-card>

    </v-row>
  </v-container>
    
</template>


<script>
  import ImageDb from '@/store/ImageDb';

  export default {
    computed: {
      images() { return this.$store.getters.images; },
      indexes() { return [...Array(this.images.length).keys()] },
    },

    data: () => ({
      srcs: [],
      model: 0,
      pending: null,
    }),

    watch: {
      images: {
        immediate: true,
        handler(to) {
          this.srcs = [];
          this.images.forEach(image => {
          if (image.url) {
            this.srcs.push(image.url);
          } else if (image.uuid) {
            ImageDb.url(image.uuid).then( e => this.srcs.push(e) );
          }
        })},
      },
    },

    mounted() {
      this.pendig = ImageDb.pending();
      console.log(this.pending);
    },

  }
</script>
