export default {

  computed: {
    countryCode() { return this.$store.getters.settings.countryCode; },
  },

  methods: {
    formatted(amount) {
      if (this.countryCode==='TZ' || this.countryCode==='BW') {
        return this.tz(amount);
      } else {
        return this.default(amount);
      }
    },
    default(amount) {
      let a = parseFloat(amount);
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    },
    tz(amount) {
      let a = parseInt(amount);
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

}