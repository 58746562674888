<template>

  <v-form ref="otpsForm" v-model="valid" class="text-left" >

    <v-row>    
      <v-col cols="12" md="5">
        <MsisdnInput v-model="customer.msisdn" />
      </v-col>
      <v-col cols="12" md="5">
        <v-otp-input length="4" v-model="customer.msisdnOtp" label="OTP" :rules="[requiredRule]" />
      </v-col>
      <v-col cols="12" md="2">
        <v-btn>Resend</v-btn>
      </v-col>
    </v-row>

    <v-row>      
      <v-col cols="12" md="5">
        <MsisdnInput v-model="customer.alternativeMsisdn" label="Alternate Telephone" />
      </v-col>
      <v-col cols="12" md="5">
        <v-otp-input length="4" v-model="customer.alternativeMsisdnOtp" label="OTP" />
      </v-col>
      <v-col cols="12" md="2">
        <v-btn>Resend</v-btn>
      </v-col>
    </v-row>

    
  </v-form>

</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";

export default {

  components: { MsisdnInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.otpsForm.validate();
    },
    requiredRule(value) {
      let r = [
        v => !!v || this.$t('required'),
      ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
      return ( typeof(r)==='string' ? r : true );
    },
  },

}
</script>
