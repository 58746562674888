<template>
  <ServerSideResult :test="actions">

    <v-data-table dense :headers="headers" :items="actions" item-key="action" class="elevation-1" style="width: 100%;">

      <template v-slot:item.follow_up_at="{ item }">
        <Time :time="item.follow_up_at" class="grey--text" v-if="item.follow_up_at && item.closed_at" />
        <Time :time="item.follow_up_at"                    v-else-if="item.follow_up_at && !item.closed_at" />
        <Time :time="item.created_at" class="grey--text"   v-else-if="item.created_at" />
      </template>

      <template v-slot:item.activity="{ item }">
        <code>{{item.activity}}</code>
      </template>

      <template v-slot:item.closed_at="{ item }">
        <v-icon color="success" v-if="item.closed_at">mdi-check-bold</v-icon>
        <v-checkbox v-else @change="toggle(item.uuid)" />
      </template>

    </v-data-table>
      
  </ServerSideResult>
</template>


<script>
  import lookups from '@/mixins/lookups';

  import ServerSideResult from '@/components/ServerSideResult';
  import Time from "@/components/Time";

  export default {

    components: { ServerSideResult, Time },

    props: {
      customer: Object,
    },

    data: () => ({
      actions: undefined,
      headers: [
        {
          text: 'Follow Up',
          align: 'start',
          sortable: true,
          value: 'follow_up_at',
        },
        {
          text: 'Activity',
          align: 'start',
          sortable: true,
          value: 'activity',
        },
        {
          text: 'Notes',
          align: 'start',
          sortable: false,
          value: 'note',
        },
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'closed_at',
        },
      ],
    }),

    methods: {
      refresh() {
        this.remoteLookup('get_actions_for_customer', { uuid: this.customer.uuid }).then( actions => this.actions = actions.actions ).catch(e => this.actions = null);
      },
      toggle(uuid) {
        this.remoteLookup('toggle_action_status', { uuid: uuid }).catch(e => console.log(e));
      },
    },

    mounted() {
      this.refresh();
    },

    mixins: [lookups],

  }
</script>
