<template>
  <div>

    <div>
      <v-stepper v-model="e1">
      <v-stepper-header v-if="action != 'edit'">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
        >
          Step 1. Search For Customer Or Select from the Recent list
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
        >
          Step 2. Select Field To Map
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="1">
          <v-card
            class="mb-12 mx-auto my-12"
            color="grey lighten-1"
          >
            <v-container v-if="recent_select">
              <v-card-title>Recently Selected Customers</v-card-title>
              <v-row>
                <v-col v-for="recent in recentSelectedCustomers">
                  <v-card
                      class="pa-1 rounded-lg rounded-x-0"
                      color="#3566af"
                      @click="customer=recent"
                      outlined
                      tile
                    >
                      {{recent.name}}    
                    </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-divider class="mx-4"></v-divider>
            <v-container>
              <v-card-title>OR Select Customers</v-card-title>
              <div class="d-flex flex-row align-center mb-6">
                <v-autocomplete
                  v-model="customer"
                  :items="searchItems"
                  :search-input.sync="search"
                  color="white"
                  placeholder="Start typing to Search"
                  prepend-icon="mdi-database-search"
                  item-text="name"
                  item-value=""
                  return-object
                  style="min-width: 100px; max-width: 100%;"
                ></v-autocomplete>
                <v-btn
                  color="primary"
                  @click="action !='edit' ? nextSlide() : moveToCustomerEdit(); recent_select = false;"
                  class="flex-grow-0 pa-2 mx-3"
                  :disabled="!customer"

                >
                  Continue
                </v-btn>
                  <!-- <v-btn depressed color="primary" @click="recent_select = !recent_select" class="flex-grow-0 pa-2 ml-10" style="min-width: 200px; max-width: 100%;">{{ recent_select ? "ALL" : "See Recent Selected" }}</v-btn> -->
              </div>
            </v-container>
            <v-card-actions class="pb-6">
              <v-layout row>
                <v-flex justify-center>
                  <v-btn text rounded outlined @click="$emit('toggleShowCustomerSearch'); recent_select = true" class="mt-5">
                    Cancel
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">

          <v-card
            class="mb-12"
            color="grey lighten-1"
            height="200px"
          >
            <v-container>
              <h2 class="mb-2 pb-2">Customer Fields</h2>
              <v-row>
                <v-col v-for="fieldItem in fieldItems">
                  <v-card
                      class="pa-1 rounded-lg rounded-x-0"
                      color="#3566af"
                      @click="field=fieldItem"
                      outlined
                      tile
                    >
                      {{fieldItem.name}}    
                    </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-layout row>
                <v-flex justify-center>
                  <v-btn text rounded outlined @click="e1 = 1; recent_select = true" class="mt-5">
                    Cancel
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

          </v-card>

          <!-- <v-btn
            color="primary"
            :disabled="!field"
            @click="moveToFieldMapping"
          >
            Continue to Map Field
          </v-btn> -->
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    </div>

  </div>
</template>
<script>
  export default {
    name: "CustomerSearch",
    data() {
      return {
        customer: null,
        search: null,
        fieldSearch: null,
        field: null,
        fields: null,
        recent_select: true,
        e1: 1,
      }
    },
    props: ['action'],
    computed: {
      searchItems() {
        if(this.action === '/mapField') {
          return this.$store.getters.customers.filter(customer => customer.fields.length >= 1)
        }
        return this.$store.getters.customers;
      },
      fieldItems() {
        if (this.customer != null && typeof(this.customer) === 'object'){
          this.fields = (this.customer.fields || []).filter(field => field.deleted===undefined )
          return (this.customer.fields || []).filter(field => field.deleted===undefined );
        }
      },
      recentSelectedCustomers() {
        return this.$store.getters.searchedCustomers
      }
    },
    methods: {
      moveToFieldMapping() {
        console.log("customer: ", this.customer)
        if(this.field != null) {
          this.$router.push({
            path: '/mapField',
            query: {
              uuid: this.customer.uuid,
              fieldUuid: this.field.uuid
            }
          })
        }
      },
      moveToCustomerEdit() {
        let recent_selected = this.$store.getters.searchedCustomers
        recent_selected = recent_selected.filter( el => el.uuid !== this.customer.uuid );
        if(this.customer != null && typeof(this.customer) === 'object') {
          recent_selected = [this.customer, ...recent_selected]
          recent_selected.splice(5, recent_selected.length)
          this.$store.dispatch('searchedCustomers', recent_selected)
        }
        if(this.customer != null && this.action === 'edit') {
          this.$router.push({
            path: `/customers/${this.action}`,
            query: {
                uuid: this.customer.uuid, 
              }
            })
        }
      },
      nextSlide() {
        let recent_selected = this.$store.getters.searchedCustomers
        recent_selected = recent_selected.filter( el => el.uuid !== this.customer.uuid );
        if(this.customer != null && typeof(this.customer) === 'object') {
          recent_selected = [this.customer, ...recent_selected]
          recent_selected.splice(5, recent_selected.length)
          this.$store.dispatch('searchedCustomers', recent_selected)
        }
        this.e1 = 2
      }
    },
    watch: {
      customer(val) {
        let recent_selected = this.$store.getters.searchedCustomers
        recent_selected = recent_selected.filter( el => el.uuid !== this.customer.uuid );
        if(val != null && typeof(val) === 'object') {
          recent_selected = [val, ...recent_selected]
          recent_selected.splice(5, recent_selected.length)
          this.$store.dispatch('searchedCustomers', recent_selected)
        }
        if(val != null && this.action === 'edit') {
          this.$router.push({
            path: `/customers/${this.action}`,
            query: {
              uuid: val.uuid, 
            }
          })
        }
        else if(val != null && this.action === 'mapField' && this.customer.fields.length === 1) {
          this.$router.push({
            path: '/mapField',
            query: {
              uuid: this.customer.uuid,
              fieldUuid: this.customer.fields[0].uuid
            }
          })
        }
        else {
          this.e1 = 2
        }
      },
      field(val) {
        if(val != null) {
          this.$router.push({
            path: '/mapField',
            query: {
              uuid: this.customer.uuid,
              fieldUuid: val.uuid
            }
          })
        }
      }
    }
  }
</script>
<style scoped></style>
