<template>

    <v-dialog v-model="dialog" width="400" >

      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="color" :small="small" :dense="dense" :disabled="disabled" width="100%" v-bind="attrs" v-on="on"><v-icon dense>{{icon}}</v-icon> {{caption}}</v-btn>
      </template>

      <v-card>
        <web-cam ref="webcam" :device-id="deviceId" width="100%" @started="onStarted"  @stopped="onStopped"  @error="onError" @cameras="onCameras" @camera-change="onCameraChange" />

        <v-card-text>
          <v-img :src="image" v-if="image" height="75" width="75" />
          <span v-else>Use your camera to take a photo. Then, if you are happy with the photo, click save. It will be stored locally and uploaded when you next have an internet connection.</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary"      @click="click"  v-if="!image">Click</v-btn>
          <v-btn color="primary" text @click="retake" v-if="image">Retake</v-btn>
          <v-btn color="primary"      @click="save" v-if="image">Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

</template>

<script>

  import ImageDb from '@/store/ImageDb';

  // From: https://codesandbox.io/s/w64x5y568w?file=/src/components/HelloWorld.vue:0-2914

  import { WebCam } from "vue-web-cam";

  export default {

    components: { WebCam },

    props: {
      value: String,
      label: String,
      icon: {
        type: String,
        default: "mdi-camera",
      },
      type: {
        type: String,
        required: true,
        validator(value) { return ['selfie', 'id_scan', 'field', 'assessment', 'payslip', 'bank_statement', 'direct_debits', 'id_card_front', 'id_card_back', 'work_id', 'credit_check', 'transactional'].includes(value); },
      },
      typeId: String,
      required: Boolean,
      dense: Boolean,
      small: Boolean,
      width: {
        type: [String, Number]
      }
    },

    data () {
      return {
        imageId: undefined,
        dialog: false,
        image: undefined,
        camera: null,
        deviceId: null,
        devices: []
      }
    },

    computed: {
      caption() {
        return this.label || this.$t(this.type.replace('_',' '));
      },
      color() {
        return ( this.imageId ? 'success' : 'primary' );
      },
      remoteLookupApiKey() { return this.$store.getters.apiKey; },
      disabled() { return ( !(this.type==='transactional' || this.typeId) ); },

      device() { return this.devices.find( e => e.deviceId==this.deviceId ); },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          this.imageId = to;
        }
      },
      camera: function(id) { this.deviceId = id; },
      devices: function() {
        let first = (this.devices || [])[0];
        if (first) {
          this.camera = first.deviceId;
          this.deviceId = first.deviceId;
        }
      },
      dialog(to) {
        if (this.$refs.webcam) {
          if (to) {
            this.image = undefined;
            this.start();
          } else {
            this.stop();
          }
        }
      },
      imageId(to) { this.$emit('input', to); },
    },

    methods: {

      onStarted(stream) { console.log("On Started Event", stream); },
      onStopped(stream) { console.log("On Stopped Event", stream); },
      onError(error) { console.log("On Error Event", error); },

      onCameras(cameras) {
        this.devices = cameras;
        console.log("On Cameras Event", cameras);
      },
      onCameraChange(deviceId) {
        this.deviceId = deviceId;
        this.camera = deviceId;
        console.log("On Camera Change Event", deviceId);
      },

      stop() { this.$refs.webcam.stop(); },
      start() {
        let _this = this;
        setTimeout(function() {
          console.log('start');
          _this.$refs.webcam.start();
        }, 100);

     },

      click() { this.image = this.$refs.webcam.capture(); },
      retake() {
        this.image=undefined;
        this.start();
      },

      cancel() { this.dialog = false; },

      save() {
        this.dialog = false;
        this.imageId = ImageDb.add(this.image);
        this.$store.dispatch('addImage', { type: this.type, type_id: this.typeId, uuid: this.imageId, 'authorization': this.remoteLookupApiKey });
      },
    },

    mounted() {
      // this.start();
    },

  }
</script>