<template>
  <v-text-field
    v-model="name"
    :rules="allRules"
    :counter="computedCounter"
    :label="$t(label)"
     @input="handleInput"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
  export default {
    props: {
      value: String,
      label: String,
      counter: Number,
      required: Boolean,
      rules: Array,
    },

    data: () => ({
      name: null,
    }),

    computed: {
      computedCounter() { return this.counter || 30; },
      allRules() {
        return !!this.rules ? [this.nameRules, ...this.rules] : [this.nameRules]
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.name = to; }
      },
    },

    methods: {
      handleInput() {
        this.$emit('input', (this.name ? this.name.trim() : this.name) );
      },
      nameRules(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
          v => /^.{0,30}$/.test(v) || this.$t('maxLength'),
          v => !(/[^\w-\s]/.test(v)) || this.$t('Characters only'),
          v => !(/\d/.test(v)) || this.$t('No numbers'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },
  };
</script>


<i18n>
{
  "en": {
    "maxLength": "Must be less than 30 characters",
    "Characters only": "Characters only",
    "No numbers": "No numbers"
  },
  "es": {
    "maxLength": "Debe tener menos de 30 caracteres",
    "Characters only": "Solo personajes",
    "No numbers": "Sin números"
  },
  "sw": {
    "maxLength": "Lazima iwe chini ya herufi 30",
    "Characters only": "Wahusika pekee",
    "No numbers": "Hakuna nambari"
  }
}
</i18n>