<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <Headline :instrument="instrument" v-model="highlight" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Headline from "@/views/instruments/Instrument"

export default {

  components: { Headline },

  props: {
    value: {
      type: String,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    instrument: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      highlight: undefined,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.highlight = to; },
    },
    highlight(to) { this.$emit('input', to); },
  },

  methods: {
    changeHighlight(to) {
      console.log(to);
    }
  }

}
</script>
