<template>
  <PageHeader :title="$t('title')"  v-if="customer" back @back="toCustomer" print @print="print" >

    <v-container fluid class="pa-0 mt-2" id="printTarget" >

      <v-row no-gutters >
        <v-col cols="12" class="text-left">
          <span class="text-h4 mr-2">{{$t('deliveredBy')}}</span>
          <span class="text-h4 font-weight-bold">{{customer.name}}</span>
        </v-col>
      </v-row>

      <v-row no-gutters >

        <v-col cols="12" sm="6" md="4" class="pa-2" v-if="delivery.deliveryNumber">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              {{ delivery.deliveryNumber }}
            </v-card-title>
            <v-card-subtitle>
              {{$t('deliveryNumber')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <Time :time="delivery.createdAt" format="date" />
            </v-card-title>
            <v-card-subtitle>
              {{$t('delivered')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <NumberFormatter :value="delivery.quantity" /> kg
            </v-card-title>
            <v-card-subtitle>
              {{$t('quantity')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              {{delivery.cropName}}
            </v-card-title>
            <v-card-subtitle>
              {{$t('crop')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2" v-if="delivery.storageCosts">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <Currency :value="delivery.storageCosts" />
            </v-card-title>
            <v-card-subtitle>
              {{$t('storageCosts')}}
            </v-card-subtitle>
          </v-card>
        </v-col>

      </v-row>
    </v-container>


  </PageHeader>
</template>

<script>
  import PageHeader from '@/components/PageHeader';
  import Time from '@/components/Time';
  import Currency from '@/components/Currency';
  import AppMenuItem from '@/components/AppMenuItem';
  import NumberFormatter from '@/components/NumberFormatter';
  
  export default {

    components: { PageHeader, Time, Currency, AppMenuItem, NumberFormatter },

    data: () => ({
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      deliveryUuid() { return this.$route.query.deliveryUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      delivery() { return this.$store.getters.delivery(this.deliveryUuid); },
    },

    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      async print() { await this.$htmlToPaper('printTarget'); },
    },


  }
</script>


<i18n>
{
  "en": {
    "title": "Delivery",
    "storageCosts": "Storage Costs",
    "deliveryNumber": "Receipt Number",
    "delivered": "Delivered",
    "deliveredBy": "Delivered By"
  },
  "es": {
    "title": "Entrega",
    "storageCosts": "Costos de almacenamiento",
    "deliveryNumber": "Número de recibo",
    "delivered": "Entregado en",
    "deliveredBy": "Entregado por"
  },
  "sw": {
    "title": "Utoaji",
    "storageCosts": "Gharama za Uhifadhi",
    "deliveryNumber": "Namba ya risiti",
    "delivered": "Iliyotolewa saa",
    "deliveredBy": "Iliyotolewa na"
  }
}
</i18n>
