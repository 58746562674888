<template>
  <UzimaUg v-if="partnerCode==='uzima_ug'" />
  <MilkCollection v-else-if="domain==='milk'" />
  <!-- <NileUg v-else-if="partnerCode==='nile_ug'" /> -->
  <Default v-else />
</template>

<script>
import domain from '@/mixins/domain';

import Default from '@/views/login/partners/Default';
import UzimaUg from '@/views/login/partners/UzimaUg';
import MilkCollection from '@/views/dairy/MilkCollection';
import NileUg from '@/views/login/partners/NileUg';

export default {
  components: { Default, UzimaUg, NileUg, MilkCollection },
  computed: {
    language() { return this.$store.getters.settings.language; },
    partnerCode() { return this.$store.getters.settings.partnerCode; },
  },
  mounted() {
    // this.$i18n.locale = this.language;
  },
  mixins: [domain],
};
</script>

