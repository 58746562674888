
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" v-if="customer">
        <Widget title="Basics">
          <v-simple-table>
            <tbody>
              <Row title="Name"      :text="approval.customer_name" bold="true" />
              <Row title="Telephone" :msisdn="customer.msisdn" />
              <Row title="Alternative Number" :msisdn="customer.alternative_msisdn" />
              <Row title="Email" :text="customer.email" />
              <Row title="National ID" :text="customer.demographic.identity.number" />
            </tbody>
          </v-simple-table>
        </Widget> 
      </v-col>
      <v-col cols="6">
        <Widget title="Loan Request">
          <v-simple-table v-if="instrument">
            <tbody>
              <Row title="Principal"       :number="instrument.amount" v-if="instrument.amount"/>
              <Row title="Cash"      :number="payload.cash" v-if="payload"/>
              <Row title="Mobile Money"          :number="payload.mobileMoney" v-if="payload"/>
              <Row title="EFT"          :number="payload.eft" v-if="payload"/>
            </tbody>
          </v-simple-table>
        </Widget> 
      </v-col>

      <v-col cols="6">
        <Widget title="Terms">
          <v-simple-table v-if="instrument">
            <tbody>
              <Row title="Interest Rate"      :number="(instrument.interest_rate*100)"/>
              <Row title="Net Disbursement" :number="payload.netDisbursement" v-if="payload" />
              <Row title="Interest" :number="payload.interest" v-if="payload" />
              <Row title="Repayable" :number="payload.repayable" v-if="payload" />
              <Row title="Installment" :number="payload.installment" v-if="payload" />
              <Row title="Installments" :number="payload.duration" v-if="payload" />
              <Row title="InstallmentDay" :number="payload.installmentDay" v-if="payload" />
              <Row title="First Installment" :number="payload.firstInstallment" v-if="payload" />
              <Row title="Last Installment" :number="payload.lastInstallment" v-if="payload" />
            </tbody>
          </v-simple-table>
        </Widget>
      </v-col>

      <v-col cols="6">
        <Widget title="Approval" v-if="approval">
          <v-simple-table>
            <tbody>
              <Row title="Created"   :time="approval.created_at" />
              <Row title="Request"   :text="approval.description" />
              <Row title="Status"    :status="approval.status"  />
            </tbody>
          </v-simple-table>
        </Widget>
      </v-col>
    </v-row>


    <v-row v-if="images">
      <v-col cols="12">
        <div no-gutters v-if="images && images.length>0">
          <Images :images="images" />
        </div>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
  import Widget from '@/components/Widget';
  import Row from "@/components/Row";
  import Images from "@/components/Images";

  export default {

    components: { Widget, Row, Images },

    props: ['approval'],

    computed: {
      instrument() { return this.approval.target },
      payload() { return this.instrument.payload },
      customer() { return this.$store.getters.customer(this.approval.customer_uuid || this.approval.customerUuid); },
      images() {
        let i = undefined;
        if (this.customer) {
          i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
          if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
          if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
          if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
        }
        return i;
      },
    },
  }
</script>
