<template>
  <span v-if="!(value===undefined || value===null)">
    {{ formatted(value) }}
    <small v-if="unit">{{unit}}</small>
  </span>
</template>


<script>
  import formatter from "@/mixins/numberFormatter";

  export default {

    props: ['value', 'unit', 'classes'],
    mixins: [formatter],
  }
</script>
