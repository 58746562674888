<template>
  <PageHeader title="Waiting for Approvals" icon="mdi-check-all">

    <template v-slot:buttons>
      <DebouncedButton dense small width="100%" color="primary" @click="loadApprovals">
        <v-icon dense>mdi-refresh</v-icon> Refresh
      </DebouncedButton>
    </template>

    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <CustomerApprovals :approvals="approvals" @refresh="loadApprovals" />
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>
</template>

<script>
import lookups from '@/mixins/lookups';
import CustomerApprovals from '@/views/approvals/CustomerApprovals';
import DebouncedButton from '@/components/DebouncedButton';
import PageHeader from '@/components/PageHeader';

export default {

  components: { CustomerApprovals, DebouncedButton, PageHeader },

  props: ['customer'],

  data() {
    return {
      approvals: undefined,
    }
  },

  computed: {
    count() { return (this.approvals ? this.approvals.length : -1); }
  },

  watch: {
    count(to) {
      if (to===0) {
        this.$emit('done');
      }
    },
  },
    
  methods: {
    loadApprovals() {
      this.remoteLookup('ultimate_TzLoanApprovals', { uuid: this.customer.uuid }).then( approvals => this.approvals = approvals.approvals ).catch(e => this.approvals = null);
    }
  },

  mounted() { this.loadApprovals(); },

  mixins: [lookups],

}
</script>
