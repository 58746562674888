<template>
  <DeliveriesUzimaUg :customer="customer" v-if="partner==='uzima_ug'" />
  <DeliveriesDefault :customer="customer" v-else-if="partner==='default'" />
</template>

<script>
  import DeliveriesDefault from '@/views/customers/components/DeliveriesDefault';
  import DeliveriesUzimaUg from '@/views/customers/components/DeliveriesUzimaUg';

  export default {

    components: { DeliveriesDefault, DeliveriesUzimaUg },

    props: ['customer'],

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      partner() {
        let r = 'default';
        if (this.partnerCode==='uzima_ug') { r = 'uzima_ug'; };
        return r;
      }
    },

  }
</script>
