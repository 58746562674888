<template>
  <PageHeader
      :title="title"
      :icon="icon"
      back
      @back="$emit('back')"
      print @print="delayedPrint" >
    <div id="printTarget">
      <slot></slot>
    </div>
  </PageHeader>
</template>

<script>

import PageHeader from '@/components/PageHeader';

export default {

  components: { PageHeader },

  props: ['title', 'icon'],
  data() {
    return {
      isPrinting: false
    }
  },

  methods: {
    async print() {
      await this.$htmlToPaper('printTarget', null,() => {
        this.isPrinting = false
      });
    },
    delayedPrint () {
      // delayed part to give time for template conditionals to render
      this.isPrinting = true
      setTimeout(() => {
        this.print()
      }, 100);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.isPrinting = to
      }
    },
    isPrinting(to) { this.$emit('input', to); },
  },
};
</script>
<style>
 #printTarget {
   width: 100%;
 }
</style>
