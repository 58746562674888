<template>
  <PageHeader title="Approvals" icon="mdi-check-all">

    <template v-slot:buttons>
      <DebouncedButton @click="refresh" color="primary" small dense class="ma-1"><v-icon dense>mdi-refresh</v-icon> Refresh</DebouncedButton>
    </template>

    <ServerSideResult :test="approvals" style="width: 100%;">
      <Approvals :approvals="approvals" />
    </ServerSideResult>

  </PageHeader>
</template>


<script>
  import lookups from '@/mixins/lookups';

  import PageHeader from "@/components/PageHeader";
  import ServerSideResult from '@/components/ServerSideResult';
  import DebouncedButton from '@/components/DebouncedButton';

  import Approvals from '@/views/approvals/components/Approvals';

  export default {

    components: { PageHeader, ServerSideResult, Approvals, DebouncedButton },

    data: () => ({
      approvals: undefined,
    }),
    
    methods: {
      refresh() {
        this.remoteLookup('approvals', {}).then( approvals => this.approvals = approvals.approvals ).catch(e => this.approvals = null);
      }
    },

    mounted() { this.refresh(); },

    mixins: [lookups],

  }
</script>
