<template>
  <v-container fluid class="pa-0 text-left">

    <v-form>
      <v-row no-gutters>

        <v-col cols="12" sm="5" class="px-1">
          <v-autocomplete
            :items="pests"
            label="Plagas y Enfermedades"
            v-model="pest"
            @change="intervention=undefined"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" class="px-1" v-if="interventions">
          <v-autocomplete
            :items="interventionOptions"
            label="Químicos posibles"
            v-model="intervention"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="1" class="px-1" v-if="interventions" align-self="center">
          <v-btn color="primary" small dense :disabled="!intervention" @click="addIntervention">Add</v-btn>
        </v-col>

      </v-row>

      <v-row no-gutters>
        <v-card v-for="recommendation in recommendations" class="ma-1">
          <v-card-subtitle>{{ recommendation.pest }}</v-card-subtitle>
          <v-card-title>{{ recommendation.intervention }}</v-card-title>
          <v-card-text>
            <p>Recommended: {{recommendation.source}}</p>
            <p><v-text-field v-model="recommendation.quantity" label="Quantity" type="number" :suffix="recommendation.unit"></v-text-field></p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary lighten-2" text @click="removeRecommendation(recommendation)" >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>

    </v-form>


  </v-container>
</template>


<script>
  import pestsAndRecommendationsData from '@/mixins/partners/hr_co/pests';

  export default {

    props: ['value'],

    data () {
      return {
        pest: undefined,
        intervention: undefined,
        recommendations: [],
      }
    },

    computed: {
      pests() { return Object.keys(this.pestsAndRecommendations) },
      interventions() { return this.pestsAndRecommendations[this.pest]; },
      interventionOptions() { return (this.interventions || []).map( e => e.intervention ).sort(); },
      areCardFormsValid() {
        console.log('areCardFormsValid')
        return true;
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.recommendations = (to || []); }
      },
      recommendations(to) { this.$emit('input', recommendations); },
    },

    methods: {
      addIntervention() {
        let i = this.interventions.find( e => (e.intervention==this.intervention) );
        i.pest = this.pest;
        this.recommendations.push(i);
        this.intervention = undefined;
      },
      removeRecommendation(recommendation) {
        console.log(recommendation);
        const index = this.recommendations.findIndex( e => ((e.pest===recommendation.pest) && (e.intervention==recommendation.intervention)) );
        if (index > -1) { this.recommendations.splice(index, 1); }
      },
      validate() {
        this.$refs.form3.validate();
      },
    },

    mixins: [pestsAndRecommendationsData],
  }
</script>