

import i18n from '@/i18n'

export const requiredValidation = v => !!v || 'Field is required'


export class HugoValidations {
    requiredValidation(v) {
        // return !!v || 'Field is required';
        return !!v || i18n.t('validation.fieldRequired');
    }
    requiredValidationSpanish(v) {
        // todo depricated
        return !!v || 'El campo es obligatorio';
    }
}
