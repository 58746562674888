<template>
  <v-form v-model="validVoucher">
    <PageHeader icon='mdi-ticket' title='Redeem a Voucher' save @save="validate" :valid='validVoucher' saveCaption="Validate" >

      <v-row no-gutters v-masonry>

        <v-col cols="12" class="pa-1">
          <v-text-field label="Voucher Number" placeholder="4FRXSPRX" solo style="font-size: 40px; text-align: center;" :rules="voucherRules" ></v-text-field>
        </v-col>
      </v-row>

    </PageHeader>
  </v-form>
</template>


<script>
  import PageHeader from '@/components/PageHeader';

  export default {

    components: { PageHeader },

    data: () => ({
      validVoucher: false,
      voucher_id: undefined,
      voucherRules: [ v => (!!v && (v.length==8)) || "Invalid entry" ],
    }),

    computed: {
    },

    methods: {
      validate() { return true; },
    },

  }
</script>
