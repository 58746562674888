<template>
  <IndexCompact :customers="customers" :moc="moc" v-if="breakpoint=='xs'" />
  <IndexNormal :customers="customers" :moc="moc" v-else />
</template>


<script>
  import IndexCompact from '@/views/customers/partners/uzima_ug/IndexCompact';
  import IndexNormal from '@/views/customers/partners/uzima_ug/IndexNormal';

  export default {
    components: { IndexCompact, IndexNormal },
    props: ['customers'],
    computed: {
      breakpoint() { return this.$vuetify.breakpoint.name; },
      moc() {
        let date = new Date();
        let start = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0]
        let r = {};
        this.$store.getters.deliveries.filter(e => (e.createdAt > start)).forEach( e => r[e.deliveredToUuid] = (r[e.deliveredToUuid] || 0) + Number(e.quantity) );
        return r;
      },
    },
  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
