<template>

  <v-row>
    <v-col cols="12" class="text-h4 text--primary">
      Thank You {{customer.name}}.
    </v-col>
    <v-col cols="12" class="text-h5">
      Ultimate Finance appreciates your business.
    </v-col>
  </v-row>


</template>

<script>
export default {
  props: ['customer'],
}
</script>
