<template>
  
  <v-container fluid class="pa-0">

        <v-row>

          <v-col cols="12" sm="6">
            <DateTimeInput
              v-model="startTime"
              dateLabel="Date"
              timeLabel="Time"
              :min="new Date().toISOString().substr(0, 10)"
              :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="!noDuration">
            <v-select class="mt-4"
              :items="durations"
              label="Duration"
              v-model="durationMinutes"
            ></v-select>
          </v-col>

        </v-row>

  </v-container>    

  
</template>

<script>
  import parseDate from '@/mixins/parseDate'

  import DateTimeInput from '@/components/DateTimeInput';

  export default {

    props: ['value', 'hideDuration'],

    components: { DateTimeInput },

    data: () => ({
      startTime: null,
      durationMinutes: 60,
      durations: [ { value: 15, text: '15 Minutes' }, { value: 30, text: 'Half an hour' }, { value: 45, text: '45 Minutes' }, { value: 60, text: 'One hour' }, { value: 90, text: 'An hour and a half' }, { value: 120, text: 'Two hours' }, { value: 180, text: 'Three hours' }, { value: 240, text: 'Four hours' }, { value: 480, text: 'All day' } ],
    }),

    computed: {
      validForm() { return !(this.startTime==null); },
      noDuration() { return this.hideDuration || this.hideDuration=='' },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.startTime = to.startsAt;
            this.durationMinutes = to.durationMinutes;
          } else {
            this.time = null;
          }
        },
      },
      startTime() { this.update(); },
      durationMinutes() { this.update(); },
    },

    methods: {
      update() {
        if (this.startTime && this.durationMinutes) {
          let appointment = {
            'startsAt': this.startTime,
            'endsAt': this.parseDateTime(this.startTime).plus({minutes: this.durationMinutes}).toISO(),
          };
          if (!this.noDuration) { appointment['durationMinutes:'] = this.durationMinutes; }
          this.$emit('input', appointment);
        }
      }
    },

    mixins: [parseDate],
  }
</script>