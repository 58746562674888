<template>
  <span v-if="!(value===undefined || value===null)">
    {{ rounded }}
    <small>%</small>
  </span>
</template>


<script>

  export default {

    props: {
      value: Number,
      decimals: {
        type: Number,
        default: 0,
      },
    },

    computed: {
      rounded() { return ((this.value || 0) * 100.0).toFixed(this.decimals); },
    },

  }
</script>
