<template>
  <v-form ref="settlementForm" v-model="valid" style="width: 100%;">
    <v-container fluid>

      <v-row v-if="disbursing">
        <v-col cols="6">
          <CurrencyInput v-model="cash" label="Cash Received" />
        </v-col>
        <v-col>
          <v-btn @click="settlement" color="primary" :disabled="!maySettle">{{caption}}</v-btn>
        </v-col>
      </v-row>

      <v-row v-else>
        <ServerSideResult :test="settlementProcess">
          <v-col cols="12" class="text-h4 text--primary text-left">
            Thank you. Your settlement has been processed.
          </v-col>
        </ServerSideResult>
      </v-row>

    </v-container>
  </v-form>
</template>

<script>
import lookups from '@/mixins/lookups';

import CurrencyInput from "@/components/CurrencyInput";
import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { CurrencyInput, ServerSideResult },

  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      cash: 0,
      cashReceiptNumber: undefined,
      disbursing: true,
      settlementProcess: undefined,
    }
  },

  computed: {
    settlementAmount() { return ((this.instrument.settlement_amount || 0)/100.0); },
    maySettle() { return this.valid && (this.cash>=0) && ( (this.instrument) && (this.cash<=this.settlementAmount ) ) },
    caption() { return (this.cash==this.settlementAmount ? 'Settle Loan' : 'Pay'); },
  },

  watch: {
    instrument(to) {
      if (to && this.cash==0) {
        this.cash = this.settlementAmount;
      }
    }
  },

  methods: {
    // validate() { this.$refs.settlementForm.validate(); },
    settlement() {
      this.disbursing = false;
      this.remoteLookup('instrument_ultimate_tz_salary_advance_settlement', {
        uuid: this.customer.uuid,
        id: this.instrument.id,
        cash: this.cash,
      }).then( e => this.settlementProcess = 'done' ).catch(e => this.settlementProcess = null);
    },
  },

  mixins: [lookups],

}
</script>
