<template>
  <PageHeader title="Customers" icon="mdi-account-multiple" refresh @refresh="refresh" >

    <v-col cols=12 v-if="customersInitialised && customers.length==0">
      {{$t('noData')}}
      <v-btn elevation="2" small :to="{ name: 'CreateContact' }" color="primary">Create One</v-btn>
    </v-col>

    <v-col cols="12" v-else>
      <v-tabs v-model="tab">
        <v-tab>All</v-tab>
        <v-tab>Open Loans</v-tab>
        <v-tab>Due</v-tab>
        <v-tab>Bucket 0</v-tab>
        <v-tab>Bucket 1</v-tab>
        <v-tab>Bucket 2</v-tab>
        <v-tab>Bucket 3</v-tab>
        <v-tab>Bucket 4</v-tab>
        <v-tab>Bucket 5</v-tab>
        <v-tab>Bucket 6</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item><Customers :data="data" /></v-tab-item>
        <v-tab-item><Customers :data="openLoans" /></v-tab-item>
        <v-tab-item><DueAt :data="openLoans" /></v-tab-item>
        <v-tab-item><Customers :data="bucket0" /></v-tab-item>
        <v-tab-item><Customers :data="bucket1" /></v-tab-item>
        <v-tab-item><Customers :data="bucket2" /></v-tab-item>
        <v-tab-item><Customers :data="bucket3" /></v-tab-item>
        <v-tab-item><Customers :data="bucket4" /></v-tab-item>
        <v-tab-item><Customers :data="bucket5" /></v-tab-item>
        <v-tab-item><Customers :data="bucket6" /></v-tab-item>
      </v-tabs-items>

    </v-col>


  </PageHeader>
</template>


<script>
  import instrumentNames from '@/mixins/instrumentNames';

  import lookups from '@/mixins/lookups';
  import PageHeader from '@/components/PageHeader';
  import Customers from '@/views/customers/partners/ultimate_tz/Customers';
  import DueAt from '@/views/customers/partners/ultimate_tz/DueAt';

  export default {

    components: {
      PageHeader,
      Customers,
      DueAt,
    },

    data: () => ({
      tab: null,
      instruments: undefined,
      data: undefined,
    }),

    computed: {
      customersInitialised() { return this.$store.getters.customersInitialised; },
      customers() { return this.$store.getters.customers.filter( e => !!e.msisdn || !!e.partnerIdentifier || !!e.name || !!e.email ); },
      uuids() { return this.customers.map( e => e.uuid ) },

      openLoans() { return (this.data || []).filter( e => e.product ) },
      bucket0() { return (this.data || []).filter( e => e.bucket===0 ) },
      bucket1() { return (this.data || []).filter( e => e.bucket===1 ) },
      bucket2() { return (this.data || []).filter( e => e.bucket===2 ) },
      bucket3() { return (this.data || []).filter( e => e.bucket===3 ) },
      bucket4() { return (this.data || []).filter( e => e.bucket===4 ) },
      bucket5() { return (this.data || []).filter( e => e.bucket===5 ) },
      bucket6() { return (this.data || []).filter( e => e.bucket===6 ) },
    },

    watch: {
      instruments: {
        immediate: true,
        handler(to) {
          if (to && this.customers) {
            this.data = this.customers.map( customer => {
              let instrument = to.find( e => e.customer_id===customer.id ) || {};
              return {
                partnerIdentifier: customer.partnerIdentifier,
                name: customer.name,
                employer: (customer.employer || {}).name,
                product: this.instrumentTypeToName(instrument.type),
                installments: instrument.duration,
                amount: parseInt(instrument.amount),
                balance: instrument.balance,
                msisdn: customer.msisdn,
                uuid: customer.uuid,
                bucket: (instrument.type ? 2 : undefined),
                due_at: new Date(Date.parse(instrument.next_financial_milestone_at)),
              };
            } );
          }
        },
      },
    },

    methods: {
      refresh() {
        console.log('refresh');
      },
      loadData() {
        this.remoteLookup('instruments', {uuids: JSON.stringify(this.uuids)}).then( instruments => this.instruments = instruments.instruments ).catch(e => this.instruments = null);
      },
    },

    mounted() {
      this.loadData();
    },

    mixins: [lookups, instrumentNames],

  }
</script>


<i18n>
{
  "en": {
    "noData": "There are no customers saved locally.",
    "all": "All"
  },
  "es": {
    "noData": "No hay agricultores, contactos u otros perfiles guardados localmente.",
    "all": "Todos"
  },
  "sw": {
    "noData": "Hakuna mkulima, mawasiliano au wasifu mwingine uliookolewa ndani.",
    "all": "Kila Mtu"
  }
}
</i18n>

