<template>
  <v-card>
    <v-card-title v-if="title">
      {{title}}
    </v-card-title>
    <v-card-text v-if="test">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>


<script>
  export default {
    props: {
      title: {
        type: String,
        required: false,
      },
      test: {
        default: true,
      }
    }
  }
</script>
