<template>
  <LoadAndShow :uuid="uuid" />
</template>


<script>

  import LoadAndShow from '@/views/customers/components/LoadAndShow';

  export default {

    components: {
      LoadAndShow,
    },

    computed: {
      uuid() { return this.$route.query.uuid; },
    },

  }
</script>
