<template>

  <v-form ref="basicForm" v-model="valid" >

    <v-row>
      <v-col cols="12" md="6" class="d-flex">
        <v-checkbox
            label="No/Low Till"
            v-model="formFields.conservationTechnique.lowTill.answer"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <v-checkbox
            label="Mulching/Cover crop"
            v-model="formFields.conservationTechnique.mulching.answer"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <v-checkbox
            label="Crop rotation/Intercropping"
            v-model="formFields.conservationTechnique.coverCrop.answer"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <v-checkbox
            label="Basin method"
            v-model="formFields.conservationTechnique.basinMethod.answer"
        ></v-checkbox>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="d-flex">
        <SelectInput
          label="Primary crop"
          :items="crops"
          class="text-left"
          v-model="formFields.mainCrop"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <SelectInput
          label="Origin of seed planted"
          :items="['EcoExist', 'Govt', 'From last season']"
          class="text-left"
          multiple
          v-model="formFields.originOfSeed"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="formFields.seedBatchCode"
          :counter="9"
          label="Seed Batch Code"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <SelectInput
          label="Origin of fertilizer"
          :items=itemOptions
          class="text-left"
          multiple
          v-model="formFields.originOfFertilizer"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="formFields.fertlizerBatchCode"
          :counter="9"
          label="Fertilizer Batch Code"
        ></v-text-field>
      </v-col>
    </v-row>
    <div v-if="formFields.conservationTechnique.coverCrop.answer">
      <v-row>
        <v-col cols="12" md="6" class="d-flex">
          <SelectInput
            label="Seconday crop"
            :items="crops"
            class="text-left"
            v-model="formFields.secondaryCrop"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <SelectInput
          label="Origin of seed planted"
          :items="['EcoExist', 'Govt', 'From last season']"
          class="text-left"
          multiple
          v-model="formFields.originOfSecondarySeed"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="formFields.secondarySeedBatchCode"
            :counter="9"
            label="Seed Batch Code"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <SelectInput
            label="Origin of fertilizer"
            :items=itemOptions
            class="text-left"
            multiple
            v-model="formFields.originOfSecondaryFertilizer"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="formFields.secondaryFertlizerBatchCode"
            :counter="9"
            label="Fertilizer Batch Code"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" md="6">
          <v-radio-group
            v-if="showCAFieldRadio"
            readonly
            v-model="CAScore"
            label="Type Of Field (Read Only)"
            :rules="[(v) => !!v || 'Required']"
          >
            <v-radio
                label="CA field"
                :value="walkFieldType.CA_FIELD"
            ></v-radio>
            <v-radio
                label="Non-CA field"
                :value="walkFieldType.NON_CA_FIELD"
            ></v-radio>
          </v-radio-group>
        </v-col>
    </v-row>

    <v-row v-if="showDistanceToHomestead">
      <v-col cols="12" md="6"  xs="6" class="d-flex">

        <TextInput
            v-model="formFields.distanceToHomestead.answer"
            :label="formFields.distanceToHomestead.label"
            required />
      </v-col>
    </v-row>

    <slot>

    </slot>
  </v-form>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import TextInput from "@/components/TextInput";
import RegionInput from "@/components/RegionInput";
import fields from "@/mixins/fields";
import {WalkFieldType} from "@/views/partners/eco_bw/views/fieldSurvey/field-survey";

export default {
  components: {
    RegionInput,
    SelectInput,
    TextInput,
  },
  props: {
    value: Object,
    customer: Object
  },
  data() {
    return {
      valid: false,
      showDistanceToHomestead: false,
      showCAFieldRadio: false,
      walkFieldType:WalkFieldType,
      itemOptions: ['EcoExist', 'Govt', 'Manure', 'No manure added'],
      coverCropLegumes: ["Groundnuts", "Bambara nuts", "Cow peas", "Beans", "Cereals", "Other legumes"],
      formFields: {
        mainCrop: null,
        originOfSeed: [],
        originOfFertilizer: [],
        fertlizerBatchCode: null,
        seedBatchCode: null,
        secondaryCrop: null,
        originOfSecondarySeed: [],
        originOfSecondaryFertilizer: [],
        secondaryFertlizerBatchCode: null,
        secondarySeedBatchCode: null,
        fieldType: this.CAScore ? this.CAScore : 'NonCAField',
        conservationTechnique : {
          lowTill: {
            label: 'Low Till',
            answer: false
          },
          basinMethod: {
            label: 'Basin method',
            answer: false
          },
          mulching: {
            label: 'Mulching/Cover crop',
            answer: false
          },
          coverCrop: {
            label: 'Crop rotation/Intercropping',
            answer: false,
            legumesSelected: []
          },
          distanceToHomestead: {
            label: 'Distance to homestead (meters)',
            answer: null
          }
        }
      }
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!!to) {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
    regionFormField:{
      deep: true,
      handler(to) {
        const village = to.village.village
        if (village){ this.updateField(village)}
      },
    },
    CAScore: {
      handler(to) {
        this.formFields.fieldType = to;
      }
    },
  },

  computed: {
    crops() { return this.$store.getters.crops.filter(item => { return isNaN(item.text);}); },
    fieldUuid() { return this.$route.query.fieldUuid; },
    CAScore() {
      let score = ['mulching', 'basinMethod', 'lowTill', 'coverCrop'].filter(fieldName => this.formFields.conservationTechnique[fieldName].answer === true).length;
      return score > 1 ? this.walkFieldType.CA_FIELD : this.walkFieldType.NON_CA_FIELD   
    },
  },

  methods: {
    validate() {
      this.$refs.basicForm.validate();
    },
    updateField(location) {
      const x = this.customer.fields.filter(item => { return item.uuid == this.fieldUuid})
      if (x) {x[0].location = location}
    }
  },

  mixins: [fields],
}
</script>

<style scoped>

</style>
