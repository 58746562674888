<template>
  <SelectInput :items="followUpOptions" v-model="future" @input="change" v-bind="$attrs" />
</template>

<script>
import SelectInput from "@/components/SelectInput";

export default {

  components: { SelectInput },

  props: {
    value: undefined,
    never: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    future: undefined,
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  }),

  computed: {
    followUpOptions() {
      let r = [];
      if ( (new Date).getHours()<=16 ) { r.push('This evening'); }
      r.push('Tomorrow');
      for(let i = 2; i < 7; i++){
        r.push(this.days[ (this.currentDay + i)%7 ]);
      }
      r.push('In One Week')
      r.push('In Two Weeks')
      if (this.never) { r.push('Never'); }
      return r;
    },
    currentDay() { return (new Date).getDay(); },
  },


  methods: {
    change(to) {
      let d = undefined;
      if (to) {
        d = new Date;
        d.setHours(8);
        d.setMinutes(0);
        if (to==='This evening') {
          d.setHours(19);
        } else if (to==='Tomorrow') {
          d = new Date;
          d.setDate(d.getDate() + 1);
        } else if (to==='In One Week') {
          d.setDate(d.getDate() + 7);
        } else if (to==='In Two Weeks') {
          d.setDate(d.getDate() + 14);
        } else if (to==='Never') {
          d = null;
        } else {
          let selected = this.days.findIndex( e => e===to );
          let offset = 0;
          if (selected < this.currentDay) {
            offset = 7 - this.currentDay + selected;
          } else {
            offset = selected - this.currentDay;
          }
          d.setDate(d.getDate() + offset);
        }
        this.$emit('input', d);
      }
    },
  },

};

</script>