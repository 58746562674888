<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <NameInput v-model="business.name" :label="$t('Business Name')" required />
        </v-col>
        <v-col cols="12" md="4">
          <NameInput v-model="business.branchName" :label="$t('Branch Name')" />
        </v-col>
        <v-col cols="12" md="4">
          <MsisdnInput v-model="business.msisdn" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field label="Branch Address" type="text" v-model="business.branchAddress"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="business.url" placeholder="Web Site" :rules="rules"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox v-model="government" label="Government" />
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox v-model="bank" label="Bank" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn color="warning" small dense @click="validate">Validate</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="save" color="primary" :disabled="!valid">Save</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import MsisdnInput from '@/components/MsisdnInput';
import NameInput from '@/components/NameInput';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    NameInput,
    MsisdnInput,
  },

  data() {
    return {
      business: { uuid: uuidv4(), government: false, bank: false },
      valid: true,
      government: false,
      bank: false,
      rules: [
        (value) => ((value || '')=='') || this.isURL(value) || "URL is not valid",
      ]
    }
     
  },

  watch: {
    government(to) {
      if (to) { this.bank=false; }
      this.business.government = to;
    },
    bank(to) {
      if (to) { this.government=false; }
      this.business.category = (to ? 'bank' : undefined);
    },
  },

  methods: {
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },

    save() {
      this.$store.dispatch('upsertBusiness', this.business);
      this.$router.push({ name: 'Home' });
    },

    validate() {
      this.$refs.form.validate();
    },
  }
}
</script>