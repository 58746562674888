<template>
  <ServerSideResult :test="fieldAgents">
    <v-select
      :label="label"
      :items="fieldAgents"
      item-value="id"
      item-text="name"
      v-model="fieldAgentId"
      @change="handleChange"
      :rules="required ? [requiredRule] : []"
    ></v-select>
  </ServerSideResult>
</template>

<script>
  import lookups from '@/mixins/lookups';

  import ServerSideResult from "@/components/ServerSideResult";

  export default {

    components: {  ServerSideResult },

    props: {
      value: Object,
      required: Boolean,
      label: {
        type: String,
        default: 'Field Agent',
      },
      depot: {
        type: Boolean,
        default: false,
      },
      online: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      fieldAgentId: null,
      fieldAgents: undefined,
    }),

    computed: {
      isOnline() { return this.$store.getters.networkOnline; },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.fieldAgentId = to.id;
          } else {
            this.fieldAgentId = null;
          }
        }
      },
    },

    methods: {
      handleChange() { this.$emit('input', this.fieldAgents.filter( e => e.id===this.fieldAgentId )[0] ); },
      requiredRule(value) {
        let r = [
          v => !this.required || !!v || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },

    mounted() {
      if (this.isOnline) {
        this.remoteLookup('getFieldAgents', {
          uuid: this.uuid,
          depot: this.depot,
        }).then( fieldAgents => this.fieldAgents = fieldAgents ).catch(e => this.fieldAgents = this.$store.getters.settings.fieldAgents);
      } else if (!this.online) {
        this.fieldAgents = this.$store.getters.settings.fieldAgents;
      }
    },

    mixins: [lookups],

  };
</script>
