<template>
  <div class="text-left mt-2">
    <h4 class="mt-4">Field Corridor Awareness</h4>

    <div class="pa-2">


      <div class="d-flex justify-space-between">
        <p>Evidence of field expansion onto elephant corridor</p>
        <div>
          {{fieldCorridorAwareness.evidenceFieldExpansionOntoElephantCorridor}}
        </div>


      </div>


      <div class="d-flex justify-space-between">
        <p>Field registration date</p>
        <div>

          <Time :time="fieldCorridorAwareness.fieldRegistrationDate" format="date" />
        </div>


      </div>

      <div class="d-flex justify-space-between">
        <p>Are corridors helping</p>
        <div>
          <StarRating
              v-model="fieldCorridorAwareness.areCorridorsHelping"
              :readonly="true"
          />

        </div>


      </div>
    </div>



  </div>
</template>

<script>
import StarRating from "@/components/StarRating";
import Time from "@/components/Time";

export default {
  props: {
    fieldCorridorAwareness: {
      type: Object,
    }
  },
  components: {
    StarRating,
    Time
  }
}
</script>

<style scoped>

</style>
