import { v4 as uuidv4 } from 'uuid';

export default {

  methods: {
    upsertAction(action) {
      console.log(action.target);
      let newAction = {
        uuid: uuidv4(),
        target_uuid: action.target.uuid,
        target_type: 'Customer',
        follow_up_at: action.follow_up_at,
        activity: action.action,
        note: action.note,
      }
      this.$store.commit('upsertAction', newAction);
    }
  },

}