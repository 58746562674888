<template>
  <PageHeader
      :title="$t('technicalVisitView')"
      save
      @save="saveForm"
      :valid="isFormValid"
      @validate="validate"
      validate
      @back="goBack"
      back
  >
    <v-main class="pt-0">
      <v-card-title class="align-self-start">
        {{$t('register')}}
      </v-card-title>

      <v-form ref="form">
        <v-divider></v-divider>
        <v-container fluid class="p-0">
          <v-row class="green lighten-5">
            <v-col cols="12" md="4">
              <DateInput
                  class="w-100"
                  persistent-hint
                  data-cy="dateInput"
                  v-model="formFields.register.visitDate.answer"
                  :label="formFields.register.visitDate.question"
                  :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
                  :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
                  :rules="[sharedValidations.requiredValidation]"
              />
            </v-col>

          </v-row>
          <v-row class="green lighten-5">
            <v-col cols="12" sm="4">
              <v-text-field
                  v-model="formFields.register.dayAfterTransplant.answer"
                  :label="formFields.register.dayAfterTransplant.question"
                  type="number"
                  disabled
                  :rules="[sharedValidations.requiredValidation]"
                  required
              ></v-text-field>
              <p>Fecha estimada del trasplante:
                <Time :time="this.cultivationContractAssessment.formFields.calculatedTargetDateSeedlingsPlanted.answer || ''" format="date" />
              </p>

            </v-col>

            <v-col cols="12" md="4">
              <v-radio-group
                  row
                  :label="formFields.register.landProperlyPrepared.question"
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.landProperlyPrepared.answer"
                  clearable
                  :disabled="formFields.register.landProperlyPrepared.disabled"
                  data-cy="landProperlyPrepared"
              >
                <v-radio label="Si" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>
              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.landProperlyPrepared.extraObservationAnswer"
                  :disabled="!formFields.register.landProperlyPrepared.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>


            <v-col cols="12" md="4">
              <!--                4-->
              <v-radio-group
                  :label="
                formFields.register.irrigationSystemProperlyWorking.question
              "
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="
                formFields.register.irrigationSystemProperlyWorking.answer
              "
                  clearable
                  row
                  :disabled="formFields.register.irrigationSystemProperlyWorking.disabled"
                  data-cy="irrigationSystemProperlyWorking"
              >
                <v-radio label="Si" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>

              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.irrigationSystemProperlyWorking.extraObservationAnswer"
                  :disabled="!formFields.register.irrigationSystemProperlyWorking.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                5-->
              <v-select
                  v-model="formFields.register.canYouAddFertilizer.answer"
                  :items="[
                  'Ventury',
                  'Flujómetro',
                  'Bomba de Inyección',
                  'otros'
              ]"
                  :label="formFields.register.canYouAddFertilizer.question"
                  :rules="[sharedValidations.requiredValidation]"
                  :disabled="formFields.register.canYouAddFertilizer.disabled"
                  persistent-hint
                  hint="seleccione en el menú o escriba uno diferente"
                  single-line
                  data-cy="canYouAddFertilizer"
              ></v-select >
              <v-text-field
                  v-if="formFields.register.canYouAddFertilizer.answer === 'otros'"
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.canYouAddFertilizer.extraObservationAnswer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>

            </v-col>

            <v-col cols="12" md="4">
              <!--                6-->
              <v-radio-group
                  :label="
                formFields.register.phenologyAccordingToAgeOfPlant.question
              "
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="
                formFields.register.phenologyAccordingToAgeOfPlant.answer
              "
                  clearable
                  row
                  :disabled="formFields.register.phenologyAccordingToAgeOfPlant.disabled"
                  data-cy="phenologyAccordingToAgeOfPlant"
              >
                <v-radio label="Si" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>

              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.phenologyAccordingToAgeOfPlant.extraObservationAnswer"
                  :disabled="!formFields.register.phenologyAccordingToAgeOfPlant.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>

            </v-col>

            <v-col cols="12" md="4">
              <!--                7-->
              <v-text-field
                  persistent-hint
                  :label="formFields.register.waterBalance.question"
                  hint="Especificar MInutos, días y Tandas"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.waterBalance.answer"
                  data-cy="waterBalance"
                  clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row  class="green lighten-5">
            <v-col cols="12" md="4">
              <!--                8-->
              <v-radio-group
                  :label="formFields.register.sticksEstablishedPerDesign.question"
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.sticksEstablishedPerDesign.answer"
                  clearable
                  row
                  :disabled="formFields.register.sticksEstablishedPerDesign.disabled"
                  data-cy="sticksEstablishedPerDesign"
              >
                <v-radio label="Si" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>

              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.sticksEstablishedPerDesign.extraObservationAnswer"
                  :disabled="!formFields.register.sticksEstablishedPerDesign.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                9-->
              <v-radio-group
                  :label="formFields.register.sticksGoodCondition.question"
                  row
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.sticksGoodCondition.answer"
                  clearable
                  :disabled="formFields.register.sticksGoodCondition.disabled"
                  data-cy="sticksGoodCondition"
              >
                <v-radio label="Si" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>

              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.sticksGoodCondition.extraObservationAnswer"
                  :disabled="!formFields.register.sticksGoodCondition.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                10-->
              <v-text-field
                  persistent-hint
                  :label="formFields.register.goodCropAdministration.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.goodCropAdministration.answer"
                  clearable
                  data-cy="goodCropAdministration"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                11-->
              <v-text-field
                  persistent-hint
                  :label="formFields.register.enoughLabourForPhase.question"
                  hint="De cuantas personas se dispone por cada hectárea"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.enoughLabourForPhase.answer"
                  clearable
                  data-cy="enoughLabourForPhase"
              ></v-text-field>

            </v-col>

            <v-col cols="12" md="4">

              <v-radio-group
                  hint="De cuantas personas se dispone por cada hectárea"
                  persistent-hint
                  :label="
                formFields.register.previousRecommendationMade.question
              "
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="
                formFields.register.previousRecommendationMade.answer
              "
                  row
                  data-cy="previousRecommendationMade"
              >
                <v-radio label="Si" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>

              <v-text-field
                  v-if="formFields.register.previousRecommendationMade.answer === 'no'"
                  :label="$t('form.label.extraObservation')"

                  v-model="formFields.register.enoughLabourForPhase.extraObservationAnswer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                13-->
              <v-select
                  persistent-hint
                  :items="[
                  '1 pésimo',
                  '2 deficiente',
                  '3 aceptable',
                  '4 bueno',
                  '5 excelente',
                  ]"
                  :label="formFields.register.complyOfTheRecommendation.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.complyOfTheRecommendation.answer"
                  clearable
                  data-cy="complyOfTheRecommendation"
              ></v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="green lighten-5">
            <v-col cols="12" md="4">
              <!--                19-->
              <v-text-field
                  :label="formFields.register.otherPendingTasks.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.otherPendingTasks.answer"
                  clearable
                  data-cy="otherPendingTasks"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                20-->
              <v-text-field
                  :label="formFields.register.observations.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.observations.answer"
                  data-cy="observations"
                  clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <!--        pesticide section-->
<!--          <v-divider class="my-4"></v-divider>-->
<!--          <div class="subtitle-2 text-left">Aplicaciones</div>-->
          <PesticideListInput
              v-model="formFields.pesticideFormFields"
              ref="pesticideForm"
          />

                    <v-divider class="my-4"></v-divider>

<!--          <br />-->
          <h4 class="text-left">
            {{$t('whichHrPhase')}}
          </h4>
          <br />

          <v-row class="green lighten-5">
            <v-col>
              <v-select
                  v-if="allPhases.length"
                  :items="allPhases"
                  label="Seleccionar fase"
                  item-text="name"
                  item-value="phase"
                  :value="selectedPhaseQuery"
                  clearable
                  :rules="[sharedValidations.requiredValidation]"
                  required
                  @change="phaseHandler"
                  data-cy="selectedPhaseQuery"
              ></v-select>
            </v-col>
          </v-row>


        </v-container>

        <div>
          <h2 class="text-left my-5">
            {{ sheetFivePhasesEnum[selectedPhaseQuery] }}
          </h2>

          <div v-if="selectedPhaseQuery === 'phaseOne'">
            <Phase1Container
                v-model="phaseFormFields"
                :field="field"
                :customer="customer"
                ref="form2"
            />
          </div>
          <div v-if="selectedPhaseQuery === 'phaseTwo'">
            <Phase2Container
                v-model="phaseFormFields"
                ref="form2"
            />
          </div>
          <div v-if="selectedPhaseQuery === 'phaseThree'">
            <Phase3Container
                v-model="phaseFormFields"
                ref="form2"/>
          </div>
          <div v-if="selectedPhaseQuery === 'phaseFour'">
            <Phase4Container
                v-model="phaseFormFields"
                ref="form2"/>
          </div>
        </div>
        <div class="text-left">
          <SignatureInput
              required
              :type="this.imageUploadTypeEnums.technicalVisitView"
              :typeId="this.field.uuid"
              @saveImage="saveSignatureImage"
          >
            <SingleImage maxHeight="200px"  :image="formFields.signedImage" />
          </SignatureInput>

        </div>
        <v-divider></v-divider>
        <v-container
            class="px-0 text-left"
            fluid
        >
          <v-checkbox
              v-model="isPhaseComplete"
              :label="`completar y cerrar esta fase: ${isPhaseComplete ? 'verdadero' : 'falso'}`"
          ></v-checkbox>
          <p style="min-height: 24px">
            <template v-if="isPhaseComplete">
              no podrá volver a llenar esta fase. pasarás a la siguiente fase
            </template>
          </p>
        </v-container>


        <div class="d-flex justify-content-between" style="width: 100%;">

          <v-card class="col-4 text-left mb-2" tile>
            <v-subheader>{{$t('hr.validations')}}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('register')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action data-cy="register-validation">
                <v-icon v-if="isRegisterFormValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('hr.pesticide')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="arePesticideFormsValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('hr.phase')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action  data-cy="phase-validation">
                <v-icon v-if="isSelectedPhaseValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('form.label.extraObservation')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="areExtraObservationsValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('signature')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="isDocumentSigned" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-card>
          <div class="ml-auto align-self-end">
            <div class="text-right hidden-xs-only">
              <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="this.validate">
                {{$t('validate')}}
              </v-btn>
              <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="this.saveForm" :disabled="!isFormValid" >
                {{$t('save')}}
              </v-btn>
            </div>

          </div>

        </div>
      </v-form>

    </v-main>

  </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import DateInput from "@/components/DateInput";
import { v4 as uuidv4 } from "uuid";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import {areFormFieldsFilled, daysBetweenDates} from "@/views/partners/hr_co/utils";
import Time from "@/components/Time";
import SignatureModal from "@/components/SignatureComponent/SignatureModal";
import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";
import PesticideListInput from "@/views/partners/hr_co/components/pestsAndDiseasesInput/PesticideListInput";
import {assessmentNameEnum, sheetFivePhases} from "@/views/partners/hr_co/constant";
import Phase4Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseFour/Phase4Container";
import Phase1Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseOne/Phase1Container";
import Phase2Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseTwo/Phase2Container";
import Phase3Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseThree/Phase3Container";
import SignatureInput from "@/components/SignatureComponent/SignatureInput";

export default {
  components: {
    PesticideListInput,
    PageHeader,
    DateInput,
    Phase1Container,
    Phase2Container,
    Phase3Container,
    Phase4Container,
    TakePhoto,
    SingleImage,
    Time,
    SignatureModal,
    SignatureInput
  },
  name: "TechnicalVisitView",
  data() {
    return {
      selectedPhaseRef: undefined,
      phaseFormFields: undefined,
      isPhaseComplete: false,
      sheetFivePhasesEnum: sheetFivePhases,
      allPhases: [],
      formFields: {
        register: {
          visitDate: {
            question: "1 Fecha Visita",
            answer: undefined,
          },
          dayAfterTransplant: {
            question: "2 Día después de transplante (DDT)",
            answer: undefined,
          },
          landProperlyPrepared: {
            question:
                "3 El terreno se encuentra adecuadamente preparado y con su sistema de drenajes bien establecido?",
            answer: undefined,
            extraObservationAnswer: undefined

          },
          irrigationSystemProperlyWorking: {
            question: "4 ¿El Sistema de riego está en buen funcionamiento?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          canYouAddFertilizer: {
            question:
                "5 ¿Puede adicionar los fertilizantes por el sistema de riego?",
            answer: undefined,
          },
          phenologyAccordingToAgeOfPlant: {
            question: "6 ¿La Fenología del cultivo va acorde a su edad?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          waterBalance: {
            question: "7 Suministro de Riego Recomendado",
            answer: undefined
          },
          sticksEstablishedPerDesign: {
            question:
                "8 ¿Se implementó el tutorado según el diseño establecido?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          sticksGoodCondition: {
            question: "9 ¿Está el Tutorado en buen estado?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          goodCropAdministration: {
            question: "10 ¿Se ha realizado buena Administración del cultivo?",
            answer: undefined,
          },
          enoughLabourForPhase: {
            question:
                "11 ¿Hay suficiente mano de obra para esta fase del cultivo?",
            answer: undefined,
          },
          previousRecommendationMade: {
            question:
                "12 ¿Se realizaron las recomendaciones de la visita anterior?",
            answer: undefined,
          },
          complyOfTheRecommendation: {
            question:
                "13 ¿Cómo calificaría el cumplimiento de las recomendaciones?",
            answer: undefined,
          },
          // pestDiseases: {
          //   question: "14 Plagas/Enfermedades",
          //   answer: undefined,
          // },
          // plantsConditionsProduction: {
          //   question: "15 Estado de las plantas/Producción",
          //   answer: undefined,
          // },
          // fertilization: {
          //   question: "16 Fertilización",
          //   answer: undefined,
          // },
          // organicMaterialApplication: {
          //   question: "17 Aplicación Materia orgánica",
          //   answer: undefined,
          // },
          // timingQuantityWater: {
          //   question: "18 Aplicación Materia orgánica",
          //   answer: undefined,
          // },
          otherPendingTasks: {
            question: "19 Otras tareas pendientes",
            answer: undefined,
          },
          observations: {
            question: "20 Observaciones",
            answer: undefined,
          },
        },
        signedImage: undefined,
        pesticideFormFields: []
      },
      sharedValidations: new HugoValidations(),
      selectedPestsSearch: []
    };
  },
  mounted: function () {
    this.allPhases = this.createAllPhasesSelectItems()
    if (this.previousAssessment) {
      if (this.previousAssessment.visitAssessmentForm.register.landProperlyPrepared.answer === 'yes') {
        this.formFields.register.landProperlyPrepared.answer = 'yes'
        this.formFields.register.landProperlyPrepared.disabled = true
      }

      if (this.previousAssessment.visitAssessmentForm.register.irrigationSystemProperlyWorking.answer === 'yes') {
        this.formFields.register.irrigationSystemProperlyWorking.answer = 'yes'
        this.formFields.register.irrigationSystemProperlyWorking.disabled = true
      }

      // if (this.previousAssessment.visitAssessmentForm.register.canYouAddFertilizer.answer === 'yes') {
      //   this.formFields.register.canYouAddFertilizer.answer = 'yes'
      //   this.formFields.register.canYouAddFertilizer.disabled = true
      // }

      if (this.previousAssessment.visitAssessmentForm.register.phenologyAccordingToAgeOfPlant.answer === 'yes') {
        this.formFields.register.phenologyAccordingToAgeOfPlant.answer = 'yes'
        this.formFields.register.phenologyAccordingToAgeOfPlant.disabled = true
      }

      if (this.previousAssessment.visitAssessmentForm.register.sticksEstablishedPerDesign.answer === 'yes') {
        this.formFields.register.sticksEstablishedPerDesign.answer = 'yes'
        this.formFields.register.sticksEstablishedPerDesign.disabled = true
      }

      if (this.previousAssessment.visitAssessmentForm.register.sticksGoodCondition.answer === 'yes') {
        this.formFields.register.sticksGoodCondition.answer = 'yes'
        this.formFields.register.sticksGoodCondition.disabled = true
      }
    }
  },
  methods: {
    saveSignatureImage(imageObj) {

      console.log('saveSignatureImage', imageObj)
      this.$store.dispatch("addImage", imageObj);
      this.formFields.signedImage = imageObj;
    },
    phaseHandler(value) {
      this.phaseFormFields = undefined;
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, phase: value },
      });
    },
    saveForm() {
      const newAssessment = {
        assessmentUuid: uuidv4(),
        assessmentName: 'technicalVisit',
        customerUuid: this.customerUuid,
        fieldUUid: this.field.uuid,
        createdAt: new Date().toISOString(),
        fields: this.formFields,
        visitAssessmentForm: this.formFields,
        selectedPhaseEnum: this.selectedPhaseQuery,
        selectedPhase: this.sheetFivePhasesEnum[this.selectedPhaseQuery],
        phaseForm: this.phaseFormFields,
        isPhaseComplete: this.isPhaseComplete
      };

      this.$store.commit("upsertAssessment", newAssessment);
      this.goAssessment(newAssessment.assessmentUuid);
    },
    validate() {
      this.$refs.form.validate();
      this.$refs.form2.validate();
      this.$refs.pesticideForm.validate();
    },
    goBack() {
      this.$router.push({
        name: "Field",
        query: { uuid: this.customerUuid, fieldUuid: this.field.uuid },
      });
    },
    goAssessment(assessmentUuid) {
      this.$router.push({
        name: "FieldAssessment",
        query: { assessmentUuid: assessmentUuid },
      });
    },
  createAllPhasesSelectItems() {
    const isPhaseOneComplete = this.isPhaseOneComplete
    const isPhaseTwoComplete = this.isPhaseTwoComplete
    const isPhaseFourComplete = this.isPhaseFourComplete
    return [
      {
        name: !isPhaseOneComplete ? sheetFivePhases.phaseOne : `${sheetFivePhases.phaseOne} íntegro`,
        phase: 'phaseOne',
        value: sheetFivePhases.phaseOne,
        disabled: isPhaseOneComplete
      },
      {
        name: !isPhaseTwoComplete ? sheetFivePhases.phaseTwo : `${sheetFivePhases.phaseTwo} íntegro`,
        phase: 'phaseTwo',
        value: sheetFivePhases.phaseTwo,
        disabled: this.isPhaseTwoComplete
      },
      {
        name: sheetFivePhases.phaseThree,
        phase: 'phaseThree',
        value: sheetFivePhases.phaseThree,
        disabled: true
      },
      {
        name: !isPhaseFourComplete ? sheetFivePhases.phaseFour : `${sheetFivePhases.phaseFour} íntegro`,
        phase: 'phaseFour',
        value: sheetFivePhases.phaseFour,
        disabled: isPhaseFourComplete
      }
    ]
  }
  },
  computed: {
    isFormValid() {

      // todo separate dynamic forms validations

      // this.$refs.form2 is dynamic component with computed boolean value isFormGroupValid
      let formFieldsValid
      if (this.selectedPhaseQuery === 'phaseThree') {
        formFieldsValid = this.isRegisterFormValid && this.$refs.form2 && this.$refs.form2.areCardFormsValid

      } else {
        // other phases
        formFieldsValid = this.isRegisterFormValid && this.isSelectedPhaseValid
      }
      return formFieldsValid && this.areExtraObservationsValid && this.arePesticideFormsValid && this.isDocumentSigned
    },
    isDocumentSigned() {
      return !!this.formFields.signedImage
    },
    isRegisterFormValid() {
      return areFormFieldsFilled(Object.values(this.formFields.register))
    },
    isSelectedPhaseValid() {
      return !!this.phaseFormFields && !Object.values(this.phaseFormFields).find(formObject => !areFormFieldsFilled(Object.values(formObject)))
    },
    areExtraObservationsValid() {
      const isValid = Object.values(this.formFields.register)
          .filter(formField =>
              'extraObservationAnswer' in formField && !formField.extraObservationAnswer
          )

      return isValid.length === 0;
    },
    arePesticideFormsValid() {
      const allInvalid = this.formFields.pesticideFormFields.filter(pesticideFormFields => {
        return !pesticideFormFields.formField.valid
      })
      return allInvalid.length === 0;
    },
    customerUuid() {
      return this.$route.query.uuid;
    },

    fieldUuid() {
      return this.$route.query.fieldUuid;
    },

    customer() {
      return this.$store.getters.customer(this.customerUuid);
    },
    selectedPhaseQuery() {
      return this.$route.query.phase;
    },
    assessments() {
      return this.$store.getters.customerAssessments(this.customerUuid).filter(assessment => assessment.fieldUUid === this.fieldUuid );
    },
    allAssessments() {
      return this.$store.getters.assessments;
    },
    previousAssessment() {
      const sortedAssessments = this.assessments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      return this.assessments.length ? sortedAssessments[0] : null;
    },
    field() {
      return this.customer.fields.find(
          (e) => e.uuid === this.$route.query.fieldUuid
      );
    },
    cultivationContractAssessment() {
      return this.$store.getters.assessments.find(assessment =>
          assessment.assessmentName === assessmentNameEnum.cultivationContract &&
          assessment.customerUuid === this.customerUuid &&
          assessment.fieldUuid === this.field.uuid
      )
    },
    isPhaseOneComplete() {
      const phase1Assessment = this.assessments
          .find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseOne && assessment.isPhaseComplete)
      return !!phase1Assessment && phase1Assessment.isPhaseComplete
    },
    isPhaseTwoComplete() {
      const phase1Assessment = this.assessments.find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseTwo && assessment.isPhaseComplete)
      return !!phase1Assessment && phase1Assessment.isPhaseComplete
    },
    isPhaseThreeComplete() {
      const phase1Assessment = this.assessments.find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseThree && assessment.isPhaseComplete)
      return !!phase1Assessment && phase1Assessment.isPhaseComplete
    },
    isPhaseFourComplete() {
      const phaseFourAssessment = this.assessments.find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseFour && assessment.isPhaseComplete)
      return !!phaseFourAssessment && phaseFourAssessment.isPhaseComplete
    }
  },
  watch: {
    'formFields.register.visitDate.answer': function(newValue) {
      if (!newValue) {
        return ''
      }
      const transplantDate = this.cultivationContractAssessment.formFields.calculatedTargetDateSeedlingsPlanted.answer
      const diffTransplantDays = daysBetweenDates(new Date(transplantDate), new Date(newValue))
      this.formFields.register.dayAfterTransplant.answer = diffTransplantDays
    },
  },
  mixins: [ImageUploadTypeEnums]
};
</script>

<style scoped >

</style>

<i18n>
{
  "en": {
    "technicalVisitView": "New Evaluation & Spraying Records",
    "register": "Register",
    "whichHrPhase": "In which H.R PHASE is the crop"
  },
  "es": {
    "technicalVisitView": "Nueva Evaluación y Record de Aplicaciones",
    "register": "Registro",
    "whichHrPhase": "¿En cuál FASE está el Cultivo?"
  },
  "sw": {
    "technicalVisitView": "Tathmini Mpya na Kumbukumbu za Kunyunyizia",
    "register": "Sajili",
    "whichHrPhase": "Uko Sehemu gani ya H.R"
  }
}
</i18n>

