<template>
    <v-col cols="12" class="pa-0">
      <v-data-table
        dense
        :headers="headers"
        :items="tableData"
        item-key="item.uuid"
        :items-per-page="-1"
        class="w-100"
        hide-default-footer
      >
        <template v-slot:header.description>{{ $t("Description") }}</template>
        <template v-slot:header.price_per_unit>{{ $t("Price") }} <small>/ {{ $t("unit") }}</small></template>
        <template v-slot:header.quantity>{{ $t("Quantity") }}</template>
        <template v-slot:header.cost>{{ $t("Cost") }}</template>

        <template v-slot:item.price_per_unit="{ item }">
          <Currency :value="item.price_per_unit" />
          <span class="hidden-xs-only pl-0"><small>/ {{ item.unit }}</small></span>
        </template>

        <template v-slot:item.quantity="{ item }">
          <v-text-field
            min="0"
            type="number"
            style="text-align: right"
            v-model.number="item.quantity"
            :rules="(item.unit === 'l' ? numberWithDecimalRules : numberRules)"
          />
        </template>

        <template v-slot:item.cost="{ item }" class="hidden-xs-only">
          <Currency :value="Math.round( (parseFloat(item.quantity) || 0) * item.price_per_unit)" />
        </template>
      </v-data-table>
    </v-col>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import Currency from "@/components/Currency";
import validationRules from "@/mixins/validationRules";

export default {

  props: ['value'],

  components: { Currency },

  data () {
    return {
      tableData: [],
    }
  },

  computed: {
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          align: "center",
          sortable: true,
          value: "price_per_unit",
        },
        {
          align: "start",
          sortable: false,
          value: "quantity",
        },
        {
          align: "end",
          sortable: false,
          value: "cost",
        },
      ];
    },
  },

  watch: { 
    tableData: {
      deep: true,
      handler(to) {
        if (to) {
          const cost = to.map( e => (parseFloat(e.quantity) || 0) * parseFloat(e.price_per_unit) ).reduce((a, b) => a + b, 0);
          let results = {
            tableResults: to,
            cost: cost,
            valid: ( (cost>0) && ( typeof( to.find( e => isNaN(e.quantity) ) )==='undefined'  ) ),
          }
          this.$emit('input', results);
        }
      },
    },
  },

  mounted() {
    this.tableData = this.value.tableResults || [];
    let skus = this.$store.getters.settings.skus;
    (skus || []).forEach(e => {
      let f = this.tableData.find( g => (g.code===e.code) );
      if (f) {
        f.quantity = f.quantity || 0;
        f.cost = f.cost || 0;
      } else {
        this.tableData.push({
          price_per_unit: e.price_per_unit,
          code: e.code,
          description: e.description,
          unit: e.unit,
          quantity: 0,
          cost: 0,
          uuid: uuidv4(),
        });
      }
    });
  },

  mixins: [validationRules],

}
</script>
