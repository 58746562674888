<template>
  <v-card>
    <v-card-title>
      <v-icon>{{icon}}</v-icon>
      <v-toolbar-title>{{$t(activity)}}</v-toolbar-title>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-text-field v-model="number" type="number" :rules="numberRules" min=0 :hint="$t('numberHint')" :label="$t('numberLabel')" ></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>


<script>
  import validationRules from '@/mixins/validationRules';

  export default {
    props: ['activity', 'type', 'icon', 'value'],

    data: () => ({
      number: undefined,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.number = to; }
      },
      number(to) {
        this.$emit('input', to);
      },
    },

    mixins: [validationRules],
  }
</script>




<i18n>
{
  "en": {
    "numberLabel": "Plants",
    "numberHint": "Number of plants",
    "wheat": "Wheat",
    "maize": "Maize",
    "sunflowers": "Sunflowers",
    "beans": "Beans"
  },
  "es": {
    "numberLabel": "Plantas",
    "numberHint": "Numero de plantas",
    "wheat": "Trigo",
    "maize": "Maíz",
    "sunflowers": "Girasoles",
    "beans": "Frijoles"
  },
  "sw": {
    "numberLabel": "Mimea",
    "numberHint": "Idadi ya mimea",
    "wheat": "Ngano",
    "maize": "Mahindi",
    "sunflowers": "Alizeti",
    "beans": "Maharagwe"
  }
}
</i18n>
