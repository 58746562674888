<template>
  <span v-if="landSize">
    <span>{{landSize.landSize}}</span>
    &nbsp;
    <small>{{landSize.unit}}</small>
  </span>
</template>

<script>

export default {

  props: ['landSize'],

};
</script>
