<template>

  <v-container fluid class="text-left pa-0">

    <v-row v-if="recentCustomers.length>0">
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Recent profiles</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1" v-if="recentCustomers.length>0">
      <v-col cols="12" class="pa-0">
        <v-btn v-for="recent in recentCustomers" color="primary" @click="customer = recent" class="ma-1">
          {{recent.name}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled"  v-if="recentCustomers.length>0">Or, search</h3>
        <h3 class="text--disabled"  v-else>Search</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1">
      <v-col cols="12">
        <v-autocomplete
          v-model="customer"
          :items="customers"
          :search-input.sync="search"
          prepend-icon="mdi-database-search"
          item-text="name"
          item-value=""
          return-object
          style="min-width: 100px; max-width: 100%;"
        ></v-autocomplete>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Then, select a field {{(customer && customer.name ? 'for ' + customer.name : '' )}}</h3>
      </v-col>
    </v-row>

    <v-row class="mb-1">
      <v-col cols="12" class="pa-0">
        <v-btn v-for="field in fields" color="primary" @click="redirectTo(field)" class="ma-1">
          {{field.name}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" class="pa-0">
        <h3 class="text--disabled">Or, back</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pa-0">
        <v-btn text outlined @click="redirectTo(undefined)">
          Cancel
        </v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>
<script>
  export default {
    props: ['value'],
    data() {
      return {
        target: undefined,
        customer: null,
        search: null,
      }
    },
    computed: {
      customers() { return this.$store.getters.customers.filter(customer => customer.fields.length >= 1).sort( (a,b) => (a.name > b.name ? 1 : -1) ); },
      recentCustomers() { return this.$store.getters.searchedCustomers.filter(customer => customer.fields.length >= 1).sort( (a,b) => (a.name > b.name ? 1 : -1) ); },
      fields() {
        let fields = [];
        if (this.customer != null && typeof(this.customer) === 'object') {
          fields = (this.customer.fields || []).filter(field => field.deleted===undefined)
        }
        return fields;
      },
    },
    methods: {
      redirectTo(field) {
        let r = undefined;
        if (field) {
          let query = {
            uuid: this.customer.uuid,
            fieldUuid: field.uuid,
          }
          r = { ...this.value };
          r.filter = undefined;
          r.query = query;
        }
        this.$emit('input', r);
      },
    }
  }
</script>