<template>

  <div>
    <l-map
        :zoom="zoom"
        :center="latlngs(filteredCoords[0].coordinates)[0]"
        style="height: 500px; width: 100%"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />
      <l-geo-json
          :geojson="geojsonFeature"
          :options="options"
      />

      <l-polygon  v-for="polygon in filteredCoords" :lat-lngs="latlngs(polygon.coordinates)" color="green"  >
        <l-tooltip :content="polygon.caption" />
      </l-polygon>

    </l-map>
  </div>
</template>

<script>
import {corridorGeojsonData} from "@/views/customers/components/CorridorAwarenessLocations/corridorGeojsonData";
import {LGeoJson, LMap, LMarker, LPolygon, LTileLayer, LTooltip} from "vue2-leaflet";

export default {
  props: ['coords'],
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
    LPolygon,
    LTooltip,
  },
  data() {
    return {
      geojsonFeature: corridorGeojsonData,
      zoom: 7,
      fillColor: "#0d8062",
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },

  mounted() {

  },

  computed: {
    filteredCoords() { return this.coords.filter( e => e.coordinates && e.coordinates.length>0 ) },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        layer.bindTooltip(
            "<div>" +
            feature.properties.FolderPath +
            "</div>",
            { permanent: false, sticky: true }
        );
      };
    }
  },
  methods: {
    latlngs(coordinates) {
      if (coordinates) {
        return coordinates.map( e => [ e.latitude, e.longitude ] )
      } else {
        return null;
      }
    },
  }
}
</script>

<style scoped>

</style>
