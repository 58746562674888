<template>
  <v-alert
      :type="alert.type"
  >
    <v-row align="center">
      <v-col class="grow" style="max-width: 180px;">
        {{alert.message}}
      </v-col>
      <v-col class="shrink">
        <v-btn
            icon
            color="white"
            @click="dismissAlert(index)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AlertMessage",
  props: ['alert', 'index'],
  methods: {
    dismissAlert(index) {
      this.$store.dispatch('removeAlert', index);
    }
  },
}
</script>

<style scoped>

</style>