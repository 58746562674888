<template>
  <v-menu
    ref="menu"
    v-model="timePickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="timePickerMenu"
      v-model="time"
      full-width
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>

</template>

<script>
  import parseDate from '@/mixins/parseDate';

  export default {
    props: ['value', 'label'],
    data () {
      return {
        time: null,
        timePickerMenu: false,
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            if ((typeof to === 'string') && ( to.length==5 )) {
              this.time = to;
            } else {
              const t = this.parseDateTime(to);
              this.time = t.toFormat('HH:mm');
            }
          } else {
            this.time = null;
          }
        },
      },
      time() { this.$emit('input', this.time); },
    },
    mixins: [parseDate],
  }
</script>