export default {

  computed: {
    remoteLookupApiKey() { return this.$store.getters.apiKey; },
    remoteLookupUrl() {
      if (process.env.NODE_ENV === 'production') {
        return 'https://connect.sasa.solutions/api/app/lookup/';
      } else {
        return 'http://api.sasa.localhost:3000/api/app/lookup/';
      }
    }
  },

  methods: {
    serializeLookupParameters(params) {
      var str = [];
      for (var p in params)
        if (params.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
        }
      return str.join("&");
    },
    remoteLookup(lookupType, params) {
      return fetch(this.remoteLookupUrl + lookupType + '?' + this.serializeLookupParameters(params), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
          'Authorization': this.remoteLookupApiKey,
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer'
      })
          .then(response => {

            if ([200, 201, 202].includes(response.status)) {
              return response.json()
            } else {
              throw(response)
            }
          })
          // .catch(error => {
          //   if (error.message) {
          //     this.$store.dispatch('newAlert', { type: 'error', message: error.message})
          //   }
          //   throw(error)
          // });

    },
    remotePost(lookupType, params) {
      return fetch(this.remoteLookupUrl + lookupType, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
          'Authorization': this.remoteLookupApiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
        referrerPolicy: 'no-referrer'
      })
          .then(response => {

            if ([200, 201, 202].includes(response.status)) {
              return response.json()
            } else {
              throw(response)
            }
          })
          .catch(error => {
            if (error.message) {
              this.$store.dispatch('newAlert', { type: 'error', message: error.message})
            }
            throw(error)
          });

    }
  },

}