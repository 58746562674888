<template>
  <v-input :rules="[requiredRule]" v-model="gender" >
    <v-radio-group v-model="gender" row dense :mandatory="mandatory">
      <v-radio :label="$t('male')"   value="male"></v-radio>
      <v-radio :label="$t('female')" value="female"></v-radio>
    </v-radio-group>
  </v-input>
</template>


<script>
  import VInput from 'vuetify/lib/components/VInput/VInput.js';

  export default {

    props: {
      value: String,
      required: Boolean,
      mandatory: Boolean,
    },

    data: () => ({
      gender: false,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.gender = to; }
      },
      gender(to) { this.$emit('input', to); },
    },

    methods: {
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    }


  }
</script>


<i18n>
{
  "en": {
    "male": "Male",
    "female": "Female"
  },
  "es": {
    "male": "Hombre",
    "female": "Mujer"
  },
  "sw": {
    "male": "Mwanaume",
    "female": "Mwanamke"
  }
}
</i18n>
