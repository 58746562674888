<template>
  <PageHeader :title="$t('title')" icon="mdi-currency-usd-circle-outline" >

    <v-data-table
      :headers="headers"
      :items="skus"
      :items-per-page=15
      class="elevation-1"
      style="width: 100%;"
      :search="search"
    >

      <template v-slot:header.code>{{$t('code')}}</template>
      <template v-slot:header.description>{{$t('description')}}</template>
      <template v-slot:header.category>{{$t('category')}}</template>
      <template v-slot:header.price_per_unit>{{$t('price')}}</template>
      <template v-slot:header.unit>{{$t('unit')}}</template>


      <template v-slot:item.code="{ item }">
        <code>{{item.code}}</code>
      </template>

      <template v-slot:item.price_per_unit="{ item }">
        <Currency :value="item.price_per_unit" />
      </template>

    </v-data-table>
  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader';
  import Currency from '@/components/Currency';

  export default {

    components: { PageHeader, Currency },

    data: () => ({
      headers: [
        {
          sortable: true,
          value: 'code',
        },
        {
          sortable: true,
          value: 'description',
        },
        {
          sortable: true,
          value: 'category',
        },
        {
          value: 'unit',
        },
        {
          align: 'right',
          sortable: true,
          value: 'price_per_unit',
        },
      ],

    }),

    computed: {
      skus() { return (this.$store.getters.settings.skus || []).filter( e => e ); },
      search() { return this.$store.getters.search; },
    },

  }
</script>


<i18n>
{
  "en": {
    "title": "Prices",
    "code": "Stock Code",
    "description": "Description",
    "category": "Category",
    "price": "Price",
    "unit": "Unit"
  },
  "es": {
    "title": "Precios",
    "code": "Número de inventario",
    "description": "Descripción",
    "category": "Categoría",
    "price": "Precio",
    "unit": "Unidad"
  },
  "sw": {
    "title": "Bei",
    "code": "Nambari ya Hisa",
    "description": "Maelezo",
    "category": "Jamii",
    "price": "Bei",
    "unit": "Kitengo"
  }
}
</i18n>
