<template>
    
  <v-form ref="rolloverForm" v-model="valid" style="width: 100%;" >
    <v-container fluid>

      <ServerSideResult :test="instrument" v-if="receipting">
        <v-row>
          <v-col class="text-h5">
            <InstrumentUltimateTzCheckMeBack :instrument="instrument" highlight="Min Payment to Rollover" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <CurrencyInput v-model="cash" label="Cash Receipted" />
          </v-col>
          <v-col cols="6">
            <TextInput v-model="cashReceiptNumber" label="Receipt Number" required />
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <v-btn @click="rollover" color="primary" :disabled="!mayRollover">Rollover Loan</v-btn>
          </v-col>
        </v-row>

      </ServerSideResult>

      <ServerSideResult :test="receiptProcess" v-else>
        <v-row>
          <v-col cols="12" class="text-h4 text--primary text-left">
            Thank you. Your rollover has been submitted.
          </v-col>
        </v-row>
      </ServerSideResult>

    </v-container>
  </v-form>

</template>

<script>
import lookups from '@/mixins/lookups';

import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import InstrumentUltimateTzCheckMeBack from "@/views/instruments/InstrumentUltimateTzCheckMeBack"


export default {

  components: { CurrencyInput, TextInput, Currency, ServerSideResult, InstrumentUltimateTzCheckMeBack },

  props: ['customer', 'instrumentId', 'instrument'],

  data() {
    return {
      valid: false,
      cash: 0,
      cashReceiptNumber: undefined,
      receipting: true,
      receiptProcess: undefined,
    }
  },

  computed: {
    rolloverAmount() { return ((this.instrument.rollover_amount || 0)/100.0); },
    mayRollover() { return this.valid && ( (this.instrument) && (this.cash>=this.rolloverAmount ) ) },
  },

  watch: {
    instrument(to) {
      if (to && this.cash==0) {
        this.cash = this.rolloverAmount;
      }
    }
  },

  methods: {
    validate() { this.$refs.rolloverForm.validate(); },
    rollover() {
      this.receipting = false;
      this.remoteLookup('instrumentUltimateTzCheckMeBackRollover', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
        cash: this.cash,
        receiptNumber: this.cashReceiptNumber,
      }).then( e => this.receiptProcess = 'done' ).catch(e => this.receiptProcess = null);
    },
  },

  mixins: [lookups],

}
</script>
