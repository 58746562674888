<template>
  <v-card>
    <v-card-title>
      Appointments
    </v-card-title>

    <v-card-text>
      <v-timeline dense clipped >
        <v-timeline-item v-for="appointment in appointments" :key="appointment.id" class="mb-1" color="grey" icon-color="grey lighten-2" small >
          <v-row justify="space-between">
            <v-col cols="4" class="text-left" >
              <Time :time="appointment.startsAt" />
            </v-col>
            <v-col cols="8" class="text-left" >
              {{ appointment.subject }}
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" text :to="{name: 'NewCustomerAppointment', query: { partnerIdentifier: customer.partnerIdentifier } }">
        New Appointment
      </v-btn>
    </v-card-actions>

  </v-card>
</template>


<script>

  import { DateTime } from 'luxon';

  import Time from '@/components/Time';

  export default {

    components: { Time },

    props: ['customer'],

    computed: {
      appointments() { return (this.customer.appointments || []).filter( e => DateTime.fromISO(e.startsAt) > Date.now() ).sort( (a,b) => ( a.startsAt > b.startsAt ? 1 : -1 ) ).slice(0,5); },
      noAppointments() { return this.appointments.length==0; },
    },

  }
</script>
