export const bactericidesData = [
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio     SC  6,4% + 1,25% + 23,2% +  4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Sulfato Gentamicina + Clorhidrato Oxitetraciclina      WP 100UI + 300UI",
        "Dose": "420 gramos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Azufre     720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado     EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado     SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio       SP  10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Cobre soluble + Azufre     cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Oxicloruro de Cobre     58,8%  WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Polisulfuro de Calcio     79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Propineb      WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio     PC   21% +  18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado     2.500 ppm  0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Oxicloruro Cobre + Clorhidrato Oxitetraciclina      45% + 350 UI   WP",
        "Dose": "450 a 600 gramos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Sulfato de Estreptomicina",
        "Dose": "400 a 500 gramos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Yodo complejo Povidona     12%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Yodo complejo y Acido Yodidrico / Yodo Polivinil Pirrolidona      SL 132  /  120",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Validamycin     5%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Streptomyces racemochromogenes  1X10⁸ UFC/ml     T  SC",
        "Dose": "1.000 centimetros cubicos/ha"
    },
    {
        "pest": "Pseudomonas spp.",
        "activeIngredient": "Bacillus subtilis  2X10⁹ Esporas/Gramo de Producto Formulado 10%     WP",
        "Dose": "500 a 1.000 gramos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Potasio + Azufre + Cobre + Streptomicina + Silicio     SC  6,4% + 1,25% + 23,2% +  4,9% + 2,7%",
        "Dose": "1,0 a 3,0 litros/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Sulfato Gentamicina + Clorhidrato Oxitetraciclina      WP 100UI + 300UI",
        "Dose": "420 gramos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Azufre     720 SC",
        "Dose": "1,0 litro/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado     EC 260",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado     SC 213",
        "Dose": "0,3 lts/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Cobre Sulfato Pentahidratado + Fosfitos de Potasio       SP  10,2% + 51,9%",
        "Dose": "2,0 kilos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Cobre soluble + Azufre     cooper 60",
        "Dose": "1,5 lts/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Oxicloruro de Cobre     58,8%  WP",
        "Dose": "1,5 a 3,0 kilos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Polisulfuro de Calcio     79%",
        "Dose": "3,5 cc/litro - 1.200 cc/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Propineb      WP 70",
        "Dose": "1,5 a 2,5 kilos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Sulfato de Cobre + Sulfato de Calcio     PC   21% +  18%",
        "Dose": "2,0 a 3,0 kilos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Dioxido de Cloro Estabililzado     2.500 ppm  0,25%",
        "Dose": "1,0 a 2,0 litros/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Oxicloruro Cobre + Clorhidrato Oxitetraciclina      45% + 350 UI   WP",
        "Dose": "450 a 600 gramos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Sulfato de Estreptomicina",
        "Dose": "400 a 500 gramos/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Yodo complejo Povidona     12%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Yodo complejo y Acido Yodidrico / Yodo Polivinil Pirrolidona      SL 132  /  120",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Validamycin     5%",
        "Dose": "1,0 a 1,5 litros/ha"
    },
    {
        "pest": "Activador radicular",
        "activeIngredient": "Bacillus megaterium 1X10⁷ Esporas/ml 20%     SL",
        "Dose": "1.000 centimetros cubicos/ha"
    }
]