<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid>

      <v-row>
        <v-col colse="6" class="text-left">
          <span class="red--text" v-if="validating">
            Validation running and probably failed!!
          </span>
          <span v-if="validating">
            Try <code>$vm0.$refs.testForm.validate()</code> in a browser console to diagnose why validation is failing.
          </span>
        </v-col>
        <v-col colse="6" class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" class="text-h4">
          General
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="5">
          <TextInput v-model="form.text" label="Required Text" required />
        </v-col>
        <v-col cols="2">
          [{{form.text}}]
        </v-col>
        <v-col cols="5">
          <TextInput v-model="form.text" label="Text" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <CurrencyInput v-model="form.currency" required label="Required" />
        </v-col>
        <v-col cols="2">
          [{{form.currency}}]
        </v-col>
        <v-col cols="5">
          <CurrencyInput v-model="form.currency" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <SelectInput v-model="form.selectedItem" :items="['alpha', 'beta', 'delta', 'gamma']" required label="Select (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.selectedItem}}]
        </v-col>
        <v-col cols="5">
          <SelectInput v-model="form.selectedItem" :items="['alpha', 'beta', 'delta', 'gamma']" label="Select (Not Required)" />
        </v-col>
      </v-row>





      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>




      <v-row>
        <v-col cols="12" class="text-h4">
          Customer Basics
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="5">
          <TitleInput v-model="form.title" required label="Title (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.title}}]
        </v-col>
        <v-col cols="5">
          <TitleInput v-model="form.title" />
        </v-col>
      </v-row>



      <v-row>
        <v-col cols="5">
          <NameInput v-model="form.name" required label="Name (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.name}}]
        </v-col>
        <v-col cols="5">
          <NameInput v-model="form.name" label="Name" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <IdentityInput v-model="form.identity" required />
        </v-col>
        <v-col cols="2">
          [{{form.identity}}]
        </v-col>
        <v-col cols="5">
          <IdentityInput v-model="form.identity" />
        </v-col>
      </v-row>



      <v-row>
        <v-col cols="5">
          <EmailInput v-model="form.email" required label="Email" />
        </v-col>
        <v-col cols="2">
          [{{form.email}}]
        </v-col>
        <v-col cols="5">
          <EmailInput v-model="form.email" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <GenderInput v-model="form.gender" required label="Required" />
        </v-col>
        <v-col cols="2">
          [{{form.gender}}]
        </v-col>
        <v-col cols="5">
          <GenderInput v-model="form.gender" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <LanguageInput v-model="form.language" required label="Language (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.language}}]
        </v-col>
        <v-col cols="5">
          <LanguageInput v-model="form.language" />
        </v-col>
      </v-row>









      <v-row>
        <v-col cols="5">
          <MsisdnInput v-model="form.msisdn" required label="Phone Number (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.msisdn}}]
        </v-col>
        <v-col cols="5">
          <MsisdnInput v-model="form.msisdn" />
        </v-col>
      </v-row>



      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      

      <v-row class="text-left">
        <v-col cols="4">
          Required
        </v-col>
        <v-col cols="4">
          Not Required
        </v-col>
        <v-col cols="4">
          Mandatory
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <GenderInput v-model="form.gender_required" required />
        </v-col>
        <v-col cols="4">
          <GenderInput v-model="form.gender_not_required" />
        </v-col>
        <v-col cols="4">
          <GenderInput v-model="form.gender_mandatory" required mandatory />
        </v-col>
      </v-row>





      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>





      <v-row>
        <v-col cols="12" class="text-h4">
          Farming
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="5">
          <FieldAgentInput v-model="form.fieldAgent" required label="Field Agent (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.fieldAgent}}]
        </v-col>
        <v-col cols="5">
          <FieldAgentInput v-model="form.fieldAgent" />
        </v-col>
      </v-row>



      <v-row>
        <v-col cols="5">
          <DepotInput v-model="form.depot" required label="Employer" />
        </v-col>
        <v-col cols="2">
          [{{form.depot}}]
        </v-col>
        <v-col cols="5">
          <DepotInput v-model="form.depot" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <LandSizeInput v-model="form.landSize" required label="Land Size" />
        </v-col>
        <v-col cols="2">
          [{{form.landSize}}]
        </v-col>
        <v-col cols="5">
          <LandSizeInput v-model="form.landSize" />
        </v-col>
      </v-row>






      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>






      <v-row>
        <v-col cols="12" class="text-h4">
          Search and Lookup
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="5">
          <CustomerSearchInput v-model="form.customer" required label="Customer" />
        </v-col>
        <v-col cols="2">
          [{{form.customer}}]
        </v-col>
        <v-col cols="5">
          <CustomerSearchInput v-model="form.customer" />
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="5">
          <BusinessInput v-model="form.business" required label="Employer" />
        </v-col>
        <v-col cols="2">
          [{{form.business}}]
        </v-col>
        <v-col cols="5">
          <BusinessInput v-model="form.business" />
        </v-col>
      </v-row>






      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>




      <v-row>
        <v-col cols="12" class="text-h4">
          Date and Time
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <DateInput v-model="form.date" required label="Required Date" />
        </v-col>
        <v-col cols="2">
          [{{form.date}}]
        </v-col>
        <v-col cols="5">
          <DateInput v-model="form.date" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <DateTimeInput v-model="form.dateTime" required label="Required Date Time" />
        </v-col>
        <v-col cols="2">
          [{{form.dateTime}}]
        </v-col>
        <v-col cols="5">
          <DateTimeInput v-model="form.dateTime" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <TimeInput v-model="form.time" required label="Required Time" />
        </v-col>
        <v-col cols="2">
          [{{form.time}}]
        </v-col>
        <v-col cols="5">
          <TimeInput v-model="form.time" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <TimeRangePicker v-model="form.timeRange" required label="Required Time Range" />
        </v-col>
        <v-col cols="2">
          [{{form.timeRange}}]
        </v-col>
        <v-col cols="5">
          <TimeRangePicker v-model="form.timeRange" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <FutureInput v-model="form.future" required label="Required, Future" />
        </v-col>
        <v-col cols="2">
          [{{form.future}}]
        </v-col>
        <v-col cols="5">
          <FutureInput v-model="form.future" />
        </v-col>
      </v-row>





      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>




      <v-row>
        <v-col cols="12" class="text-h4">
          Geographic
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="5">
          <LocationInput v-model="form.location" required label="Required Location" />
        </v-col>
        <v-col cols="2">
          [{{form.location}}]
        </v-col>
        <v-col cols="5">
          <LocationInput v-model="form.location" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <MapInput v-model="form.location" label="Location" required style="width: 100%;" />
        </v-col>
        <v-col cols="2">
          [{{form.location}}]
        </v-col>
        <v-col cols="5">
          <MapInput v-model="form.location" style="width: 100%;" />
        </v-col>
      </v-row>







      <v-row>
        <v-col class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import TextInput from "@/components/TextInput";
import MsisdnInput from "@/components/MsisdnInput";
import EmailInput from "@/components/EmailInput";
import TitleInput from "@/components/TitleInput";
import NameInput from "@/components/NameInput";
import IdentityInput from "@/components/IdentityInput";
import CurrencyInput from "@/components/CurrencyInput";
import SelectInput from "@/components/SelectInput";
import FieldAgentInput from "@/components/FieldAgentInput";
import BusinessInput from "@/components/BusinessInput";
import DepotInput from "@/components/DepotInput";
import LandSizeInput from "@/components/LandSizeInput";
import CustomerSearchInput from "@/components/CustomerSearchInput";
import GenderInput from "@/components/GenderInput";
import LanguageInput from "@/components/LanguageInput";
import DateInput from "@/components/DateInput";
import DateTimeInput from "@/components/DateTimeInput";
import FutureInput from "@/components/FutureInput";
import TimeRangePicker from "@/components/TimeRangePicker";
import TimeInput from "@/components/TimeInput";
import LocationInput from "@/components/LocationInput";
import MapInput from "@/components/MapInput";

export default {

  components: {
    TextInput,
    MsisdnInput,
    EmailInput,
    TitleInput,
    NameInput,
    IdentityInput,
    CurrencyInput,
    SelectInput,
    FieldAgentInput,
    BusinessInput,
    LandSizeInput,
    DepotInput,
    CustomerSearchInput,
    GenderInput,
    LanguageInput,
    DateInput,
    DateTimeInput,
    FutureInput,
    TimeInput,
    TimeRangePicker,
    LocationInput,
    MapInput,
  },

  data() {
    return {
      validForm: false,
      validating: false,
      form: {},
    }
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
      this.validating = false;
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  }

}
</script>
