<template>
  <v-sheet elevation="2">
    <ServerSideResult :test="nok">
      <v-form v-model="valid">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <div class="d-flex flex-column">
                <div v-if="nok">
                  <div class="text-caption">{{nok.relationship}}</div>
                  <div class="text-h5"><Telephone :msisdn="nok.msisdn" /></div>
                  <div class="text-caption">{{nok.name}}</div>
                </div>
                <div>&nbsp;</div>
                <div>
                  <div>
                    <FutureInput :items="followUpOptions" v-model="follow_up_at" label="Follow Up" required never  />
                  </div>
                  <div>
                    <v-btn @click="submit('nok_phone_call')" :disabled="!valid" color="primary" small dense class="ma-1"><v-icon dense>mdi-phone-in-talk</v-icon></v-btn>
                    <v-btn @click="submit('nok_no_answer')"  :disabled="!valid" color="primary" small dense class="ma-1"><v-icon dense>mdi-phone-off</v-icon></v-btn>
                    <v-btn @click="submit('nok_p2p')"        :disabled="!valid" color="primary" small dense class="ma-1"><v-icon dense>mdi-handshake</v-icon> P2P</v-btn>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="8">
              <v-textarea solo v-model="note" label="Notes" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ServerSideResult>
  </v-sheet>
</template>


<script>
import upsertActions from '@/mixins/models/actions'
import lookups from "@/mixins/lookups";

import ServerSideResult from "@/components/ServerSideResult";
import Telephone from "@/components/Telephone";
import FutureInput from "@/components/FutureInput";

export default {

  components: { ServerSideResult, Telephone, FutureInput },

  props: ['customer'],

  data() {
    return {
      nok: undefined,
      follow_up_at: undefined,
      valid: false,
      note: '',
    }
  },

  computed: {
    followUpOptions() {
      let r = [];
      if ( (new Date).getHours()<=16 ) { r.push('This evening'); }
      r.push('Tomorrow');
      for(let i = 2; i < 7; i++){
        r.push(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][ ((new Date).getDay() + i)%7 ]);
      }
      r.push('In One Week')
      r.push('In Two Weeks')
      return r;
    },
  },

  methods: {
    submit(action) {
      this.upsertAction({ target: this.customer, follow_up_at: this.follow_up_at, action: action, note: this.note });
      this.$emit('close');
    }
  },

  mounted() {
    this.follow_up_at = undefined;
    this.valid = false;
    this.note = '';
    this.remoteLookup('next_of_kin', { uuid: this.customer.uuid }).then( nok => this.nok = nok.nok ).catch(e => this.nok = null);
  },

  mixins: [upsertActions, lookups],

}
</script>