import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

let colour_scheme = {
  primary: '#3566AF',
  accent: '#e91e63',
  secondary: '#30b1dc',
  success: '#4CAF50',
  info: '#3664B0',  // complements primary
  warning: '#FB8C00',
  error: '#FF5252'
}

if (window.location.host.startsWith("app.e-mazao.co.tz")) {
  colour_scheme = {
     primary: '#139645',
      secondary: '#C4DBCB',
      accent: '#F4D35E',
      sasaAppBarBackground: '#f5f5f5',
      success: '#4CAF50',
      info: '#5f8d47',  // complements primary
      warning: '#FB8C00',
      error: '#FF5252'
  }
}

if(window.location.host.startsWith("nile.sasa.solutions")){
  colour_scheme = {
    primary: '#0033AA',
    accent: '#FF6611',
    secondary: '#0089FF',
    success: '#0E8A00',
    info: '#00AADD',  // complements primary
    warning: '#FFB822',
    error: '#E0000F'
  }
}

// https://lobotuerto.com/vuetify-color-theme-builder/
// https://www.canva.com/learn/100-color-combinations/

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: colour_scheme,
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
