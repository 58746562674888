<template>
  <v-form ref="basicForm" v-model="valid" v-if="field">
    <SelectInput
        :items="visibleMitigationItems"
        v-model="visibleMitigationForm.visibleMitigationMultiSelect"
        @change="onMultiSelectChange"
        :menu-props="{ maxHeight: '400' }"
        label="Visible Mitigation"
        multiple
        outlined
        class="my-3"
        persistent-hint/>

    <v-card outlined v-for="visibleMitigation in visibleMitigationForm.effectivenessFormFields">
      <v-row>
        <v-col cols="8">
          <v-list-item three-line>
            <v-list-item-content class="text-left">
              <v-list-item-title class="text-h5 mb-1">
                {{ visibleMitigation.visibleMitigationTitle }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Maintenance
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="2" class="mt-4">
          <PhotoInput
            v-model="visibleMitigation.visibleMitigationImage"
            type="field"
            :typeId="field.fieldUuid"
            required />
        </v-col>
      </v-row>
      <StarRating v-model="visibleMitigation.starRating" />
    </v-card>
    <slot>

    </slot>
  </v-form>
</template>

<script>
import StarRating from "@/components/StarRating";
import PhotoInput from "@/components/PhotoInput";
import SelectInput from "@/components/SelectInput";

export default {
  components: {
    StarRating,
    PhotoInput,
    SelectInput
  },
  props: {
    field: Object,
    value: Object
  },
  data() {
    return {
      valid: false,
      visibleMitigationItems:[
        'Chilli Fence',
        'Chilli Bricks',
        'Tin Cans Fence',
        'Solar electric fences',
        'Trenches',
        'Beehive fencing',
        'Drumming',
        'Field buffers',
        'Torches',
        'Dogs',
        'Non palatable crops',
        'None',
      ],
      visibleMitigationForm: {
        visibleMitigationMultiSelect: null,
        effectivenessFormFields: []

      }
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(to) {
        if (!!to) {
          // todo create new
          this.visibleMitigationForm = to;
        }
      }
    },
    visibleMitigationForm:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },

  methods: {
    validate() {
      this.$refs.basicForm.validate();
    },
    newVisibleMitigation(name) {
      return {
        visibleMitigationTitle: name,
        starRating: null,
        visibleMitigationImage: null
      }
    },
    onMultiSelectChange(newVal) {
      const oldVal = this.visibleMitigationForm.effectivenessFormFields.map(val => val.visibleMitigationTitle)
      if((!newVal || newVal.length === 0)&& (!oldVal || oldVal.length === 0)) {
        return
      }
      //
      if (!oldVal || oldVal.length === 0) {
        this.visibleMitigationForm.effectivenessFormFields = newVal.map((name) => this.newVisibleMitigation(name))
        return
      }

      let newValues = newVal.filter(x => !oldVal.includes(x));
      let removedValues = oldVal.filter(x => !newVal.includes(x));


      if(newValues.length > 0) {
        this.visibleMitigationForm.effectivenessFormFields.push(...newValues.map((name) => this.newVisibleMitigation(name)))

      }

      if (removedValues.length > 0) {
        for( let i = 0; i < this.visibleMitigationForm.effectivenessFormFields.length; i++){

          if (removedValues.includes(this.visibleMitigationForm.effectivenessFormFields[i].visibleMitigationTitle)) {
            this.visibleMitigationForm.effectivenessFormFields.splice(i, 1);
            i--;
          }
        }

      }
    }
  }
}
</script>
