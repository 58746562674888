<template>
  <a :href="`tel:${phoneNumberFromMsisdn}`">
    {{ phoneNumberFromMsisdn | regexGroups(/(\d\d\d)(\d\d\d)(\d+)/) }}
  </a>
</template>

<script>
import msisdnToTelephone from '@/mixins/msisdnToTelephone';
export default {
  props: ['msisdn'],
  mixins: [msisdnToTelephone],
};
</script>
