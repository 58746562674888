<template>
  <PrintLayout
      :title="selectedAssessment.selectedPhase"
      @back="goBack"
  >
    <v-main class="text-left">

      <h3 class="green lighten-5 pa-5">
        Visitas técnicas y Record de Aplicaciones: {{selectedAssessment.visitAssessmentForm.register.visitDate.answer}}
      </h3>

      <v-simple-table
          class="text-left table"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{$t('question')}}
              </th>
              <th class="text-left">
                {{$t('answer')}}
              </th>
            </tr>
          </thead>
          <tbody>
          <template
          >

            <tr
                v-for="(value, propertyName) in selectedAssessment.fields.register"
            >
              <td>{{ value.question }}</td>
              <td>{{ value.answer }}
                <template v-if="value.extraObservationAnswer">
                  :observación adicional:{{ value.extraObservationAnswer }}
                </template>
              </td>
            </tr>
          </template>

          </tbody>
        </template>
      </v-simple-table>

      <h4 class="green lighten-5 pa-5">plagas y pesticidas</h4>
      <v-simple-table
          class="text-left table"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{$t('hr.pestType')}}
              </th>
              <th class="text-left">
                {{$t('hr.pest')}}
              </th>
              <th class="text-left">
                {{$t('hr.activeIngredient')}}
              </th>
              <th class="text-left">
                {{$t('hr.doseFrequency')}}
              </th>
            </tr>
          </thead>
          <tbody>
          <template>
            <tr
                v-for="(value, propertyName) in selectedAssessment.fields.pesticideFormFields"
            >
              <td>
                {{ value.pesticideType }}
              </td>
              <td>
                {{ value.formField.pest.answer.pest }}
              </td>
              <td>
                {{ value.formField.pesticide.answer.activeIngredient }},
                Dosis: {{ value.formField.pesticide.answer.dose }}
              </td>
              <td>
                {{ value.formField.pesticideFrequency.answer }}
              </td>

            </tr>
          </template>

          </tbody>
        </template>
      </v-simple-table>

      <h3 class="green lighten-5 pa-5">Fase seleccionada: {{selectedAssessment.selectedPhase}}</h3>

      <div
          v-for="(phaseFormValue, propertyName) in selectedAssessment.phaseForm"
          v-if="selectedAssessment.selectedPhaseEnum !== 'phaseThree'"
      >
        <v-divider></v-divider>
        <h4 class="green lighten-5 pa-5">{{$t(propertyName)}}</h4>
        <v-divider></v-divider>
        <!--        <h4>{{phaseFormValue}}</h4>-->
        <v-simple-table
            class="text-left table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('question')}}
                </th>
                <th class="text-left">
                  {{$t('answer')}}
                </th>
              </tr>
            </thead>
            <tbody>
            <template>
              <tr
                  v-for="(value, propertyName) in phaseFormValue"
              >
                <td>{{ value.question }}</td>
                <td>{{ value.answer }}</td>
              </tr>
            </template>

            </tbody>
          </template>
        </v-simple-table>

        <div class="form-images-wrapper d-md-flex">
          <div v-for="item in phaseFormValue.attachedImages" class="mx-4 my-4">
            <SingleImage :image="item" maxHeight="200px" />
          </div>
        </div>

      </div>

      <div
          v-else
          v-for="(phaseFormValue, propertyName) in selectedAssessment.phaseForm.register"
      >

        <v-divider></v-divider>
        <h4 class="green lighten-5 pa-5">{{phaseFormValue.question}}</h4>
        <v-divider></v-divider>
        <v-simple-table
            class="text-left table"
            v-if="!!phaseFormValue.answer && phaseFormValue.answer.length"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('intervention')}}
                </th>
                <th class="text-left">
                  {{$t('source')}}
                </th>
                <th class="text-left">
                  {{$t('description')}}
                </th>
                <th class="text-left">
                  {{$t('unit')}}
                </th>
                <th class="text-left">
                  {{$t('min')}}
                </th>
                <th class="text-left">
                  {{$t('max')}}
                </th>
                <th class="text-left">
                  {{$t('pest')}}
                </th>
                <th class="text-left">
                  {{$t('quantity')}}
                </th>
                <th class="text-left">
                </th>
              </tr>
            </thead>
            <tbody>
            <template>

              <tr

                  v-for="(value, propertyName) in phaseFormValue.answer"
              >
                <td>{{ value.intervention }}</td>
                <td>{{ value.source }}</td>
                <td>{{ value.description }}</td>
                <td>{{ value.unit }}</td>
                <td>{{ value.min }}</td>
                <td>{{ value.max }}</td>
                <td>{{ value.pest }}</td>
                <td>{{ value.quantity }}</td>
                <!--                <td>{{ value }}</td>-->
              </tr>
            </template>

            </tbody>
          </template>
        </v-simple-table>
      </div>

      <v-divider></v-divider>
      <p v-if="selectedAssessment.isPhaseComplete">Finalización de la fase</p>

      <div class="form-images-wrapper d-md-flex">
        <div class="mx-4 my-4">
          <v-card
              class="mx-auto"
              max-width="344"
              outlined
          >
            <v-card-title>
              Firma del agricultor
            </v-card-title>
            <SingleImage :image="selectedAssessment.visitAssessmentForm.signedImage" maxHeight="200px" />
          </v-card>
        </div>
      </div>
      <FarmerAgentDisplay :customer="customer" />
    </v-main>
  </PrintLayout>

</template>

<script>
import PageHeader from '@/components/PageHeader';
import SingleImage from "@/components/SingleImage";
import PrintLayout from "@/components/PrintLayout";
import FarmerAgentDisplay from "@/components/FarmerAgentDisplay";

export default {
  components: { PageHeader, SingleImage, PrintLayout, FarmerAgentDisplay },
  computed: {
    assessmentUuid() { return this.$route.query.assessmentUuid; },
    assessments() { return this.$store.getters.assessments; },
    selectedAssessment() { return this.assessments.find(assessment => assessment.assessmentUuid === this.assessmentUuid); },
    customer() { return this.$store.getters.customer(this.selectedAssessment.customerUuid); },

  },
  methods: {
    goBack() {
      this.$router.push({
        name: "Field",
        query: { uuid: this.selectedAssessment.customerUuid, fieldUuid: this.selectedAssessment.fieldUUid },
      });
    },
  }
}
</script>

<style scoped>

</style>


<i18n>
{
  "en": {
    "question": "Question",
    "intervention": "intervention",
    "source": "source",
    "description": "description",
    "unit": "unit",
    "min": "min",
    "max": "max",
    "pest": "pest",
    "quantity": "quantity",
    "answer": "Answer",
    "register": "Register",
    "irrigation": "Irrigation",
    "plasticMulch": "Plastic Mulch"
  },
  "es": {
    "question": "Pregunta",
    "intervention": "intervención",
    "source": "fuente",
    "description": "descripción",
    "unit": "unidad",
    "min": "min",
    "max": "max",
    "pest": "parásito",
    "quantity": "cantidad",
    "answer": "Respuesta",
    "register": "Registrarse",
    "irrigation": "Irrigación",
    "plasticMulch": "Mantillo de plástico"
  },
  "sw": {
    "question": "Swali",
    "intervention": "kuingilia kati",
    "source": "chanzo",
    "description": "maelezo",
    "unit": "kitengo",
    "min": "min",
    "max": "max",
    "pest": "wadudu",
    "quantity": "wingi",
    "answer": "Jibu",
    "register": "Sajili",
    "irrigation": "Umwagiliaji",
    "plasticMulch": "Plastiki Mulch"
  }
}
</i18n>