<template>
  
  <v-data-table dense :headers="headers" :items="customers" item-key="item.uuid" :search="search" class="w-100" >

    <template v-slot:item.msisdn="{ item }">
      <Telephone :msisdn="item.msisdn" />
    </template>

    <template v-slot:item.isAFarmer="{ item }">
      {{ (item.incomeDetails || {}).fcsCardNumber }}
    </template>

    <template v-slot:item.email="{ item }">
      <v-icon dense small color="green" v-if="item.nileUgApplication">mdi-check</v-icon>
      <Percentage :value="(item.lastNileSubmissionPage || 1)/8.0" v-else />
    </template>

    <template v-slot:item.id="{ item }">
      <v-btn small color="primary" :to="{name: 'ShowCustomer', query: { uuid: item.uuid } }" class="mr-2" :outlined="!item.nileUgApplication" >
        <v-icon dense small>mdi-account</v-icon>
      </v-btn>
      <v-btn small color="primary" :to="{name: 'NileRegisterContact', query: { uuid: item.uuid } }" class="mr-2" :outlined="item.nileUgApplication" >
        <v-icon dense small>mdi-text-account</v-icon>
      </v-btn>
    </template>

    <template v-slot:item.firstName="{ item }">
      <Currency :value="balances.sbg_credit[item.uuid] || 0" />
    </template>

    <template v-slot:item.doNotLend="{ item }">
      <Currency :value="balances.agri[item.uuid] || 0" />
    </template>


  </v-data-table>

</template>


<script>

  import IdentityNumber from '@/components/IdentityNumber';
  import Telephone from '@/components/Telephone';
  import Percentage from '@/components/Percentage';
  import Currency from '@/components/Currency';

  export default {

    components: { IdentityNumber, Telephone, Percentage, Currency },

    props: ['customers', 'balances'],

    data: () => ({
    }),

    computed: {
      search() { return this.$store.getters.search; },
      headers() {
        return [
          {
            text: 'Name',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Telephone',
            align: 'start',
            sortable: true,
            value: 'msisdn',
          },
          {
            text: 'FCS #',
            align: 'start d-none d-md-table-cell',
            sortable: false,
            value: 'isAFarmer',
          },
          {
            text: 'Complete',
            align: 'center',
            sortable: true,
            value: 'email',
          },
          {
            text: 'Available Credit',
            align: 'right',
            sortable: true,
            value: 'firstName',
          },
          {
            text: 'Nile Balance',
            align: 'right d-none d-md-table-cell',
            sortable: true,
            value: 'doNotLend',
          },
          {
            text: 'Actions',
            align: 'center',
            value: 'id',
          },
        ];
      },
    },

    methods: {
      getType(customer) {
        let t = 'General';
        if (customer.isAFarmer) { t = 'Farmer'; }
        if (customer.type=='FieldAgent') { t = 'Agent'; }
        if (customer.contactStatus) { t = 'Contact'; }
        return t;
      }
    },

  }
</script>
