<template>
  <PageHeader :title="fieldName" icon="mdi-flower-outline" @subtitle="toCustomer" @back="toCustomer" back>

    <template v-slot:buttons>
      <!-- <v-btn v-if="hrContract" color="primary" small dense class="mr-1" :to="{name: 'HRContractDetail', query: { uuid: customerUuid, fieldUuid: fieldUuid } }"><v-icon dense>mdi-format-line-style</v-icon> {{$t('contract')}}</v-btn> -->
    <div  class="d-md-flex">
<!--      <v-btn v-if="hrContract" color="primary" small dense class="mr-1" :to="{name: 'HRPhaseOne', query: { uuid: customerUuid, fieldUuid: fieldUuid } }"><v-icon dense>mdi-format-line-style</v-icon> {{$t('contract')}}</v-btn>-->
<!--      <ChooseAssessmentDialog :customer-uuid="customer.uuid" :field="field"  />-->
<!--      <AssessButton :customer="customer" :field="field" />-->
      <TechnicalVisitLink v-if="partnerCode=== 'hr_co'" :customer="customer" :cultivation-contract-assessment="cultivationContractAssessment" :field="field" />

<!--      <TakePhoto @click="saveImage">-->
<!--        <v-btn color="primary" small dense class="ml-1"><v-icon dense class="mr-1">mdi-camera-outline</v-icon> <span class="hidden-sm-and-down">{{$t('photo')}}</span></v-btn>-->
<!--      </TakePhoto>-->
      <v-btn color="primary" small dense class="ml-1" :to="{name: 'FieldMap', query: { uuid: customerUuid, fieldUuid: fieldUuid } }"><v-icon dense>mdi-ruler-square</v-icon> {{$t('Map')}}</v-btn>
      <v-btn color="primary" small dense @click="edit" v-if="partnerCode === 'eco_bw'" :to="{name: 'EcoExistBwFieldSurvey', query: { uuid: customerUuid, field: fieldUuid } }" class="ml-1"><v-icon dense>mdi-account-edit-outline</v-icon> {{$t('edit')}}</v-btn>
      <v-btn color="primary" small dense @click="edit" v-else :to="{name: 'FieldEdit', query: { uuid: customerUuid, fieldUuid: fieldUuid } }" class="ml-1"><v-icon dense>mdi-account-edit-outline</v-icon> {{$t('edit')}}</v-btn>
      <v-btn color="primary" small dense :to="{name: 'FieldDelete', query: { uuid: customerUuid, fieldUuid: fieldUuid } }" class="ml-1"><v-icon dense>mdi-delete-outline</v-icon></v-btn>
    </div>
    </template>


    <template v-slot:menu>

<!--      <AppMenuItem v-if="hrContract" icon="mdi-format-line-style" :to="{name: 'HRContractDetail', query: { uuid: customer.uuid, fieldUuid: field.uuid } }" :title="$t('contract')" />-->
<!--      <AppMenuItem v-if="hrContract" icon="mdi-format-line-style" :to="{name: 'HRContractDetail', query: { uuid: customer.uuid, fieldUuid: field.uuid } }" :title="$t('contract')" />-->

      <TechnicalVisitLink
          :customer="customer"
          :cultivation-contract-assessment="cultivationContractAssessment"
          :field="field"
      />

<!--      <TakePhoto  :text="$t('imageInfo')" @click="saveImage">-->
<!--        <AppMenuItem icon="mdi-camera-outline" :title="$t('Take Photos')" />-->
<!--      </TakePhoto>-->

      <AppMenuItem icon="mdi-ruler-square" :to="{name: 'FieldMap', query: { uuid: customerUuid, fieldUuid: fieldUuid } }" :title="$t('mapField')" />
      <AppMenuItem icon="mdi-account-edit-outline" @click="edit" :title="$t('edit')" />
      <AppMenuItem icon="mdi-delete-outline" :to="{name: 'FieldDelete', query: { uuid: customerUuid, fieldUuid: fieldUuid } }" :title="$t('delete')" />

    </template>

    <slot></slot>

  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader'
  import TakePhoto from '@/components/TakePhoto';
  import AppMenuItem from '@/components/AppMenuItem';
  import AssessButton from '@/views/fields/components/AssessButton';
  import TechnicalVisitLink from "@/views/fields/components/TechnicalVisitLink";
  import ChooseAssessmentDialog from "@/views/fields/ChooseAssessmentDialog";

  export default {

    components: { PageHeader, TakePhoto, AppMenuItem, AssessButton, ChooseAssessmentDialog, TechnicalVisitLink },

    props: ['customer', 'field', 'cultivationContractAssessment'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      cropLookups() { return this.$store.getters.cropLookups; },
      partnerCode() { return this.$store.getters.partnerCode; },
      hr() { return ['hr_co', 'winwin_zw'].includes(this.partnerCode); },
      hrContract() {
        let r = this.hr;
        if (r && this.field & this.field.payload && this.field.payload['hrContractDetailAt']) {
          r = ( this.field.payload['hrContractDetailAt'] < ( new Date( new Date - 180*24*60*60000 ) ).toISOString() );
        }
        return r;
      },
      assessmentForm() {
        let f = 'FieldScore';
        if ( ['hr_co', 'winwin_zw'].includes(this.partnerCode) ) { f = 'HRFieldScore'; }
        return f;
      },
      fieldName() {
        let f = this.field.name || 'New Field';
        if (f.length>16) { f = f.replace(/Hectares/, 'ha'); }
        return f;
      },
    },

    methods: {
      saveImage(uuid) { this.$store.dispatch('addImage', { type: 'field', type_id: this.fieldUuid, uuid: uuid }); },
      edit() { this.$emit('edit', true); },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    }

  }

</script>


<i18n>
{
  "en": {
    "contract": "Contract",
    "assess": "Assess",
    "Take Photos": "Take Photos",
    "imageInfo": "Use your camera to take photos of the field",
    "Map": "Map",
    "mapField": "Map Field",
    "edit": "Edit",
    "newAssessment": "New Assessment",
    "delete": "Delete"
  },
  "es": {
    "contract": "Contrato",
    "assess": "Evaluar",
    "Take Photos": "Tomar fotos",
    "imageInfo": "Usa tu cámara para tomar fotos de los cultivos",
    "Map": "Area",
    "mapField": "Area del Lote",
    "edit": "Editar",
    "newAssessment": "VISITA TÉCNICA",
    "delete": "Eliminar"
  },
  "sw": {
    "contract": "Mkataba",
    "assess": "Tathmini",
    "Take Photos": "Piga Picha",
    "imageInfo": "Tumia kamera yako kuchukua picha za uwanja",
    "Map": "Ramani",
    "mapField": "Shamba la Ramani",
    "edit": "Hariri",
    "newAssessment": "Tathmini Mpya",
    "delete": "Futa"
  }
}
</i18n>
