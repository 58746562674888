<template>
  <div>
    <v-btn @click="showAction('call')"   :color="(action=='call'   ? 'success' : 'primary')" small dense class="ma-1"><v-icon dense>mdi-phone</v-icon> <span class="hidden-sm-and-down">Call</span></v-btn>
    <v-btn @click="showAction('nok')"    :color="(action=='nok'    ? 'success' : 'primary')" small dense class="ma-1"><v-icon dense>mdi-phone</v-icon> <span class="hidden-sm-and-down">NOK</span></v-btn>
    <v-btn @click="showAction('demand')" :color="(action=='demand' ? 'success' : 'primary')" small dense class="ma-1"><v-icon dense>mdi-email-open-outline</v-icon> <span class="hidden-sm-and-down"></span></v-btn>
    <v-btn @click="showAction('note')"   :color="(action=='note'   ? 'success' : 'primary')" small dense class="ma-1"><v-icon dense>mdi-pencil-box-outline</v-icon></v-btn>
    <v-btn :to="{ name: 'UltimateTzScanner', query: { uuid: customer.uuid } }" small dense class="ma-1"><v-icon dense>mdi-camera</v-icon></v-btn>
  </div>
</template>

<script>
export default {

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      action: undefined,
    }
  },

  methods: {
    showAction(action) {
      this.action = (this.action==action ? undefined : action);
      this.$emit('showAction', this.action);
    }
  },

}
</script>
