<template>
  <v-icon :color="colour" dense :title="title">mdi-cloud-sync-outline</v-icon>
</template>

<script>

export default {

  computed: {
    unsyncedChanges() { return this.$store.getters.unsyncedChanges; },
    colour() {
      let r = 'primary'
      if (this.unsyncedChanges>5) { r = 'error' }
      return r;
    },
    title() {
      let r = null;
      const changes = this.unsyncedChanges;
      if (changes==0) {
        r = 'Up to date.'
      } else {
        r = 'Changes not uploaded: ' + changes;
      }
      return r;
    }
  },

  methods: {
    forceSync() {
//      this.$store.dispatch('reinitialise');
    },
  }

};
</script>
