<template>
  <IndexUltimateTz v-if="partnerCode=='ultimate_tz' || partnerCode=='two_tz'" />
  <IndexDefault    v-else />
</template>


<script>
  import IndexDefault from '@/views/customers/IndexDefault';
  import IndexUltimateTz from '@/views/customers/partners/ultimate_tz/Index';
  export default {
    components: {
      IndexDefault,
      IndexUltimateTz,
    },
    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
    },
  }
</script>