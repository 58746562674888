<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-1 mr-0" :class="{ 'px-0': iconOnly, 'px-2': !iconOnly }" :icon="iconOnly" small :color="colour" :to="{name: to}" v-bind="attrs" v-on="on" >
        <v-icon dense>{{icon}}</v-icon>
        <span v-if="!iconOnly">{{text}}</span>
      </v-btn>
    </template>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>

<script>
// import SearchBox from '@/components/SearchBox';
// import HeaderBarRoutes from '@/components/HeaderBarRoutes';
export default {
  props: ['icon', 'tooltip', 'to', 'caption', 'shortCaption'],

  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "small";
        case "sm":
          return "medium";
        case "md":
          return "large";
        case "lg":
          return "large";
        case "xl":
          return "large";
      }
    },
    iconOnly() { return (this.width==='small'); },
    text() {
      switch (this.width) {
        case "small":
          return "";
        case 'medium':
          return this.shortCaption || this.caption;
        case 'large':
          return this.caption;
      }
    },
    colour() { return (this.iconOnly ? 'white' : 'info' ); }
  }
};
</script>
