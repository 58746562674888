<template>
  <v-container fluid class="pa-1" >

    <v-row no-gutters class="pb-1">
      <v-btn-toggle v-model="action" mandatory>
        <v-btn small :disabled="!gpsOkay">{{$t('useGPS')}}</v-btn>
        <v-btn small :disabled="!gpsOkay" v-if="previouslySet">{{$t('dontChange')}}</v-btn>
        <v-btn small :disabled="!gpsOkay" v-else>{{$t('dontSet')}}</v-btn>
      </v-btn-toggle>
    </v-row>

    <v-row no-gutters class="text-caption" v-if="waitingForGps">
      <v-progress-linear v-model="gpsProximity" height="25">
        {{$t('acquiringGPS')}}: {{ gpsProximity }} <small>%</small>
      </v-progress-linear>
    </v-row>

    <v-row no-gutters class="text-caption" v-else>
      <span v-bind:class="errorClass">{{$t('accuracy')}} {{gpsRoundedAccuracy}}m.</span>
      <span class="ml-1" v-if="previouslySet">{{$t('current')}}: {{Math.round(value.latitude*100)/100}};{{Math.round(value.longitude*100)/100}}.</span>
    </v-row>

  </v-container>
</template>

<script>

import gps from '@/mixins/gps';

import LatLong from '@/components/LatLong';

export default {

  components: { LatLong },

  props: { value: Object },

  data: () => ({
    previouslySet: false,
    original: undefined,
    coords: undefined,
    errorClass: '',
    updateCaller: false,
    action: undefined
  }),

  computed: {
    gpsStatus() { return this.$store.getters.geolocationStatus; },
    gpsLocation() { return this.$store.getters.geolocation; },
  },

  watch: {
    gpsOkay: function(status) { this.errorClass = ( status ? '' : 'red--text' ); },
    coords: function(to) { if (this.updateCaller) { this.$emit('input', this.coords); } },
    action: function(to) { this.setAction( (to===0 ? 'gps' : 'no') ); },
  },

  mounted() {
    this.original = this.value;
    if ( this.original && this.original.latitude ) { this.previouslySet = true; }
    if (this.previouslySet) {
      this.action = 1;
      this.updateCaller = false;
    } else {
      this.action = 0;
      this.updateCaller = true;
    }
    this.gpsAcceptableAccuracy = 50;
  },

  beforeDestroy() {
    navigator.geolocation.clearWatch(this.gps);
  },

  methods: {
    gpsUpdate(coords) { this.coords = coords; },
    setAction(action) {
      if (action=='no') {
        this.updateCaller = false;
        this.coords = this.original;
      } else {
        this.updateCaller = true;
      }
      this.$emit('input', this.coords);
    },
  },

  mixins: [gps],

};
</script>


<i18n>
{
  "en": {
    "accuracy": "Accuracy",
    "use": "Use",
    "status": "GPS Location Status",
    "notSet": "Not Set",
    "useGPS": "Use GPS Location",
    "dontChange": "Don't Update",
    "dontSet": "Don't Set",
    "current": "Was",
    "currentLocation": "Using current location",
    "acquiringGPS": "Acquiring GPS"
  },
  "es": {
    "accuracy": "Precisión",
    "use": "Usar",
    "status": "Estado de ubicación GPS",
    "notSet": "No establecido",
    "useGPS": "Usar ubicación GPS",
    "dontChange": "No Actualizar",
    "dontSet": "No establezcas",
    "current": "Era",
    "currentLocation": "Ubicación actual",
    "acquiringGPS": "Adquirir GPS"
  },
  "sw": {
    "accuracy": "Usahihi",
    "use": "Tumia",
    "status": "Hali ya Mahali pa GPS",
    "notSet": "Haijawekwa",
    "useGPS": "Tumia Mahali pa GPS",
    "dontChange": "Usisasishe",
    "dontSet": "Usiweke",
    "current": "Ilikuwa",
    "currentLocation": "Eneo la sasa",
    "acquiringGPS": "Kupata GPS"
  }
}
</i18n>
