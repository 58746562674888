<template>
  <div class="text-left pb-2">
    <v-btn class="ml-0 mr-2" color="warning" @click="$emit('validate')"> validate </v-btn>
    <v-btn color="primary" @click="$emit('save')" :disabled="!isValid" > Save & Continue </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    isValid: {
      required: false,
      default: true
    }
  },

  methods: {
  }
}
</script>

<style scoped>

</style>