<template>
  <ServerSideResult :test="approvals">

    <v-data-table dense :headers="headers" :items="approvals" item-key="approval" class="elevation-1">

      <template v-slot:item.created_at="{ item }">
        <Time :time="item.created_at" />
      </template>

      <template v-slot:item.status="{ item }">
        <code>{{item.status}}</code>
      </template>

    </v-data-table>

  </ServerSideResult>
</template>


<script>
  import ServerSideResult from '@/components/ServerSideResult';
  import Time from '@/components/Time';

  export default {

    components: { ServerSideResult, Time },

    props: {
      approvals: Array,
    },

    data: () => ({
      headers: [
        {
          text: 'Created',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Approval',
          align: 'start',
          sortable: true,
          value: 'assessment_name',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Reviewer',
          align: 'start',
          sortable: true,
          value: 'reviewer',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
      ],
    }),

  }
</script>
