<template>
  
  <v-data-table dense :headers="headers" :items="customers" item-key="item.uuid" :search="search" class="w-100" >

    <template v-slot:header.firstName>{{$t('firstName')}}</template>
    <template v-slot:header.surname>{{$t('surname')}}</template>
    <!-- <template v-slot:header.partnerIdentifier>{{$t('partnerIdentifier')}}</template> -->
    <template v-slot:header.msisdn>{{$t('telephone')}}</template>
    <template v-slot:header.identity_number>{{$t('idNumber')}}</template>


    <template v-slot:item.id="{ item }">
      <v-btn small color="primary" @click="$emit('show', item)" >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

<!--     <template v-slot:item.partnerIdentifier="{ item }">
      <PartnerIdentifier v-if="item.partnerIdentifier" :identifier="item.partnerIdentifier" />
    </template> -->

    <template v-slot:item.msisdn="{ item }">
      <Telephone :msisdn="item.msisdn" />
    </template>

    <template v-slot:item.identity_number="{ item }">
      <IdentityNumber :demographic="item.demographic" />
    </template>

    <template v-slot:item.id="{ item }">
      <v-btn small color="primary" :to="{name: 'ShowCustomer', query: { uuid: item.uuid } }" class="mr-2" >
        <v-icon dense small>mdi-account</v-icon>
      </v-btn>
    </template>


  </v-data-table>

</template>


<script>

  import IdentityNumber from '@/components/IdentityNumber';
  import Telephone from '@/components/Telephone';

  export default {

    components: {
      IdentityNumber,
      Telephone,
    },

    props: ['customers'],

    data: () => ({
      headers: [
        {
          text: 'Identifier',
          align: 'center',
          value: 'partnerIdentifier',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'ID Number',
          align: 'start',
          sortable: false,
          value: 'isAFarmer',
        },
        {
          text: 'Telephone',
          align: 'start',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Edit',
          align: 'center',
          value: 'id',
        },

      ],

    }),

    computed: {
      search() { return this.$store.getters.search; },
    },

    methods: {
      getType(customer) {
        let t = 'General';
        if (customer.isAFarmer) { t = 'Farmer'; }
        if (customer.type=='FieldAgent') { t = 'Agent'; }
        if (customer.contactStatus) { t = 'Contact'; }
        return t;
      }
    },

  }
</script>
