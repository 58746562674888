<template>
  <code>
    {{number | regexGroups(/(\d{6})(\d{4})(\d+)/)}}
  </code>
</template>

<script>

export default {

  props: ['demographic', 'number', 'type'],

};
</script>
