
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" v-if="business">
        <Widget title="Basics">
          <v-simple-table>
            <tbody>
              <Row title="Name"      :text="business.name" bold="true" />
              <Row title="Address" :msisdn="business.postal_address || business.postalAddress" />
              <Row title="Government" :msisdn="business.government ? 'Government' : 'Private' " />
              <Row title="Category" :text="business.category" />
              <Row v-if="business.payload" title="Payroll Day" :text="business.payload.payrollDay" />
            </tbody>
          </v-simple-table>
        </Widget> 
      </v-col>
      <v-col cols="6">
        <Widget title="Approval" v-if="approval">
          <v-simple-table>
            <tbody>
              <Row title="Created"   :time="approval.created_at" />
              <Row title="Request"   :text="approval.description" />
              <Row title="Status"    :status="approval.status"  />
            </tbody>
          </v-simple-table>
        </Widget>
      </v-col>
    </v-row>


    <v-row v-if="images">
      <v-col cols="12">
        <div no-gutters v-if="images && images.length>0">
          <Images :images="images" />
        </div>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
  import Widget from '@/components/Widget';
  import Row from "@/components/Row";
  import Images from "@/components/Images";

  export default {

    components: { Widget, Row, Images },

    props: ['approval'],

    computed: {
      business() { return this.approval.target },
      customer() { return this.$store.getters.customer(this.approval.customer_uuid || this.approval.customerUuid); },
      images() {
        let i = undefined;
        if (this.customer) {
          i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
          if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
          if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
          if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
        }
        return i;
      },
    },
  }
</script>
