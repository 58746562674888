<template>
  <ServerSideResult :test="creditLimits">
    <Terms v-model="creditLimits" @termsAccepted="termsAccepted()" v-if="signTerms" />
    <Purchases :balances="creditLimits" v-else />
  </ServerSideResult>
</template>



<script>
  import lookups from '@/mixins/lookups';

  import ServerSideResult from '@/components/ServerSideResult';
  import Terms from "@/views/partners/nile_ug/TermsConditions";
  import Purchases from "@/views/purchases/partners/NileUg";

  export default {

    components: { ServerSideResult, Terms, Purchases },
    
    data: () => ({
      creditLimits: undefined,
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      signTerms() { return (this.creditLimits || {})['otp_required']; },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          this.remoteLookup('nileUgBalance', { uuid: this.customer.uuid }).then( creditLimits => {
            this.creditLimits = creditLimits;
          }).catch(e => {
            console.log('Failed to retrieve credit limits', e)
          });
        },
      },
    },

    methods: {
      termsAccepted() {
        this.creditLimits.otp_required=false;
      }
    },

    mixins: [lookups],

  }
</script>
