<template>
  
  <PageHeader :title="title" icon="mdi-bank">
    <v-container fluid>

      <v-row>
        <v-col cols="4" class="text-h5 text--primary text-left">
          <span v-if="status=='capture'">                {{(isSafe ? 'Safebox' : 'Cashbox')}}: Move Cash      </span>
          <span v-if="status=='authorise'">              Move <Currency v-model="amount" /> to {{who}}        </span>
          <span v-if="status=='authoriseRecipient'">     Move <Currency v-model="amount" /> to {{who}}        </span>
          <span v-if="status=='authorised'">             Moving....                                           </span>
          <span v-if="status=='done'">                   <Currency v-model="amount" /> moved to {{who}}       </span>
        </v-col>

        <v-col cols="4" class="text-left">
          <div>
            <span v-if="status=='authorise'">            Cashbox owner (sender) to authorise </span>
            <span v-if="status=='authoriseRecipient'">   Recipient to authorise </span>
          </div>
        </v-col>

        <v-col cols="4" class="text-left">
          <div v-if="status=='capture'">             <Capture             v-model="parameters"                @submit="initialised=true"    />   </div>
          <div v-if="status=='authorise'">           <Authorise           v-model="parameters.senderAuth"                                   />   </div>
          <div v-if="status=='authoriseRecipient'">  <AuthoriseRecipient  v-model="parameters.recipientAuth"                                />   </div>
          <div v-if="status=='authorised'">          <Move       :parameters="parameters"                     @done="finished"              />   </div>
          <div v-if="status=='done'" class="text-h4">
            Thank you. The transaction has been recorded.
          </div>
        </v-col>
      </v-row>

    </v-container>
  </PageHeader>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import Capture from '@/views/partners/ultimate_tz/cashbox/Capture'
import Authorise from '@/views/partners/ultimate_tz/cashbox/Authorise'
import AuthoriseRecipient from '@/views/partners/ultimate_tz/cashbox/AuthoriseRecipient'
import Move from '@/views/partners/ultimate_tz/cashbox/Move'

import Currency from "@/components/Currency";

export default {

  components: { PageHeader, Capture, Authorise, AuthoriseRecipient, Move, Currency },

  data() {
    return {
      parameters: undefined,
      initialised: false,
      done: false,
    }
  },

  computed: {
    isSafe() { return (this.$route.query.safe); },
    amount() { return (this.parameters ? this.parameters.cash : 0 ); },
    who() {
      let r = 'Safe Box';
      if (this.parameters && this.parameters.target=='consultant') { r =  this.parameters.recipient.name; }
      return r;
    },
    title() {
      return (this.isSafe ? 'Safebox' : 'Your cashbox');
    },
    status() {
      let r = 'capture';
      if (this.initialised) {
        r = 'authorise';
        if (this.parameters.senderAuth) {
          if (this.parameters.target=='toSafe' || this.parameters.target=='toBank' || this.parameters.target=='fromBank') {
            r = 'authorised';
          } else {
            r = 'authoriseRecipient';
          }
        }
        if (r==='authoriseRecipient' && this.parameters.recipientAuth) {
          r = 'authorised';
        }
        if (this.done) {
          r = 'done';
        }
      }
      return r;
    }
  },

  methods: {
    finished() {
      this.done = true;
    },
  },

  mounted() {
    this.parameters = {
      cash: parseInt(this.$route.query.cash || '0'),
      target: this.$route.query.target || 'safe',
      recipient: {
        name: this.$route.query.name,
        id: this.$route.query.recipient_id,
      },
      toBank: this.$route.query.toBank,
      fromBank: this.$route.query.fromBank,

    }
    if (this.$route.query.target) { this.initialised = true; }
  }

}
</script>
