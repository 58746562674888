<template>
  <div class="d-inline-flex mx-0 px-0">

<!-- 
      <HeaderBarIcon icon="mdi-google-maps" tooltip="GPS Details" to="LocationDetail" />
      <HeaderBarIcon icon="mdi-account-plus-outline" tooltip="Capture Farmer" to="CreateFarmer" />
      <HeaderBarIcon icon="mdi-animation-outline" tooltip="Photos" to="Photos" />
 -->

      <HeaderBarIcon icon="mdi-account-search" :tooltip="$t('Online search')" to="Search" :caption="$t('Online Search')" :shortCaption="$t('Online')" />
      <HeaderBarIcon icon="mdi-account-multiple" :tooltip="$t('Farmers and Contacts')" to="Customers" :caption="$t('Profiles')" />
      <!-- <HeaderBarIcon icon="mdi-ticket" :tooltip="$t('Vouchers')" to="VoucherRedemption" v-if="partnerCode==='urban_zm'" /> -->
      <HeaderBarIcon icon="mdi-account-plus" :tooltip="$t('Create New Contact')" to="CreateContact" :caption="$t('Add')" />

      <AppMenu />

  </div>

</template>

<script>
import domain from '@/mixins/domain';
import AppMenu from '@/components/AppMenu';
import HeaderBarIcon from '@/components/HeaderBarIcon';
export default {
  components: {
    HeaderBarIcon,
    AppMenu,
  },
  props: ['color'],
  data() {
    return { }
  },
  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode },
    showSearch() { return this.domain!='milk' },
    showCustomers() { return this.domain!='milk' },
    showCreateContact() { return this.domain!='milk' },
  },
  mixins: [domain],
};
</script>


<i18n>
{
  "en": {
    "Online search": "Online search",
    "Farmers and Contacts": "Farmers and Contacts",
    "Vouchers": "Vouchers",
    "Create New Contact": "Create New Contact",
    "Online Search": "Online Search",
    "Online": "Online",
    "Customers": "Customers",
    "Add": "Add",
    "Profiles": "Profiles"
  },
  "es": {
    "Online search": "Búsqueda en línea",
    "Farmers and Contacts": "Agricultores y Contactos",
    "Vouchers": "Vales",
    "Create New Contact": "Crear nuevo Contacto",
    "Online Search": "Búsqueda en línea",
    "Online": "En línea",
    "Customers": "Clientes",
    "Add": "Nuevo",
    "Profiles": "Personas"
  },
  "sw": {
    "Online search": "Utafutaji wa mkondoni",
    "Farmers and Contacts": "Wakulima na Mawasiliano",
    "Vouchers": "Vocha",
    "Create New Contact": "Unda Mawasiliano Mpya",
    "Online Search": "Tafuta Mtandaoni",
    "Online": "Mtandaoni",
    "Customers": "Wateja",
    "Add": "Mpya",
    "Profiles": "Watu"
  }
}
</i18n>
