<template>
  <v-text-field
    v-model="email"
    :rules="allRules"
    :label="$t('emailAddress')"
     @input="handleInput"
  ></v-text-field>
</template>

<script>
  export default {
    
    props: {
      value: String,
      label: String,
      counter: Number,
      required: Boolean,
      rules: Array,
    },

    data: () => ({
      email: null,
    }),

    computed: {
      allRules() {
        return !!this.rules ? [this.emailRules, ...this.rules] : [this.emailRules];
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.email = to; }
      },
    },

    methods: {
      handleInput() {
        this.$emit('input', (this.email ? this.email.trim() : this.email) );
      },
      emailRules(value) {
        let r = [
          v => !this.required || !!(v || '').trim() || this.$t('required'),
          v => (v || '').length==0 || /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || this.$t('invalid'),
          v => /^.{0,255}$/.test(v) || this.$t('length'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },

  };
</script>


<i18n>
{
  "en": {
    "emailAddress": "Email Address",
    "length": "Must be less than 255 characters",
    "invalid": "Invalid email"
  },
  "es": {
    "emailAddress": "Dirección de correo electrónico",
    "length": "Debe tener menos de 255 caracteres",
    "invalid": "Email inválido"
  },
  "sw": {
    "emailAddress": "Barua Pepe",
    "length": "Lazima iwe chini ya herufi 255",
    "invalid": "Barua pepe si sahihi"
  }
}
</i18n>
