<template>

  <v-row>
    <v-col cols="12">
      <ServerSideResult :test="done" />
    </v-col>
  </v-row>

</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { ServerSideResult },

  props: ['parameters'],

  data() {
    return {
      done: undefined
    }
  },

  watch: {
    done(to) {
      if (to) {
        this.$emit('done', to);
      }
    }
  },

  mounted() {
    let params = this.parameters;
    params.recipient_id = (this.parameters || {}).recipient.id;
    this.remoteLookup('ultimate_tz_wallet_send', params).then( done => this.done = true ).catch(e => console.log(e));
  },

  mixins: [lookups],

}
</script>
