<template>
  <SalaryAdvanceInput :customer="customer" :topup="instrument.id" :transfer="transfer" :arrears="instrument.arrears" />
</template>

<script>
import SalaryAdvanceInput from "@/views/partners/ultimate_tz/SalaryAdvanceInput";

export default {

  components: { SalaryAdvanceInput },

  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    beforeFifteenth() { return (new Date().getDate()<15); },
    transfer() {
      // before the 15th, we don't raise interest in the transfer: settlement amount include interest so back it out
      return (this.instrument.settlement_amount - ( this.beforeFifteenth ? this.instrument.interest_due : 0))/100;
    },
  },

}
</script>
