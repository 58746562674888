export default {

  methods: {
    actionTypeToName(actionType) {
      let r = actionType;
      if (actionType=='phone_call')         { r = 'Called and spoke to customer'; }
      if (actionType=='no_answer')          { r = 'Called. No answer'; }
      if (actionType=='p2p')                { r = 'Promised to pay'; }
      if (actionType=='escalate')           { r = 'Escalated'; }
      if (actionType=='nok_phone_call')     { r = 'Called and spoke to next of kin'; }
      if (actionType=='nok_no_answer')      { r = 'Called next of kin. No answer'; }
      if (actionType=='nok_p2p')            { r = 'Called next of kin. They promised to pay'; }
      if (actionType=='note')               { r = 'Note'; }
      if (actionType=='demand_delivered')   { r = 'Demand letter delivered to customer'; }
      return r;
    }
  },

}