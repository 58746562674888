<template>
  <tr>
    <td class="text-left">{{ value.description }}</td>
    <td class="text-right pr-1"><Currency :value="price" /></td>
    <td class="text-left hidden-xs-only pl-0"><small>/ {{ value.unit }}</small></td>
    <td>
      <v-text-field min=0 type='number' :rules="[numberRules]" v-model="value.quantity" @input="handleInput" style="text-align: right;" />
    </td>
    <td class="hidden-xs-only pr-1">
      <div style="width: 100px;" class="text-right pr-0" >
        <Currency :value="amount" />
      </div>
    </td>
  </tr>
</template>


<script>
  import Currency from '@/components/Currency';

  export default {

    components: { Currency },

    props: ['value'],
    
    computed: {
      currency() { return this.$store.getters.currency; },
      price() { return (parseFloat(this.value.price_per_unit) || 0); },
      quantity() { return (parseFloat(this.value.quantity) || 0); },
      amount() { return this.price * this.quantity; },
    },


    methods: {
      handleInput() { this.$emit('input', this.value ); },
      numberRules(v) {
        let r = Number.isInteger(Number(v)) || this.$t('numbersOnly');
        if (r) { r = (Number(v) >= 0) || this.$t('positiveOnly'); }
        if (r) { r = !(/\D/.test(v)) || this.$t('numbers'); }
        return r;
      },

    },

  }
</script>


<i18n>
{
  "en": {
    "numbersOnly": "Whole numbers only",
    "positiveOnly": "A positive number please",
    "numbers": "Numbers please"
  },
  "es": {
    "numbersOnly": "Solo números enteros",
    "positiveOnly": "Un número positivo por favor",
    "numbers": "Números por favor"
  },
  "sw": {
    "numbersOnly": "Nambari nzima tu",
    "positiveOnly": "Nambari chanya tafadhali",
    "numbers": "Nambari tafadhali"
  }
}
</i18n>
