<template>
  
  <v-container fluid class="pa-0">

    <v-row>

      <v-col cols="12" sm="6">
        <DateInput
          v-model="date"
          :label="dateLabel"
          :min="new Date().toISOString().substr(0, 10)"
          :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <TimeInput :label="timeLabel" v-model="time" />
      </v-col>


    </v-row>

  </v-container>    

  
</template>

<script>
  import parseDate from '@/mixins/parseDate'

  import DateInput from '@/components/DateInput';
  import TimeInput from '@/components/TimeInput';

  export default {

    components: { DateInput, TimeInput },

    props: ['value', 'dateLabel', 'timeLabel'],

    data: () => ({
      date: null,
      time: null,
    }),

    watch: {
      date() { this.emitChanges(); },
      time() { this.emitChanges(); },
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            const t = this.parseDateTime(to);
            this.date = t.toFormat('yyyy-MM-dd');
            this.time = t.toFormat('HH:mm');
          } else {
            this.date = null;
            this.time = null;
          }
        },
      },
    },

    methods: {
      emitChanges() {
        this.$emit('input', this.parseIsoDate(this.date + 'T' + this.time));
      }
    },

    mixins: [parseDate],

  }
</script>