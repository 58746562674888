<template>
  <v-input :rules="[requiredRule]" v-model="output" >
    <v-switch v-model="ageOrDate" />
    <DateInput v-model="date" v-bind="$attrs" v-on="$listeners" dropLists />
  </v-input>
</template>

<script>
  import DateInput from '@/components/DateInput';

  export default {

    components: { DateInput },

    props: {
      value: [String, Date],
    },

    data: () => ({
      date: undefined,
      ageOrDate: false,
      output: undefined,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.date = to; },
      },
      date(to) { this.$emit('input', to); }
    },

    methods: {
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
      getDate(t) { return this.parseDateTime(t); },
    },

  };
</script>
