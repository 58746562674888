<template>
  <v-container fluid class="text-left pb-3">
    <h4 class="mb-3">Thank You</h4>
    <p class="body2 text-left">
      We will sms {{name}} to let them know about the outcome of this credit application.
    </p>
    <v-row class="mt-4">
      <v-btn color="primary" class="mr-3 mb-2" @click="toCustomers">Customer Profiles</v-btn>
      <v-btn color="primary" class="mr-3 mb-2 d-xs-block" :to="{name: 'CreateContact'}">Another Application</v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ThankYou",
  props: ["name"],
  data: function () {
    return {
      valid: false,
    };
  },
  computed: {
    customerUuid() { return this.$route.query.uuid; },
  },
  methods: {
    toCustomers() {
      this.$router.push({ name: 'Customers' });
    },
  },
};
</script>

<style>
</style>