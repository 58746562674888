<template>
  <v-form ref="otherForm" v-model="valid" >

    <v-row>
      <v-col cols="12" md="4">
        <ServerSideResult :test="banks">
          <SelectInput :items="banks" label="Bank" v-model="customer.bank_name" required @change="bankChange" />
        </ServerSideResult>
      </v-col>
      <v-col cols="12" md="4">
        <SelectInput :items="branches" label="Branch" v-model="customer.branch_name" :disabled="branches.length==0" required />
      </v-col>
      <v-col cols="12" sm="4">
        <TextInput label="Account Number" v-model="customer.account_number" required />
      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import lookups from '@/mixins/lookups';
import ServerSideResult from '@/components/ServerSideResult';
import SelectInput from '@/components/SelectInput';
import TextInput from '@/components/TextInput';

export default {

  components: { ServerSideResult, SelectInput, TextInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
      banks: undefined,
      branches: [],
    };
  },

  computed: {
    bank() { return this.customer.bank_name; },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    
    customer(to) {
      this.$emit('input', to);
    },

    valid(to) { this.$emit('valid', to); },

  },

  methods: {
    validate() {
      this.$refs.otherForm.validate();
    },
    bankChange() {
      this.branches = [];
      if (this.bank) {
        this.remoteLookup('bankBranchBranches', { bank: this.bank }).then( branches => this.branches = branches.branches ).catch(e => this.branches = [] );
      }
    },
  },

  mounted() {
    this.remoteLookup('bankBranchBanks', {}).then( banks => this.banks = banks.banks ).catch(e => this.banks = null );
    this.bankChange();
  },

  mixins: [lookups],

}
</script>
