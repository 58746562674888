<template>
  <v-form v-model="valid" class="red lighten-5" ref="form2">
    <v-card-title>
      {{$t('terrain')}}
    </v-card-title>
    <v-divider></v-divider>
    <v-container fluid>
      <v-row class="green lighten-5">
        <v-col cols="12" md="4">
          <DateInput
              :label="formFields.dateOfTransplant.question"
              :rules="[sharedValidations.requiredValidation]"
              required
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
              v-model="formFields.dateOfTransplant.answer"
              clearable
              data-cy="dateOfTransplant"
          />
        </v-col>

        <v-col cols="12" sm="4">

          <v-combobox
            :items="['CINCEL', 'RASTRA', 'RASTRILLO']"
            :label="formFields.landPreparation.question"
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.landPreparation.answer"
            single-line
            clearable
            data-cy="landPreparation"
          ></v-combobox>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            :items="['Sí', 'No']"
            :label="formFields.drainageTrenches.question"
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.drainageTrenches.answer"
            required
            clearable
            data-cy="drainageTrenches"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-combobox
            v-model="formFields.distanceBetweenRows.answer"
            :items="['1.4', '1.5']"
            :label="formFields.distanceBetweenRows.question"
            :rules="[sharedValidations.requiredValidation]"
            single-line
            clearable
            data-cy="distanceBetweenRows"
          ></v-combobox>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            :label="formFields.plantingBedsHeight.question"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.plantingBedsHeight.answer"
            clearable
            suffix="Cms"
            data-cy="plantingBedsHeight"
          ></v-text-field>

        </v-col>
        <!--          6-->
        <v-col cols="12" md="4">
          <v-combobox
            persistent-
            :items="['0.30', '0.40', '0.50']"
            :label="formFields.distanceBetweenPlants.question"
            :rules="[sharedValidations.requiredValidation]"
            single-line
            v-model="formFields.distanceBetweenPlants.answer"
            clearable
            data-cy="distanceBetweenPlants"
          ></v-combobox>
        </v-col>
        <!--6-->
        <v-col cols="12" md="4">
          <v-select
            :items="['1', '2']"
            :label="formFields.rows.question"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.rows.answer"
            clearable
            data-cy="rows"
          ></v-select>
        </v-col>
        <!--7-->
        <v-col cols="12" md="4">
          <v-text-field
            persistent-hint
            :label="formFields.totalPlants.question"
            :hint="$t('formFields.totalPlants.label')"
            type="number"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.totalPlants.answer"
            clearable
            disabled
            data-cy="totalPlants"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-combobox
              persistent-hint
              :items="[
                    4,
                    5,
                    6
                ]"
              :label="formFields.linesOfFibers.question"
              hint="Seleccionar del menú o escribir nuevo"
              :rules="[sharedValidations.requiredValidation]"
              v-model="formFields.linesOfFibers.answer"
              clearable
              data-cy="linesOfFibers"
          ></v-combobox>
        </v-col>

        <!--12-->
        <v-col cols="12" md="4">
<!--          ¿Distancia Tutorado (Mts)?-->
          <v-combobox
              :items="['1', '1.5', '2']"
              :label="formFields.stickDistanceForPlanting.question"
              :rules="[sharedValidations.requiredValidation]"
              required
              v-model="formFields.stickDistanceForPlanting.answer"
              clearable
              data-cy="stickDistanceForPlanting"
          ></v-combobox>
        </v-col>

          <!--          10-->

          <v-col cols="12" md="4">
            <v-text-field
              persistent-hint
              :label="formFields.totalSticks.question"
              :hint="$t('formFields.totalSticks.label')"
              type="number"
              :rules="[sharedValidations.requiredValidation]"
              required
              v-model="formFields.totalSticks.answer"
              disabled
              data-cy="totalSticks"
            ></v-text-field>
          </v-col>

        <v-col cols="12" md="4">
<!--          14-->
          <v-text-field
            :label="formFields.totalContractPlants.question"
            type="number"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.totalContractPlants.answer"
            :hint="`(7 Total Plantas por hectárea ${this.formFields.totalContractFiber.answer}  x Área de Campo ${this.field.fieldSize.landSize})`"
            clearable
            persistent-hint
            disabled
            data-cy="totalContractPlants"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
<!--          15-->
          <v-select
            persistent-hint
            :items="[
                '0.7',
                '1.5'
                ]"
            :label="formFields.fiberCaliber.question"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.fiberCaliber.answer"
            clearable
            data-cy="fiberCaliber"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
<!--          16-->
          <v-select
            persistent-hint
            :items="[
                '30',
                '40',
                '50'
                ]"
            :label="formFields.fiberMooring.question"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.fiberMooring.answer"
            clearable
            data-cy="fiberMooring"
          ></v-select>
        </v-col>

<!--        (Total Tutores x Amarre de Fibra) + ( Total Tutores x Distancia Tutorado) = Total x Líneas de Fibra-->
        <v-col cols="12" md="4">
<!--          17-->
          <v-text-field
            persistent-hint
            :label="formFields.totalFiberHectare.question"
            hint="(Total Tutores x Amarre de Fibra) + ( Total Tutores x Distancia Tutorado)"
            type="number"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.totalFiberHectare.answer"
            clearable
            disabled
            data-cy="totalFiberHectare"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
<!--          18-->
          <v-text-field
            persistent-hint
            :label="formFields.totalContractFiber.question"
            hint="(Total Fibra por Hectárea x Área de Campo)"
            type="number"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.totalContractFiber.answer"
            clearable
            disabled
            data-cy="totalContractFiber"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
<!--          19-->
          <v-select
            persistent-hint
            :items="[
                '750',
                '1000',
                ]"
            :label="formFields.typeOfRoll.question"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.typeOfRoll.answer"
            clearable
            data-cy="typeOfRoll"
          ></v-select>
        </v-col>
<!--        (Total Fibra del Contrato / Tipo de Rollo)-->

<!--          20-->
        <v-col cols="12" md="4">
          <v-text-field
            persistent-hint
            :label="formFields.totalFiberRolls.question"
            hint="(Total Fibra del Contrato / Tipo de Rollo)"
            type="number"
            :rules="[sharedValidations.requiredValidation]"
            required
            v-model="formFields.totalFiberRolls.answer"
            clearable
            disabled
            data-cy="totalFiberRolls"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row class="green lighten-5">
        <TakePhoto :text="'Upload Images'" @click="saveImage">
          <v-btn color="primary" elevation="2"> Fotos del Terreno </v-btn>

        </TakePhoto>
        <div class="form-images-wrapper d-md-flex">

          <div v-for="item in formFields.attachedImages" class="mx-4 my-4">
            <SingleImage :image="item" maxHeight="200px" />
          </div>
        </div>
      </v-row>

    </v-container>
  </v-form>
</template>

<script>
import DateInput from "@/components/DateInput";
import {isInputEmpty} from "@/views/partners/hr_co/utils";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";

export default {

  name: "Phase1Section1Form",
  props: ['value', 'field', 'customer'],
  components: { DateInput, TakePhoto, SingleImage },
  data() {
    return {
      valid: false,
      formFields: {},
      sharedValidations: new HugoValidations()
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; }
    },
    formFields(to) { this.$emit('input', to); },

    'formFields.rows.answer': function(newValue) {
      this.formFields.totalPlants.answer = this.calculateTotalPlants(this.formFields.distanceBetweenRows.answer, this.formFields.distanceBetweenPlants.answer, newValue)
      this.formFields.totalSticks.answer = this.calculateTotalSticks(this.formFields.distanceBetweenRows.answer, this.formFields.stickDistanceForPlanting.answer, newValue)
    },
    'formFields.distanceBetweenRows.answer': function(newValue) {
      this.formFields.totalPlants.answer = this.calculateTotalPlants(newValue, this.formFields.distanceBetweenPlants.answer, this.formFields.rows.answer)
      this.formFields.totalSticks.answer = this.calculateTotalSticks(newValue, this.formFields.stickDistanceForPlanting.answer, this.formFields.rows.answer)
    },
    'formFields.distanceBetweenPlants.answer': function(newValue) {
      this.formFields.totalPlants.answer = this.calculateTotalPlants(this.formFields.distanceBetweenRows.answer, newValue, this.formFields.rows.answer)
    },
    'formFields.stickDistanceForPlanting.answer': function(newValue) {
      this.formFields.totalSticks.answer = this.calculateTotalSticks(this.formFields.distanceBetweenRows.answer, newValue, this.formFields.rows.answer)

      this.formFields.totalFiberHectare.answer = this.calculateTotalFiberHectare(
          this.formFields.totalSticks.answer,
          this.formFields.fiberMooring.answer,
          newValue)
    },
    'formFields.totalSticks.answer': function(newValue) {
      this.formFields.totalFiberHectare.answer = this.calculateTotalFiberHectare(
          newValue,
          this.formFields.fiberMooring.answer,
          this.formFields.stickDistanceForPlanting.answer)
    },
    'formFields.fiberMooring.answer': function(newValue) {
      this.formFields.totalFiberHectare.answer = this.calculateTotalFiberHectare(
          this.formFields.totalSticks.answer,
          newValue,
          this.formFields.stickDistanceForPlanting.answer)
    },
    'formFields.totalFiberHectare.answer': function(newValue) {
      this.formFields.totalContractFiber.answer = newValue * this.field.fieldSize.landSize
    },
    'formFields.typeOfRoll.answer': function(newValue) {
      this.formFields.totalFiberRolls.answer = this.calculateTotalFiberRolls(this.formFields.totalContractFiber.answer, newValue)
    },
    'formFields.totalContractFiber.answer': function(newValue) {

      this.formFields.totalFiberRolls.answer = this.calculateTotalFiberRolls(newValue, this.formFields.typeOfRoll.answer)
      this.formFields.totalContractPlants.answer = this.formFields.totalContractFiber.answer * this.field.fieldSize.landSize

    },
    'formFields.totalPlants.answer': function(newValue) {
      // todo fix: totalPlants from another place
      this.formFields.totalContractPlants.answer = (newValue * this.field.fieldSize.landSize).toFixed(1)
    },
    'formFields.sticks.answer': function(newValue) {
      // todo error difference between Si and Sí
      if (['si', 'sí'].includes(this.formFields.sticks.answer.toLowerCase())) {
        this.formFields.linesOfFibers = {
          question: '10 ¿Líneas de fibra?',
          answer: undefined
        }
      } else if (this.formFields.sticks.answer === 'No') {
        this.formFields.linesOfFibers = {}
      }
    },
  },
  methods: {
    validate() {
      this.$refs.form2.validate();
    },

    saveImage(uuid) {
      const savedImageObj = {
        type: this.imageUploadTypeEnums.technicalVisitView,
        type_id: this.field.uuid,
        uuid: uuid,
      };
      this.$store.dispatch("addImage", savedImageObj);
      // console.log("saveImage", savedImageObj);
      this.formFields.attachedImages.push(savedImageObj);
    },
    calculateTotalPlants(distanceBetweenRows, distanceBetweenPlants, rows) {
      // console.log('calculateTotalPlants.', distanceBetweenRows)
      if(isInputEmpty(distanceBetweenRows) || isInputEmpty(distanceBetweenPlants) || isInputEmpty(rows)) {
        return null
      }
      const ans = (100/ distanceBetweenRows) * (100/ distanceBetweenPlants) * rows
      console.log('calculateTotalPlants', ans)
      return ans.toFixed(1)
    },
    calculateTotalSticks(distanceBetweenRows, stickDistanceForPlanting, rows) {
      if(isInputEmpty(distanceBetweenRows) || isInputEmpty(stickDistanceForPlanting) || isInputEmpty(rows)) {
        return null
      }
      const ans = (100/ distanceBetweenRows) * (100/ stickDistanceForPlanting) * rows
      // console.log('calculateTotalSticks', ans)
      return ans.toFixed(1)
    },
    calculateTotalFiberHectare(totalSticks, fiberMooring, stickDistanceForPlanting) {
      if(!totalSticks || !fiberMooring || !stickDistanceForPlanting) {
        return null
      }
      const ans = (totalSticks * fiberMooring) + (totalSticks * stickDistanceForPlanting)
      // console.log('calculateTotalFiberHectare.ans', ans)
      return ans
    },
    calculateTotalFiberRolls(totalContractFiber, typeOfRoll) {
      // console.log('calculateTotalFiberRolls', typeOfRoll, totalContractFiber)
      if(isInputEmpty(typeOfRoll) || isInputEmpty(totalContractFiber)) {
        return null
      }
      if (totalContractFiber === 0) {
        return 0
      }
      const ans = (totalContractFiber/ typeOfRoll)
      console.log('calculateTotalFiberRolls.ans', ans)
      return ans.toFixed()
    }
  },
  mixins: [ImageUploadTypeEnums]
}
</script>

<style scoped>

</style>


<i18n>
{
  "en": {
    "terrain": "Terrain",
    "formFields.totalPlants.label": "(100/3 distance between rows) x (100/5 distance between plants) x 6 number of Rows",
    "formFields.totalSticks.label": "(100/distance between rows) x (100/distance between tutores) x number of Rows"
  },
  "es": {
    "terrain": "Terreno",
    "formFields.totalPlants.label": "(100/3 distancia entre hileras) x (100/5 distancia entre plantas) x 6 número de hileras",
    "formFields.totalSticks.label": "(100/distancia entre filas) x (100/distancia entre tutores) x número de Filas"
  },
  "sw": {
    "terrain": "Terrain",
    "formFields.totalPlants.label": "(100/3 distance between rows) x (100/5 distance between plants) x 6 number of Rows",
    "formFields.totalSticks.label": "(100/distance between rows) x (100/distance between tutores) x number of Rows"
  }
}
</i18n>


