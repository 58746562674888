<template>
  <div>
    <ApexTz
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        v-if="formChoice==='apex_tz'"
        ref="createForm"
    />
    <DairyKe
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        v-if="formChoice==='dairy_ke'"
        ref="createForm"
    />
    <HrCo
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        v-else-if="formChoice==='hr_co'"
        ref="createForm"
    />

    <NinayoTz
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        v-else-if="formChoice==='ninayo_tz'"
        ref="createForm"
    />
    <Urban
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        v-else-if="formChoice==='urban'"
        ref="createForm"
    />
    <UzimaUg
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        v-else-if="formChoice==='uzima_ug'"
        ref="createForm"
    />
    <NileUg :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice === 'nile_ug'"/>

    <NuCafeUg :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice === 'nucafe_ug'"/>

    <UltimateTz v-else-if="formChoice==='ultimate_tz'" />

    <EcoBw
        v-else-if="formChoice==='eco_bw'"
        :customer="customer"
    />

    <DefaultCreate
        :customer="customer"
        @clearInputs="clearInputs"
        @submitAndEdit="submitAndEdit"
        @submitAndNew="submitAndNew"
        ref="createForm"
        v-else
    />
  </div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import fields from '@/mixins/fields';

  import DefaultCreate from '@/views/contacts/partners/Default';
  import ApexTz from '@/views/contacts/partners/ApexTz';
  import DairyKe from '@/views/contacts/partners/DairyKe';
  import HrCo from '@/views/contacts/partners/HrCo';
  import NinayoTz from '@/views/contacts/partners/NinayoTz';
  import Urban from '@/views/contacts/partners/Urban';
  import UzimaUg from '@/views/contacts/partners/UzimaUg';
  import NileUg from '@/views/contacts/partners/NileUg';
  import NuCafeUg from '@/views/contacts/partners/NuCafeUg';
  import UltimateTz from "@/views/contacts/partners/ultimate_tz/UltimateTz";
  import EcoBw from "@/views/contacts/partners/eco_bw/EcoBw";

  export default {

    components: {
      DefaultCreate,
      ApexTz,
      DairyKe,
      HrCo,
      NinayoTz,
      Urban,
      UzimaUg,
      NileUg,
      UltimateTz,
      NuCafeUg,
      EcoBw,
    },

    data: () => ({
      emptyCustomer: {
        demographic: { identity: {} },
        farm: {},
      },
      customer: {
        demographic: { identity: {} },
        farm: {},
      },
    }),

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      formChoice() {
        let f = 'default';
        if (['hr_co', 'winwin_zw'].includes(this.partnerCode)) { f = 'hr_co'; }
        if (['urban_zm', 'urban_ke'].includes(this.partnerCode)) { f = 'urban'; }
        if (this.partnerCode=='apex_tz') { f = 'apex_tz'; }
        if (this.partnerCode=='dairy_ke') { f = 'dairy_ke'; }
        if (this.partnerCode=='uzima_ug') { f = 'uzima_ug'; }
        if (this.partnerCode=='ninayo_tz') { f = 'ninayo_tz'; }
        if (this.partnerCode=='nile_ug') { f = 'nile_ug'; }
        if (this.partnerCode=='ultimate_tz') { f = 'ultimate_tz'; }
        if (this.partnerCode=='two_tz') { f = 'ultimate_tz'; }
        if (this.partnerCode=='nucafe_ug') { f = 'nucafe_ug'; }
        if (this.partnerCode=='eco_bw') { f = 'eco_bw'; }
        return f;
      },
      crops() { return this.$store.getters.cropNames; },
      depotId() { return this.$store.getters.settings.fieldAgentDepotId; },
      depotName() { return ((this.$store.getters.settings.depots.filter(e => e.id==this.depotId )[0]) || {}).name },
    },

    methods: {
      submitAndNew() {
        this.save();
        this.clearInputs()
      },
      submitAndEdit() {
        let uuid = this.save();
        this.$router.push({ name: 'ShowCustomer', query: { uuid: uuid } });
      },
      save() {

        // this.$store.dispatch('logErrorTrace', 'Start contacts.create');

        let customer = this.customer;

        let depotId = ( customer.addToDepot ? this.depotId : null );
        let depot = {
          id: this.depotId,
          name: this.depotName,
        }

        // this.$store.dispatch('logErrorTrace', JSON.stringify(customer) );
        // this.$store.dispatch('logErrorTrace', 'initialised');

        let newCustomer = Object.assign({}, this.customer);
        let demographic = Object.assign({}, (customer.demographic || {}));
        let identity    = Object.assign({}, (demographic.identity || {}));

        // this.$store.dispatch('logErrorTrace', 'cloned');

        newCustomer.uuid = uuidv4();
        newCustomer.isAFarmer = true;
        if (customer.firstName || customer.surname) {
          newCustomer.name = `${(customer.firstName ? customer.firstName : '')} ${(customer.surname ? customer.surname : '')}`.trim();
        }
        newCustomer.savings_group_name = newCustomer.savings_group_name || customer.church;
        newCustomer.fieldAgent = {
          id: this.$store.getters.settings.fieldAgentId,
          name: this.$store.getters.settings.name,
        };
        // this.$store.dispatch('logErrorTrace', 'fa');
        newCustomer.demographic = {
          gender: customer.gender || demographic.gender,
          geographicsArray: customer.regions,
          birthdate: demographic.birthdate,
          dependents: demographic.dependents,
          language: demographic.language,
          maritalStatus: demographic.maritalStatus,
          income: demographic.income,
          incomePeriod: demographic.incomePeriod,
          identity: identity,
        }
        // this.$store.dispatch('logErrorTrace', 'demographic');

        delete(newCustomer.gender);
        delete(newCustomer.church);
        delete(newCustomer.fieldSize);

        // this.$store.dispatch('logErrorTrace', 'customer set');

        if (customer.identity) {
          newCustomer.demographic['identity'] = {
            number: customer.identity.number,
            type: customer.identity.type,
          }
        }

        // this.$store.dispatch('logErrorTrace', 'identity set');

        if (depotId) { newCustomer.depot = depot; }

        // this.$store.dispatch('logErrorTrace', 'depot set');

        if (customer.fieldSize || customer.primaryCrop) {
          // todo add field size
          let field = this.newField(customer.primaryCrop);
          field.fieldSize = customer.fieldSize || {};
          this.$set(newCustomer, 'fields', [field]);
          if (depotId) { field.depot = depot; }
        }

        // this.$store.dispatch('logErrorTrace', 'crops set');

        // this should move out of here
        if (this.partnerCode=='hr_co' || this.partnerCode=='winwin_zw') { newCustomer.hrContactStatus = 'new'; }

        // this.$store.dispatch('logErrorTrace', JSON.stringify(newCustomer) );
        // this.$store.dispatch('logErrorTrace', 'dispatching');

        this.$store.dispatch('upsertCustomer', newCustomer);

        return newCustomer.uuid;

      },

      clearInputs() {
        this.customer = this.emptyCustomer;
        this.$refs.createForm.resetForm()
      },
    },

    mixins: [fields],

  }
</script>
