<template>
  <ServerSideResult :test="contractNumber" >
    <v-form v-model="valid">

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" width="100%" :href="`${domain}/ultimate_tz/documents/contract/${uuid}?${qs}`" target="_blank">
            Print Contract
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <DocumentInput v-model="contract" label="Contract" type="contract" :typeId="customer.uuid" accept=".pdf" requiredx caption="Drag the signed contract PDF into this box, or click to upload it." />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="success" style="width: 100%;" :disabled="!valid" @click="$emit('uploaded')">
            Continue
          </v-btn>
        </v-col>
      </v-row>

    </v-form>
  </ServerSideResult>

</template>

<script>

import lookups from '@/mixins/lookups';
import prodDebug from "@/mixins/prodDebug";

import DocumentInput from "@/components/DocumentInput";
import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { DocumentInput, ServerSideResult },

  props: ['customer', 'loanRequest'],

  data() {
    return {
      valid: false,
      contract: undefined,
      contractNumber: undefined,
    }
  },

  computed: {
    uuid() { return this.customer.uuid; },
    qs() {
      let other = {
        agent_name: this.userName,
        product: 'Check Me Back',
        contract_number: this.contractNumber,
        arrears_interest: 0.15,
      }
      return new URLSearchParams( { ...this.loanRequest, ...other } ).toString();
    },
    domain() { return (this.production ? 'https://connect.sasa.solutions' : 'http://connect.sasa.local:3000'); },
  },

  watch: {
    loanParameters(to) { this.$emit('input', to); }
  },

  mounted() {
    this.remoteLookup('ultimateTzContractNumber', { uuid: this.customer.uuid }).then( contractNumber => this.contractNumber = contractNumber.contractNumber ).catch(e => this.contractNumber = null);
  },

  mixins: [lookups, prodDebug],

}
</script>
