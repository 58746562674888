<template>

  <v-container fluid>
    <v-row class="text-center">
      <v-col class="text-h4 red--text">
        {{customer.name}} has open loans and is ineligible for another one.
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
  
export default {

  props: ['customer'],

}
</script>
