import {parseToPesticideFormData} from "@/views/partners/hr_co/utils";
import {fungicidesData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/fungicidesData";
import {bactericidesData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/bactericidesData";
import {weedsData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/weedsData";
import {insecticidesData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/insecticidesData";
import {bioInputsData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/bioInputsData";
import {
    compostAndAmendmentsData
} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/compostAndAmendmentsData";
import {fertilizersData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/fertilizersData";


export const pestTypesEnum = {
    fungicides: 'Fungicidas',
    insecticides: 'Insecticidas',
    bactericides: 'Bactericidas',
    weeds: 'Malezas',
    bioInputs: 'Bio Insumos',
    compostAndAmendments: 'Compost y Enmiendas',
    fertilizers: 'Fertilizantes',
}

export default {
    data() {
        return {
            pestTypesEnum:pestTypesEnum,
            fungicidesAutocompleteData: parseToPesticideFormData(fungicidesData),
            insecticidesAutocompleteData: parseToPesticideFormData(insecticidesData),
            bactericidesAutocompleteData: parseToPesticideFormData(bactericidesData),
            weedsAutocompleteData: parseToPesticideFormData(weedsData),
            bioInputsAutocompleteData: parseToPesticideFormData(bioInputsData),
            compostAndAmendmentsAutocompleteData: parseToPesticideFormData(compostAndAmendmentsData),
            fertilizersAutocompleteData: parseToPesticideFormData(fertilizersData),
        }
    }
};
