
export const ESTIMATED_TRANSPLANT_DAYS = 35
export const ESTIMATED_TARGET_DATE_HARVERST = 120
export const sheetFivePhases = {
    phaseOne: 'FASE 1 - DISEÑO Y PREPARACION LOTE',
    phaseTwo: 'FASE 2 - PLANTULAS Y TRANSPLANTE',
    phaseThree: 'FASE 3- DESARROLLO CULTIVO - APLICACIONES',
    phaseFour: 'FASE 4- COSECHA',
}

export const assessmentNameEnum = {
    primeraVisita: 'primeraVisita',
    cultivationContract: 'cultivationContract',
}

export const assessmentQuestionType = {
    RegionPicker: 'RegionPicker',
    LandSizeInput: 'LandSizeInput',
    MultiSelect: 'MultiSelect',
    DepotInput: 'DepotInput',
}
