<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr
            v-for="field in farmsForCustomers"
            :key="field.uuid"
        >
          <td v-if="field.name">{{ field.name }}  </td>
          <td>{{ isElephantAware(field) ? 'CA Field' : 'Non CA Field' }}</td>
          <td>
            <v-btn color="primary" text :to="{name: 'FarmField', query: { uuid: customerUuid, farmUuid: field.uuid } }" class="ml-1">
              <v-icon dense>mdi-flower-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import fields from "@/mixins/fields";

export default {
  props: {
    customer: Object,
  },

  computed: {
    customerFields() {
      return this.customer.fields.filter((field) => {
        const cropIdToUse = field.primaryCropId !== undefined ? field.primaryCropId : field.cropId;
        return cropIdToUse != null;
      });
    },
    customerUuid() { return this.$route.query.uuid; },
    farmsForCustomers() { return this.$store.getters.farmsForCustomers(this.customerUuid) },
    crops() { return this.$store.getters.cropLookups; },
    customerAssessments() { return this.$store.getters.fieldSurveyAssessments(this.customer.uuid); },
  },
  methods: {
    isElephantAware(field) {
      const { lowTill, basinMethod, mulching, coverCrop } = field.payload.conservationTechnique || {};

      // Check if 2 or more techniques have an answer of true
      const techniques = [lowTill, basinMethod, mulching, coverCrop];
      const trueCount = techniques.reduce((count, technique) => {
        return technique && technique.answer ? count + 1 : count;
      }, 0);

      // Return true if 2 or more techniques are marked as true
      return trueCount >= 2;
    }
  },
  mixins: [fields],
}
</script>

<style scoped>

</style>
