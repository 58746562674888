<template>
  <v-form v-model="valid" class="red lighten-5" ref="form2">
    <v-card-title>
      {{$t('irrigation')}}
    </v-card-title>
    <v-divider></v-divider>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-radio-group
            :label="formFields.typeOfMulch.question"
            row
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.typeOfMulch.answer"
            clearable
            required
            data-cy="typeOfMulch"
          >
            <v-radio label="Si" value="yes"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <!--3-->
        <v-col cols="12" md="4">
          <v-select
            :items="['0.1', '0.15', 0.2, 0.3]"
            :label="formFields.drainageTrenches.question"
            required
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.drainageTrenches.answer"
            data-cy="irrigationDrainageTrenches"
            clearable
          ></v-select>
        </v-col>

        <!--4-->
        <v-col cols="12" md="4">
          <v-text-field
            :label="formFields.dripperFlow.question"
            type="number"
            required
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.dripperFlow.answer"
            suffix="Litros"
            clearable
            data-cy="dripperFlow"
          ></v-text-field>
        </v-col>
        <!--5-->
        <v-col cols="12" md="4">
          <v-combobox
            :items="[
              'DE ANILLOS',
              'DE ARENA',
              'PISCINAS DE DECANTACION',
              'NO INSTALARA',
              'OTRO',
            ]"
            :label="formFields.typeOfFilterInTheSystem.question"
            single-line
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.typeOfFilterInTheSystem.answer"
            clearable
            data-cy="typeOfFilterInTheSystem"
          ></v-combobox>
        </v-col>
        <!--6-->
        <v-col cols="12" md="4">
          <DateInput
            :label="formFields.dateForInstallingIrrigationSystem.question"
            :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
            :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.dateForInstallingIrrigationSystem.answer"
            clearable
            data-cy="dateForInstallingIrrigationSystem"
          />
        </v-col>
        <!--7-->
        <v-col cols="12" md="4">
          <v-radio-group
            :label="formFields.irrigationSystemWasTested.question"
            row
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.irrigationSystemWasTested.answer"
            clearable
            data-cy="irrigationSystemWasTested"
          >
            <v-radio label="Si" value="yes"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <!--8-->
        <v-col cols="12" md="4">
          <DateInput
            :label="formFields.dateWhen.question"
            required
            :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
            :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            v-model="formFields.dateWhen.answer"
            :rules="[sharedValidations.requiredValidation]"
            clearable
            data-cy="dateWhen"
          />
        </v-col>
        <!--9-->
        <v-col cols="12" md="4">
          <v-text-field
              :label="formFields.totalTapePerHectare.question"
              type="number"
              required
              :rules="[sharedValidations.requiredValidation]"
              v-model="formFields.totalTapePerHectare.answer"
              disabled
              clearable
              persistent-hint
              hint="Cálculo automático: ((100/Distancia entre Hileras) x 100)"
              data-cy="totalTapePerHectare"
          ></v-text-field>
        </v-col>
        <!--10-->
        <v-col cols="12" md="4">
          <v-text-field
              :label="formFields.totalTapePerContract.question"
              type="number"
              required
              :rules="[sharedValidations.requiredValidation]"
              v-model="formFields.totalTapePerContract.answer"
              disabled
              clearable
              persistent-hint
              :hint="`Cálculo automático: (100/Distancia entre Hileras) x 100)/ Área de Campo ${fieldArea}`"
              data-cy="totalTapePerContract"
          ></v-text-field>
        </v-col>
        <!--11-->
        <v-col cols="12" md="4">
          <v-text-field
              :label="formFields.totalDroppers.question"
              type="number"
              required
              :rules="[sharedValidations.requiredValidation]"
              v-model="formFields.totalDroppers.answer"
              disabled
              clearable
              persistent-hint
              hint="9 Total Cinta del Contrato x (1/Distancia entre Goteros)"
              data-cy="totalDroppers"
          ></v-text-field>
        </v-col>

        <!--12-->
        <v-col cols="12" md="4">
          <v-text-field
              :label="formFields.totalLandFlows.question"
              type="number"
              required
              :rules="[sharedValidations.requiredValidation]"
              v-model="formFields.totalLandFlows.answer"
              disabled
              clearable
              persistent-hint
              suffix="Litros"
              hint="(Total Goteros del Contrato x Flujo por Gotero)"
              data-cy="totalLandFlows"
          ></v-text-field>
        </v-col>

        <!--11-->
        <v-col cols="12" md="4">
          <v-combobox
              :items="[
                'VENTURI',
                'INYECCION POR MODULO',
                'FLUJOMETRO',
                'OTRO',
              ]"
              :label="formFields.typeOfFertilizerInjection.question"
              single-line
              :rules="[sharedValidations.requiredValidation]"
              v-model="formFields.typeOfFertilizerInjection.answer"
              clearable
              data-cy="typeOfFertilizerInjection"
          ></v-combobox>
        </v-col>

      </v-row>

      <v-row class="green lighten-5">
        <TakePhoto :text="'Upload Images'" @click="saveImage">
          <v-btn elevation="2" color="primary" > Fotos del Terreno </v-btn>
        </TakePhoto>
        <div class="form-images-wrapper d-md-flex">
          <div v-for="item in formFields.attachedImages" class="mx-4 my-4">
            <SingleImage :image="item" maxHeight="200px" />
          </div>
        </div>
      </v-row>

    </v-container>
  </v-form>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import DateInput from "@/components/DateInput";
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";
import {calculateTotalDroppers, calculateTotalLandFlows} from "@/views/partners/hr_co/utils";

export default {
  components: {
    PageHeader,
    DateInput,
    TakePhoto,
    SingleImage
  },
  name: "Phase1Section2Form",
  props: ['value', 'customer', 'distanceBetweenRows', 'fieldArea', 'field'],
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
      }
    },
    formFields(to) { this.$emit('input', to); },
    'distanceBetweenRows': function(newValue) {
      if (parseInt(newValue) < 1) {
        this.formFields.totalTapePerHectare.answer = undefined
        return
      }

      const totalTapePerHectare = Math.floor(((100/newValue) * 100))

      this.formFields.totalTapePerHectare.answer = totalTapePerHectare
      this.formFields.totalTapePerContract.answer = Math.floor(totalTapePerHectare/this.fieldArea)
      this.formFields.totalDroppers.answer = calculateTotalDroppers(this.formFields.totalTapePerContract.answer, this.formFields.drainageTrenches.answer)
    },
    'formFields.drainageTrenches.answer': function(newValue) {
      this.formFields.totalDroppers.answer = calculateTotalDroppers(this.formFields.totalTapePerContract.answer, newValue)
    },
    'formFields.totalDroppers.answer': function(newValue) {
      this.formFields.totalLandFlows.answer = calculateTotalLandFlows(newValue, this.formFields.dripperFlow.answer)
    },
    'formFields.dripperFlow.answer': function(newValue) {
      this.formFields.totalLandFlows.answer = calculateTotalLandFlows(this.formFields.totalDroppers.answer, newValue)
    },
  },
  data() {
    return {
      valid: false,
      formFields: {},
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form2.validate();
    },
    saveForm() {
      this.validate();
    },
    saveImage(uuid) {
      const savedImageObj = {
        type: this.imageUploadTypeEnums.technicalVisitView,
        type_id: this.field.uuid,
        uuid: uuid,
      };
      this.$store.dispatch("addImage", savedImageObj);
      this.formFields.attachedImages.push(savedImageObj);
    },
  },
  mixins: [ImageUploadTypeEnums]
};
</script>

<style scoped>
</style>

<i18n>
{
  "en": {
    "irrigation": "Irrigation"
  },
  "es": {
    "irrigation": "Riego"
  },
  "sw": {
    "irrigation": "Umwagiliaji"
  }
}
</i18n>
