<template>
  <v-simple-table>
    <tbody>
      <Row title="Client Number"          :code="customer.partnerIdentifier" v-if="customer.partnerIdentifier" />
      <Row title="Name"                   :text="customer.name" bold="true" v-if="customer.name" />
      <Row title="Telephone">
        <Telephone :msisdn="customer.msisdn" />
        <span v-if="customer.alternative_msisdn">&nbsp;/&nbsp;</span>
        <Telephone :msisdn="customer.alternative_msisdn" v-if="customer.alternative_msisdn" />
      </Row>
      <Row title="Identity"               :text="customer.demographic.identity.number"  />
      <Row title="Married"                :status="(customer.demographic.maritalStatus ? 'Yes' :  'No')"     v-if="customer.demographic" />
      <Row title="Gender"                 :gender="customer.demographic.gender"                              v-if="customer.demographic" />
      <Row title="Age"                    :number="customer.demographic.age"                                 v-if="customer.demographic" />


      <Row title="Income"                 :currency="parseInt(customer.economic.icome)"                      v-if="customer.economic && customer.economic.icome"           />
      <Row title="Net Pay"                :currency="parseInt(customer.economic.nettPay)"                    v-if="customer.economic && customer.economic.nettPay"         />
      <Row title="Adjusted Net Income"    :currency="parseInt(customer.economic.adjustedNettPay)"            v-if="customer.economic && customer.economic.adjustedNettPay" />

      <Row title="Employer"               :text="customer.employer.name"                                     v-if="customer.employer && customer.employer.name"          />
      <Row title="Department"             :text="customer.employer.department"                               v-if="customer.employer && customer.employer.department"    />
      <Row title="Payroll Day"            :text="customer.employer.payload.payrollDay"                       v-if="customer.employer && customer.employer.payload && customer.employer.payload.payrollDay"    />
      <Row title="" >
        <v-btn color="primary" dense small :to="{ query: { uuid: uuid }, name: 'CreateContact' }">
          <v-icon small left>mdi-account-edit</v-icon>
          Edit
        </v-btn>
      </Row>
    </tbody>
  </v-simple-table>
</template>

<script>
import Row from "@/components/Row";
import Telephone from "@/components/Telephone";

export default {

  components: { Row, Telephone },

  props: {
    customer: {
      type: Object,
      required: true,
    }
  },


  computed: {
    uuid() { return this.customer.uuid; },
  },

}
</script>
