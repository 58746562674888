export const bioInputsData = [
    {
        "pest": "Huevos  de Lepidópteros",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Phytophtora parasitica",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Acaro Blanco  -  Polyphagotarsonemus latus",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Acondicionador de Suelo",
        "activeIngredient": "LACTOBACILLUS UFC/ML; 2.7X10^6; BACTERIAS NITRIFICANTES 20x10^6 UFC/ML; HONGOS Y LEVADURAS/ML 17x10^6     SL",
        "Dose": "10 a 20 Litros/Hectárea"
    },
    {
        "pest": "Acondicionador de Suelo",
        "activeIngredient": "MICORRIZAS  Glomus sp.     Micelio externo de MVA (Glomus sp):   3.300 metros/Litro          SC",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Acondicionador de Suelo",
        "activeIngredient": "Bacillus laterosporus (2 cepas) Bacillus mycoides     1X10^8  UFC/ML     WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Acondicionador de Suelo",
        "activeIngredient": "MICORRIZAS DE LOS GENEROS Glomus spp., Acualospora spp., Entrosphora sspp., Gigaspora spp. - 30-40 ESPORAS VIABLES/g DE SUELO       WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Acondicionador de Suelo",
        "activeIngredient": "Azospirillum brasilense-Azotobacter chroococcum-Lactobacillus acidophilus-Saccharomyces cerevisiae: 1X10^4 UFC/ML -1X10^4UFC/Ml-1x10^4,UFC/ml-1X10^2UFC/ml",
        "Dose": "1 Litro po cada 10 Toneladas"
    },
    {
        "pest": "Acondicionador de Suelo",
        "activeIngredient": "Azotobacter chroococcum-Pseudomona aureofaciens-Bacillus subtillis-Bacillus lincheniformis & Bacillus megaterium:  2X10^10 UFC/g DE CADA MICROORGANISMO",
        "Dose": "250 a 500 g/Hectárea"
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Afidos  -   Myzus persicae",
        "activeIngredient": "Metarhizium anisopliae",
        "Dose": "1,0 - 2,0 grs/litro"
    },
    {
        "pest": "Alternaria solani",
        "activeIngredient": "Bacillus subtilis        2X10⁹ ESPORAS/GRAMO DE PRODUCTO FORMULADO     WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Alternaria solani",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Alternaria solani",
        "activeIngredient": "Trichoderma harzianum CEPA DSM 14944    5 X 10⁸ CONIDIAS VIABLES/ML      SC",
        "Dose": "1,0 a 1,5 cc/Litro"
    },
    {
        "pest": "Alternaria spp.",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus -richoderma spp-Saccharomyces cerevisiae-Trichoderma spp:  1X10⁸ ESPORAS/g  C/U",
        "Dose": "15 g/Litro"
    },
    {
        "pest": "Alternaria spp.",
        "activeIngredient": "Burkholderia vietnamensis     1 X 10⁸ UFC/cc       SL",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Alternaria spp.",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Alternaria spp.",
        "activeIngredient": "Bacillus pumilis RAZA (QST 2808)    1 X 10⁹ UFC/g 1.38%      SC",
        "Dose": "5,0 a 6,0 cc/Litro"
    },
    {
        "pest": "Alternaria spp.",
        "activeIngredient": "Extracto de Canela    70%      SC",
        "Dose": "3,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Aphis gossippi",
        "activeIngredient": "Entomophtora virulenta     1 X 10⁶ ZYGOSPORAS VIABLES/ML",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Bacterias Fitopatogenas",
        "activeIngredient": "Streptomyces racemochromogenes     1X10⁸ UFC/ml      SC",
        "Dose": "1,0 Litros/Hectárea"
    },
    {
        "pest": "Belonolaimus sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Bemicia tabaci",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 1     200.000.000   UFC      SC",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Trichoderma harzianum T-39    1 X 10⁹ UFC/G         WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "EXTRACTO DE LIMON NO COMESTIBLE 96,7% (967 g/L)  -  Swinglea glutinosa      SL",
        "Dose": "1,0  a 1,5 cc/Litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Burkholderia vietnamensis     1 X 10⁸ UFC/cc       SL",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Trichoderma koninglopsis    1x10⁹ UFC/g VIABLES 3.3%        WP",
        "Dose": "1,0 g/Litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "EXTRACTO DE ACEITE ESENCIAL DE TOMILLO    23%",
        "Dose": "1,5 a 2,5 cc/Litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Extracto de Lauréaceas (1.8 Cineol)     1%P/p       EC",
        "Dose": "2,0 a 3,0 Litros/Hectárea"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Extracto de Aloe Vera       100 g/litro           SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Bacillus subtilis        2X10⁹ ESPORAS/GRAMO DE PRODUCTO FORMULADO     WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Bacillus amyloliquefaciens cepa QTS 713   1 X 10⁹ UFC/g 1.34%      SC",
        "Dose": "2,0 a 3,0 Litros/Hectárea"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "Trichoderma harzianum CEPA DSM 14944    5 X 10⁸ CONIDIAS VIABLES/ML      SC",
        "Dose": "1,0 a 1,5 cc/Litro"
    },
    {
        "pest": "Botrytis cinerea",
        "activeIngredient": "EXTRACTO BOTANICO DE CÍTRICOS    430 g/L      SL",
        "Dose": "1,5 a 2,0 cc/Litro"
    },
    {
        "pest": "Botrytis cinérea",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Burkholderia glumae",
        "activeIngredient": "EXTRACTO DE AJO  -  EXTRACTO DE CAÑA DE AZUCAR   10%  - 15%      SC",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Burkohalderia glumae",
        "activeIngredient": "Streptomyces racemochromogenes     1X10⁸ UFC/ml      SC",
        "Dose": "2,5 cc/Litro"
    },
    {
        "pest": "Cercospora spp.",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Chizas  -  Phyllophaga obsoleta",
        "activeIngredient": "Bacillus popilliae      WP 160.000.000 UFC",
        "Dose": "3 - 5 gramos por metro cuadrado"
    },
    {
        "pest": "Cigarritas  -  Empoasca spp.",
        "activeIngredient": "Metarhizium anisopliae",
        "Dose": "1,0 - 2,0 grs/litro"
    },
    {
        "pest": "Cladosporium sp",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Cladosporium sp.",
        "activeIngredient": "EXTRACTO DE LIMON NO COMESTIBLE 96,7% (967 g/L)  -  Swinglea glutinosa      SL",
        "Dose": "1,0  a 1,5 cc/Litro"
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "Beauveria bassiana     1 X 10⁸ CONIDIAS VIABLES/G 20%",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "Metharizium anisopliae CEPA DSM 15168   1 X 10⁹ CONIDIAS VIABLES/ML    SC",
        "Dose": "1,75 cc/Litro"
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "Bacillus thuringiensis var tenebrionis     1x10⁸ esporas viables/mL 20%        SL",
        "Dose": "5 cc/Litro"
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Coleópteros",
        "activeIngredient": "Metarhizium anisopliae",
        "Dose": "1,0 - 2,0 grs/litro"
    },
    {
        "pest": "Coletotrichum spp.",
        "activeIngredient": "Bacillustatinas 99.9 %        SL",
        "Dose": "4,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Collaria columbiensis",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus     1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁸ UFC/g       WP",
        "Dose": "1,0 a 2,0 g/Litro"
    },
    {
        "pest": "Colletotrichum acutatum",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Colletotrichum gloeosporioides",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Colletotrichum gloeosporioides",
        "activeIngredient": "COMPLEJO DE EXTRACTOS DE CÍTRICOS / ACIDO CITRICO    150 g/L       SC",
        "Dose": "0,3 cc/Litro"
    },
    {
        "pest": "Colletotrichum sp.",
        "activeIngredient": "Extracto de pulpa y semillas de citrico    57.5 % g/L p/v      SC",
        "Dose": "150 a 230 cc/litro"
    },
    {
        "pest": "Colletotrichum spp.",
        "activeIngredient": "Burkholderia vietnamensis     1 X 10⁸ UFC/cc       SL",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Colletotricum spp.",
        "activeIngredient": "Bacillus amyloliquefaciens cepa QTS 713   1 X 10⁹ UFC/g 1.34%      SC",
        "Dose": "3,0 a 4,0 Litros/Hectárea"
    },
    {
        "pest": "Deroceras sp.",
        "activeIngredient": "ALCALOIDES EXTRAIDOS DE PLANTAS DE LA FAMILIA RUBIACEAE   33%      DP",
        "Dose": "12,5 a 25  g/Litro      Foliar  - Drench"
    },
    {
        "pest": "Deroceras sp.",
        "activeIngredient": "EXTRACTO DE Coffea arabica - EXTRACTO DE Sapindus saponaria:    33% - 0.9%     WP",
        "Dose": "1,5 a 5,0 g/Litro"
    },
    {
        "pest": "Diabrotica spp.",
        "activeIngredient": "EXTRACTO DE AJO-EXTRACTO DE AJI-LIMONERO-DISULFURO DE ALILO-CAPSAISIN-ACIDO NICOTINICO-CAROTENOIDES-OTROS      EC",
        "Dose": "1,0 a 2,0 cc/Litro"
    },
    {
        "pest": "Erinnyis ello",
        "activeIngredient": "Granulovirus de Erinnyis ello EeGV   34%   1X10⁹ CUERPOS DE  INCLUSION/GRAMO (CI/g)     WP",
        "Dose": "100 g/Hectárea"
    },
    {
        "pest": "Escamas Blandas y Cochinillas",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Extracto Vegetal",
        "activeIngredient": "RUDA (100 g/L) EQUIVALENTE A RUTIN HIDROLATO (19%) UNDECANONA (05%) GRASA (16%)      EC",
        "Dose": "10 cc/litro"
    },
    {
        "pest": "Fijador de Nitrogeno",
        "activeIngredient": "Azotobacter vinelandii     3X10⁵ UFC/ml          SC",
        "Dose": "1,0 cc/100 cc de agua + 2,0 g de Melaza"
    },
    {
        "pest": "Fijador de Nitrogeno",
        "activeIngredient": "Azotobacter chroococcum     1X10^8 UFC/mL       SC",
        "Dose": "5,0 A 10,0 cc/Litro"
    },
    {
        "pest": "Fijador de Nitrogeno",
        "activeIngredient": "Azotobacter chroococcum & Azospirillum sp    1X10^8 UFC/ml      SC",
        "Dose": "1,0 Litro/Hectárea"
    },
    {
        "pest": "Frankiniella occidentalis",
        "activeIngredient": "Beauveria bassiana 4x108 esporas/g- Metarhizium anisopliae 4x108 esporas/g - Lecanicillium lecanii 1x108 esporas/g - Bacillus thuringiensis 1x108 esporas/g",
        "Dose": "1,5 g/Litro"
    },
    {
        "pest": "Frankliniella occidentalis",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 1     200.000.000   UFC      SC",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Fusarium oxysporum",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Fusarium oxysporum",
        "activeIngredient": "Trichoderma lignorum    2 X 10⁷ CONIDIAS VIABLES/g        WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Fusarium oxysporum",
        "activeIngredient": "Extracto de ROMERO-HIERBABUENA-TOMILLO     1.2% - 1.4% - 0.8%        SL",
        "Dose": "5,0 a 7,0 cc/Litro"
    },
    {
        "pest": "Fusarium oxysporum",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Fusarium sp.",
        "activeIngredient": "Trichoderma harzianum     1.0 X 10⁸ UFC ESPORAS/GRAMO DE PRODUCTO FORMULADO       WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Fusarium spp.",
        "activeIngredient": "Bacillus subtilis-Bacillus thuringiensis  var kurstaki-Bacillus pumilis-Bacillus amyloliquefaciens: 1x10⁸ UFC /ml-1x10⁸ UFC/ml-1x10⁸ UFC/ml-1x10⁸ UFC/ml      SC",
        "Dose": "1,0 a 2,5 cc/Litro"
    },
    {
        "pest": "Fusarium spp.",
        "activeIngredient": "Extracto de pulpa y semillas de citrico    57.5 % g/L p/v      SC",
        "Dose": "150 a 230 cc/litro"
    },
    {
        "pest": "Glodobera sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Glomerella cingulata",
        "activeIngredient": "EXTRACTO BOTANICO DE CÍTRICOS    430 g/L      SL",
        "Dose": "1,5 a 2,0 cc/Litro"
    },
    {
        "pest": "Helycotilenchus sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Hemipteros",
        "activeIngredient": "Bacillus thuringiensis var tenebrionis     1x10⁸ esporas viables/mL 20%        SL",
        "Dose": "5 cc/Litro"
    },
    {
        "pest": "Heterodera sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Hydrellia sp.",
        "activeIngredient": "EXTRACTO DE AJI (Capsicum annum)  -  EXTRACTO DE CAÑA DE AZUCAR (Saccharum officinarum)    6.0%m  -  15.0%       SC",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Hypothenemus hampei",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus     1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁸ UFC/g       WP",
        "Dose": "1,0 a 2,0 g/Litro"
    },
    {
        "pest": "Larvas de Lepidópteros",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Lepidópteros  - Spodoptera spp.",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Lepidópteros  Estado De Huevo",
        "activeIngredient": "Trichogramma spp.   2.400 huevos Parasitados por Pulgada Cuadrada    T. atopovirilia, T. minitum, T. pretiosum; T. exiguum",
        "Dose": "50 a 300 Pulgadads por Hectárea en Liberación Semanal       > 35 DDT"
    },
    {
        "pest": "Lepidópteros Estado de Larva",
        "activeIngredient": "Bacillus thuringiensis var.  Kurstaki    32000 IU/mg 2x10⁹ UFC/g      WP",
        "Dose": "5,0 g/litro"
    },
    {
        "pest": "Lepidópteros Estado de Larva",
        "activeIngredient": "Bacillus thuringiensis var. Kurstaki   3.5% 9,2X10⁸ UFC/ml      SE",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Lepidópteros Estado de Larva",
        "activeIngredient": "Bacillus thuringiensis var. kurstaki (SEROTIPOS IIIA Y IIIB)    1 X 10¹⁰ UFC/g",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Lepidópteros Estado de Larva",
        "activeIngredient": "Bacillus thuringiensis var aizawai    3.8% DE DELTA ENDOTOXINA (25000 UI/MG)",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Lepidópteros Estado de Larva",
        "activeIngredient": "Virus de granulosis de Baculovirus phthorimaea    0.05 % (EQUIVALENTES LARVALES 2/100 g)       Polvo Espolvoreo",
        "Dose": "2,5 kilogramos por Tonelada"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Trichoderma harzianum CEPA DSM 14944    5 X 10⁸ CONIDIAS VIABLES/ML      SC",
        "Dose": "1,0 a 1,5 cc/Litro"
    },
    {
        "pest": "Leveilulla taurica",
        "activeIngredient": "Extracto de Canela    70%      SC",
        "Dose": "3,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Limax sp.",
        "activeIngredient": "ALCALOIDES EXTRAIDOS DE PLANTAS DE LA FAMILIA RUBIACEAE   33%      DP",
        "Dose": "12,5 a 25  g/Litro      Foliar  - Drench"
    },
    {
        "pest": "Liriomyza  spp.",
        "activeIngredient": "Oleorresina de ajo-Oleorresina de ají-Aceite Esencial de Eucalipto-Aceite Esencial de Clavo-Aceite Esencial de Limoncillo: 1- 3.00%-0.40%- 0.85%-0.30%-0.85%      EW",
        "Dose": "2,0 a 3,0 cc/Litro"
    },
    {
        "pest": "Mejorador Absorción de Fosforo",
        "activeIngredient": "ENDOMICORRIZAS VESICO-ARBUSCULAR (VAM) DE LOS GENEROS: Glomus spp. Acaulospora spp. y Entrophospora spp.     3 ESPORAS VIABLES/G   WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Meloidogyne incognita",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus     1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁸ UFC/g       WP",
        "Dose": "1,0 a 2,0 g/Litro"
    },
    {
        "pest": "Meloidogyne javanica",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus     1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁸ UFC/g       WP",
        "Dose": "1,0 a 2,0 g/Litro"
    },
    {
        "pest": "Meloidogyne sp.",
        "activeIngredient": "Paecilomyces lilacinus   2 X 10⁷ CONIDIAS/G       WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Meloidogyne spp.",
        "activeIngredient": "Trichoderma harzianum-Trichoderma asperellum,-Trichoderma atroviride -Paecilomyceslilacinus:    5x108 conidias/g de C/U      WP",
        "Dose": "1,0 g/Litro"
    },
    {
        "pest": "Meloidogyne spp.",
        "activeIngredient": "EXTRACTO DE QUILLAY SAPONINAS+SNS (SOLIDOS NO SAPONINAS COMO POLIFENOLES SALES Y AZUCARES)    350 g/L       SL",
        "Dose": "1,5 Litros/Hectárea"
    },
    {
        "pest": "Meloidogyne spp.",
        "activeIngredient": "EXTRACTO DE TAGETES ERECTA       80% p/v         SL",
        "Dose": "10 a 15 Litros/Hectárea"
    },
    {
        "pest": "Meloidogyne spp.  Estado de Huevos",
        "activeIngredient": "Bacillus subtilis-Bacillus thuringiensis  var kurstaki-Bacillus pumilis-Bacillus amyloliquefaciens: 1x10⁸ UFC /ml-1x10⁸ UFC/ml-1x10⁸ UFC/ml-1x10⁸ UFC/ml      SC",
        "Dose": "1,0 a 2,5 cc/Litro"
    },
    {
        "pest": "Meloidogyne spp.  Estado de Huevos",
        "activeIngredient": "Pochonia chlamydosporia    1x10⁸ CONIDIOS VIABLE/ml      SC",
        "Dose": "0,5 a 2,5 cc/Litro"
    },
    {
        "pest": "Meloidoyine sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Milax sp.",
        "activeIngredient": "ALCALOIDES EXTRAIDOS DE PLANTAS DE LA FAMILIA RUBIACEAE   33%      DP",
        "Dose": "12,5 a 25  g/Litro      Foliar  - Drench"
    },
    {
        "pest": "MIX Malezas  No selectivo  Post",
        "activeIngredient": "ACIDO PELARGÓNICO       649 g/L     EC",
        "Dose": "13 Litros/Hectárea"
    },
    {
        "pest": "Mosca Blanca   -  Bemicia tabaci",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Mycosphaerella Fijensis",
        "activeIngredient": "EXTRACTO DE CANELA    30%       EC",
        "Dose": "1,0 a 1,5 Litros/Hectárea"
    },
    {
        "pest": "Mycosphaerella fijensis",
        "activeIngredient": "EXTRACTO DE EUCALIPTO  -  EXTRACTO DE CAÑA DE AZUCAR    20%  - 25%      SC",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Mycosphaerella fijensis",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Myzus persicae",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 1     200.000.000   UFC      SC",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Myzus persicae",
        "activeIngredient": "EXTRACTO DE Tithonia diversifolia:   3.3%    Repelencia Liriomyza     Efecto Acaricida Mortalidad       CE",
        "Dose": "1 a 2 Litros/Hectárea"
    },
    {
        "pest": "Oligonychus sp",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Oligonychus yothers",
        "activeIngredient": "EXTRACTO DE QUILLAY SAPONINAS+SNS (SOLIDOS NO SAPONINAS COMO POLIFENOLES SALES Y AZUCARES)    350 g/L       SL",
        "Dose": "1,5 Litros/Hectárea"
    },
    {
        "pest": "Orthesia sp.",
        "activeIngredient": "Entomophtora virulenta     1 X 10⁶ ZYGOSPORAS VIABLES/ML",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Pectobacterium spp.",
        "activeIngredient": "Burkholderia vietnamensis     1 X 10⁸ UFC/cc       SL",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Phytophtora cinnamomi",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Phytophtora infestans",
        "activeIngredient": "EXTRACTO DE ARBOL DE TE  Malaleuca alternifolia    23.8%       EC",
        "Dose": "0,75 a  1,0   Litro/Hectárea"
    },
    {
        "pest": "Phytophtora infestans",
        "activeIngredient": "Bacillus subtilis        1 X 10⁹ UFC/g        SC",
        "Dose": "2,5 cc a 10 cc/Litro"
    },
    {
        "pest": "Phytophtora infestans",
        "activeIngredient": "Bacillus pumilis RAZA (QST 2808)    1 X 10⁹ UFC/g 1.38%      SC",
        "Dose": "5,0 a 6,0 cc/Litro"
    },
    {
        "pest": "Polyphagotarsonemus latus",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 2 - Paecilomyces fumosoroseus  DSM15126     200.000.000 UFC - 500.000.000 UFC       SC",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Polyphagotarsonemus latus",
        "activeIngredient": "EXTRACTO DE Tithonia diversifolia:   3.3%    Repelencia Liriomyza     Efecto Acaricida Mortalidad       CE",
        "Dose": "1 a 2 Litros/Hectárea"
    },
    {
        "pest": "Polyphagotarsonemus latus",
        "activeIngredient": "Extracto de Canela    70%      SC",
        "Dose": "3,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Polyphgotarsonemus latus",
        "activeIngredient": "EXTRACTO DE QUILLAY SAPONINAS+SNS (SOLIDOS NO SAPONINAS COMO POLIFENOLES SALES Y AZUCARES)    350 g/L       SL",
        "Dose": "1,5 Litros/Hectárea"
    },
    {
        "pest": "Pratylenchus sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Prodiplosis longifila",
        "activeIngredient": "EXTRACTO DE Tithonia diversifolia:   3.3%    Repelencia Liriomyza     Efecto Acaricida Mortalidad       CE",
        "Dose": "1 a 2 Litros/Hectárea"
    },
    {
        "pest": "Promotor de Crecimiento",
        "activeIngredient": "Bacillus licheniformis-Bacillus megaterium-Bacillus subtilis-Azotobacter chrococcum-Pseudomonas fluorescens: 1 X 10¹⁰ UFC/mL de cada especie    SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Promotor de Crecimiento",
        "activeIngredient": "Bacillus thuringensis var kurstaki-Hirsutella thompsonii-Akanthomyces johnsonii: 1X10⁸ UFC/mL-1x10⁹ UFC/mL- 1x10⁹ UFC/Ml",
        "Dose": "1,0 a 3,0 cc/Litro"
    },
    {
        "pest": "Promotor de Crecimiento",
        "activeIngredient": "Azospirillum brasilence-Bacillus subtillis-Pseudomonas fluorescens-Sacharomyces cerevisiae:  1.3X10¹⁰ UFC/g-1.3X10¹⁰ UFC/g-1.3X10¹⁰ UFC/g-1.0X10¹⁰ UFC/g      SP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Promotor de Crecimiento",
        "activeIngredient": "BACILLUS SUBTILIS LACTOBACILLUS ACIDOPHILUS SACCHAROMYCES CEREVISIAE:    1X10⁸ UFC/mL-1X10⁸ UFC/mL-1X10⁴ UFC/mL     SC",
        "Dose": "5,0 cc/litro"
    },
    {
        "pest": "Pseudomonas fuscavaginae",
        "activeIngredient": "Streptomyces racemochromogenes     1X10⁸ UFC/ml      SC",
        "Dose": "2,5 cc/Litro"
    },
    {
        "pest": "Pseudomonas syringae",
        "activeIngredient": "Bacillus subtilis        2X10⁹ ESPORAS/GRAMO DE PRODUCTO FORMULADO     WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Radopholus  sp.",
        "activeIngredient": "Paecilomyces lilacinus   2 X 10⁷ CONIDIAS/G       WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Radopholus similis",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Ralstonia solanacearum",
        "activeIngredient": "EXTRACTO DE MIMOSA (Mimosa tenuiflora)     80% g/L",
        "Dose": "2,5  a 5,0 cc/litro"
    },
    {
        "pest": "Ralstonia spp.",
        "activeIngredient": "Bacillustatinas 99.9 %        SL",
        "Dose": "4,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Ralstonia spp.",
        "activeIngredient": "Burkholderia vietnamensis     1 X 10⁸ UFC/cc       SL",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Ramularia areola",
        "activeIngredient": "Bacillus subtilis        2X10⁹ ESPORAS/GRAMO DE PRODUCTO FORMULADO     WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Ramularia oreola",
        "activeIngredient": "Bacillus subtilis  5x10⁹ ESPORAS VIABLES POR ml 17.5% p/p       SL",
        "Dose": "1,0 cc - 2,0 cc/Litro"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Trichoderma harzianum     1.0 X 10⁸ UFC ESPORAS/GRAMO DE PRODUCTO FORMULADO       WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Trichoderma harzianum-Trichoderma asperellum,-Trichoderma atroviride -Paecilomyceslilacinus:    5x108 conidias/g de C/U      WP",
        "Dose": "1,0 g/Litro"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Trichoderma viride     1 X 10⁷ CONIDIAS/g     WP    SC",
        "Dose": "500 g-cc/Hectárea"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Trichoderma koninglopsis    1x10⁹ UFC/g VIABLES 3.3%        WP",
        "Dose": "1,0 g/Litro"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "EXTRACTO DE Artemisa absinthium    700 g/L (70%)     SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Rhizoctonia solani",
        "activeIngredient": "Bacillus subtilis        2X10⁹ ESPORAS/GRAMO DE PRODUCTO FORMULADO     WP",
        "Dose": "2,5 g - 5,0 g/Litro"
    },
    {
        "pest": "Rhizoctonia solani kuhn",
        "activeIngredient": "Trichoderma lignorum    2 X 10⁷ CONIDIAS VIABLES/g        WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Sclerotinia sclerotiorum",
        "activeIngredient": "Trichoderma koninglopsis    1x10⁹ UFC/g VIABLES 3.3%        WP",
        "Dose": "1,0 g/Litro"
    },
    {
        "pest": "Solubilizador de Fosfatos",
        "activeIngredient": "Pseudomonas fluorescens    1X10^8 UFC/ml      SL",
        "Dose": "5 Litros/hectárea"
    },
    {
        "pest": "Solubilizador de Fosfatos",
        "activeIngredient": "Penicillium janthinellum     1X10^8 ESPORAS/ml      SC",
        "Dose": "1,0 Litro/Hectárea"
    },
    {
        "pest": "Solubilizador de Fosfatos",
        "activeIngredient": "Penicillium janthinellun    1X10⁸ esporas/Kg       SC",
        "Dose": "1,0 Litros/Hectárea"
    },
    {
        "pest": "Solubilizador de Fosfatos",
        "activeIngredient": "Bacillus subtilis-Penicillium bilaiae-Trichoderma harzianum:   1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁶ UFC/g",
        "Dose": "1 a 2 Kilogramos/Hectárea"
    },
    {
        "pest": "Solubilizador de Fosfatos y nitrogeno",
        "activeIngredient": "Bacillus subtillis-Bacillus amyloliquefasciens-Pseudomonas montinelli:     2X10⁸ UFC/g- 5X10⁶ UFC/g- 2X10⁸ UFC/g       SP",
        "Dose": "10 Kilogramos/Hectárea"
    },
    {
        "pest": "Solubilzador de Fosfatos",
        "activeIngredient": "Bacillus megaterium      1X10⁷ ESPORAS/ml 20%     SL",
        "Dose": "2,5 cc/Litro"
    },
    {
        "pest": "Spodoptera frugiperda",
        "activeIngredient": "Beauveria bassiana 4x108 esporas/g- Metarhizium anisopliae 4x108 esporas/g - Lecanicillium lecanii 1x108 esporas/g - Bacillus thuringiensis 1x108 esporas/g",
        "Dose": "1,5 g/Litro"
    },
    {
        "pest": "Spodoptera frugiperda",
        "activeIngredient": "Nucleopoliedrovirus de Spodoptera frugiperda NPV003    34%  1X10⁹ CUERPOS DE  INCLUSION/GRAMO (CI/g)",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Spodoptera spp.",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus     1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁸ UFC/g       WP",
        "Dose": "1,0 a 2,0 g/Litro"
    },
    {
        "pest": "Stenoma cecropia",
        "activeIngredient": "Beauveria bassiana-Metarhizium anisopliae-Paecilomyces lilacinus     1x10⁶ UFC/g-1x10⁶ UFC/g-1x10⁸ UFC/g       WP",
        "Dose": "1,0 a 2,0 g/Litro"
    },
    {
        "pest": "Tetranychus cinnabarinus",
        "activeIngredient": "EXTRACTO DE Sophora spp.  MATRINE 06g ± 02 g/L      SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "Extracto Ajo y Ají    Capsaicina 6%   -  Disulfuro de Alilo 4%      SL",
        "Dose": "2,0 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "Phytoseiulus persimilis      2000 UNIDADES (INDIVIDUOS) EN BOTELLAS DE 100 ML    -   DEPREDADOR         Individuos Vivos",
        "Dose": "2  Individuos por Metro Cuadrado"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 2 - Paecilomyces fumosoroseus  DSM15126     200.000.000 UFC - 500.000.000 UFC       SC",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "OXIDO DE SILICIO (tierra de diatomeas)    84,30%      DP",
        "Dose": "1,3 g/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE Sophora spp.  MATRINE 06g ± 02 g/L      SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "Neoseiulus californicus  (Anblyseius)     5000 INDIVIDUOS/FRASCO 250 Ml    -   2000 INDIVIDUOS/FRASCO 100 mL   DEPREDADOR         Individuos Vivos",
        "Dose": "25 Individuos Por Metro cuadrado"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "MEZCLAS DE SALES POTASICAS DE ACIDOS GRASOS DE ORIGEN VEGETAL    0,3       SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE Sephora flavescens (MATRINE)   5 g/L* C.S.P 100%",
        "Dose": "1,4  a 1,6 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE PALO SANTO| EXTRACTO DE LUPULO    380 g/L   -  70 g/L        EC",
        "Dose": "1,5 a 2,5 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE Tithonia diversifolia:   3.3%    Repelencia Liriomyza     Efecto Acaricida Mortalidad       CE",
        "Dose": "1 a 2 Litros/Hectárea"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE ACEITE ESENCIAL DE TOMILLO    23%",
        "Dose": "1,5 a 2,5 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "Bacillus thuringiensis var tenebrionis     1x10⁸ esporas viables/mL 20%        SL",
        "Dose": "5 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE NEEM (AZADIRACHTA INDICA) | EXTRACTO DE KARANJA (PONGAMIA PINNATAL.) | ACEITE DE CANELA (CINNAMOMUM VERUM):   350 g/L - 400 g/L - 200 g/L       EC",
        "Dose": "1,5 a 2,0 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "Extracto de Canela    70%      SC",
        "Dose": "3,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Tetranychus urticae",
        "activeIngredient": "EXTRACTO DE QUILLAY SAPONINAS+SNS (SOLIDOS NO SAPONINAS COMO POLIFENOLES SALES Y AZUCARES)    350 g/L       SL",
        "Dose": "1,5 Litros/Hectárea"
    },
    {
        "pest": "Thrips s.p.",
        "activeIngredient": "Beauveria bassiana   -  Bacillus thuringiensis    1 X 10⁸ ESPORAS/g   -  1 X 10⁶ESPORAS/g        WP",
        "Dose": "1,5 g/Litro"
    },
    {
        "pest": "Transformador de Materia Organica",
        "activeIngredient": "Lactobacillus brevis & Saccharomyces cerevisiae    20X10⁴ UFC/ml   -  10X10⁴ UFC/ml     SC",
        "Dose": "10 Litros/Hectárea"
    },
    {
        "pest": "Trialeurodes vaporariorum",
        "activeIngredient": "Beauveria bassiana 4x108 esporas/g- Metarhizium anisopliae 4x108 esporas/g - Lecanicillium lecanii 1x108 esporas/g - Bacillus thuringiensis 1x108 esporas/g",
        "Dose": "1,5 g/Litro"
    },
    {
        "pest": "Trialeurodes vaporariorum",
        "activeIngredient": "Beauveria bassiana    3X10¹⁰ CONIDIAS/g    WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Trialeurodes vaporariorum",
        "activeIngredient": "Beauveria bassiana    3X10¹⁰ CONIDIAS/g    WP",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Trialeurodes vaporariorum",
        "activeIngredient": "Paecilomyces fumosoroseus  PF 1     200.000.000   UFC      SC",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Trialeurodes vaporariorum",
        "activeIngredient": "MEZCLAS DE SALES POTASICAS DE ACIDOS GRASOS DE ORIGEN VEGETAL    0,3       SL",
        "Dose": "Información en Construcción"
    },
    {
        "pest": "Trialuroides vaporariorum",
        "activeIngredient": "EXTRACTO DE Tithonia diversifolia:   3.3%    Repelencia Liriomyza     Efecto Acaricida Mortalidad       CE",
        "Dose": "1 a 2 Litros/Hectárea"
    },
    {
        "pest": "Trialuroides vaporariorum",
        "activeIngredient": "EXTRACTO DE NEEM (AZADIRACHTA INDICA) | EXTRACTO DE KARANJA (PONGAMIA PINNATAL.) | ACEITE DE CANELA (CINNAMOMUM VERUM):   350 g/L - 400 g/L - 200 g/L       EC",
        "Dose": "1,5 a 2,0 cc/Litro"
    },
    {
        "pest": "Trialuroides vaporariorum",
        "activeIngredient": "Oleorresina de ajo-Oleorresina de ají-Aceite Esencial de Eucalipto-Aceite Esencial de Clavo-Aceite Esencial de Limoncillo: 1- 3.00%-0.40%- 0.85%-0.30%-0.85%      EW",
        "Dose": "2,0 a 3,0 cc/Litro"
    },
    {
        "pest": "Trichodorus sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "(Bacillus thuringiensis- Beauveria Bassiana) - (Lecanicillium lecanii- Metarhizium anisopliae)   ///   (100.000.000 Esporas/Gramo) - (400.000.000 Esporas/Gram)",
        "Dose": "1,0 -2,0 grs/litro --- 300 - 500 cc/Ha."
    },
    {
        "pest": "Trips  -  Trips tabaci / T. palmi",
        "activeIngredient": "Chrysoperla carnea - C. externa",
        "Dose": "20.000 a 100.000 larvas/ha."
    },
    {
        "pest": "Trips palmi",
        "activeIngredient": "EXTRACTO DE Tithonia diversifolia:   3.3%    Repelencia Liriomyza     Efecto Acaricida Mortalidad       CE",
        "Dose": "1 a 2 Litros/Hectárea"
    },
    {
        "pest": "Trips palmi",
        "activeIngredient": "Extracto de Canela    70%      SC",
        "Dose": "3,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Bacillustatinas 99.9 %        SL",
        "Dose": "4,0 a 5,0 cc/Litro"
    },
    {
        "pest": "Xanthomonas spp.",
        "activeIngredient": "Burkholderia vietnamensis     1 X 10⁸ UFC/cc       SL",
        "Dose": "1,0 - 2,0 cc/litro"
    },
    {
        "pest": "Xiphinema sp.",
        "activeIngredient": "EXTRACTOS VEGETALES & ACIDOS GRASOS      53 MILIGRAMOS POR LITRO DE FORMULACION         SC",
        "Dose": "1,0 a 2,0 Litros/Hectárea"
    }
]
