<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid>


      <v-row>
        <v-col cols="12" class="text-h4">
          General
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <DateInput v-model="form.date" required label="Date (Required)" />
        </v-col>
        <v-col cols="3">
          <DateInput v-model="form.date" required dropLists label="Date (Drop Lists; Required)" />
        </v-col>
        <v-col cols="3">
          <DateInput v-model="form.date" dropLists label="Date (Drop Lists; Not Required)" />
        </v-col>
        <v-col cols="3">
          <DateInput v-model="form.date" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          Result: {{form.date}}
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" class="text-h4">
          Birthdate
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <BirthdateInput v-model="form.birthdate" required label="Birthdate (Required)" />
        </v-col>
        <v-col cols="3">
          <BirthdateInput v-model="form.birthdate" required dropLists label="Birthdate (Drop Lists; Required)" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          Result: {{form.birthdate}}
        </v-col>
      </v-row>



      <v-row>
        <v-col class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import DateInput from "@/components/DateInput";
import BirthdateInput from "@/components/BirthdateInput";

export default {

  components: {
    DateInput,
    BirthdateInput,
  },

  data() {
    return {
      validForm: false,
      form: {},
    }
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  }

}
</script>
