<template>
  <v-card>
    <v-card-title>
      <v-icon>mdi-bird</v-icon>
      <v-toolbar-title>{{$t(activity)}}</v-toolbar-title>
    </v-card-title>
    <v-card-text>
      <v-row>

        <v-col cols=12 sm=4 v-for="type in types" :key="type">
          <v-checkbox v-model="poultryTypes" :label="$t(type)" :value="type" ></v-checkbox>
        </v-col>

        <v-col cols=12 v-if="poultryTypes.includes('broiler')">
          <v-text-field v-model="numberBroiler" type="number" :rules="numberRules" min=0 :hint="$t('numberHint')" :label="$t('broilerCount')" ></v-text-field>
        </v-col>

        <v-col cols=12 v-if="poultryTypes.includes('layer')">
          <v-text-field v-model="numberLayer" type="number" :rules="numberRules" min=0 :hint="$t('numberHint')" :label="$t('layerCount')" ></v-text-field>
        </v-col>

        <v-col cols=12 v-if="poultryTypes.includes('indigenous')">
          <v-text-field v-model="numberIndigenous" type="number" :rules="numberRules" min=0 :hint="$t('numberHint')" :label="$t('indigenousCount')" ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6"><v-switch v-model="freeRange" label="Free Range" color="primary" :value="true" hide-details></v-switch></v-col>
        <v-col cols="12" sm="6"><v-switch v-model="openSidedHouses" label="Open Sided Houses" color="primary" :value="true" hide-details></v-switch></v-col>
        <v-col cols="12" sm="6"><v-switch v-model="pipedWater" label="Piped Water" color="primary" :value="true" hide-details></v-switch></v-col>
        <v-col cols="12" sm="6"><v-switch v-model="dam" label="Dam" color="primary" :value="true" hide-details></v-switch></v-col>
        <v-col cols="12" sm="6"><v-switch v-model="pumpedWater" label="Pumped Water" color="primary" :value="true" hide-details></v-switch></v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
  import validationRules from '@/mixins/validationRules';
  export default {
    props: ['activity', 'type', 'icon', 'value'],

    data: () => ({
      types: ['broiler', 'layer', 'indigenous'],
      poultryTypes: [],
      numberBroiler: undefined,
      numberLayer: undefined,
      numberIndigenous: undefined,
      freeRange: undefined,
      openSidedHouses: undefined,
      pipedWater: undefined,
      dam: undefined,
      pumpedWater: undefined,
    }),

    computed: {
      payload() {
        return {
          poultryTypes: this.poultryTypes,
          numberBroiler: this.numberBroiler,
          numberLayer: this.numberLayer,
          numberIndigenous: this.numberIndigenous,
          freeRange: this.freeRange,
          openSidedHouses: this.openSidedHouses,
          pipedWater: this.pipedWater,
          dam: this.dam,
          pumpedWater: this.pumpedWater,
        };
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.poultryTypes = to.poultryTypes || [];
            this.numberBroiler = to.numberBroiler;
            this.numberLayer = to.numberLayer;
            this.numberIndigenous = to.numberIndigenous;
            this.freeRange = to.freeRange;
            this.openSidedHouses = to.openSidedHouses;
            this.pipedWater = to.pipedWater;
            this.dam = to.dam;
            this.pumpedWater = to.pumpedWater;
          } else {
            this.poultryTypes = [];
            this.numberBroiler = undefined;
            this.numberLayer = undefined;
            this.numberIndigenous = undefined;
            this.freeRange = undefined;
            this.openSidedHouses = undefined;
            this.pipedWater = undefined;
            this.dam = undefined;
            this.pumpedWater = undefined;
          }
        }
      },
      payload(to) {
        this.$emit('input', to);
      },
    },

    mixins: [validationRules],
  }
</script>


<i18n>
{
  "en": {
    "numberLabel": "Birds",
    "numberHint": "Number of birds farmed",
    "poultry": "Poultry",
    "broiler": "Broiler",
    "layer": "Layer",
    "indigenous": "Indigenous",
    "broilerCount": "Number of Broilers",
    "layerCount": "Number of Layers",
    "indigenousCount": "Number of Indigenous birds"
  },
  "es": {
    "numberLabel": "Animales",
    "numberHint": "Número de aves criadas",
    "poultry": "poultry",
    "broiler": "Parrilla",
    "layer": "Capa",
    "indigenous": "Indígena",
    "broilerCount": "Número de aves de engorde",
    "layerCount": "Número de capas",
    "indigenousCount": "Número de aves autóctonas"
  },
  "sw": {
    "numberLabel": "Wanyama",
    "numberHint": "Idadi ya ndege wanaofugwa",
    "poultry": "Kuku",
    "broiler": "Wa nyama",
    "layer": "Safu",
    "indigenous": "Asili",
    "broilerCount": "Idadi ya ndege wa nyama",
    "layerCount": "Idadi ya tabaka",
    "indigenousCount": "Idadi ya ndege asilia"
  }
}
</i18n>
