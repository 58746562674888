<template>
  <v-icon title="Connected" color="primary" dense v-if="isOnline">mdi-access-point-network</v-icon>
  <v-icon title="Disconnected" disabled dense v-else>mdi-access-point-network-off</v-icon>
</template>

<script>
  export default {
    computed: {
      isOnline() { return this.$store.getters.networkOnline; },
    },
  };
</script>
