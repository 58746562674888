<template>
  <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Insurance</td>
          <td v-if="value.insurance_requested"><Currency :value="value.insurance" /></td>
          <td v-else>Not Requested</td>
        </tr>
        <tr>
          <td>Fees</td>
          <td><Currency :value="value.fees" /></td>
        </tr>
        <tr>
          <td>Interest</td>
          <td><Currency :value="value.interest"/></td>
        </tr>
      </tbody>
      <p class="px-4">In total, you will repay: <Currency :value="value.exposure" /></p>
    </template>
  </v-simple-table>
</template>

<script>
import Currency from "@/components/Currency";

export default {
  components: { Currency },
  props: {
    value: {
      type: Object,
      required: true,
    },
  }
}
</script>
