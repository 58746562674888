<template>
  <v-container fluid>

    <v-row>
      <v-col cols="6">
        <Widget>
          <CustomerDetails :customer="customer" />
        </Widget>
      </v-col>

      <v-col cols="6">
        <Widget title="Actions">
          <CustomerActions :customer="customer" ref="customerActions" />
        </Widget>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import CustomerDetails from '@/views/customers/partners/ultimate_tz/components/CustomerDetails'
import Widget from "@/components/Widget";
import CustomerActions from "@/views/actions/CustomerActions"

export default {

  components: { CustomerDetails, Widget, CustomerActions },

  props: {
    customer: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
    }
  },

  computed: {
  },

  watch: {

  },

}
</script>
