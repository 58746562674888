<template>

  <v-row>
    <v-col cols="12">
      <ServerSideResult :test="done">
        <div class="text-h5">Expense saved.</div>
      </ServerSideResult>
    </v-col>
  </v-row>

</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { ServerSideResult },

  props: ['value'],

  data() {
    return {
      done: undefined
    }
  },

  mounted() {
    this.remoteLookup('ultimate_tz_raise_expense', this.value).then( done => this.done = true ).catch(e => console.log(e));
  },

  mixins: [lookups],

}
</script>
