<template>
  <span style="cursor: pointer;">
    <span class="white--text text-h5" v-if="partnerCode==='apex_tz'">eMazao</span>
    <Logo v-else />
  </span>
</template>

<script>
  import Logo from '@/components/Logo';
  export default {
    components: { Logo },
    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
    },
  };
</script>
