import {v4 as uuidv4} from "uuid";


export function totalPrimeraVisitaRatingsSummary(formFieldArray) {
    return {
        totalWeight: formFieldArray.reduce((total, formField) => total+ formField.weight, 0),
        totalAnswersWeight: sumTotalPrimeraVisitaRatings(formFieldArray),
    }
}

export function sumTotalPrimeraVisitaRatings(formFieldArray) {
    return formFieldArray.reduce((total, formField) => {
        if (!!formField.answer) {
            if (formField.weightAsc) {
                return total + (formField.answer/5 * formField.weight)
            } else {
                return total + ((6 - formField.answer)/5 * formField.weight)
            }
        } else {
            return total
        }
    }, 0)
}

// 3 numbers and one optional letter at the end eg 123Q
export const HR_FIELD_CODE_REGEX = /^\d{3}[a-zA-Z]?$/


export function calcBusinessDays(startDate, noOfDaysToAdd) {
    // calculates business days without weekends

    startDate.setHours(0,0,0,0);
    let endDate = ""
    let count = 0;
    while(count < noOfDaysToAdd){
        endDate = new Date(startDate.setDate(startDate.getDate() + 1));
        if(endDate.getDay() !== 0 && endDate.getDay() !== 6){
           //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
           count++;
        }
    }
    return endDate instanceof Date ? endDate.toISOString() : endDate
}

export function calcAllDays(startDate, noOfDaysToAdd) {
    // calculates business days weekends
    const date = startDate;
    date.setDate(startDate.getDate() + noOfDaysToAdd);
    return date.toISOString();
}

export function addMonthsToDate(startDate, noOfMonths) {
    return new Date(startDate.setMonth(startDate.getMonth() + noOfMonths));
}

export function createNewAssessment(assessmentName, customerUuid, formFields) {
    return {
        assessmentUuid: uuidv4(),
        assessmentName: assessmentName,
        createdAt: new Date(),
        customerUuid: customerUuid,
        formFields: formFields
    }
}

export function daysBetweenDates(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
}

export function customDebounce (fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function isInputEmpty(value) {
    return (value === '' || value === undefined || value === null);
}

// todo merge similar functions
export function parseToPesticideFormData(fungicidesData) {
    const parsedPests = {}
    fungicidesData.forEach(pestAndMitesPesticide => {
        if (pestAndMitesPesticide.pest in parsedPests) {

            parsedPests[pestAndMitesPesticide.pest].push({
                activeIngredient: pestAndMitesPesticide.activeIngredient,
                dose: pestAndMitesPesticide.Dose
            })
        } else {
            parsedPests[pestAndMitesPesticide.pest] = [
                {
                    activeIngredient: pestAndMitesPesticide.activeIngredient,
                    dose: pestAndMitesPesticide.Dose
                }
            ]
        }
    })

    const arrayPests = Object.keys(parsedPests).map(pestKey => ({
        pest: pestKey,
        pesticides: parsedPests[pestKey]
    }))
    return arrayPests;
}


export function calculateTotalDroppers(totalTapePerContract, drainageTrenches) {
    // Automatic Formula: Total Cinta del Contrato x (1/Distancia entre Goteros)
    if(isInputEmpty(totalTapePerContract) || isInputEmpty(drainageTrenches)) {
        return null
    }
    if (drainageTrenches === 0) {
        return 0
    }
    const ans = (totalTapePerContract * (1/drainageTrenches))
    return ans
}


export function calculateTotalLandFlows(totalDroppers, dripperFlow) {
    // Automatic Formula: (Total Goteros del Contrato x Flujo por Gotero)
    if(isInputEmpty(totalDroppers) || isInputEmpty(dripperFlow)) {
        return null
    }

    const ans = (totalDroppers * dripperFlow)
    return ans
}

export function areFormFieldsFilled(formQuestions) {
    const formFieldsWithQuestions = filterFormFieldsWithQuestions(Object.assign([], formQuestions))
    return formFieldsWithQuestions.filter(item => !(item.answer === null || item.answer === undefined || item.answer === '')).length === formFieldsWithQuestions.length
}

export function filterFormFieldsWithQuestions(formQuestions) {
    return formQuestions.filter(item => !!item.question)
}
