<template>
  <v-card>
    <v-card-title>
      {{$t('title')}}
      <v-spacer />
      <v-btn color="primary" small dense :to="{name: 'NewReceipt', query: { uuid: customer.uuid } }">{{$t('new')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table v-if="receipts.length>0">
        <tbody>
          <tr v-for="receipt in receipts">
            <td class="text-left"><Time :time="receipt.createdAt" format="date" /></td>
            <td class="text-right"><Currency :value="receipt.amount" /></td>
            <td>
              <v-btn color="primary" text :to="{name: 'ShowReceipt', query: { uuid: receipt.customerUuid, receiptUuid: receipt.uuid }}" class="ml-1">
                <v-icon dense>mdi-receipt</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else>
        {{$t('noReceipts')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>
  import Time from '@/components/Time';
  import Currency from '@/components/Currency';

  export default {

    components: { Time, Currency },

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      receipts() { return this.$store.getters.receiptsForCustomer(this.customerUuid).reverse().slice(0,5); },
    },

    methods: {
    },

  }
</script>


<i18n>
{
  "en": {
    "title": "Cash Receipts",
    "noReceipts": "No receipts."
  },
  "es": {
    "title": "Recibos de efectivo",
    "noReceipts": "Sin recibos de efectivo."
  },
  "sw": {
    "title": "Stakabadhi za pesa taslimu",
    "noReceipts": "Hakuna risiti."
  }
}
</i18n>
