<template>
  
  <ServerSideResult :test="data">
    <v-data-table dense :headers="headers" :items="data" item-key="item.uuid" :search="search" class="w-100" >

      <template v-slot:header.msisdn>{{$t('telephone')}}</template>
      
      <template v-slot:item.msisdn="{ item }">
        <Telephone :msisdn="item.msisdn" />
      </template>

      <template v-slot:item.amount="{ item }">
        <Currency :value="item.amount" />
      </template>

      <template v-slot:item.balance="{ item }">
        <Currency :value="item.balance" cents />
      </template>

      <template v-slot:item.due_at="{ item }">
        <Time :time="item.due_at" format="d MMM" v-if="item.due_at" />
      </template>

      <template v-slot:item.uuid="{ item }">
        <v-btn small color="primary" :to="{name: 'ShowCustomer', query: { uuid: item.uuid } }" class="mr-2" >
          <v-icon dense small>mdi-account</v-icon>
        </v-btn>
      </template>


    </v-data-table>
  </ServerSideResult>

</template>


<script>
  import ServerSideResult from '@/components/ServerSideResult';
  import IdentityNumber from '@/components/IdentityNumber';
  import Currency from '@/components/Currency';
  import Telephone from '@/components/Telephone';
  import Time from '@/components/Time';

  export default {

    components: {
      ServerSideResult,
      IdentityNumber,
      Currency,
      Telephone,
      Time,
    },

    props: ['data'],

    data: () => ({
      headers: [
        {
          text: 'Account',
          align: 'left',
          value: 'partnerIdentifier',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Employer',
          sortable: true,
          value: 'employer',
        },
        {
          text: 'Telephone',
          align: 'start',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Product',
          align: 'start',
          sortable: true,
          value: 'product',
        },
        {
          text: 'Installments',
          align: 'end',
          sortable: true,
          value: 'installments',
        },
        {
          text: 'Principal',
          align: 'end',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Balance',
          align: 'end',
          sortable: true,
          value: 'balance',
        },
        {
          text: 'Next',
          align: 'end',
          sortable: true,
          value: 'due_at',
        },
        {
          text: '',
          align: 'center',
          value: 'uuid',
        },

      ],

    }),

    computed: {
      search() { return this.$store.getters.search; },
    },

  }
</script>
