<template>
  <v-card>
    <l-map :zoom="zoomFactor" :center="center" :style="styles">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker v-for="marker in markers" :key="marker.name" :lat-lng="marker.latLng" :name="marker.name">
        <l-tooltip v-if="marker.title">
          {{marker.title}}
        </l-tooltip>
      </l-marker>
    </l-map>
  </v-card>
</template>

<script>

import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';

export default {
  components: { LMap, LTileLayer, LMarker, LTooltip },

  props: ['locations', 'width', 'height', 'zoom'],

  data() {
    return {
      // center: L.latLng(47.413220, -1.219482),
      url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: L.latLng(47.413220, -1.219482),
    }
  },

  computed: {
    styles() {
      return {
        'width': this.width || '100%',
        'height': this.height || '300px',
      }
    },
    zoomFactor() { return this.zoom || 9 },
    center() {
      let c = null;
      if (this.locations.length==0) {
        c = L.latLng(-2.983, 30.856);
      } else {
        const lat = this.locations.map( e => parseFloat(e.latitude) ).reduce((a, b) => a + b, 0);
        const lon = this.locations.map( e => parseFloat(e.longitude) ).reduce((a, b) => a + b, 0);
        c = L.latLng( lat/this.locations.length, lon/this.locations.length );
      }
      return c;
    },
    markers() {
      let m = [];
      this.locations.forEach(function(l) {
        m.push({
          latLng: L.latLng( parseFloat(l.latitude), parseFloat(l.longitude) ),
          title: l.title,
        });
      });
      return m;
    },
  },

};
</script>

