<template>

  <v-container fluid class="pa-0">

    <v-data-table dense :headers="headers" :items="customers" item-key="id" >

      <template v-slot:item.id="{ item }">
        <v-btn small color="primary" @click="$emit('show', item)" >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.msisdn="{ item }">
        <Telephone :msisdn="item.msisdn" />
      </template>

      <template v-slot:item.identity_number="{ item }">
        <IdentityNumber :demographic="item.demographic" />
      </template>

      <template v-slot:item.location="{ item }">
        <v-icon small color="red" v-if="item.location">mdi-google-maps</v-icon>
        {{item.location}}
      </template>

    </v-data-table>

  </v-container>



</template>


<script>

  import IdentityNumber from '@/components/IdentityNumber';
  import Telephone from '@/components/Telephone';

  export default {

    components: { IdentityNumber, Telephone },

    props: ['customers'],

    data: () => ({
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Telephone',
          align: 'start',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'ID Number',
          align: 'start',
          sortable: true,
          value: 'identity_number',
        },
        {
          text: 'Location',
          align: 'start',
          sortable: false,
          value: 'location',
        },
        {
          align: 'right',
          value: 'id',
          sortable: false,
        },

      ],

    }),

    computed: {
      // search() { return this.$store.getters.search; },
    },

  }
</script>
