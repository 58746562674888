<template>
  <PageHeader title="Your Cashbox" icon="mdi-piggy-bank-outline">
    <template v-slot:buttons>
      <v-btn color="primary" small dense class="ma-1" :to="{ name: 'UltimateTzExpense' }"><v-icon dense>mdi-cash-register</v-icon> Expense</v-btn>
      <v-btn color="primary" small dense class="ma-1" v-if="hasSafe" :to="{ name: 'UltimateTzSafebox' }"><v-icon dense>mdi-bank</v-icon> Safe Box</v-btn>
      <v-btn color="primary" small dense class="ma-1" v-if="!hasSafe" @click="grab"><v-icon dense>mdi-bank</v-icon> Grab Safe Box</v-btn>
    </template>
    
    <v-container fluid>

      <v-row>

        <v-col cols="6" class="text-left">
          <ServerSideResult :test="balance">
            <Widget title="Balance">
              <Currency :value="balance" cents />
            </Widget>
          </ServerSideResult>
        </v-col>

        <v-col cols="6" class="text-left">
          <Widget title="Safe Box Owner">
            <span v-if="hasSafe">You</span>
            <span v-else>{{safeboxOwnerName}}</span>
          </Widget>
        </v-col>

      </v-row>


      <v-row>

        <v-col cols="6">
          <Widget title="Send Money">
            <Capture @submit="toMove" v-model="transfer" :balance="balance" />
          </Widget>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12" class="text-h5 text--primary text-left">
          Transaction History
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <ServerSideResult :test="transactions">
            <Widget title="Transaction History">
              <v-data-table
                :headers="headers"
                :items="transactions"
                :items-per-page="25"
                class="elevation-1"
              >
              
                <template v-slot:item.at="{ item }">
                  <Time v-if="item.at" :time="item.at" />
                </template>
              
                <template v-slot:item.amount="{ item }">
                  <Currency v-if="item.amount" :value="item.amount" cents />
                </template>
              
                <template v-slot:item.after="{ item }">
                  <Currency v-if="item.after" :value="item.after" cents />
                </template>

              </v-data-table>
            </Widget>
          </ServerSideResult>
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';

import PageHeader from "@/components/PageHeader";
import Time from "@/components/Time";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import Widget from "@/components/Widget";

import Capture from "@/views/partners/ultimate_tz/cashbox/Capture";


export default {

  components: { PageHeader, Time, Currency, ServerSideResult, Widget, Capture },

  data() {
    return {

      transactions: undefined,
      safeboxOwnerName: undefined,
      balance:  undefined,

      transfer: {},

      headers: [
        {
          text: 'When?',
          align: 'start',
          sortable: true,
          value: 'at',
        },
        {
          text: 'What?',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Counter Party',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Amount',
          align: 'end',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Balance',
          align: 'end',
          sortable: true,
          value: 'after',
        },
      ],
    }
  },

  computed: {
    hasSafe() { return this.$store.getters.hasUltimateSafe; },
  },

  watch: {
    // balance(to) {

    // }
  },

  methods: {
    grab() {
      this.$store.commit('setUltimateSafe', false);
      this.remoteLookup('ultimateTzGrabSafe', {}).then( safe => this.setSafeboxOwner() ).catch(e => console.log(e));
    },
    setSafeboxOwner(wallet) {
      this.safeboxOwnerName = 'You';
      this.$store.commit('setUltimateSafe', true);
    },
    setWalletInfo(wallet) {
      this.safeboxOwnerName = ( wallet.user_has_safe ? 'You' : 'Nobody' );
      this.balance = wallet.balance;
      this.$store.commit('setUltimateSafe', (wallet.user_has_safe ? true : false) );
    },
    toMove() {
      let params = this.transfer || {};
      params['recipient_id'] = (this.transfer.recipient || {}).id;
      params['name'] = (this.transfer.recipient || {}).name;
      params['safe'] = 'safe';
      this.$router.push({ name: 'UltimateTzCashboxSend', query: params });
    },
  },

  mounted() {
    this.remoteLookup('ultimateTzWalletInfo', {}).then( wallet => this.setWalletInfo(wallet) ).catch(e => console.log(e));
    this.remoteLookup('ultimateTzWalletTransactions', {}).then( transactions => this.transactions = transactions ).catch(e => this.transactions = null);
  },

  mixins: [lookups],

}
</script>
