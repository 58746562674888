

export function checkVisibleMitigationValid(visibleMitigation) {
    return !!visibleMitigation && !visibleMitigation.effectivenessFormFields.find(val => !val.starRating || !val.visibleMitigationImage)

}


export const WalkFieldType = {
    CA_FIELD: 'CAField',
    NON_CA_FIELD: 'NonCAField',
}
