import { v4 as uuidv4 } from 'uuid';

export function createNewCorridorAwarenessAssessment(customer, assessmentType, awarenessQuestions) {
    const score = Object.values(awarenessQuestions.areCorridorsHelping).filter(item => item.answer === true).length
    return {
        uuid: uuidv4(),
        customerUuid: customer.uuid,
        assessmentType: assessmentType,
        awarenessQuestions: awarenessQuestions,
        assessmentName: "Corridor Awareness Assessment",
        description: `Corridor Awareness Assessment for ${customer.name}`,
        score: score,
        scoredAt: new Date().toISOString()
    }
}

export function createEmptyFieldSurveyAssessment(customerUuid, fieldUuid) {

    return {
        uuid: uuidv4(),
        customerUuid: customerUuid,
        assessmentType: 'FieldSurvey',
        fieldUuid: fieldUuid,
    }
}
