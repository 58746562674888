<template>
  <div>
    <Stepper
        :tabs="tabs"
        :captions="captions"
        :complete="complete"
        @position="stepChange($event)"
        ref="stepper"
        v-if="!!customer"
        vertical
    >

      <template v-slot:BasicInfo>
        <BasicInfo
            v-model="basicFormFields"
            :customer="customer"
            ref="basicInfoForm">

        </BasicInfo>
      </template>

    </Stepper>
    <div class="text-left py-2">
      <v-btn color="primary" class="mb-2" @click="saveAndGoToFieldSurvey">Submit and fill field survey form</v-btn>
    </div>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="green"
        elevation="24"
    >
      You have successfully onboarded the farmer
    </v-snackbar>

  </div>
</template>

<script>
import BasicInfo from "@/views/partners/eco_bw/views/register/BasicInfo";
import Stepper from "@/components/Stepper";
import StepperValidationSection from "@/views/partners/eco_bw/components/StepperValidationSection";

export default {
  components: {
    BasicInfo,
    Stepper,
    StepperValidationSection
  },
  data () {
    return {
      snackbar: false,
      timeout: 2000,
      valid: false,
      step: 1,
      tabs: [
        'BasicInfo',
      ],
      captions: {
        'BasicInfo': 'Basic Details',
      },
      basicFormFields: {
        firstName: null,
        surname: null,
        gender: null,
        birthDate: null,
        familySize: null,
        yearsFarming: null,
        transport: null,
        livelihood: null,
        atTheFarmersLocationOnRegister: null,
        farmerImageUuid: null,
        ethnicity: {
          options: ['Bambukushu', 'Basarwa', 'Bayei','Badxeriku','Batawana','Bakalaka','Banyemba','Baweku','Basubiya'],
          answer: null
        },
        region: {}
      },
    }
  },
  mounted() {
    if (this.customer.economic && this.customer.economic.payload) {
      if (this.customer.economic.payload['basicInfo']) {
        this.basicFormFields = this.customer.economic.payload.basicInfo
        // todo add ethnicity and family size

      }
    }
    if (this.customer) {
      this.basicFormFields.firstName = this.customer.firstName
      this.basicFormFields.surname = this.customer.surname
      this.basicFormFields.gender = this.customer.demographic.gender
      this.basicFormFields.birthDate = this.customer.demographic.birthdate
      this.basicFormFields.familySize = this.customer.economic.dependants
      this.basicFormFields.yearsFarming = this.customer.economic.incomePeriod
      this.basicFormFields.transport = this.customer.economic.transport
      this.basicFormFields.livelihood = this.customer.economic.payload.livelihood
    }

  },

  computed: {
    complete() { return [] },
    customerUuid() { return this.$route.query.uuid; },
    isStart() { return this.step === 1; },
    isLastStep() { return this.step === 9; },
    msisdn() { return this.customer.msisdn; },
    customer() { return this.$store.getters.customer(this.$route.query.uuid); }
  },

  methods: {

    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },

    isBasicFormFieldsValid() {
      return Object.values(this.basicFormFields).filter(basic => {return basic === null}).length === 0
          && !!this.basicFormFields.ethnicity.answer
          && !!this.basicFormFields.region
    },

    nextStep() {
      this.$refs.stepper.nextStep();
    },

    previous() { this.step -= 1; },

    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    toFieldSurveyForm() {
      this.$router.push({ name: 'EcoExistBwFieldSurvey', query: { uuid: this.customerUuid } });
    },

    saveBasicInfo() {
      this.customer.firstName = this.basicFormFields.firstName
      this.customer.surname = this.basicFormFields.surname
      this.customer.demographic.gender = this.basicFormFields.gender
      this.customer.demographic.birthdate = this.basicFormFields.birthDate

      // done backend
      this.customer.name = `${this.customer.firstName} ${this.customer.surname}`

      this.customer.economic = this.customer.economic || {};
      this.customer.economic.payload = this.customer.economic.payload || {};
      this.customer.economic.payload['basicInfo'] = this.basicFormFields

      if (!this.customer.fields) {
        this.customer.fields = []
      }

      this.save()

      this.snackbar = true;
    },

    validate() {
      this.$refs.basicInfoForm.validate();
    },
    saveAndDone() {
      this.validate()
      if (this.isBasicFormFieldsValid()) {
        this.saveBasicInfo();
        this.toCustomer();
      }
    },
    saveAndGoToFieldSurvey() {
      this.validate()
      if (this.isBasicFormFieldsValid()) {
        this.saveBasicInfo();
        this.toFieldSurveyForm();
      }
    },
    save() {
      this.snackbar = true;
      this.$store.dispatch('upsertCustomer', this.customer);
    },

  },

}
</script>
