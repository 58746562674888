<template>
    <v-img :src="src"
           :max-width="this.maxHeight"
           v-bind:height="height"
           v-bind="$attrs"
    ></v-img>

</template>

<script>
import ImageDb from "@/store/ImageDb";

export default {
  props: {
    imageUuid: {
      required: false,
      type: String
    },
    maxHeight: {
      required: false,
      type: String,
      default: '200px'
    },
    height: Number,
  },

  data: () => ({
    src: undefined,
  }),

  watch: {
    imageUuid: function (newValue) {
      if (newValue) {
        ImageDb.url(newValue).then( e => {
          this.src = e
        } );
      }

    }
  }
}
</script>

<style scoped>

</style>
