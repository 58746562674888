<template>
  <v-container class="pa-3 d-flex flex-column align-center justify-center">
    <v-card class="text-left pa-3 mt-n1 mb-3" :width="width" outlined>
      <v-card-title class="text-h6 mb-4"> Enter Farmer Phone Number </v-card-title>
      <v-form class="pa-3" ref="form" v-model="valid">
        <MsisdnInput v-model="customer.msisdn" required />
        <v-btn :disabled="!valid" color="primary" class="mr-4 mt-3" @click="submit">
          Go
        </v-btn>
      </v-form>
    </v-card>
    <v-card class="text-left mb-5 mx-auto" :width="width" outlined>
      <v-card-text>
        <div class="text--primary body-1">
           By proceeding you acknowledge that SBG may use your data for credit decision making processes, including sharing your data with credit bureaus and third parties. You also consent to SBG using your data for research purposes, special offers, and marketing purposes.
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";
import { v4 as uuidv4 } from "uuid";

export default {

  props:['customer'],

  components: { MsisdnInput },

  data: function () {
    return {
      valid: false,
    };
  },

  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "80%";
        case "lg":
          return "80%";
        case "xl":
          return "80%";
      }
    },
  },

  methods: {
    submit() {
      this.customer.send_takeon_otp = true;
      this.$store.dispatch('upsertCustomer', this.customer);
      this.$router.push({name: "NuCafeUgRegisterContact", query: { uuid: this.customer.uuid }})
    },
  },

  mounted() {
    this.customer.uuid = this.customer.uuid || uuidv4();
    this.customer.msisdn = this.customer.msisdn || undefined;
    this.customer.isAFarmer = true;
  },

};
</script>

<style>
.height100 {
  height: 100vh;
}
</style>