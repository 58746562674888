<template>

  <v-form v-model="formValid">
    <FormContainer color="blue lighten-5">
      <v-row>
        <v-col cols="12" md="6" v-if="fieldAgents && fieldAgents.length>0">
          <FieldAgentInput v-model="customer.fieldAgent" />
        </v-col>
        <v-col cols="12" md="6" v-if="depots && depots.length>0">
          <DepotInput v-model="customer.depot" />
        </v-col>
      </v-row>
    </FormContainer>

    <FormContainer color="orange lighten-5">
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-select :items="crops" label="Primary Crop" class="text-left" v-model="customer.primaryCrop"></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <LandSizeInput label="Land size" v-model="customer.landSize" />
        </v-col>s
      </v-row>
    </FormContainer>

    <Field v-for="field in customer.fields" :field="field">
    </Field>

    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-btn color="green lighten-3" @click="newField" style="width: 100%;" >Add A Field</v-btn>
        </v-col>
      </v-row>
    </v-container>


  </v-form>

</template>

<script>
  import fields from '@/mixins/fields';

  import FormContainer from '@/components/FormContainer'
  import LandSizeInput from '@/components/LandSizeInput';
  import FieldAgentInput from '@/components/FieldAgentInput';
  import DepotInput from '@/components/DepotInput';
  import Field from '@/views/fields/components/Edit'

  export default {
    components: { FormContainer, LandSizeInput, FieldAgentInput, DepotInput, Field },
    props: ['customer'],
    data: () => ({
      formValid: true,
    }),
    computed: {
      crops() { return this.$store.getters.cropNames; },
      fieldAgents() { return this.$store.getters.settings.fieldAgents; },
      depots() { return this.$store.getters.settings.depots; },
    },
    watch: {
      formValid: function(to) { this.$emit('valid', to) },
    },
    mixins: [fields],
  }
  
</script>