<template>
  <PageHeader :title="$t('title')"  v-if="customer" back @back="toCustomer" print @print="print" >

    <v-container fluid class="pa-0 mt-2" id="printTarget" >

      <v-row class="mb-2">

        <v-col cols="12" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              {{ purchase.description }}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <NumberFormatter :value="purchase.quantity" unit="units" />
            </v-card-title>
            <v-card-subtitle>
              {{ $t('quantity') }}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <Currency :value="purchase.value" />
            </v-card-title>
            <v-card-subtitle>
              {{ $t('amount') }}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-card class="text-left">
            <v-card-title class="text-h2 mb-1">
              <Time :time="purchase.createdAt" format="caption" />
            </v-card-title>
            <v-card-subtitle>
              {{ $t('createdAt') }}
            </v-card-subtitle>
          </v-card>
        </v-col>

      </v-row>

    </v-container>


  </PageHeader>
</template>

<script>
  import PageHeader from '@/components/PageHeader';
  import Time from '@/components/Time';
  import Currency from '@/components/Currency';
  import NumberFormatter from '@/components/NumberFormatter';
  import AppMenuItem from '@/components/AppMenuItem';
  
  export default {

    components: { PageHeader, Time, Currency, NumberFormatter, AppMenuItem },

    data: () => ({
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      purchaseUuid() { return this.$route.query.purchaseUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      purchase() { return this.$store.getters.purchase(this.purchaseUuid); },
    },

    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      async print() { await this.$htmlToPaper('printTarget'); },
    },


  }
</script>


<i18n>
{
  "en": {
    "title": "Purchase",
    "amount": "Amount",
    "createdAt": "Purchased"
  },
  "es": {
    "title": "Compra",
    "amount": "Monto",
    "createdAt": "Comprado"
  },
  "sw": {
    "title": "Ununuzi",
    "amount": "Thamani",
    "createdAt": "Imenunuliwa"
  }
}
</i18n>
