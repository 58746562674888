<template>
  <v-select
    :label="(label || $t('depot'))"
    :items="depots"
    item-value="id"
    item-text="name"
    v-model="depotId"
    @change="handleChange"
    :rules="[validate]"
  ></v-select>
</template>

<script>
  export default {
    props: {
      value: Object,
      required: { type: Boolean, default: false },
      label: String,
    },
    
    data: () => ({
      depotId: null,
    }),
    computed: {
      depots() {
        const r =  this.$store.getters.settings.depots;
        r.push("Co-op name not found");
        return r
      },
      req() { return true; },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.depotId = to.id;
          } else {
            this.depotId = null;
          }
        }
      },
    },
    methods: {
      validate(v) {
        if (this.required) {
          return (!!v || this.$t('required'));
        } else {
          return true;
        }
      },
      handleChange() { this.$emit('input', this.depots.filter( e => e.id===this.depotId )[0] ); },
    },
  };
</script>


<i18n>
{
  "en": {
    "depot": "Depot"
  },
  "es": {
    "depot": "Estación de molienda"
  },
  "sw": {
    "depot": "Mwanaume"
  }
}
</i18n>
