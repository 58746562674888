export const fertilizersData = [
    {
        "pest": "ABOCOL * 12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "ABOCOL * TRIPLE CATORCE GRADO 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "ABOCOL * 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ABOCOL * UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ABOCOL * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ABOCOL * TRIPLE QUINCE GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABOCOL * SULFATO DE POTASIO GRADO 0-0-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "SUPERFOSFATO TRIPLE GRADO 0-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SUPERFOSFATO TRIPLE"
    },
    {
        "pest": "ABOCOL * KCl GRANULADO GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "ABOCOL * FOSFATO DIAMONICO GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "ABOCOL * REMITAL-m * GRADO 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "ABOCOL * FOSFATO MONOAMONICO GRADO 10-50-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "GUAYACAN * 32-15-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "32-15-6"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 31-20-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-20-0"
    },
    {
        "pest": "ABOCOL * AMONIACO ANHIDRO 99,5 % GRADO 82-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "82-0-0"
    },
    {
        "pest": "GUAYACAN * 46-0-0 UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ABOCOL * 80-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ABONO QUIMICO GRANULADO GRADO 27-10-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONO QUIMICO GRANULADO GRADO 17-6-18-2 CAFETERO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "ABONO QUIMICO GRANULADO 12-26-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * NITRAX  GRADO 22-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "22-0-0"
    },
    {
        "pest": "ABONO QUIMICO GRANULADO GRADO 17-17-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONO QUIMICO GRANULADO GRADO 20-4-20-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONO QUIMICO GRADO 17-1-20-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 0-0-60 CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "ABOCOL * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "ABOCOL * EL LECHERO * GRADO 25-10-5-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * NITRAX-K GRADO 21-0-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "GUAYACAN * 18-46-0 FOSFATO DIAMONICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "ABOCOL * KUMBA25K * GRADO 15-0-25-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-0-25"
    },
    {
        "pest": "ABOCOL * NIPOTEK-6MGO * GRADO 18-5-15-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * ABOTEK *  GRADO 14-4-23-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-4-23"
    },
    {
        "pest": "ABOCOL * MINOX * GRADO 10-5-5-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * NITRAX-M GRADO 22-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ABOCOL * 25-14-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "ABONO COMPUESTO GRADO 30-6-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "ABOCOL * 14-14-14-2  \"FORMULA TABACALERA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "GUAYACAN * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "GUAYACAN * 18-18-18-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "GUAYACAN * 13-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * MAP (FOSFATO MONOAMONICO) GRADO 12-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "ABOCOL * RAFOS * GRADO 12-24-12-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "CHAMPION * 15-0-14 (NITRATO SODICO-POTASICO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 10-24-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-24-24"
    },
    {
        "pest": "ABOCOL * NITROFER * POTASIO  13-0-44",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "ABOCOL * NITROFER * POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "GUAYACAN * 21-3-30 SEGUNDA ABONADA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 13-26-8-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 17-6-18-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-6"
    },
    {
        "pest": "GUAYACAN * 10-20-20-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "GUAYACAN * 15-15-15-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "GUAYACAN * 18-18-18 -1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "GUAYACAN * 10-30-10-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "GUAYACAN * 32-8-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 11-5-27-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "11-5-27"
    },
    {
        "pest": "GUAYACAN * 15-4-24-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 15-15-15-4 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "GUAYACAN * 18-9-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 25-10-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-10-18"
    },
    {
        "pest": "ABOCOL * NITRASUL GRADO 36-0-0-17(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "GUAYACAN * 12-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-34-12"
    },
    {
        "pest": "GUAYACAN * 25-15-5-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-5"
    },
    {
        "pest": "ABOCOL * MAP FOSFATO MONOAM¾NICO GRADO 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "GUAYACAN * 12-24-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "GUAYACAN * 15-15-15 NUTRI-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABOCOL * CONPLEX * GRADO 27-6-3-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "27-6-3-2"
    },
    {
        "pest": "ABOCOL * PLAN-K * GRADO 14-3-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-3-17"
    },
    {
        "pest": "ABOCOL * AZUTEK * 14-0-24-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "GUAYACAN * 15-15-15-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 17-6-18-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-6"
    },
    {
        "pest": "ABOCOL * FERTI - INDIA * INICIO 23-14-3-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 15-4-23-1 NITRAGRAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-4-23"
    },
    {
        "pest": "GUAYACAN * 15-15-15-3 NITRAGRAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABOCOL - MEZCLAS GUAYACAN * 10-31-12-3 PRIMERA ABO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 17-6-18-3 NITRAGRAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "GUAYACAN * 10-20-20-3 NITRAGRAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 15-25-12-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 31-8-8",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-8-8"
    },
    {
        "pest": "ABOCOL * NITRAX  21 GRADO 21-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "ABOCOL SULKSOL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "ABOCOL * KCl  ESTANDAR (CLORURO DE POTASIO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "ABOCOL * FOSKFER *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "GUAYACAN * 10-40-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-40-10"
    },
    {
        "pest": "GUAYACAN * 10-20-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN 25-15-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-5"
    },
    {
        "pest": "GUAYACAN * PASTOS 31-8-8-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-8-8"
    },
    {
        "pest": "GUAYACAN * ETAPA 3 24-0-17-5-6(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * NITRAX-S GRADO 22-0-0-3 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "22-0-0"
    },
    {
        "pest": "GUAYACAN * 15-15-15 + ZEOLITA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "GUAYACAN * FOLLAJE 30-3-7-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-3-7"
    },
    {
        "pest": "MEZCLAS GUAYACAN * 16-16-16 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "ABOCOL * PLANCTON GRADO 15-7-16-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * DAP - FOSFATO DIAMONICO GRADO 21-53-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * EL CAFETAL  22-4-21-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCOL * MEZCLAS GUAYACAN * 15-0-40 TERCERA ABONAD",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "ABOCOL * GUAYACAN * 23-0-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-30"
    },
    {
        "pest": "GUAYACAN * 15-30-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-30-15"
    },
    {
        "pest": "GUAYACAN * REABONE 10-20-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "GUAYACAN * 10-30-10-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "GUAYACAN * ARRANQUE 10-40-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-40-10"
    },
    {
        "pest": "GUAYACAN * 11-3-36-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "11-3-36"
    },
    {
        "pest": "GUAYACAN * 12-3-26-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 11-3-30-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 12-21-21-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-21-21"
    },
    {
        "pest": "GUAYACAN * 13-3-30-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 13-4-23-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-4-23"
    },
    {
        "pest": "GUAYACAN * 13-26-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-10"
    },
    {
        "pest": "GUAYACAN * 15-15-15-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "GUAYACAN * RICE MIX 2 GRADO 22-20-6-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * RICE MIX 3  20-9-23-1 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * COTTON MIX 1 GRADO 5-24-21-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * COTTON MIX 2 GRADO 23-0-30-1(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "GUAYACAN * RICE MIX 1 GRADO 8-38-6-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 15-3-28-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN * 15-7-18-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-7-18"
    },
    {
        "pest": "ABOCOL* NITRAX -S 28-4-0-6 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "28-4-0-6"
    },
    {
        "pest": "GUAYACAN * 25-15-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "GUAYACAN* 21-3-17-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "21-3-17"
    },
    {
        "pest": "ABOCOL*  JARDINES TRIPLE QUINCE 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "GUAYACAN* KAFETAL 19-4-19-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-4-19"
    },
    {
        "pest": "ACIDO FOSFORICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "85%"
    },
    {
        "pest": "GUAYACAN*  PA'APORQUE 13-14-25-1 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-14-25"
    },
    {
        "pest": "GUAYACAN * PA' SIEMBRA 12-25-16-1 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-25-16"
    },
    {
        "pest": "SULFATO DE MAGANESIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "ABOCOL* KORN-KALI + B  0-0-40-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12% S - 16 % MgO"
    },
    {
        "pest": "ABOCOL  *  PATENTKALI   0-0-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "UREA PRILLED 46- 0-0-",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CoteP* 10-48-0 (4M)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "CoteK 12-0-44 (12M)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "NITRATRO DE CALCIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-0-0-26"
    },
    {
        "pest": "GUAYACAN VERDE INTENSO 44-0-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "GUAYACAN CAFETERO 22-4-21-2-3 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN NUTRIVERDOR 20-3-23",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GUAYACAN LLENADOR 5-0-54",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "NITRASUL 26-0-0-15 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "ABOCOL PIN MASTER BANANO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "ABOTAIN GRANULADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ABOTAIN PRILLED 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "GRADO TABACALERO 17-9-18-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GRADO PALMERO 13-5-27-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TRIPLE ACCION 16 (16-16-16)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "ABOCOL MASTERMIX UCALCIO 28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ABOCOL MASTERMIX UCALCIO 31",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "EMBAJADOR 20-4-18-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONAGRO * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABONAGRO * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ABONAGRO * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "ABONAGRO GRADO 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "ABONAGRO GRADO 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "ABONAGRO GRADO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ABONAGRO * UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ABONAGRO * PASTOS Y PRADERAS GRADO 25-5-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-5-10"
    },
    {
        "pest": "ABONAGRO GRADO 10-20-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "ABONO QUIMICO  \"NITRAFOS\" 10-20-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONEMOS C.O. GRADO 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "ABONEMOS C.O. GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABONEMOS C.O. GRADO 25-15-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "ABONEMOS C.O. GRADO 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "AGROLLENADO GRADO 8-15-30-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PLATANOL GRADO 16-5-27-7 CAO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTIABONAR 19-18-18-0-5 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROPAPERO GRADO 13-26-6-4(MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "AGROFERTY GRADO 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "AGROABONAR GRADO 15-15-0-10(CaO)-5(MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "ALGI-P",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ORMO-ZYM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-0-0"
    },
    {
        "pest": "RAIZEEL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KAR-FOL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ACTMIN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PA CARGUE PRODUCCION 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "10-30-10 SIEMBRA YA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "MANAGRO * 3-5-3 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CLORURO DE POTASIO KCL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "PLANTOSAN GRADO 20-10-15-5 + MICROELEMENTOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TERRADRIN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ALG - FE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "ALG - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "BAICLEAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ADN BAC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "ADN  MITE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "CRESCAL TOP NPK",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CRESCAL TOP K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CRESCAL TOP N",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CRESCAL TOP P",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ADN SOIL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ADN MITE 1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "AGRISUR 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "AGRISUR 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "AGRISUR 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "AGRICOL * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "AGRICOL * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "AGROANDINOS N - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "AGROANDINOS - SURCO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTIFOL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "STAVIP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "FERTI UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FERTI KCL 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FERTI PLATANO GRADO 15-4-23",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-4-23"
    },
    {
        "pest": "FERTI DAP (FOSFATO DIAMONICO) GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "AGROFERCOL GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "AGROFERCOL GRADO 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "AGROFERCOL * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "AGROFERCOL * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "AGROFERCOL * NITRATO DE POTASIO GRADO 13-0-45",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "AGROPRADERAS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "DAP AGROFERCOL 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "AGROFERCOL POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-3-43"
    },
    {
        "pest": "HIDRAFOS \"AGROFERT",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "NITRO-AGROFERT",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "SULFATO DE POTASIO - AGROFERT 0-0-50-16 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "NITRATO DE POTASIO - AGROFERT 13-0-46",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "HIDROSTAR - AGROFERT",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROFERT - CUAJE YAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "AGROFERT - PRODUC - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROFERT-FERRIGAR INICIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "INCA 30 N",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "LIGNOFOS-K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "LIGNO-K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INCAFOS K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ACTISOL INICIAL  14-35-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ACTISOL * FORMULA 1 GRADO 9-12-36-4 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NIKKO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ACIDO FOSFORICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO MONOPOTASICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "MAP (12-61-0)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "ACIDO FOSFÓRICO 85%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "ECIFONPA * FOSFATO DE POTASIO GRADO 0 - 30 - 60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DE POTASIO"
    },
    {
        "pest": "NUTRIDAP * GRADO 21-53-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "NUTRIMAP * GRADO 12-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "ECIFONPA * NUTRIFER 3-10-5 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRICADA GRADO 0-10-10-7(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ECIFONPA * ARROZ GRADO 12-20-8-1 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "POTASIL GRADO 0-0-25 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-25"
    },
    {
        "pest": "NITRATO DE POTASIO ECIFONPA * 12-0-44",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "NICROZIL GRADO 36-0-0-10 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "NITRATO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "FOSFATO DIAMONICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FOSFATO DIPOTASICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DE POTASIO"
    },
    {
        "pest": "GRANUM * COMPLETO GRADO 8-5-3 + SEC. Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PLANTUM MANTENIMIENTO GRADO 3-0-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CHELAKEL-SOIL GRADO 7-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "PLATANERO GRADO 10-4-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUCLEO PASTOS GRADO 3-6-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TROPI PASTOS  GRADO 31-5-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TROPI CAFE  22-3-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCONDOR * 13-26-6-3 (CaO) + Menores con Lombricompost",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "ABOCONDOR * 10-20-20-1 (CaO) + Menores con Lombricompost",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ABOCONDOR * 15-15-15-3 (CaO) + Menores con Lombricompost",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABOCONDOR * 10-30-10-2 (CaO) + Menores con Lombricompost",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ABOCONDOR * 5-15-5-3 (CaO) + Menores con Lombricompuestos",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABOCONDOR * 17-6-18-2 + Menores con Lombricompost",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "CENAGRO UREA PRILLED 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CENAGRO DAP (FOSFATO DIAMONICO) 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CENAGRO CLORURO DE POTASIO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "CENAGRO TRIPLE 16 (16-16-16)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "CENAGRO 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ACIDO FOSFORICO 85%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "FOSFATO BIAMONICO - GRADO 21-53-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TECNOVERDE RADICULAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NITRATO DE AMONIO GRADO 20-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "NUTRICOLJAP * GRADO 3-5-5-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "3-5-5-6"
    },
    {
        "pest": "HAIFA MAP FOSFATO MONOAMONICO 12-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NITROFOSKA  PERFEKT * (15-5-20-2)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NITROFOSKA * AZUL (12-12-17-2)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HAKAPHOS * VIOLETA 13-40-13",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HAKAPHOS * AZUL  20-5-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "20-5-5"
    },
    {
        "pest": "ENTEC * 14 GRADO 14-7-17-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BASACOTE * 11-9-19  9 M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BASACOTE 12 M 15-8-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BASACOTE* 16-8-12  6M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BLAUKORN AZUL 12-8-16-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "RADIQUEL MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ÑAÑEZ PRECOSECHA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NITRON - COMBO SL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FRUCTIFOL RAICES Y FRUTOS GRADO 12-30-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFATO DE AMONIO (DAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CLORURO DE POTASIO GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP) GRADO 11-48-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP) GRADO 12-62-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NITRATO DE POTASIO GRADO 13-0-46",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "FOSFATO DIAMONICO GRADO 21-53-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "SULFATO DE POTASIO GRADO 0-0-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "CLORURO DE POTASIO SOLUBLE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "KEMIRA \"MAGNUM P-44\" (UREA FOSFATO) GRADO 18-44-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NITRATO DE POTASIO + MAGNESIO \"GRADO HORTICOLA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "AGROFEED * AGRODAP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "AGROFEED * AGRONIK GRADO 3-0-11",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "AGROFEED * AGROFOSK-M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "AGROFEED * AGROFOSK",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "AGROFEED * FOSFEED * FOSFITO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "AGROFEED * FOSFEED *  FOSFITO (ACIDO FOSFOROSO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "AGROFEED * AGROFEED - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "AGROFEED * MULTI - NPK 13-3-43",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-3-43"
    },
    {
        "pest": "AGROFEED 12-12-17-2 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROFEED 14-0-21-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "AGROFEED 15-5-20-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROFEED 13-13-21-2 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "8-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "D.A.P. 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "15-6-25-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA  GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CLORURO DE POTASIO GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "ACEPALMA * FOSFATO DIAMONICO (DAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "ACEPALMA * CLORURO DE POTASIO GR GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "KORN KALI 0-0-40-6 (MgO) ACEPALMA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "SULFATO DE POTASIO 0-0-50-16 (S) ACEPALMA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "KORN KALI + B 0-0-40-6 (MGO)- 0,8 B",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "TATENT KALI 0-30-10 (MgO) ACEPALMA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "MAP ACEPALMA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "PATENT KALI 0-0-30-10 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "SULFATO DE AMONIO SAM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "UREA 46 % N",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "BANAFERTIL 12-4-25 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BANAFERTIL 13-3-27 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA BANASAN C.I. GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46% IBG",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "SOLUFOS 44 (UREA FOSFATO) GRADO 17-44-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-44-0"
    },
    {
        "pest": "SOLUFOS K15 GRADO 15-30-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-30-15"
    },
    {
        "pest": "SOLUFOS K30 GRADO 14-14-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ACIDO FOSFORICO GRADO 0-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "SOLUFOS P 30 GRANULADO GRADO 10-30-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "MERISTEM 11-40-11-2 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MERISTEM 8-4-42-2 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MERISTEM 20-5-20-2 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SULFONIT 32-3-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "FOSFATO MONOAMÓNICO - MAP 10-15-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "CLORURO DE POTASIO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "UREA GRANULADA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO DIAMÓNICO - DAP 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "SOLUFOS 49 14-49-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "PATENTKALI* GRAN 0-0-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "SUPERFOSFATO TRIPLE TSP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SUPERFOSFATO TRIPLE"
    },
    {
        "pest": "12-24-12-7(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "ROSIER 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "KORN-KALI 0-0-40-6 + 4(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "KORN-KALI 0-0-40-6 + 4(S) - 0,25(B)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "MAP FOSFATO MONOAMONICO 10-54-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "MAP FOSFATO MONOAMONICO 12-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "GEOCUR DESARROLLO 30-10-10-2 + TE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "DISAN LLENADO 11-6-25-4 + 3 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA PRILLED 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "SENTINEL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "UREA INTEROCEANICA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "DAP - FOSFATO DIAMONICO GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "14-14-14-9 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "TRIPLE QUINCE GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "C.I. JAGO ORDODEZ E.U. GRADO 12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "18-30-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "FOSFATO DIAMONICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "14-14-14-8 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "DAPITO GRADO 16-42-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO (KCl) GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "SUPERFOSFATO TRIPLE Q.C.A  0-43-0-22 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SUPERFOSFATO TRIPLE"
    },
    {
        "pest": "NITRATO DE POTASIO Q.C.A.  13-0-45",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "SERVIFERTIL NPK TRIPLE 15 GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP)  16-42-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "UREA FOSFATO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-44-0"
    },
    {
        "pest": "SERVIFERTIL 18 NPK GRADO 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "FOSFATO MONOAMONICO - MAP 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "UREA NITROGENO GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TRIPLE 16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "FERTILIZANTE 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "TRIPLE 15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "11-0-45",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "24-04-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "21-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "19-0-35",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO MONOPOTASICO GRADO 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "ACIDO FOSFORICO 85% GRADO 0-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "UREA 46 % GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOS-MA-CAL-S LLANERO RIO CLARO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "FERTINUCLEO PASTOS RIO CLARO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CALFERQUIM * FERQUIAZA 7-7-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CALFERQUIM * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "CALFERQUIM * 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "CALFERQUIM * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "CAMPOFERT CAÑA 1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "CAMPOFERT CAÑA 2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "TRIPLEHOJA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "REBROTE 10-50-8",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "WIFIX GEL FLOWER",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "WIPHOS GEL FLOWER",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "WIPHOS 0-40-27",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "AGROABONO 15-17-15-2.0 (MgO) PRIMER ESTADO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROABONO TERCER ESTADO 24-0-12-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "CIAMSA * KCl GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "CIAMSA * UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "DAP GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CIAMSA * FOSFATO MONOAMONICO (MAP) GRADO 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "CIAMSA * 15-15-15 + ESM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "CIAMSA * 18-18-18 + ES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "CIAMSA * ABONO CAFETERO GRADO 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "CIAMSA * ABONO CAFETERO GRADO 17-6-18-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-6"
    },
    {
        "pest": "CIAMSA * PLATANO GRADO 14-4-23-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-4-23"
    },
    {
        "pest": "CIAMSA * PASTOS GRADO 25-5-10-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-5-10"
    },
    {
        "pest": "CIAMSA * PAPA GRADO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "CIAMSA * PAPA GRADO 13-26-10-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-10"
    },
    {
        "pest": "CIAMSA * PAPA GRADO 10-30-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "CIAMSA 10-20-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "CIAMSA 3 ABONO PARA ARROZ 24-0-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "24-0-17"
    },
    {
        "pest": "CIAMSA 3 ABONO PARA PASTOS 30-20-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "CIAMSA 12-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-34-12"
    },
    {
        "pest": "CIAMSA 3 ABONO PARA PASTOS 25-15-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "NUTRI KIMIA CIAMSA 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "NUTRI KIMIA CIAMSA 30-0-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "NUTRI KIMIA CIAMSA 27-6-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "34-5-5 - ES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "34-5-4"
    },
    {
        "pest": "8-24-24 - 2(S) CIAMSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SULPHOKING CIAMSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "17-6-14-13(S) CIAMSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "15-15-15-9(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "22-3-20 ES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "12-24-12 CIAMSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "14-11-20 CIAMSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NITROCOTE 9M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "PLANTIN * CLUB 1 GRADO 15-7-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PLANTIN * CLUB 3  GRADO 38-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FOLIA 3  GRADO 20-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PLANTIN * CLUB 7 GRADO 16-8-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TRIABON * GRADO 16-8-12-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FLORANID * PERMANENT GRADO 16-7-15-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOCIEDAD UREA N-46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "MICROGRANT *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FUENTE Ca"
    },
    {
        "pest": "AGRIPLANT-19  GRADO 19-19-19",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-19-19"
    },
    {
        "pest": "AGRIPLANT * PASTOS GRADO 28-6-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGRIPLANT* CAFETERO GRADO 21-5-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGRIPLANT * PAPA - REABONE GRADO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "AGRIPLANT * PAPA - SIEMBRA GRADO 10-26-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PRODUCCION CAFETERO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCOTE 0-0-43.5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "AGROCOTE 0-0-51",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "AGROCOTE 37-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "OSMOCOTE CLASSIC 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "AGROCOTE 38-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "AGROCOTE 39-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "OSMOCOTE 19-6-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "OSMOCOTE MANIPRILL 19-6-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGRIFORM 20-10-5 + ME",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCOTE 9-47-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "PEKACID 0-60-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "FERTI-K 0-0-61",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NOVAMAP 12-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "PEAK MKP 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "COLANDINO * UREA 46 % GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FLORALIA UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "VICOR* TSF GRADO 4-7-0-5 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "PRADEMINS * GRAMA & CESPED 12-4-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VICOR * 2 GRADO 3-10-3-6 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "3-10-3-6"
    },
    {
        "pest": "FERTILIZANTE MEZCLADO GRADO 14-14-14-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "CRECIMAG *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FLORAMAG *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-25-0"
    },
    {
        "pest": "PRODUMAG *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-25"
    },
    {
        "pest": "AMOPHOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "AMOPHOSKO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTILIZANTE GRANULADO COLINAGRO GRADO 10-20-6-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTICAÐA GRADO 0-10-20  + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-10-20"
    },
    {
        "pest": "GANADERO GRADO 20-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTILEN * 12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "FLORALIA FOLLAJE 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FLORALIA * 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "MACSIL GRADO 3-5-0-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "SPECIAL T SUSPENSIONS * 15-30-12-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SPECIAL T SUSPENSIONS * 19-19-19-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-19-19"
    },
    {
        "pest": "SPECIAL T SUSPENSIONS * 16-16-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GRANUPOTASSE * SULFATO DE POTASIO GRANULADO 0-0-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "SPECIAL T SUSPENSIONS * 20-10-20-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SPECIAL T SUSPENSIONS * 16-8-24-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SPECIAL T CRYSTALLINE * 10-5-35-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SPECIAL T SUSPENSIONS * 25-10-10-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ROSASOL P GRADO 15-30-15 + EM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-30-15"
    },
    {
        "pest": "ROSASOL P 15-45-10 + EM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ROSASOL V GRADO 15-5-30 + EM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HERMAREZ UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSPHO ZEO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NITRO ZEO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "18-18-18-2(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "UREA COLANTA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) COLANTA GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FERTIPAPA COLANTA GRADO 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "FERTI-15 COLANTA  GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "FERTIPASTOS COLANTA GRADO 25-5-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-5-5"
    },
    {
        "pest": "FOSFATO MONOAMONICO GRADO 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "CLORURO DE POTASIO COLANTA GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FERTICAFETO PRODUCCIËN COLANTA  GRADO 17-7-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-7-17"
    },
    {
        "pest": "BIOPASTOS GRADO 25-5-10-3 CON VINAZAS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-5-10"
    },
    {
        "pest": "FERTINITRO PRADERAS COLANTA GRADO 24-5-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "20-5-5"
    },
    {
        "pest": "10-20-20  COLANTA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "31-20-0  COLANTA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-20-0"
    },
    {
        "pest": "UREA RECUBIERTA COLANTA GRADO 36-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FERTI-18 COLANTA GRADO 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "FOSFORO COLANTA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-30-0"
    },
    {
        "pest": "34-5-4  COLANTA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "34-5-4"
    },
    {
        "pest": "NITRO COMPLEX * COLANTA GRADO 24-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FERTI NITRO-P * COLANTA GRADO 23-13-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-13-0"
    },
    {
        "pest": "FERTIGRASS COLANTA GRADO 26-4-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-4-4"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "INTA 15 + COMPOST",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "INTA-CAF",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INTA-COSECHA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PLANT 3/4 ABONADA ARROZ  GRADO 24-0-17-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "24-0-17"
    },
    {
        "pest": "KALI_MOL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "TRIPLE 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "COOUNIAGRO * UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "KCL 0-0-60 COOUNIAGRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "MAP 11-52-0 COOUNIAGRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "DAP 18-46-0 COOUNIAGRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "TRIPLE 16 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "DAPITO GRADO 16-42-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CAFENORTE * UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "KCl 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "DAP 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CAFICENTRO * UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "16-16-16 CAFICENTRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "KCl 0-0-60 CAFICENTRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "DAP 18-46-0 CAFICENTRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "COSMO-R 14-8-19 CON EDTA-MICRONUTRIENTES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA \"CRECEAGRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CLORURO DE POTASIO \"CRECEAGRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO DIAMONICO \"CRECEAGRO\" (DAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "TRIPLE 18  \"CRECEAGRO\" GRADO 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "ACIDO FOSFORICO 55 % \"CRECEAGRO\" GRADO 0-41-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "17-6-18-3 \"CRECEAGRO\" GRADO 17-6-18-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "ACIDO FOSFORICO 85 % \"CRECEAGRO\" GRADO 0-62-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "UREA PERLADA \"CRECEAGRO\" GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "10-30-10 \"CRECEAGRO\" GRADO 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "CLORURO DE POTASIO ESTANDAR GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "CRECEAGRO 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "CRECEAGRO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "TRIPLE 15 CRECEAGRO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "PROTEK PK",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "UREA PRILLED  46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NUTRO-GEN 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "EFI-100-T",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INICIAGRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "REYFOL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SPLENDOR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "DISTRIABONOS * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "DISTRIABONOS * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "DISTRIABONOS * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "DISTRIABONOS * 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "DISTRIABONOS * 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "DISTRIABONOS * 25-15-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "DISTRIABONOS * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "DINAMICO * 15-15-15 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "DISTRIABONOS * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "TERRASORB 4 * RADICULAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "KCL (CLORURO DE POTASIO) GRANULAR 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "DAP (FOSFATO DIAMONICO)GRANULAR 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "K-MAG 0-0-22-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "UREA GRANULAR 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "OSMOCOTE 15-9-12+M-E",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "DIABONOS 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "DIABONOS 10-30-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "DIABONOS 25-15-5-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-5"
    },
    {
        "pest": "DIABONOS 21-3-17-6 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "21-3-17"
    },
    {
        "pest": "DIABONOS 11-5-27-7 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "11-5-27"
    },
    {
        "pest": "DIABONOS 15-15-15-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "DIABONOS 18-18-18-1 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "DIABONOS 17-6-18-6 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-6"
    },
    {
        "pest": "DIABONOS 31-8-8-2 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-8-8"
    },
    {
        "pest": "DIABONOS 12-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-34-12"
    },
    {
        "pest": "TRIPLEQUINCE 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "MAP FOSFATO MONOAMONICO 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "DIABONOS 10-20-20-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "KCL CLORURO DE POTASIO ESTANDAR 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "MAP-FOSFATO MONOAMONICO 10-50-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "DIABONOS 17-6-14-13",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "10-20-20-5(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "DIABONOS 23-0-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-30"
    },
    {
        "pest": "DIABONOS 12-24-12-9(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "TRIPLEQUINCE - 9(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "KCL CLORURO DE POTASIO SOLUBLE0-0-62",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "TRIPLEDIECISEIS 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "UREA GRANULADA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO DIAMÓNICO - DAP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "Phos - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ACTIVADOR 8-24-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ECOFERTIL *  31-20-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-20-0"
    },
    {
        "pest": "ECOFERTIL * MAP (FOSFATO MONOAMONICO) GRADO 11-52",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "ECOFERTIL * 12-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-34-12"
    },
    {
        "pest": "ECOFERTIL * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "ECOFERTIL * CAFETERO GRADO 17-6-18-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-6"
    },
    {
        "pest": "ECOFERTIL * 15-15-15-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ECOFERTIL * 18-9-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ECOFERTIL*  PRADERAS  35-10-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "35-10-5"
    },
    {
        "pest": "ECOFERTIL* KUNDO 25-15-5 -3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-5"
    },
    {
        "pest": "ECOFERTIL* FEDEPAPA 12-25-16-2 SIEMBRA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-25-16"
    },
    {
        "pest": "ECOFERTIL* FEDEPAPA 30-14-3-2  PASTOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-14-3"
    },
    {
        "pest": "ECOFERTIL * EL PAPERO 13-26-6-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "ECOFERTIL * COLACTEOS 27-10-6-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "27-10-6"
    },
    {
        "pest": "ECOFERTIL * MENORES 15-15-15-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ECOFERTIL * KIKUYO * GRADO 30-0-7-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "ECOFERTIL * URE-AZU 56",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ECOFERTIL * MAICERO 25-10-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-10-18"
    },
    {
        "pest": "ECOFERTIL * KCl GRANULAR GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "ECOFERTIL * DAP GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "ECOFERTIL * UREA AGRICOLA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ECOFERTIL * 18-18-18-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "ECOFERTIL * TRIPLE 16 GRADO 16-16-16-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "ECOFERTIL * TRIPLE 16 MENORES GRADO 16-16-16-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "ECOFERTIL * ANDINO GRADO 12-28-12-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-28-12"
    },
    {
        "pest": "ECOFERTIL * MUS-K GRADO 18-5-27-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-5-27"
    },
    {
        "pest": "POTREROS 31-8-8-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-8-8"
    },
    {
        "pest": "ECOFERTIL * 10-20-20-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ECOFERTIL * 10-30-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ECOFERTIL * PLATANERO GRADO 11-5-27-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "11-5-27"
    },
    {
        "pest": "ECOFERTIL * ARROZ FASE 3 GRADO 24-0-17-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "24-0-17"
    },
    {
        "pest": "ECOFERTIL * RETAPE 10-24-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-24-24"
    },
    {
        "pest": "ECOFERTIL * 10-40-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-40-10"
    },
    {
        "pest": "ECOFERTIL * 10-20-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "FEDEPAPA APORQUE GRADO 13-14-25-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-14-25"
    },
    {
        "pest": "ECOFERTIL * GRADO 31-0-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-0-20"
    },
    {
        "pest": "KAFE GRADO 21-3-17-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "21-3-17"
    },
    {
        "pest": "ECOFERTIL * 35-0-15 NUTRIFINAL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "35-0-15"
    },
    {
        "pest": "ECOFERTIL * KORN KALI GRADO 0-0-40-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "NUTRI AGUACATE 15-10-20-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ECOFERTIL* LEVANTE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "ECOFERTIL* NITRO FERTIL 30-0-0-11 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-0-0"
    },
    {
        "pest": "FINALIZADOR ARROZ FASE 4 GRADO 22-4-16-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KOKUY MENORES 30-8-0-11",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "ECOFERTIL* TRIPLE 18 S ZN GRADO 18-18-18-5 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "ECOFERTIL* ZAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-24-12"
    },
    {
        "pest": "ECOFERTIL* ME-SZ GRADO 12-40-0-10 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-40-0"
    },
    {
        "pest": "ECOFERTIL* SOP SULFATO DE POTASIO GRANULAR  0-0-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "ALTO FOS - ZINC 14-25-14-6 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KAFE CALDAS 25-3-19-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VERDESOL 42-0-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "ECOFERTIL 26-0-26",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "NUTRIFORRAJE 30-10-0-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "AGROCAFE 24-3-20-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCAFE 23-2-21-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCAFE 30-3-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCAFE 17-6-18-6 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-6"
    },
    {
        "pest": "AGROCAFE 26-3-16-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCAFE 25-3-19-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PRODU - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ECOFERTIL 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "ARRIERO 23-0-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-30"
    },
    {
        "pest": "UREA GRANULAR 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NUEVO DIA 12-32-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOL DORADO 27-4-0-6(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "SOLAR 14-2-20-4(MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCAFE * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "EFITEC * UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "DESARROLLO EFITEC 37-7-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PRODUCCION EFITEC 5-16-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFITEK 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "FOSFITEK PALMA 0-56-0-23 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "NUTRITEC FLOW",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFITEC ZINC 0-41-0-25 (ZN)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "FOSFITEK POTASIO GOLD",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "DAPITO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FOSFATO MONOPOTASICO GRADO 0-51-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "FOSFATO MONOAMONICO GRADO 12-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO DIAMONICO GRADO 21-53-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FERTICAMPOS 17-6-18-2 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "UREA - Eurofert 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO (KCl) GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NUTRAFED * AZUL GRADO 12-12-17-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRAFED * PERFEKT GRADO 15-5-20-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FEELAGRO - NITRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FEELAGRO DESAROLLO  41-0-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "FEELAGRO - BALANCE   30-0-11",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "FERTICAMPO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "KCL FERTICAMPO (0-0-60)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FERTICAMPO 12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "FERTICAMPO 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "NITRON DOBLE N 26-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NITRON DOBLE- N * LIQUIDO GRADO 23-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "22-0-0"
    },
    {
        "pest": "ABONO MEZCLADO GRADO 19-5-19",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONO MEZCLADO GRADO 25-15-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "ABONO MEZCLADO GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP) GRADO 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO (KCl) GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "SOLUCION U.N.A (UREA-NITRATO DE AMONIO) GRADO 32-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "ABONO MEZCLADO GRADO 17-17-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOLUCION U.N.A  30-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-0-0"
    },
    {
        "pest": "UREBOR *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "MACNITRON GRADO 26-0-0-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "FERTILIKA * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "FERTILIKA * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "FERTILIKA * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "FERTILIKA * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NITROLITA GRADO 30-0-0-6 (S) UREA RECUBIERTA CON AZUFRE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-0-0"
    },
    {
        "pest": "NPK 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "FOSFATO MONOAMONICO GRADO 12-60-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NITRATO DE POTASIO GRADO 12-0-44",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "POTASIO LIQUIDO K-18 GRADO (0-0-17) P/V",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "NITRATO DE AMONIO GRADO 26-0-0 P/V",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-5-5"
    },
    {
        "pest": "FERTILISA PRODUCCION GRADO 8-4-12 (P/V)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NITROGENO LIQUIDO N-32 GRADO 32-0-0 (P/V)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FOSFATO DIAMONICO LIQUIDO GRADO 8-24-0 (P/V)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FERTILISA DESARROLLO GRADO 14-4-10 (P/V)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CRECIFERT 36-7-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA PRILLED 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FERTIPAEZ * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "GLOBAL K 500",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "FERTI 7-7-7-13 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTIMAXI GRADO 15-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTIMOLIBDENO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONO QUIMICO GRADO 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "ABONO QUIMICO GRADO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ABONO QUIMICO GRADO 9-23-29",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ABONO QUIMICO GRADO 19-4-23-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTILIZANTE COMPUESTO 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "ABONO QUIMICO GRADO 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ABONO QUIMICO GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TRIPLE  CATORCE GRADO 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "FOLKABO - Pentaborato de Potasio Tetrahidratado",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "FOSFACOL * MAFOS POLVO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-25-0"
    },
    {
        "pest": "FOSFACOL * MAFOS GRANULADO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-25-0"
    },
    {
        "pest": "FOSFACOL * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "FOSFACOL * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "FOSFACOL * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "FOSFACOL * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "FOSFACOL * UREA NEGRITA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FOSFACOL * 14-14-14-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "FOSFACOL * 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "FOSFACOL * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "FOSFACOL * PIJAO 27-6-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFACOL * TERRAFOS 10-8-10-12 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFACOL * HORTIFRUTAL 11-4-18-12 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFACOL * PRIMORDIO 23-3-4-9 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFACOL * FORRAJERO 17-3-0-13 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "FOSFACOL * CARGUE 17-3-12-9 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-3-12"
    },
    {
        "pest": "FOSFACOL * RENDIFOS 13-7-6-13 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-22-6"
    },
    {
        "pest": "FOSFACOL * SEMBRADOR 14-8-12-9 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFACOL * TERRANO 15-6-12-10 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SILFOS - MG-Ss   0-10-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "TRIPLE 14 (14-14-14-10 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) (18-46-0)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "UREA (46-0-0)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TRIPLE 15 FRESCO Y NATURAL ( 15-15-15 )",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "CLORURO DE POTASIO FRESCO Y NATURAL (KCL)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "TRIPLE 18 FRESCO Y NATURAL (18-18-18)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "TRIPLE 16 FRESCO Y NATURAL (16-16-16)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "11-52-00 FRESCO Y NATURAL (11-52-00)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "HORTICULTOR FRESCO Y NATURAL (12-12-17-2)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "13-13-21 FRESCO Y NATURAL (13-13-21-3 (S))",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIK GRANULADO FRESCO Y NATURAL 13-0-46",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "FOSFATO DIAMONICO GRADO 21-23-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FOSFATO MONOAMONICO GRADO 12-27-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "KCL 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NITROAMMOPHOSKA 16-16-16-0-3 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "DAP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FOSAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NUTRIABONO GRADO 21-8-24 + EM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA 46% GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO DE AMONIO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "MulticoteAGRI 12-5-26+2 MgO + Microelementos",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MULTIFEED 20-20-20 + MICROELEMENTOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MUlticoteAGRI 19-10-13+2 MgO + Microelementos",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MULTICOTEAGRI 16-0-39",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "RAIZ 1000",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VERSUS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "PRODUKCION SAN AGUSTIN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "UREA M&E",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "PRADERA FERTILIZANTE GRADO 16-4-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CAFETAL PRODUCCION",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 % GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA UREALIDER GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "KELIK * CALCIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "SOLUPLANT PRODUCCION",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-10-40"
    },
    {
        "pest": "SOLUPLANT DESARROLLO GRADO 20-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOLUPLANT INICIO GRADO 10-40-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-40-10"
    },
    {
        "pest": "INAGRO 13-23-22",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INAGRO 13-20-25",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INAGRO 10-16-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INAGRO K-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "AGROMENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRY ALL SEMBRAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "MKP MONOFOSCAL FOSFATO MONOCALCIO 0-56-24 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "MKP FOSFATO MONOPOTASICO 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "FOSCAL * 25 GRADO 0-25-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-25-0"
    },
    {
        "pest": "SULFOCA *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "TIO-K * (SULFATO DE POTASIO) GRADO 0-0-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "FOSCAL * 38",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "TIO-K 54",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "URSUDOL *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "SILI-K *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "SOM * 10-20-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOM * 20-8-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOM * 13-26-6-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "URE-A ZINC (UREA RECUBIERTA)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "PRIMERA + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "Apo-K 20-0-25",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "SEGUNDA 20-6-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KCL 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "CRISTATEC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "INFLORVAL * HARD SOIL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "INFLORVAL * VEGETATIVO GRADO 5-3-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "INFLORVAL * VEGETATIVO GRADO 3-12-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BARRENO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "NOVAPLANT DEFENDER K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NOVAPLANT FOSFORO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NOVAPLANT COMPLETO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KELANOVA Cu",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "KELANOVA B",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FERTIPOL A + ZN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "FERTIPOL AA - ZN +",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "SOL PRODUCCIÓN 14-6-15-2 (MgO) - 7 (CaO) - 2 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOL SIEMBRA 11-20-4-2 (MgO) - 13 (CaO) -1 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SOL PASTO BABY 20-5-3-1(MgO)-11(CaO)-5(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TRICHODEX * TRICHO K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "Trichodex * PHYTOFOS K-B",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ANTI-D",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "PTC * 19-19-19",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-19-19"
    },
    {
        "pest": "PTC * UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "27-0-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "RIN D",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "UREA PERLADA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "14-14-14-8 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "FERTIUREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA AGRICOLA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FERTILIZANTE 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "FERTILIZANTE 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "FERTILIZANTES KCL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "LABUNIDOS - NPK",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO DIAMONICO DAP 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO KCL 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "PROGROSS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "LIGAPHOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "REABONE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP) GRADO 10-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO MONOPOTASICO GRADO 0-51-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "ACIDO FOSFORICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "CLORURO DE POTASIO ESTANDAR MANUCHAR COLOMBIA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP) MANUCHAR COLOMBIA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO DIAMONICO MANUCHAR COLOMBIA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO GRANULADO MANUCHAR COLOMBIA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO DIAMONICO MANUCHAR COLOMBIA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "DAPITO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "CAÑATECK",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TIOSAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-0"
    },
    {
        "pest": "NITROL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-0"
    },
    {
        "pest": "NITRATO DE POTASIO M.F. GRADO 13-0-44",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "NITROFOSFATO M.F. (FOSFATO MONOAMONICO) GRADO 12-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "FOSFATO DE POTASIO M.F. GRADO 0-40-53",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DE POTASIO"
    },
    {
        "pest": "MF * AFORIC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "MF MICRORRIEGO INICIO 15-25-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MF MICRORRIEGO PRODUCION 10-3-40",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOCAFOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "NUTRIALGODONERO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "FOS-CESAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "KCl   0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO DIAMÓNICO - DAP 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "KCL GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NUTRIMON * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIMON * 25-15-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "NUTRIMON * 20-20-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIMON * 13-26-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "NUTRIMON * 12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "NUTRIMON * SULFATO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "NUTRIMON * UREA G  46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NUTRIMON * UREA  46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NUTRIMON * 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "NUTRIMON * FOSFATO MONOAMONICO  10-50-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NUTRIMON * FOSFATO DIAMONICO (DAP)  18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "NUTRIMON * CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NUTRIMON * 14-14-14-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "NUTRIMON * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "NUTRIMON * FOSFATO DE AMONIO  11-53-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NUTRIMON * 8-30-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 15-5-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * SUPERFOSFATO TRIPLE 0-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SUPERFOSFATO TRIPLE"
    },
    {
        "pest": "NUTRIMON * NITRATO DE POTASIO 13-0-45",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "NUTRIMON * 22-12-6-2(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 25-15-0-3(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "NUTRIMON * 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "AGROCAFE * UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "AGROCAFE * KCl GRANULADO CLORURO DE POTASIO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "AGROCAFE * FOSFATO DIAMONICO (DAP) 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "NUTRIMON * NITRATO DE POTASIO  13-0-46",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "AGROCAFE * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIMON * 20-10-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 16-20-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIMON * 25-15-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "NUTRIMON * 15-15-15-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIMON * 13-26-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-10"
    },
    {
        "pest": "NUTRIMON * 17-17-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGROCAFE * 17-6-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "NUTRIMON * 12-3-20-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 15-5-20-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 13-26-6-2(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "NUTRIMON * 15-15-15-2(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIMON * 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NUTRIMON * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "NUTRIMON * 12-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-34-12"
    },
    {
        "pest": "NUTRIMON * 18-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 15-15-15-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIMON * 15-38-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 20-4-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 22-4-5-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * FOSFATO  DE AMONIO 16-42-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NUTRIMON * 14-14-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-14-14"
    },
    {
        "pest": "NUTRIMON * 15-4-23-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-4-23"
    },
    {
        "pest": "NUTRIMON * MONOFOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "NUTRIMON * NUTRIMAG * 22-4-0-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIMON * 18-18-18-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NUTRIMON * 12-24-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 24-14-14-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 27-11-11-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "27-11-11"
    },
    {
        "pest": "NUTRIMON * 19-19-14-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 25-15-5-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-5"
    },
    {
        "pest": "NUTRIMON * 12-12-17-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NUTRIMON * 13-26-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-10"
    },
    {
        "pest": "NUTRIMON * SUPERFOSFATO GRADO 0-42-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SUPERFOSFATO TRIPLE"
    },
    {
        "pest": "NUTRIMON * 13-6-23-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-6-23"
    },
    {
        "pest": "NUTRIMON * FOSFATO MONOAMONICO (MAP) 10-50-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "NUTRIMON * CLORURO DE POTASIO 0-0-60 G",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NUTRIMON * 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "NUTRIMON * SULPOMAG",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "NUTRIMON * MONOFOSCA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-30-0"
    },
    {
        "pest": "NUTRIMON * MAG.NI.SAM 22-4-0-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIMON * NITRA.SAM 28-4-0-6 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "28-4-0-6"
    },
    {
        "pest": "NUTRIMON * 13-11-24-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-11-24"
    },
    {
        "pest": "NUTRIMON * 16-5-18-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * U.SAM 45",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "NUTRIMON * GRADO 10-20-30-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "NUTRIMON * NUTRIOCHO GRADO 22-4-0-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIMON * PATENTKALI * GRADO 0-0-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "NUTRIMON * KORN-KALI * GRADO 0-0-40-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "NUTRIMON * MICROESSENTIALS * SZ GRADO 12-40-0-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-40-0"
    },
    {
        "pest": "NUTRIMON * ENTEC * PERFEKT 14-7-17-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * ENTEC * 13 GRADO 13-10-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * FERFOS * 30 GRADO 10-30-0-5 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIMON * SULFAMON * 26 GRADO 26-0-0-14 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "NUTRIMON * FERFOS * 44 GRADO 17-44-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-44-0"
    },
    {
        "pest": "NUTRIMON * KORN-KALI + B",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-40"
    },
    {
        "pest": "NUTRIMON ENTEC*24-8-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON * EXCELENTEC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ENTEC* ENERGIC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NUTRIMON 13-11-24-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-11-24"
    },
    {
        "pest": "NUTRIMON *12-24-12-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "NUTRIMON 17-9-18-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIMON FERTIRRIEGO SOLUNK.P CLEAN 13-3-43",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-3-43"
    },
    {
        "pest": "REKOX* FABORIC 1000",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "REKOX* SUMA 1000",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "REKOX ZINCRONY 1000",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "NUTRI 15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "TRIPLE 18-18-18-2 (CaO) - 2 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "UREA GRANULADA LUCROSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA PRILLER LUCROSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "PASTOS 5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "PHOSXPRES 0-50-0-28 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "PHOS PLANT 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "SUPERFOSFATO TRIPLE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SUPERFOSFATO TRIPLE"
    },
    {
        "pest": "CLORURO DE POTASIO GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "MAP 11-50-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "DAP GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "NUTRIPLANT * INICIO I GRADO 11-25-15-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIPLANT * 20-3-30 COMPLEMENTO II",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIPLANT * 15-0-37-1 COMPLEMENTO III",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "NUTRIPLANT * 15-15-15-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIPLANT * 10-30-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "NUTRIPLANT * 10-20-23 DESYERBE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-23"
    },
    {
        "pest": "NUTRIPLANT * 12-36-6 EL FOSFATADO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-36-6"
    },
    {
        "pest": "NUTRIPLANT * 15-15-15-3 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIPLANT * 10-40-10 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-40-10"
    },
    {
        "pest": "NUTRIPLANT * 30-3-7-4 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-3-7"
    },
    {
        "pest": "NUTRIPLANT * 10-20-30 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "NUTRIPLANT * 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "NUTRIPLANT * 18-18-18-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NUTRIPLANT * 12-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-34-12"
    },
    {
        "pest": "NUTRIPLANT * 15-4-24-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIPLANT * INICIO II GRADO 10-31-12-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIPLANT * 17-6-18-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "NUTRIPLANT * 25-15-5-3 PASTOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-25-5"
    },
    {
        "pest": "NITROFOSFATO GRADO 32-5-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "TRIPLE 16 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "NITROGEN-K 21-0-21",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "NUTRIFOS  14-34-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "VALLE CAFETERO 25-6-22",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VALLE CAFETERO 28-7-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VALLE CAFETERO 28-4-21",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VALLE CAFETERO 29-4-19",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VALLE CAFETERO 26-8-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VALLE CAFETERO 27-3-23",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "VALLE CAFETERO 24-7-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AGRIFOS 16-20-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NITROCAÑA 34-0-11",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "11-25-14 + Menores",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "13-9-25 + M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRICAFE 18-3-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NITROCAÑA MENORES 30-0-13+M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "20-04-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BALANCE 12-24-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "NIFOKAZ 27-6-6-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRI3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIGRASS 35-5-3-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "N-FOS 20-20-0-10 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "PASTOREO 33-10-0-5 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIPAL 13-6-23-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-6-23"
    },
    {
        "pest": "NUTRIBAN 15-4-23-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-4-23"
    },
    {
        "pest": "DESPEGUE 28-17-0-6 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRICAFFI 22-3-18-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KCL STANDAR 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "OILKEM 17-0-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "TRIPLE 15 GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "TRIPLE 16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "TRIPLE 15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "AGROCELL 20 W NUTRIENTE CELULAR PK",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TRIPLE 16 (16-16-16)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FERTILIZANTE 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) (18-46-0)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "MAP PORVENIR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "TRIPLE 15 (15-15-15)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "PRODUKIN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFAN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "SOLUDRENCH",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "POLY - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "KCL (CLORURO DE POTASIO ) GRANULAR 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "KCL (CLORURO DE POTASIO ) SOLUBLE 0-0-62",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "SULFATO DE POTASIO GRANULAR 0-0-50-17 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "MAP (FOSFATO MONOAMONICO) GRANULAR 11-52-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "DAP (FOSFATO DIAMONICO) GRANULAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA PERLADA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA GRANULAR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NUTRIFERT F-17 17-6-18-7 + 8,5%S",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "NUTRIFERT T-15-7S 15-15-15-6 + 7,5%S",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIFERT 15-4-23-4 + 5%S",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-4-23"
    },
    {
        "pest": "NUTRIFERT SIR 24 12-20-12-10 (CaO) + 1,5(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-24-12"
    },
    {
        "pest": "NUTRIFERT FASE CRECIMIENTO 27-17-0-6 (CaO) + 1(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIFERT PRODUCCION 24-3-20-3 + 4(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIFERT 13-34-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIFERT T-18-2 GRADO 18-18-18-2 + 2,5%(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NUTRIFERT TREINTA Y 1 GRADO 31-8-8-2 + 3%(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "31-8-8"
    },
    {
        "pest": "NUTRIFERT 11-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIFERT HOJAS ANCHAS 33-0-0-12(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "NUTRIFERT 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "NUTRIFERT ARABIGO 21-3-17-6 + 7(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "21-3-17"
    },
    {
        "pest": "NUTRIFERT 32-15-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "32-15-6"
    },
    {
        "pest": "NUTRIFERT FASE FINAL 35-0-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "35-0-15"
    },
    {
        "pest": "NUTRIFERT 30 VERDE 30-0-7-6 + 7(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "NUTRIFERT 10-20-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "NUTRIFERT T-15 COMBATE 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIFERT NITRO SAMMY 40-0-0-6 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "NUTRIFERT 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "NITRO POT 23-0-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-30"
    },
    {
        "pest": "NUTRIFERT LOTE VERDE 30-3-0-9 (CaO) + 1,5(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIFERT NITRO YESO 30-0-0-9 (CaO) + 5(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "30-0-0"
    },
    {
        "pest": "NUTRIFERT N-CA-S 30-10-0-5 (CaO) + 3(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NUTRIFERT CICLO 3 GRANULAR 24-0-17-5 + 7(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "24-0-17"
    },
    {
        "pest": "NUTRIFERT 10-20-20-5 + 6(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "NUTRIFERT TRECE 26 13-26-6-5 + 6(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-6"
    },
    {
        "pest": "TECNIFEED SULPOMAG PR 0-0-21-17",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "EURO TECH NPK 3 - QUINCE 15-15-15-8(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIFERT Q + S PRODUFER 21-4-19-2 (CaO) - 1,5(S) (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIFERT Q + S PRIMERA ABONADA 13-26-10-5 + 6(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-26-10"
    },
    {
        "pest": "EURO TECH NPK PRODUCCION 19 19-9-19",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "EURO TECH NPK 27 27-6-6-2 (CaO) + 2(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "EURO TECH 16-16-16 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "NUTRIFERT Q + S SEGUNDA ABONADA 12-10-30 + 1(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "EURO TECH NPK 3 DIEZ Y SEIS 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "EURO TECH NPKS 27 27-6-6-2 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "NUTRIFERT TRI - 15 15-15-15-1 + 1(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "NUTRIFERT T - 18 + BORO-ZINC 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "TECNIFEED NITRO XTEND 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TECNIFEED NITRO XTEND + K 30-0-17-6(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "TECNIFEED NITRO XTEND + S 40-0-0-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "NITRO XTEND + Mg  39-0-3-2 (MgO) + 3(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "CLORURO DE POTASIO (KCL) GRANULADO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "GRANUFOS - 20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FUENTE Ca"
    },
    {
        "pest": "GRANUFOS - 40",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-40-0"
    },
    {
        "pest": "CLORURO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FERTILIZANTE PQP GRADO 15-3-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA P.Q.P.",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA RECUBIERTA CON AZUFRE PQP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FERTILIZANTE PQP GRADO 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "TRICHODEX * TRICHO K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "Trichodex * PHYTOFOS K-B",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "ANTI-D",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES K"
    },
    {
        "pest": "QUIMIANDINA  HIDRO - POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-3-43"
    },
    {
        "pest": "RAIZET",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSQUIMINT 36",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "FOSQUIMINT 43",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "QUIMINT 8-21-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "QUIMINT 15-11-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "QUIMIFER * NITRATO DE POTASIO GRADO 13-0-44",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "QUIMIFER * PASTOS - N GRADO 22-6-3 + SEC. Y MENOR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "QUIMIFER * QUIMAG - K",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-0-30"
    },
    {
        "pest": "QUIMIFER * SULFATO DE POTASIO GRADO 0-0-50",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "QUIMIRRIEGO INICIO 15-18-12-2 (MGO)+ ELEMENTOS MEN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "QUIMIRRIEGO PRODUCCION 12-5-30-2 (MGO) + ELEMENTOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "CLORURO DE POTASIO RB",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FERTILIZANTE 18-18-18 RB",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "FERTILIZANTE 10-20-20 RB",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "RIOPASTOS 28-4-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "28-4-0-6"
    },
    {
        "pest": "FERTIPLANT-INICIO 13-36-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTIPLANT-PRODUCCION 10-5-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "ABOFERTIL F.C. GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "ABOFERTIL CF GRADO 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "ABOFERTIL CF GRADO 17-6-18-4 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "ABOFERTIL CF GRADO 10-30-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-30-10"
    },
    {
        "pest": "ABOFERTIL CF GRADO 25-15-0-3 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "FERTIFLORA FLORACION 10-30-10-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTIFLORA FOLLAJE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "MONTYIS * 8-16-8",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MONTYIS * 4-15-12",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "OASIS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "N-YOORIN S",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "FOSFAMON",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "NITROPOT",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "GROW MORE 5-0-48",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GROW MORE 20-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GROW MORE 30-10-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "GROW MORE 10-50-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PHOSPRO 0-25-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "KCL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "UREA 46%",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "A - MICSUR-S*",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "GREENZIT FOSILAN 70",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "AGROCOMTA 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "POTAFOS * 0-40-53",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "FOSS 61 GRADO 12-61-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "CRECIMIX GRADO 38-6-3 + SECUNDARIOS Y MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSSFITO 0-30-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "FRUTOS 0-3-40",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "DESARROLLO SN",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "STARTAZO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "NITRO CROP 23 *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "23-0-0"
    },
    {
        "pest": "PRO-START *",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "22-0-0"
    },
    {
        "pest": "KOKEI NUGGET",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FIELD KING",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UR-FOS * 44 (UREA-FOSFATO) GRADO 18-44-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "KLINGQUEL RAICES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FERTILIZANTE 10-20-20",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-20"
    },
    {
        "pest": "FOSFATO DE AMONIO (18-46-0)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "CLORURO DE POTASIO (0-0-60)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "TRIPLE 16 (16-16-16)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "TRIPLE 15 TECNOINSUMOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "PRODUCCION TECNOINSUMOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18"
    },
    {
        "pest": "DAP TECNOINSUMOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA PRILLED TECNOINSUMOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NITRATO DE POTASIO TECNOINSUMOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "FORTEC POTASIO 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "FORTEC CALCIO 0-56-0-23 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "FORTEC PAPA LLENADO 10-17-25-8 (CaO) - 1 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FORTEC PARA ARRANQUE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "UREA 46 % GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "SULFAMMO* 26N 26-0-0-3 (MgO) - 7 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "BASIFERTIL TOUT  8-19-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SULFAMMO* 16N 16-0-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "SULFAMMO* 10N 10-5-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BASIFERTIL EXPERT  6-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BASIFERTIL OPTI PREMIUM 7-19-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "BASIFERTIL OPTI ABONDANCE 5-13-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BASIFERTIL OPTI BASIMMO 12-8-8",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "LITHO FERTIL SD 8-18-14",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "FOSFATO MONOAMONICO (MAP)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOAMONICO (MAP)"
    },
    {
        "pest": "TM NITRATO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "T.M. SULFATO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "TM ACIDO FOSFORICO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "ACIDO FOSFORICO"
    },
    {
        "pest": "TM UREA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "BASIFERTIL ABONDANCE 6-24-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "SULFAMMO* 26N 26-0-0-3 (MgO) - 7 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "SULFAMMO* 10N 10-5-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KSC PHYT - actyl III 15-5-35",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KSC PHYT - actyl I 14-40-5 + Secundarios y Menores",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KSC PHTY - actyl V 8-16-42",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MICRO NP",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "KENDAL NEM",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "CARVASOL",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS PK"
    },
    {
        "pest": "VARIFOS P10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "0-25-0"
    },
    {
        "pest": "VARIFOS",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES P"
    },
    {
        "pest": "UREA AGRICOLA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "VITABONO * NITRATO DE POTASIO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "VITABONO * 25-15-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "VITABONO * 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "VITABONO * FOSFATO MONOPOTASICO 0-52-34",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO MONOPOTASICO"
    },
    {
        "pest": "VITABONO * NKS 14-0-42",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "CLORURO DE POTASIO GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "FOSFATO DIAMONICO (DAP) GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "UREA 46 %",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TRIPLE 15 GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "FERTISOL* INICIO 10-40-10  + MICRONUTRIENTES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-40-10"
    },
    {
        "pest": "FERTISOL* CUAJE 10-20-30 + MICRONUTRIENTES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-20-30"
    },
    {
        "pest": "FERTISOL* FRUTO  10-10-40 + MICRONUTRIENTES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "10-10-40"
    },
    {
        "pest": "FERTISOL* CRECIMIENTO  30-10-10 + MICRONUTRIENTES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTISOL* FLORACION 20-20-20 + MICRONUTRIENTES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FERTID'OR 25-10-15-2,5 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "ACID OR*  26-0-0-19 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "UREA 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "NITROMAG * NITRATO DE AMONIO Y MAGNESIO GRADO 22",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "22-0-0"
    },
    {
        "pest": "DAP (FOSFATO DIAMONICO) GRADO 18-46-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "FOSFATO DIAMONICO (DAP)"
    },
    {
        "pest": "KCl (CLORURO DE POTASIO GRANULADO) GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "NPK 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "YARAVERA AMIDAS * (UREA-SULFATO DE AMONIO) GRADO 40-0-0-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "40-0-0"
    },
    {
        "pest": "KRISTALON * ETIQUETA VERDE  GRADO 18-18-18 + MICR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "KRISTALON * ETIQUETA AZUL  GRADO19-6-20-3 + MICRO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTALON * AMARILLO  GRADO 13-40-13 + MICRONUTRI",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTALON * NARANJA GRADO 6-12-36-3 + MICRONUTRIE",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTALON * ETIQUETA BLANCA GRADO 15-5-30-3 + MIC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTALON * ROJO GRADO 12-12-36-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDRAN * GRADO 19-4-19-7(S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-4-19"
    },
    {
        "pest": "TRIPLE HYDRO GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "KRISTA-K * GRADO 13-0-45",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "NITRATO DE POTASIO"
    },
    {
        "pest": "MEZCLAS NORDICAS PHOSNITRO * GRADO 25-15-0-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-15-0"
    },
    {
        "pest": "MEZCLAS NORDICAS BANYPAL * GRADO 13-4-23-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-4-23"
    },
    {
        "pest": "FERTIGUINEO * GRADO 11-3-36",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "11-3-36"
    },
    {
        "pest": "MEZCLAS NORDICAS TRIPLE VIKINGO 15 * GRADO 15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "HYDROCAFETERA * GRADO 15-7-18-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-7-18"
    },
    {
        "pest": "MEZCLAS NORDICAS MAGNITRO-K * GRADO 11-0-30-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "11-0-30"
    },
    {
        "pest": "MEZCLAS NORDICAS EL RENOVADOR * GRADO 12-21-21",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-21-21"
    },
    {
        "pest": "MEZCLAS NORDICAS SOLUBAN * GRADO 13-2-30-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-2-30"
    },
    {
        "pest": "MEZCLAS NORDICAS \"EL AMIGO\" * GRADO 14-30-15-1",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-30-15"
    },
    {
        "pest": "HYDROMEZCLA * AGROHYDRO GRADO 18-5-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDROGENERATIVO * GRADO 8-5-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDROMEZCLA * HYDRO MILENIO GRADO 24-4-23",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MEZCLAS NORDICAS TRADICIËN CAFETERA GRADO 17-6-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "17-6-18-2"
    },
    {
        "pest": "HYDROMEZCLA * FERTIHYDRO GRADO 12-2-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDROMEZCLA * NITROHYDROS GRADO 29-8-10",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDROMEZCLA * AGRAN K GRADO 17-0-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "HYDROMEZCLA * NITROKALCYB GRADO 10-0-36",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "HYDROMEZCLA * HYDROHATO GRADO 25-15-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDROMEZCLA * AGROPALYBAN GRADO 16-1-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "HYDROMEZCLA * NITROKABOR 12-0-30",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "HYDROMEZCLA * NITROBOROKA GRADO 20-0-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "AMIDITAS *  42-0-0-8 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "SULFATO DE POTASIO  0-0-50-18 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "TRIPLE NORUEGO 15 GRADO 15-15-15-3 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "HYDRO AVANCE 1 GRADO 14-30-15 + MENORES",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "14-30-15"
    },
    {
        "pest": "HYDRO 18 GRADO 18-18-18 + BORO Y ZINC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "HYDROMEZCLA * TRIPLE 18 GRADO 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "HYDRO 16  GRADO 16-16-16",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-16-16"
    },
    {
        "pest": "HYDROMEZCLA ZONA CAFETERA GRADO 25-4-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "25-4-24"
    },
    {
        "pest": "HYDROMEZCLA HYDRON * GRADO 19-4-19-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-4-19"
    },
    {
        "pest": "YARAMILA * HYDROCOMPLEX * GRADO 12-11-18-3 + E. M",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "12-11-18"
    },
    {
        "pest": "SULFATO DE POTASIO GRADO 0-0-50-18 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "FERTI - SPO",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "KRISTA K GR GRADO 13-0-44",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-0-44"
    },
    {
        "pest": "KRISTASOL * MULTIPROPOSITO GRADO 18-18-18-1 + MIC",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTASOL * CRECIMIENTO GRADO 25-10-10-1 + MICRON",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTASOL * DESARROLLO GRADO 18-6-18-2 + MICRONUTR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTASOL * INICIAL GRADO 15-30-15 + MICRONUT",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-30-15"
    },
    {
        "pest": "KRISTASOL * PRODUCCION GRADO 13-6-40 + MICRON",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-6-40"
    },
    {
        "pest": "YARAMILA * HYDRAN GRADO 19-4-19-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "19-4-19"
    },
    {
        "pest": "KCL  ESTANDAR GRADO 0-0-60",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "CANS * 22 GRADO 22-0-0-6",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "22-0-0"
    },
    {
        "pest": "MEZCLAS NORDICAS ARROZTOL * 18-18-18-2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    },
    {
        "pest": "NITROMAGK * 21",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "YARA BELA  * NITROMAG * GRADO 21-0-0-7",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "HYDROGAND * GRADO 30-12-8",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MEZCLAS NORDICAS PALYBAN * GRADO 15-3-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "KRISTASOP 52 GRADO 0-0-52-18 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "SULFATO DE POTASIO"
    },
    {
        "pest": "KRISTA-KP GRADO 13-3-43",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "13-3-43"
    },
    {
        "pest": "AGRONAS GRADO 26-0-0-13 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "26-0-0"
    },
    {
        "pest": "NITRAMAG GRADO 10-0-0-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "MEZCLAS NORDICAS NITRAMID",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "KASOL 62 (CLORURO DE POTASIO ESTANDAR)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "CLORURO DE POTASIO (KCl)"
    },
    {
        "pest": "YARA * UREA GRADO 46-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "UREA"
    },
    {
        "pest": "TEPROSYN Zn/P",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NP"
    },
    {
        "pest": "MEZCLAS NORDICAS * TABACOLTA GRADO 16-6-18-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MEZCLAS NORDICAS * FERTABACO GRADO 16-6-17-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-6-17"
    },
    {
        "pest": "MEZCLAS NORDICAS * FERTABACO 1 GRADO 15-6-17-4",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MEZCLAS NORDICAS * TABACALERA GRADO 12-8-18-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MEZCLAS NORDICAS * TABACALERA 1 GRADO 12-8-18-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "TRIAN * QUINCE GRADO 15-15-15",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "15-15-15"
    },
    {
        "pest": "YARAMILA * ACTYVA 27 GRADO 27-5-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "27-5-5"
    },
    {
        "pest": "MEZCLAS NORDICAS * TABACOL GRADO 16-6-17-3",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "16-6-17"
    },
    {
        "pest": "MEZCLAS NORDICAS * AVANCE 3 GRADO 24-0-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "KRISTA KS GRADO 9-0-47-5 (S)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "MEZCLAS NORDICAS SEMBRADOR GRADO 12-27-13-5",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FEDEPAPA CALIDAD GRADO 12-8-28",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "FEDEPAPA PRODUCCION",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "YaraMila INTEGRADOR",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PALMY GRADO 13-6-24",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BANI GRADO 16-3-27",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "PAPOSA",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AMIDUR GRADO 43-0-0",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTRAS FUENTES N"
    },
    {
        "pest": "NITRAMID K 15-0-30-6 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "KALCIB Grado 14-0-22-13 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NK"
    },
    {
        "pest": "CAFETAL Grado 16-6-18-3 (MgO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "BANOSO Grado 14-4-25-2 (MgO) - 3 (CaO)",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "MEZCLAS NORDICAS TABACOL 2",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "OTROS COMPUESTOS NPK"
    },
    {
        "pest": "AMID 18-18-18",
        "activeIngredient": "De acuerdo al Analísisde De Suelos y Plan de Nutrición Definido.",
        "Dose": "18-18-18"
    }
]
