<template>

    <v-form :valid="areCardFormsValid" ref="growingAndApplications">
      <v-container fluid class="green lighten-4 text-left">
        <v-row class="text-center">
          <v-divider></v-divider>
        </v-row>

        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-card-title>{{
              formFields.register.seedlings.question
            }}</v-card-title>
            <small v-if="formFields.register.seedlings.answer.length === 0 && shouldValidate" style="color: #ff5252" class="form-text text-muted">
              Field cannot be empty
            </small>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.seedlings.answer" />
          </v-col>
        </v-row>
        <v-row class="blue lighten-5">
          <v-col cols="12">
            <v-card-title>{{
              formFields.register.fieldChemicals.question
            }}</v-card-title>
            <small v-if="formFields.register.fieldChemicals.answer.length === 0 && shouldValidate" style="color: #ff5252" class="form-text text-muted ">
              Field cannot be empty
            </small>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.fieldChemicals.answer" />
          </v-col>
        </v-row>

        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-card-title>{{
              formFields.register.transplantingChemicals.question
            }}</v-card-title>
            <small v-if="formFields.register.transplantingChemicals.answer.length === 0 && shouldValidate" style="color: #ff5252" class="form-text text-muted ">
              Field cannot be empty
            </small>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.transplantingChemicals.answer" />
          </v-col>
        </v-row>
        <v-row class="blue lighten-5">
          <v-col cols="12">
            <v-card-title>{{
              formFields.register.growingChemicals.question
            }}</v-card-title>
            <small v-if="formFields.register.growingChemicals.answer.length === 0 && shouldValidate" style="color: #ff5252" class="form-text text-muted ">
              Field cannot be empty
            </small>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.growingChemicals.answer" />
          </v-col>
        </v-row>
        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-card-title>{{
              formFields.register.nutritionPlan.question
            }}</v-card-title>
            <small v-if="formFields.register.nutritionPlan.answer.length === 0 && shouldValidate" style="color: #ff5252" class="form-text text-muted ">
              Field cannot be empty
            </small>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.nutritionPlan.answer" />
          </v-col>
        </v-row>

        <v-row class="blue lighten-5">
          <v-col cols="12">
            <v-card-title>{{
              formFields.register.biologicalInputsPercentage.question
            }}</v-card-title>
            <small v-if="formFields.register.biologicalInputsPercentage.answer.length === 0 && shouldValidate" style="color: #ff5252" class="form-text text-muted ">
              Field cannot be empty
            </small>

          </v-col>
          <v-col cols="12">
            <Pests
                v-model="formFields.register.biologicalInputsPercentage.answer"
            />
          </v-col>
        </v-row>
        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-text-field
              v-model="formFields.register.listChallenges.answer"
              type="text"
              :label="formFields.register.listChallenges.question"
              :hint="formFields.register.listChallenges.question"
              :rules="[rules.requiblue]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

</template>

<script>
import Pests from "@/views/partners/hr_co/components/Pests";

export default {
  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!to) {
        } else {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },
  components: {Pests},
  data() {
    return {
      validForm: false,
      shouldValidate: false,
      formFields:{
        register: {
          seedlings:{
            question: "1 ¿Cuales insumos se utilizaron en los semilleros?",
            answer: []
          },
          fieldChemicals:{
            question: "2 ¿Cuales insumos utilizó en la preparación del terreno?",
            answer: []
          },
          transplantingChemicals:{
            question: "3 ¿Cuales insumos utilizó en la preparación de las plántulas para la siembra?",
            answer: []
          },
          growingChemicals:{
            question: "4 ¿Cuales insumos utilizó durante el desarrollo del cultivo hasta Cosecha?",
            answer: []
          },
          nutritionPlan:{
            question: "5 ¿Cuál plan de nutrición utilizó durante el desarrollo del cultivo?",
            answer: []
          },
          biologicalInputsPercentage:{
            question: "6 ¿Cuál es el porcentaje de Insumos Biológicos aplicados?",
            answer: []
          },
          listChallenges: {
            question: '7 Describa las dificultades/retos encontrados.',
            answer: undefined
          }
        }
      },
      rules: {
        requiblue: (value) => !!value || "Requerido",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
      }
    }
  },
  computed: {
    areCardFormsValid() {
      const invalidAnswer = Object.values(this.formFields.register)
          .map(formField => formField.answer)
          .filter(formField => !!formField && formField.length > 0)
          .flatMap(formField => formField)
          .find(formField => !formField.quantity)

      return !invalidAnswer;
    }
  }
}
</script>

<style scoped>

</style>
