<template>
  <div>
    <Phase1Section1Form
        v-model="formFields.register"
        :field="field"
        :customer="customer"
        ref="form1"
    />

    <Phase1Section2Form
        v-model="formFields.irrigation"
        :customer="customer"
        :field="field"
        :distanceBetweenRows="formFields.register.distanceBetweenRows.answer"
        :fieldArea="field.fieldSize.landSize"
        ref="form2" />

    <v-divider></v-divider>

    <Phase1Section3Form
        v-model="formFields.plasticMulch"
        :field="field"
        :customer="customer"
        ref="form3"
    />

  </div>

</template>


<script>
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import Phase1Section1Form from "./Phase1Section1Form";
import Phase1Section2Form from "./Phase1Section2Form";
import Phase1Section3Form from "./Phase1Section3Form";
import {
  phase1Irrigation, phase1PlasticMulch,
  phase1registerFormFields
} from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseOne/phase1FormFieldsData";

export default {
  components: { Phase1Section1Form, Phase1Section2Form, Phase1Section3Form},
  props: ['value', 'field', 'customer'],
  watch: {
    value: {
      immediate: true,
      handler(to) {
        // console.log('handler', to)
        if (!to) {
        } else {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
    'formFields.register.distanceBetweenPlants.answer': function(newValue) {
      if (!newValue) {
        return ''
      }
      this.formFields.plasticMulch.distanceBetweenHoles.answer = newValue
    },
  },
  data() {
    return {
      formFields: {
        register: Object.assign(phase1registerFormFields, {}),
        irrigation: Object.assign(phase1Irrigation, {}),
        plasticMulch: Object.assign(phase1PlasticMulch, {}),
      },
      sharedValidations: new HugoValidations()
    }
  },
  methods: {
    validate() {
      this.$refs.form1.validate();
      this.$refs.form2.validate();
      this.$refs.form3.validate();
    },
  },
}

</script>

<style scoped>

</style>