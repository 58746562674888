<template>
  <div>
    <div class="d-lg-flex">
      <v-combobox
        :items="partnerVillages"
        v-model="formFields.village"
        :label="'Village'"
        class="mr-6"
      ></v-combobox>

      <v-combobox
        :items="wardOptions"
        :label="'Ward'"
        v-model="formFields.ward"
        class="mr-6"
      ></v-combobox>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      formFields: {},
      wardOptions: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.formFields = newValue;
      },
    },
    'formFields.village': function (newVillage) {
      this.updateWardList(newVillage);
    },
  },
  computed: {
    partnerRawRegions() {
      return this.$store.getters.settings.regions
    },
    partnerVillages() {
      return Object.keys(this.partnerRawRegions);
    },
  },
  methods: {
    updateWardList(village) {
      if (village && this.partnerRawRegions[village]) {
        this.wardOptions = Object.keys(this.partnerRawRegions[village].wards);
      } else {
        this.wardOptions = []; 
      }
    },
  },
};
</script>

<style scoped>
</style>
