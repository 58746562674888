<template>
  <v-container>
    <v-card class="mx-auto" outlined >
      <v-list-item>

        <v-list-item-content>

          <div class="text-overline mb-4">
            <Time :time="date" format="LLLL" />
          </div>

          <v-row>

            <v-col v-if="false">
                <v-list-item-title class="text-h5">
                  {{docSold}}
                </v-list-item-title>
                <v-list-item-subtitle>Day Old Chicks Sold</v-list-item-subtitle>
            </v-col>

            <v-col>
                <v-list-item-title class="text-h5">
                  {{activeVas}}
                </v-list-item-title>
                <v-list-item-subtitle>Village Ambassadors</v-list-item-subtitle>
            </v-col>

          </v-row>

          <v-list-item v-if="false">
            <v-container>
              <v-row no-gutters>
                <v-col cols="4">
                  <div>{{agents}}</div>
                  <div class="text-caption">Agents</div>
                </v-col>
                <v-col cols="4">
                  <div>{{newAgents}}</div>
                  <div class="text-caption">New Agents Recruited</div>
                </v-col>
                <v-col cols="4">
                  <div>{{weeksCycleTime}}</div>
                  <div class="text-caption">Weeks Avg Cycle Time</div>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>


          <v-list-item>
            <v-container>
              <v-row no-gutters>
                <v-col cols="4">
                  <div>{{vas}}</div>
                  <div class="text-caption">VA's</div>
                </v-col>
                <v-col cols="4">
                  <div>{{newVas}}</div>
                  <div class="text-caption">New VA's Recruited</div>
                </v-col>
                <v-col cols="4">
                  <div>{{mocSold}}</div>
                  <div class="text-caption">Total MOCs Sold</div>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>


          <div class="text-overline mb-4">
            <span v-if="refreshing">Refreshing...</span>
            <span v-else>Last refreshed: <Time v-if="date" :time="date" format="ago" update /></span>
          </div>

          <v-progress-circular indeterminate color="primary" :size="20" :width="2" v-if="refreshing" />

        </v-list-item-content>

      </v-list-item>
    </v-card>

    <v-card class="mx-auto my-4" outlined >
      <div class="mt-2">MOCs Dispatched</div>
      <column-chart :data="vaChartData" legend="bottom"></column-chart>
    </v-card>

  </v-container>
</template>

<script>
  import lookups from '@/mixins/lookups';

  import Time from '@/components/Time';

  export default {

    components: { Time },

    data: () => ({
      refreshing: true,
      // vaChartDataX: [
      //   {name: 'You',      data: { 'May': 3*Math.random(), 'Jun': 4*Math.random(), 'Jul': 6*Math.random(), 'Aug': 7*Math.random(), 'Sep': 8*Math.random(), 'Oct': 9*Math.random()} },
      //   {name: 'Cluster',  data: { 'May': 5*Math.random(), 'Jun': 3*Math.random(), 'Jul': 6*Math.random(), 'Aug': 7*Math.random(), 'Sep': 8*Math.random(), 'Oct': 9*Math.random()} },
      //   {name: 'National', data: { 'May': 7*Math.random(), 'Jun': 1*Math.random(), 'Jul': 6*Math.random(), 'Aug': 7*Math.random(), 'Sep': 8*Math.random(), 'Oct': 9*Math.random()} },
      // ],
    }),

    computed: {
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
      report() { return this.$store.getters.landingPageCache; },
      date() { return (this.report || {}).generated_at },

      docSold() { return (this.report || {}).doc_sold; },
      agents() { return (this.report || {}).agents;  },
      newAgents() { return (this.report || {}).new_agents; },
      weeksCycleTime() { return (this.report || {}).weeks_cycle_time; },
      activeVas() { return (this.report || {}).active_vas; },
      vas() { return (this.report || {}).vas; },
      newVas() { return (this.report || {}).new_vas; },
      mocSold() { return (this.report || {}).moc_sold; },

      vaChartData() { return (this.report || {}).va_chart_data; },

    },

    watch: {
      report: function(to) { this.refreshing = (to ? false : true); },
    },

    mounted() {
      this.remoteLookup('uzimaUgLanding', { fieldAgentId: this.fieldAgentId }).then( report => this.$store.dispatch('updateLandingPageCache', report) ).catch(e => this.refreshing = false );
    },

    mixins: [lookups],

  };
</script>
