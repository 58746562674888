<template>

  <v-hover v-slot="{ hover }" style="width:100%;">
    <div class="mr-2">

      <v-card outlined class="mx-1 my-2 px-2 py-1 d-none d-sm-block" :class="color" :elevation="hover ? 8 : 0">
        
        <v-container fluid class="pa-0" style="width:100%;">
          <slot />
        </v-container>
      </v-card>

      <div class="mx-0 my-1 pa-0 d-block d-sm-none" >
        <v-container fluid class="pa-1 ma-0" :class="color">
          <slot />
        </v-container>
        <v-divider class="mt-1"></v-divider>
      </div>

    </div>
  </v-hover>

</template>

<script>
export default {
  props: ['color']
};
</script>
