<template>
  <v-container fluid class="pa-0">

    <v-row v-if="customersInitialised">
      <v-col cols=12>

        <div v-if="customersInitialised && customers.length==0">
          <div class="mb-2">
            {{$t('noData')}}
          </div>
          <div>
            <v-btn elevation="2" small :to="{ name: 'CreateContact' }" color="primary">Create One</v-btn>
          </div>
        </div>
        
        <div class="pa-0 ma-0" v-else>
          <v-tabs v-model="tab" class="px-0">
            <v-tab v-if="tabs.includes('farmersInProgress') && farmersInProgress.length>0">
              <v-icon left>mdi-account-question</v-icon> {{$t('In progress')}}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-if="tabs.includes('farmersInProgress') && farmersInProgress.length>0"><Farmers :farmers="farmersInProgress" /></v-tab-item>
          </v-tabs-items>
        </div>

      </v-col>

      <v-snackbar bottom right :value="false" :color="( balances===null ? 'error' : 'primary')" :timeout="-1">
        <span v-if="balances===undefined">
          Refreshing balances...
        </span>
        <span v-else>
          Unable to retrieve balances
        </span>
      </v-snackbar>

    </v-row>

    <v-row align="center" justify="center" v-else>
      <v-col cols=3 />
      <v-col cols=6 align="center" justify="center" >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col cols=3 />
    </v-row>

    <v-row class="mx-4 my-4">
      <v-col cols="12">
        <v-btn color="primary" @click="addNewFarmer" > Add a new farmer </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Farmers from '@/views/farmers/components/Index';

export default {
  components: {
    Farmers
  },

  data: () => ({
    tab: null,
    balances: undefined,
  }),

  computed: {
    customersInitialised() { return this.$store.getters.customersInitialised; },
    customers() { return this.$store.getters.customers.filter( e => !!e.msisdn || !!e.partnerIdentifier || !!e.name || !!e.email ); },
    tabs() {
      return ['farmers', 'farmersInProgress'];
    },
    farmers() { 
      // return all customers finished and not
      return this.$store.getters.customers.filter(e => !!e.firstName );
     },
    farmersInProgress() {
      // return un finished customers, we'll use customer_at
      return this.$store.getters.customers.filter(e => !e.customer_at).reverse();
    },
  },

  methods: {
    addNewFarmer() {
      this.$router.push({ name: 'CreateContact' });
    }
  }
}
</script>

<style>

</style>