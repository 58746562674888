<template>

    <v-card outlined>

      <v-card-title>{{title}}</v-card-title>

      <v-card-text>
        <slot name='Inputs' />
      </v-card-text>

      <v-card-actions>
        <v-btn outlined rounded text @click="initiateTest">
          Test
        </v-btn>
      </v-card-actions>

      <v-card-text v-if="runTest">
        <v-row>
          <v-col cols="2">Result</v-col>
          <v-col cols="10">
            <ServerSideResult :test="results">
              <slot name='Results' />
            </ServerSideResult>
          </v-col>
        </v-row>
      </v-card-text>


    </v-card>


  </v-container>
</template>



<script>

  import ServerSideResult from '@/components/ServerSideResult';

  export default {

    props: {
      results: {},
      title: { type: String, default: 'Test' },
    },


    components: { ServerSideResult },

    data: () => ({
      runTest: false,
    }),

    methods: {
      initiateTest(farmer) {
        this.$emit('test');
        this.runTest = true;
      },
      reset() {
        this.runTest = false;
      },
    },

  }
</script>
