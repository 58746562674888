<template>
  <span v-if="time">{{ time | time(format) }}</span>
</template>

<script>
  import { DateTime } from 'luxon';
  export default {

    props: {
      time: {
        type: [String, Number, Object, Date],
        required: false,
      },
      format: {
        type: String,
        default: "d MMM yyyy, HH:mm",
      },
      update: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      timer: undefined,
    }),

    methods: {
      ageTime () { this.$forceUpdate(); },
      cancelAutoUpdate () { if (this.timer) { clearInterval(this.timer); } },
    },
    
    mounted() { if (this.update) { this.timer = setInterval(this.ageTime, 10000); } },
    beforeDestroy() { this.cancelAutoUpdate(); }

  };
</script>