<template>
    <v-input :rules="[requiredRule]" v-model="authCode" class="pa-2" style="width: 100%;">
      <v-card style="width: 100%;" :loading="!authCode" loader-height="4" elevation="4">
  
        <template slot="progress">
          <v-progress-linear color="red" indeterminate v-if="acquisitionStarted"></v-progress-linear>
        </template>
  
        <v-card-text v-if="!connected" class="red--text">Can't connect to fingerprint reader.</v-card-text>
        <v-card-text v-if="!acquisitionStarted" class="red--text">
          Not yet acquiring.
          <v-btn text @click="startCapture" color="red">Retry</v-btn>
        </v-card-text>
  
        <v-img height="220px" :src="image" v-if="image" />
        <v-img height="220px" v-if="acquisitionStarted && !image" />
  
        <v-card-actions v-if="!this.production">
          <v-btn color="error" block @click="cheat">
            Cheat
          </v-btn>
        </v-card-actions>
  
        <v-btn id="start" @click="startCapture">start</v-btn>
        <v-btn id="start" @click="onSamplesAcquired">test</v-btn>
  
      </v-card>
    </v-input>
  </template>
  
  
  <script>
    import prodDebug from "@/mixins/prodDebug";
    import lookups from "@/mixins/lookups";
    import { Base64 } from '@digitalpersona/core';
    import VInput from 'vuetify/lib/components/VInput/VInput.js';
    import { FingerprintReader, SampleFormat } from '@digitalpersona/devices';
  
    export default {
  
      props: {
        value: {
          type: String,
          required: false,
        },
        user: {
          type: Boolean,
          default: true,
        },
        customer: {
          type: Object,
          required: false,
        },
        msisdn: {
          type: String,
          required: false,
        },
      },
  
      data: () => ({
        sample: undefined,
        unavailable: false,
        connected: false,
        reader: undefined,
        acquisitionStarted: false,
        authCode: undefined,
        sequenceNo: undefined,
      }),
  
      computed: {
        image() { return (this.sample ? "data:image/png;base64," + Base64.fromBase64Url(this.sample) : undefined); },
        strategy() {
          let  r = undefined;
          if (this.user) {
            r = 'user';
          } else {
            if (this.customer) {
              r = 'customer';
            } else if (this.msisdn) {
              r = 'msisdn';
            }
          }
          return r;
        },
      },
  
      watch: {
        authCode(to) {
          this.$emit('input', JSON.stringify({authCode: to, sequenceNO: this.sequenceNo}));
        },
        sample(to) {
          console.log('acquired');
          if (this.sample) {
            let params = {
              sample: Base64.fromBase64Url(this.sample),
              strategy: this.strategy,
              msisdn: this.msisdn,
              customer_uuid: (this.customer || {}).uuid,
            }
            this.remotePost('fingerprint_identify', params).then( authCode => {this.authCode = authCode.authCode; this.sequenceNo = authCode.sequenceNo} ).catch(e => this.authCode = null);
          }
        },
      },
  
      methods: {
  
        cheat() {
          this.sample = (new Date()).getTime().toString();
        },
  
        requiredRule(value) {
          let r = [
            v => !!((v || '').trim()) || this.$t('required'),
          ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
          return ( typeof(r)==='string' ? r : true );
        },
  
        onSamplesAcquired(s) {
          this.sample = s.samples[0];
        },
  
        startCapture() {
          console.log('start capture');
          if (this.acquisitionStarted) return;
          // this.reader = new FingerprintReader();
          var currentFormat = SampleFormat.PngImage;
          console.log("reader: ", this.reader);
          console.log("acquisition: ", this.acquisitionStarted);
          this.reader.startAcquisition(currentFormat).then(() => {
            this.acquisitionStarted = true
            console.log("starting the capture process: ")
            //Disabling start once started
            // this.disableEnableStartStop();
          });
        },
      },
  
  
  
      mounted () {
  
        this.reader = new FingerprintReader();
  
        this.reader.onDeviceConnected = (e) => {
              // Detects if the deveice is connected for which acquisition started
          this.connected = true
          console.log("Scan your finger");
          console.log("nothing is beeing logged")
        }
        
        this.reader.onDeviceDisconnected = (e) => {
            // Detects if device gets disconnected - provides deviceUid of disconnected device
          this.connected = false
          console.log("Device disconnected");
          console.log("its not active any more")
          console.log("nothing is beeing logged")
        }
  
        this.reader.onSamplesAcquired = (s) => {
          this.onSamplesAcquired(s);
        }
      
        this.reader.onQualityReported = (quality) => {
          console.log("quality : ", quality.quality);
        }
  
        if (this.production) {
          console.log('start scanning');
          this.startCapture();
        }
        
      },
  
      mixins: [prodDebug, lookups],
  
    }
  </script>