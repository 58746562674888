

export function getPastFiveYears() {

    const allYears = []
    const currentYear = new Date().getFullYear()

    for (let i = (2000); i <= currentYear; i++) {
        allYears.push(i)
    }
    return allYears.reverse()
}

export function generatePastFourYears() {
    var max = new Date().getFullYear()
    var min = max - 4
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

export function summarizeSeasonHarvestTableResults(tableResults) {
    const validationResults ={}

    tableResults.forEach((seasonForm) => {
        if (!seasonForm.year) {
            return
        }
        if (!validationResults[seasonForm.year]) {
            validationResults[seasonForm.year] = [seasonForm.season]
        } else {
            validationResults[seasonForm.year].push(seasonForm.season)
        }
    })
    return validationResults
}
