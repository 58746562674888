<template>

  <SalaryAdvance :customer="customer" v-if="loanType=='InstrumentUltimateTzSalaryAdvance'" />
  <CheckMeBack   :customer="customer" v-else-if="loanType=='InstrumentUltimateTzCheckMeBack'" />
  <InEligible    :customer="customer" v-else />

</template>

<script>
import SalaryAdvance from "@/views/partners/ultimate_tz/SalaryAdvance";
import CheckMeBack from "@/views/partners/ultimate_tz/CheckMeBack";
import InEligible from "@/views/customers/partners/ultimate_tz/components/InEligible";

export default {

  components: { SalaryAdvance, CheckMeBack, InEligible },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    openLoans: {
      type: Array,
      required: true,
    },
  },

  computed: {
    availableLoanTypes() {
      let r = [];
      if ( this.customer.employer.category==='bank' ) {
        r.push('InstrumentUltimateTzCheckMeBack');
      } else {
        r.push('InstrumentUltimateTzSalaryAdvance');
      }
      return r;
    },
    current() { return (this.openLoans || []).map( e => e.type ); },
    // choices() { return this.availableLoanTypes.filter(n => !this.current.includes(n)); },
    eligible() { return ( this.current.filter(n => (n==='InstrumentUltimateTzCheckMeBack' || n==='InstrumentUltimateTzCheckMeBack') ) ).length==0; },
    choices() { return (this.eligible ? this.availableLoanTypes : [] ); },
    loanType() { return this.choices[0] },
  },

}
</script>
