<template>
  <span v-if="valid">
    <v-icon small color="red">mdi-google-maps</v-icon>
    {{ description }}
    <LatLong :location="latLong" v-if="showLatLong" />
  </span>
</template>

<script>

import LatLong from '@/components/LatLong';

export default {

  components: { LatLong },

  props: {
    location: Object,
    short: { type: Boolean, default: false }
  },

  data: () => ({
    description: undefined,
    latLong: undefined,
  }),

  computed: {
    valid() { return (this.description || this.latLong); },
    showLatLong() { return (this.latLong && !this.description); },
  },

  watch: {
    location: {
      immediate: true,
      handler(to) {
        if (to) {
          this.setDescription();
          this.setLatLong();
        } else {
          this.description = undefined;
          this.latLong = undefined;
        }
      }
    },
  },

  methods: {
    setDescription() {
      let d = undefined;
      if (this.location) {
        if (typeof(this.location)==='string') {
          d = this.location;
        } else if (this.location.description) {
          d = this.location.description;
        } else if (this.location.lookupParameters) {
          if (this.location.lookupParameters.address) {
            d = this.location.lookupParameters.address;
          }
        }
      }
      if (d && this.short) { d = d.split(',')[0]; }
      this.description = d;
    },
    setLatLong() {
      let ll = undefined;
      if (this.location.latitude) {
        ll = this.location;
      } else if (this.location.lookupParameters && this.location.lookupParameters.latitude) {
        ll = this.location.lookupParameters;
      }
      this.latLong = ll;
    },
  },

};
</script>
